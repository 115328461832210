import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SIconClose, SMaskStyle } from 'components/theme/CustomModal/CustomModal.styles';
import { CustomText } from 'components/theme/CustomText/CustomText';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { Button } from 'components/theme/Button/Button';
import { images } from 'assets';
import { useTranslation } from 'react-i18next';

interface IStuffSaveAccountErrorModalProps {
  open: boolean;
  onClose: () => void;
}

export const StuffSaveAccountErrorModal = ({ open, onClose }: IStuffSaveAccountErrorModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('addAccount');

  const handleOnContinue = () => {
    onClose();
    navigate(ROUTES.home.path);
  };

  return (
    <CustomModal
      open={open}
      centered
      onCancel={onClose}
      onClose={onClose}
      destroyOnClose
      footer={null}
      maskStyle={SMaskStyle}
      closeIcon={<SIconClose />}
      topPosition="0"
      modalName="stuffSaveAccountError"
    >
      <CustomRow marginBottom={32} justifyContent="center">
        <img src={images.failedVerification} alt="verification" />
      </CustomRow>

      <CustomRow flexDirection="column" justifyContent="flex-start" alignItems="center">
        <CustomText textColor="charcoal" size="xxl" marginBottom={24} font="Poppins">
          {t('addAccount.Error')}
        </CustomText>
        <CustomText textColor="charcoal" marginBottom={32}>
          {t('addAccount.Try again later')}
        </CustomText>
      </CustomRow>

      <Button preset="primary" size="large" onClick={handleOnContinue} marginBottom={24} marginTop={24}>
        {t('addAccount.Back to Home')}
      </Button>
    </CustomModal>
  );
};
