import styled, { css } from 'styled-components/macro';
import { getColor, SCREEN_SIZE, mediaFrom, mediaUpTo, mediaBetween } from 'utils/helpers/styleHelpers';
import { cornerDots, bgCreamCurves } from 'assets/images';

export const SLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${getColor('cream50')} no-repeat;

  ${mediaUpTo(
    SCREEN_SIZE.tablet,
    css`
      background: ${getColor('blue')};

      &.move-money_international-transfer {
        .main-layout__content-wrapper {
          padding-top: 24px;
        }
      }
    `
  )}

  ${mediaBetween(
    SCREEN_SIZE.tablet,
    SCREEN_SIZE.desktop - 1,
    css`
      .desktop-header {
        margin: 0;
        border-radius: 0px 0px 10px 0px;
      }
    `
  )}
`;

export const SContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 40px 0 15px;
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  margin-bottom: -10px;
  overscroll-behavior-y: inherit;
`;

export const SLayoutContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
  background: ${getColor('cream70')};
  border-radius: 25px 25px 0 0;
  background-clip: padding-box;
  box-sizing: border-box;
  overflow: hidden;

  ${mediaUpTo(
    'tablet',
    css`
      &.has-bottom-nav {
        padding-bottom: 72px;
      }

      &.menu-page,
      &.profile-page,
      &.do-more-page,
      &.add-account-needs-page,
      &.add-account-goals-page {
        .main-layout__content-wrapper {
          padding: 20px 0 10px;
        }

        .grid-layout {
          padding: 0 16px;
        }
      }
    `
  )}

  ${mediaFrom(
    'tablet',
    css`
      &.has-bottom-nav {
        padding-bottom: 98px;
      }
    `
  )}

  ${mediaFrom(
    'tablet',
    css`
      background: ${getColor('cream50')} url(${cornerDots}) no-repeat bottom right;

      &.has-bottom-nav {
        padding-bottom: 0;
      }

      &.top-bg-curves {
        background-image: url(${bgCreamCurves});
        background-position: top center;
        background-size: 100% auto;
      }

      &.profile-page,
      &.do-more-page {
        .main-layout__content-wrapper {
          padding: 0;

          .grid-layout {
            padding: 0;
          }
        }
      }

      &.profile-page,
      &.do-more-page {
        background-image: none;
      }
    `
  )}
  
  ${mediaFrom(
    'desktop',
    css`
      &.do-more-page {
        background: ${getColor('cream50')} url(${cornerDots}) no-repeat bottom right;
      }
    `
  )};

  &.layout-content {
    &_bg-white {
      background: ${getColor('white')};
    }
  }
`;
