import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { ISystemProperty } from 'store/user/properties/types';
import { SCustomSheet } from './AvailableDestinationsSheet.styles';
import { DestinationListItem } from './DestinationListItem/DestinationListItem';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  countries?: ISystemProperty[];
  transferTermsAndConditionsHandler?: () => void;
}

export const AvailableDestinationsSheet: React.FC<IProps> = ({ isOpen, onClose, countries, transferTermsAndConditionsHandler }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <SCustomSheet isOpen={isOpen} onClose={onClose} maxHeight="95%" padding="0 20px" paddingTop="32px" paddingBottom="56px">
      <div className="header">
        <Title marginBottom={4}>{t('internationalTransferPage.AvailableDestinations')}</Title>
      </div>

      <div className="body">
        <BodyText size="N" color="charcoal70" lineHeight={1.4} marginBottom={16}>
          {t('internationalTransferPage.CheckTheAvailableTransferMethodsAndService')}
        </BodyText>

        <div className="additional-text">
          <BodyText size="T" lineHeight={1.3} marginBottom={24}>
            <Trans i18nKey="internationalTransferPage.CurrencyExchangeRatesAndAdditionalConversionFees" ns="moveMoney" />
          </BodyText>

          <BodyText size="T" lineHeight={1.3}>
            <Trans i18nKey="internationalTransferPage.LimitOf2TransactionsPerTransferMethod" ns="moveMoney" />
          </BodyText>
        </div>

        <div className="destination-list">
          {countries?.map((item: ISystemProperty) => (
            <DestinationListItem {...item} />
          ))}
        </div>

        <BodyText size="T" color="charcoal70" lineHeight={1.3}>
          <Trans
            i18nKey="internationalTransferPage.InternationalTransferServicesProvidedThroughBancorp"
            ns="moveMoney"
            components={{
              termsLink: <span className="terms-link" onClick={transferTermsAndConditionsHandler} />,
            }}
          />
        </BodyText>
      </div>
    </SCustomSheet>
  );
};
