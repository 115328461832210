import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Icon } from 'components/general/Icon/Icon';
import { Title, BodyText } from 'components/general/Typography';
import { setShowComingSoonModal, setShowMobileCheckComingSoonModal } from 'store/ui.slice';
import { TIconName } from 'components/general/Icon/Icon.types';
import { IconSign } from 'components/general/Icon/IconSign';
import { SBar } from './Bar.style';

interface IBarProps {
  title: string;
  subtitle?: string;
  iconName: TIconName;
  to?: string;
  isComingSoon?: boolean;
  onClickInfo?: () => void;
  hasInfoIcon?: boolean;
  onClick?: () => void;
  className?: string;
  hasFeeIcon?: boolean;
  feeIconText?: string;
  isMobileCheck?: boolean;
}

export const Bar = ({ title, subtitle, iconName, to, isComingSoon = false, className = '', onClick, hasInfoIcon, onClickInfo, hasFeeIcon, feeIconText, isMobileCheck = false }: IBarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobileSmallSize } = useDeviceDimension();

  const openComingSoonModal = (): void => {
    dispatch(setShowComingSoonModal(true));
  };

  const openMobileCheckComingSoonModal = (): void => {
    dispatch(setShowMobileCheckComingSoonModal({ displayMobileCheckComingSoonModal: true }));
  };

  const handleClickInfo = (event: MouseEvent) => {
    event.stopPropagation();
    onClickInfo?.();
  };

  const handleClick = () => {
    if (isMobileCheck && isComingSoon) {
      openMobileCheckComingSoonModal();
      return;
    }

    if (isComingSoon) {
      openComingSoonModal();
      return;
    }

    if (to) {
      navigate(to);
    }

    onClick?.();
  };

  return (
    <SBar onClick={handleClick} className={`bar ${className}`}>
      <div className="left">
        <IconSign iconName={iconName} size={40} />
        <div className="title">
          <div className="heading">
            <Title size="sS" font="DM Sans" fontWeight="B" color="charcoal">
              {title}
            </Title>
            {hasInfoIcon && <Icon name="circleInfo" className="info" color="blue" size="smaller" onClick={handleClickInfo} marginTop={2} />}
            {hasFeeIcon && (
              <div className="fee">
                <Icon name="dollarSign" color="blue" size="smaller" />
                {feeIconText && (
                  <BodyText textType="bodyText" color="charcoal50" size="S" fontWeight="R" lineHeight="16px" paddingRight={8}>
                    {feeIconText}
                  </BodyText>
                )}
              </div>
            )}
          </div>

          {subtitle && (
            <BodyText textType="bodyText" color="charcoal" size="T" fontWeight="R" lineHeight="16px" paddingRight={10} marginTop={5}>
              {subtitle}
            </BodyText>
          )}

          {isComingSoon && (
            <BodyText textType="bodyText" color="charcoal" className="coming-soon" size="S" fontWeight="M" lineHeight="16px" paddingRight={10} marginTop={5}>
              {t('translations:common.ComingSoon')}
            </BodyText>
          )}
        </div>
      </div>

      <Icon name="chevronRight" className="arrow" color="blue" size={isMobileSmallSize ? 'smallest' : 'smaller'} />
    </SBar>
  );
};
