import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Title, BodyText } from 'components/general/Typography';
import { errorWhale } from 'assets/images';
import survey from 'assets/images/survey.png';
import { Button } from 'components/theme/Button/Button';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SCustomModal } from './CashFinishCloseAccountInformationModal.styles';

interface IFinishCloseAccountInformationModal {
  isOpen: boolean;
  onClose: () => void;
  onClickHandler: () => void;
  hasNeedsAccount: boolean;
  hasGoalsAccount: boolean;
  openSurvey: () => void;
  hasSurvey?: boolean;
}

export const CashFinishCloseAccountInformationModal = ({ isOpen, onClose, hasSurvey = false, onClickHandler, hasNeedsAccount, hasGoalsAccount, openSurvey }: IFinishCloseAccountInformationModal) => {
  const { t } = useTranslation('accountInformation');
  const { isDesktopSize } = useDeviceDimension();

  return (
    <SCustomModal
      open={isOpen}
      onCancel={onClose}
      modalName="cashFinishCloseAccountInformation"
      topPosition="7%"
      padding="10px 22px"
      display="none"
      closable
      className="finishCloseAccountInformationModal"
      closeIconColor="charcoal70"
    >
      {isDesktopSize && (
        <div className="center-image">
          <img src={errorWhale} alt="exclamation" />
        </div>
      )}

      <Title color="charcoal" fontWeight="M" size="M" marginBottom={15} font="Poppins" textAlign="start" marginTop={20}>
        {t('accountInformation.WerAreSorrySeeYouGo')}
      </Title>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" textAlign="start" lineHeight={1.4}>
        <Trans i18nKey="accountInformation.TewWillReviewRequestToClose" ns="accountInformation" />
      </BodyText>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" marginBottom={15} marginTop={25} paddingRight={4} font="DM Sans" textAlign="start">
        {t('accountInformation.MonitorYourEmailToCompleteRequiredSteps')}
      </BodyText>
      {hasSurvey && (
        <>
          <div className="center-image">
            <img src={survey} alt="exclamation" />
          </div>
          <BodyText size="N">{t('accountInformation.FeedbackImportant')}</BodyText>

          <Button preset="primary" size="large" onClick={openSurvey} marginTop={32}>
            {t('accountInformation.TakeSurvey')}
          </Button>
        </>
      )}
      <Button preset={hasNeedsAccount && hasGoalsAccount ? 'outline' : 'primary'} size="large" marginBottom={15} onClick={onClickHandler} marginTop={25}>
        {hasSurvey && hasNeedsAccount && !hasGoalsAccount ? t('accountInformation.SkipSurvey') : t('accountInformation.GoHomeScreen')}
      </Button>
    </SCustomModal>
  );
};
