import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, BodyText } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { useSelector } from 'react-redux';
import { formatPhone } from 'utils/helpers/phone';
import { selectSystemProperties } from 'store/user/authentication.slice';
import { DataItem } from './DataItem/DataItem';

const CUSTOM_SERVICE = 'customerservice@percapita.com';

export const ContactInformation = () => {
  const { t } = useTranslation('accountInformation');
  const { supportPhoneNumber } = useSelector(selectSystemProperties);
  const supportTelVal = useMemo(() => `tel:${formatPhone(supportPhoneNumber)}`, [supportPhoneNumber]);

  return (
    <>
      <Title font="Poppins" color="charcoal" fontWeight="SB" size="S" marginBottom={25}>
        {t('accountInformation.ContactInformation')}
      </Title>
      <CustomCard width="100%" borderRadius={16} padding="32px 24px">
        <BodyText textType="bodyText" color="charcoal" fontWeight="R" font="DM Sans" size="M" marginBottom={18}>
          {t('accountInformation.ContactInformationSubtitle')}
        </BodyText>
        <DataItem label={t('accountInformation.OurEmail')} url={`mailto:${CUSTOM_SERVICE}`} value={CUSTOM_SERVICE} icon="mail" marginBottom={18} />
        <DataItem label={t('accountInformation.OurPhoneNumber')} url={supportTelVal} value={supportPhoneNumber} icon="telephone" />
      </CustomCard>
    </>
  );
};
