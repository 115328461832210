import styled from 'styled-components';
import { flex } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  .country-row {
    ${flex('row', 'flex-start')};
    margin-bottom: 24px;

    .body-text {
      flex: 1;
    }
  }
`;
