import styled, { css } from 'styled-components';
import { Button } from 'components/theme/Button/Button';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { Checkbox } from 'components/general/Checkbox/Checkbox';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: 100%;
`;

export const SMainContentContainer = styled.div`
  width: 100%;
  position: relative;

  section.group {
    width: 100%;
    margin-bottom: 16px;
    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 26px;
    }

    .btn-manage {
      color: ${getColor('blue')};
      cursor: pointer;
      min-width: fit-content;
      padding-right: 0;
      max-width: 816px;

      ${mediaFrom(
        'mobile',
        css`
          font-size: 14px;
        `
      )};
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-start {
      align-items: flex-start;
    }

    &-column {
      flex-direction: column;
    }

    &-justify-center {
      justify-content: center;
    }

    &-justify-end {
      justify-content: flex-end;
    }
  }

  .notification {
    &-center {
      margin-bottom: 28px;
      padding-top: 16px;

      ${mediaFrom(
        'desktop',
        css`
          padding: 56px 24px 56px 32px;
          border-bottom: 1px solid rgba(236, 232, 220, 1);
        `
      )};

      ${mediaUpTo(
        'mobile',
        css`
          .icon-settings {
            width: 20px;
            height: 20px;
          }
        `
      )};

      .title {
        .svg-icon {
          display: none;
        }

        ${mediaFrom(
          'desktop',
          css`
            .svg-icon {
              display: block;
            }
            .custom-title-text {
              margin-left: 8px;
            }
          `
        )};

        ${mediaUpTo(
          'mobile',
          css`
            .custom-title-text {
              font-size: 20px;
            }
          `
        )};
      }
    }

    &-items {
      margin-bottom: 80px;
      min-height: 100%;
    }

    &-date {
      margin-bottom: 16px;

      ${mediaUpTo(
        'mobile',
        css`
          .custom-text-inner {
            font-size: 12px;
          }
        `
      )};
    }
  }

  .sort-by {
    margin-bottom: 20px;
  }

  .filter-search {
    padding-top: 4px;
  }

  .no-result {
    margin-top: 64px;
    img {
      width: 200px;
      height: 200px;
    }

    button {
      color: ${getColor('blue')};
      width: auto;
    }
  }

  ${mediaFrom(
    'desktop',
    css`
      border-left: 1px solid rgba(236, 232, 220, 1);

      .filter-block {
        display: none;
      }

      .filter-search {
        button:nth-child(1),
        button:nth-child(2) {
          display: none;
        }
      }

      .notification-items {
        width: 616px;
        margin: 0 auto;
      }
    `
  )};
`;

export const SCustomButton = styled(Button)`
  border-color: ${getColor('creamS10')};
  background-color: transparent;
  color: ${getColor('charcoal70')};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: auto;
  padding: 8px 16px;

  &.noBorder {
    border-color: transparent;
    padding: 0;
  }

  &.active {
    border-color: ${getColor('blue')};
    background-color: ${getColor('blue')};
    color: ${getColor('white')};
  }

  ${mediaUpTo(
    'mobile',
    css`
      .btn__inner .icon {
        width: 20px;
        height: 20px;
      }
    `
  )};
`;

export const SCheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const SCheckbox = styled(Checkbox)`
  margin-right: 24px !important;
`;
