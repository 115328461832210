import styled, { css } from 'styled-components';
import { MEDIA_SIZE, getColor, mediaUpTo, mediaUpToHeight, mediaFrom } from 'utils/helpers/styleHelpers';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';

export const PhoneLink = styled.a`
  color: ${getColor('charcoal')};
  font-weight: 600;

  &:hover {
    color: ${getColor('blue')};
  }
`;

export const SLayout = styled.div`
  .account {
    &Cards {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .title .icon {
    min-width: 16px;
  }

  .data-list {
    &-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .subtitle {
        margin-right: 8px;
        .custom-text-inner {
          font-size: 14px;
          ${mediaUpTo(
            410,
            css`
              font-size: 13px;
            `
          )}
        }
        .icon {
          min-width: 16px;
        }
      }

      .value {
        margin-left: auto;
        margin-top: 0;

        .custom-text-inner {
          font-size: 14px;
          ${mediaUpTo(
            410,
            css`
              font-size: 13px;
            `
          )}
        }

        .custom-row {
          text-wrap: none;
        }
      }

      .copy-icon {
        margin-left: 8px;
      }
    }

    &Number {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .debitCard {
    &Row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    &Column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
    }
  }
`;

export const SAccountInformationModal = styled.div`
  padding-inline: 8px;

  ${mediaUpTo(
    'mobile',
    css`
      .custom-title-text {
        font-size: 24px;
      }
    `
  )}

  .checkAccountModalFooter {
    display: flex;
    flex-direction: column;
    align-items: center;

      ${mediaFrom(
        'tablet',
        css`
          flex-direction: row-reverse;
          justify-content: end;
          gap: 8px;
        `
      )}

      > button {
          ${mediaFrom(
            'tablet',
            css`
              margin-top: 0 !important;
              width: auto;
            `
          )}
      }
  }

  .checkCloseCashAccountInformationModalList {
    margin-bottom: 30px;

    & .custom-text-inner {
      font-size: 15px;
    }
  }

  .closeCashAccountListItemsOpacity {
    position: relative;
    top: -28px;
    margin-bottom: -40px;
    height: 50px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1));
  }

  .closeCashAccountListItems {
    overflow-y: auto;
    height: 205px;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    & .bold-text {
      display: inline;
      font-weight: 600;
    }

    &Inner {
      margin-top: 10px;

      &:last-child {
        margin-bottom: 25px;
      }
    }

    & > ul {
      font-size: 13px;
      color: ${getColor('charcoal70')};
      padding-left: 25px;
      padding-right: 10px;
      line-height: 1.5;
      & > li::marker {
        color: ${getColor('red')};
      }
      ${mediaUpTo(
        'mobile',
        css`
          padding-left: 18px;
        `
      )}
    }
  }

  .doubleCheckListItems {
    &Inner {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 16px;
      }
    }

    & > ul {
      margin: 0;
      font-size: 13px;
      color: ${getColor('charcoal70')};
      padding-left: 24px;
      line-height: 1.5;

      & > li::marker {
        color: ${getColor('red')};
      }
      ${mediaUpTo(
        'mobile',
        css`
          padding-left: 18px;
        `
      )}

  .closeAccountModalFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 15px;

    ${mediaUpTo(
      'mobile',
      css`
        margin-top: 35px;
        margin-right: 0;
        & > button {
          font-size: 14px;
          padding: 10px 24px;
        }
      `
    )}
  }

  .startCloseAccountInformation {
    padding-left: 12px;

    ${mediaUpToHeight(
      735,
      css`
        padding-left: 5px;

        & .custom-title-text {
          font-size: 26px;
        }

        & .callNumber {
          padding-left: 0;
          padding-right: 0;
          margin-top: 18px;
        }

        & .closeAccount {
          margin-top: 35px;
          margin-bottom: 6px;
        }
      `
    )}

    ${mediaUpTo(
      'mobile',
      css`
        & .closeAccount {
          margin-top: 20px;
          & .custom-text-inner {
            font-size: 14px;
          }
        }
      `
    )}
  }
`;

export const SInfoBlock = styled(CustomCard)`
  padding-bottom: 0;

  .custom-row:last-of-type {
    margin-bottom: 0;
  }

  .bottom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    border-top: 1px solid ${getColor('charcoal10')};
    margin: 0 -24px;
  }
`;

export const SAccountsTabs = styled.div`
  display: flex;
  margin-bottom: 22px;
  overflow: scroll;
  gap: 10px;

  ::-webkit-scrollbar {
    display: none !important;
  }

  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
  }
`;

export const SAccountsList = styled.div`
  .internal-accounts {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  .add-new-cash-account {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 50px 0 20px 0;
  }

  .add-new-account-btn {
    width: 100%;
    height: 64px;
    display: flex;
    gap: 16px;
    align-content: center;
    justify-content: center;
    float: none;
  }

  .internal-account-group {
    background: ${getColor('creamSS1')};

    @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
      background-color: ${getColor('cream')};
      border: 2px solid ${getColor('creamS5')};

      .primary .internal-account {
        border: 2px solid ${getColor('creamS5')};
      }

      .secondary .internal-account {
        border: 2px solid ${getColor('creamS5')};
      }
    }
  }

  .external-account {
    border: none;
    margin-bottom: 8px;

    @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
      border: 2px solid ${getColor('creamS5')};
    }
  }
`;

export const SAccountInformationPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  .account-information-page__content {
    max-width: 540px;
    margin: 0 auto;
  }
`;

export const SCustomRow = styled(CustomRow)`
  border-bottom: 2px solid ${getColor('creamS5')};
  padding-bottom: 32px;
  padding-top: 24px;
`;
export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    justify-content: start;
  }

  @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
    max-width: 488px;
    margin: 0 auto;

    .services {
      flex-direction: row;
      gap: 8px;
    }
  }

  ${mediaUpTo(
    390,
    css`
      .internal-wrapper {
        padding: 24px 15px;
      }
    `
  )}

  .disclaimer-wrapper {
    margin-top: auto;
    margin-bottom: 90px;
  }
`;
