import React, { useEffect, useState } from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Map from 'assets/images/map.svg';
import successImage from 'assets/images/successImage.svg';
import { Button } from 'components/theme/Button/Button';
import { useToggle } from 'utils/hooks/useToggle';
import { useProperties } from 'utils/hooks/useProperties';
import { ConsentId, ConsentType } from 'components/general/Consent/Consents.types';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { useConsents } from 'utils/hooks/useConsents';
import { IConsentData } from 'store/user/consents/consents.types';
import { lsGetItem } from 'utils/helpers/storage';
import { mobileApiCall } from 'services/mobileService';
import { SLayout, SRemindModalLayout } from './InternationalTransfer.styles';
import { HowDoesItWorkSection } from './HowDoesItWorkSection/HowDoesItWorkSection';
import { CountriesListSheet } from './CountriesListSheet/CountriesListSheet';
import { SelectedCountrySheet } from './SelectedCountrySheet/SelectedCountrySheet';
import { TCountriesItem } from './types';
import { ICantSelectMyCountrySheet } from './ICantSelectMyCountrySheet/ICantSelectMyCountrySheet';
import { SelectYourCountrySheet } from './SelectYourCountrySheet/SelectYourCountrySheet';
import { CountriesRequestSubmitModal } from './CountriesRequestSubmitModal/CountriesRequestSubmitModal';
import { AvailableDestinationsSheet } from './AvailableDestinationsSheet/AvailableDestinationsSheet';
import { CountryFlag } from './CountryFlag/CountryFlag';

export const InternationalTransfer: React.FC = () => {
  const { t } = useTranslation('moveMoney');
  const isMobileApp = lsGetItem('isMobileApp');
  const remindModal = useToggle(false);
  const countryListSheet = useToggle(false);
  const selectedCountrySheet = useToggle(false);
  const selectYourCountrySheet = useToggle(false);
  const iCantSelectMyCountrySheet = useToggle(false);
  const availableDestinationsSheet = useToggle(false);
  const countriesRequestSubmitModal = useToggle(false);
  const readyRemitConsentSheet = useToggle(false);
  const [selectedCountry, setCountry] = useState<TCountriesItem | null>(null);
  const {
    getSystemProperty,
    getSystemPropertyResult: { data: countries },
  } = useProperties();

  const readyRemitConsent = useConsents(ConsentType.READY_REMIT);
  const readyRemitConsentData = readyRemitConsent?.consentsData?.find((consent) => consent?.id === ConsentId.READY_REMIT_TERMS_OF_USE);
  const transferTermsAndConditionsHandler = () => readyRemitConsentSheet.show();

  const sendMoneyButtonHandler = () => {
    if (isMobileApp) mobileApiCall('readyRemitRequest');
  };

  const AvailableDestinationsSheetActions = {
    isOpen: availableDestinationsSheet.isActive,
    onClose: availableDestinationsSheet.hide,
    transferTermsAndConditionsHandler,
  };

  const countryListSheetActions = {
    isOpen: countryListSheet.isActive,
    onClose: countryListSheet.hide,
    selectCountry: (countryData: any) => {
      if (countryData) {
        setCountry(countryData);
        countryListSheet.hide();
        selectedCountrySheet.show();
      }
    },
    learnMore: () => {
      iCantSelectMyCountrySheet.show();
      countryListSheet.hide();
    },
  };

  const selectedCountrySheetActions = {
    isOpen: selectedCountrySheet.isActive,
    onClose: selectedCountrySheet.hide,
    onBack: () => {
      countryListSheet.show();
      selectedCountrySheet.hide();
    },
    selectedCountry,
  };

  const iCantSelectMyCountrySheetActions = {
    isOpen: iCantSelectMyCountrySheet.isActive,
    onClose: iCantSelectMyCountrySheet.hide,
    onBack: () => {
      countryListSheet.show();
      iCantSelectMyCountrySheet.hide();
    },
    onSelectDestinationClick: () => {
      iCantSelectMyCountrySheet.hide();
      selectYourCountrySheet.show();
    },
  };

  const selectYourCountrySheetActions = {
    isOpen: selectYourCountrySheet.isActive,
    onClose: () => {
      selectYourCountrySheet.hide();
      iCantSelectMyCountrySheet.show();
    },
    showCountriesRequestSubmitModal: () => {
      selectYourCountrySheet.hide();
      countriesRequestSubmitModal.show();
    },
  };

  const countriesRequestSubmitModalActions = {
    isOpen: countriesRequestSubmitModal.isActive,
    onClose: countriesRequestSubmitModal.hide,
    onBack: () => {
      countriesRequestSubmitModal.hide();
      iCantSelectMyCountrySheet.show();
    },
    handleContinue: () => {
      console.log('continue');
    },
  };

  useEffect(() => {
    getSystemProperty('internationalTransfersDestinations');
  }, []);
  return (
    <>
      <SLayout>
        <Title marginBottom={18}>{t('internationalTransferPage.InternationalTransfer')}</Title>

        <BodyText size="N" color="charcoal70" lineHeight={1.4} marginBottom={24}>
          {t('internationalTransferPage.WeVePartneredWithReadyRemitToProvide')}
        </BodyText>

        <div className="destination-card">
          <img src={Map} alt="map" className="image-map" />

          <Title size="M" fontWeight="M" marginBottom={24}>
            {t('internationalTransferPage.SafelySendMoneyOverseas')}
          </Title>

          {countries?.length ? (
            <div className="flags-list">
              <CountryFlag flagCode={countries[0].flag} title={countries[0].country} type="circle" width="auto" marginRight={16} />
              {countries?.[1] ? <CountryFlag flagCode={countries[1].flag} title={countries[1].country} type="circle" width="auto" marginRight={16} /> : null}
              {countries?.[2] ? <CountryFlag flagCode={countries[2].flag} title={countries[2].country} type="circle" width="auto" marginRight={16} /> : null}

              <BodyText size="T" fontWeight="B" display="inline-flex" className="flags-list-item">
                +{Number(countries?.length) - 3}
              </BodyText>
            </div>
          ) : null}

          <Button className="check-destinations-button" size="large" onClick={() => availableDestinationsSheet.show()} marginBottom={12}>
            {t('internationalTransferPage.CheckDestinations')}
          </Button>

          <Button className="check-destinations-button" preset="primary" size="large" onClick={sendMoneyButtonHandler}>
            {t('internationalTransferPage.SendMoney')}
          </Button>
        </div>

        <HowDoesItWorkSection />

        <div className="verification-times">
          <BodyText size="N" color="charcoal70" lineHeight={1.4}>
            <Trans
              i18nKey="internationalTransferPage.VerificationTimesVaryAndMayDelay"
              ns="moveMoney"
              components={{ termsLink: <span className="terms-link" onClick={transferTermsAndConditionsHandler} /> }}
            />
          </BodyText>
        </div>

        <BodyText size="T" color="charcoal70" lineHeight={1.3} marginBottom={20}>
          {t('internationalTransferPage.InternationalTransferServicesProvided')}
        </BodyText>

        <BodyText size="T" color="charcoal70" lineHeight={1.3}>
          {t('internationalTransferPage.PercapitaGroupIncIsADigitalCompany')}
        </BodyText>
      </SLayout>

      <SRemindModalLayout open={remindModal.isActive} onClose={remindModal.hide} topPosition="20%">
        <img src={successImage} alt="success" className="main-image" />

        <Title size="M" fontWeight="M">
          {t('internationalTransferPage.WeWillRemindYou')}
        </Title>
      </SRemindModalLayout>

      <CountriesListSheet {...countryListSheetActions} />
      <SelectedCountrySheet {...selectedCountrySheetActions} />
      <SelectYourCountrySheet {...selectYourCountrySheetActions} />
      <ICantSelectMyCountrySheet {...iCantSelectMyCountrySheetActions} />
      <AvailableDestinationsSheet {...AvailableDestinationsSheetActions} countries={countries} />
      <CountriesRequestSubmitModal {...countriesRequestSubmitModalActions} />
      <ConsentSheet key={readyRemitConsentData?.id} consentData={readyRemitConsentData as IConsentData} isOpen={readyRemitConsentSheet.isActive} onClose={readyRemitConsentSheet.hide} isReadonly />
    </>
  );
};
