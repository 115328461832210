import React from 'react';
import { Trans } from 'react-i18next';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { BodyText } from 'components/general/Typography';
import { SLink } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage.styles';
import { SPageFooter } from './PageFooter.styles';

interface IPageFooter {
  type?: 'sutton' | 'external-transfers' | 'full';
}

export const PageFooter = ({ type = 'sutton' }: IPageFooter) => (
  <SPageFooter className={`page-footer ${type}`}>
    {(type === 'sutton' || type === 'external-transfers') && <SuttonDisclaimerNote isForExternalTransfer={type === 'external-transfers'} />}
    {type === 'full' && (
      <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" lineHeight={1.4} paddingRight={5}>
        <Trans i18nKey="Full" ns="footer" components={{ Link: <SLink href="http://www.fdic.gov" /> }} target="_blank" />
      </BodyText>
    )}
  </SPageFooter>
);
