import React from 'react';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { SData } from './DataItem.styles';

interface IDataItem {
  label: string;
  value?: string;
  url: string;
  icon: string;
  marginBottom?: number;
}

export const DataItem: React.FC<IDataItem> = ({ label, value, url, icon, marginBottom = 0 }) => (
  <CustomRow width="100%" marginBottom={marginBottom} cursorPointer>
    <SData>
      <BodyText marginBottom={4} textType="bodyText" font="DM Sans" fontWeight="R" color="charcoal60" size="M">
        {label}
      </BodyText>
      <BodyText textType="bodyText" font="DM Sans" fontWeight="M" color="charcoal" size="M">
        <a href={url}>{value}</a>
      </BodyText>
    </SData>
    <a href={url}>
      <Icon name={icon} color="blue" size="big" />
    </a>
  </CustomRow>
);
