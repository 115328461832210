import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sharedAccountV2 } from 'assets/images';
import { BodyText } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { useDeclineJointAccountHolderMutation } from 'store/user/accounts/accounts.api';
import { useToggle } from 'utils/hooks/useToggle';
import { useUserStatus } from 'utils/hooks/useUserStatus';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { SCardLayout } from './YouHaveBeenInvitedCard.styles';
import { FinishCAOFirstModal } from './FinishCAOFirstModal';

type YouHaveBeenInvitedCardProps = {
  firstName: string;
  inviteId: string;
  onHide: () => void;
};

export const YouHaveBeenInvitedCard = ({ firstName = '(undefined name)', inviteId, onHide }: YouHaveBeenInvitedCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('home');
  const { isProspect } = useUserStatus();
  const [declineJointAccountHolder] = useDeclineJointAccountHolderMutation();
  const { openingAccountData, isUnder18 } = useCashAccountOpening();
  const shouldCompleteCAO = isProspect && openingAccountData.currentUrl && !isUnder18;

  const finishCAOFirstModal = useToggle();

  const onDecline = () => {
    declineJointAccountHolder(inviteId);
    onHide();
  };

  const onAcceptInvitation = () => {
    if (shouldCompleteCAO) {
      finishCAOFirstModal.show();
    } else {
      navigate(`/accept-invite/${inviteId}`);
    }
  };

  return (
    <SCardLayout>
      <div className="img-container">
        <img src={sharedAccountV2} alt="sharedAccount" />
      </div>

      <div className="content-container">
        <BodyText size="M" fontWeight="SB" font="Poppins" paddingRight={24} marginBottom={24}>
          {t('homeScreen.youHaveBeenInvitedCard.YouveBeenInvitedBy', { firstName })}
        </BodyText>

        <CustomRow justifyContent="flex-end">
          <Button className="decline" size="small" marginRight={8} onClick={onDecline}>
            {t('homeScreen.youHaveBeenInvitedCard.Decline')}
          </Button>

          <Button className="accept" size="small" preset="primary" onClick={onAcceptInvitation}>
            {t('homeScreen.youHaveBeenInvitedCard.AcceptInvitation')}
          </Button>
        </CustomRow>
      </div>
      <FinishCAOFirstModal isOpen={finishCAOFirstModal.isActive} onClose={finishCAOFirstModal.hide} />
    </SCardLayout>
  );
};
