import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { IconSign } from 'components/general/Icon/IconSign';
import { SPhoneNumberBlock } from './PhoneNumberBlock.styles';

interface IPhoneNumberBlockProps {
  phone?: string;
  handleContinue: () => void;
  isContinueDisabled?: boolean;
}

export const PhoneNumberBlock = ({ phone, handleContinue, isContinueDisabled }: IPhoneNumberBlockProps) => {
  const { t } = useTranslation('verification');

  return (
    <SPhoneNumberBlock>
      <section className="phone-block">
        <IconSign iconName="telephone" bgColor="white" />
        <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="L">
          {t('verification.Phone')}
        </BodyText>
        <BodyText textType="bodyText" color="charcoal" fontWeight="M" size="L">
          {phone}
        </BodyText>
      </section>

      <div className="button-container">
        <Button preset="primary" onClick={handleContinue} disabled={isContinueDisabled}>
          {t('verification.Continue')}
        </Button>
      </div>
    </SPhoneNumberBlock>
  );
};
