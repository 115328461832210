import React, { useMemo } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ageInputValidator } from 'utils/helpers/validationRules';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { formatDate, parseDate } from 'utils/helpers/date';
import { MaskedInput } from 'components/general/MaskedInput/MaskedInput';
import { IAgeForm, MyInfoFormInputProps } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

export const MyInfoAgeForm: React.FC<MyInfoFormInputProps<IAgeForm>> = ({ onCompletion, form, handleSubmit, isEditMode }) => {
  const { openingAccountData } = useCashAccountOpening();
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation('cashAccountOpening');

  const memoizedAgeDateStringRules = useMemo(() => ageInputValidator(), [t]);

  const handleFieldsChange = () => {
    const formErrors = form.isFieldsTouched() && form.getFieldsError().some(({ errors }): number => errors.length);
    onCompletion(!formErrors);
  };

  return (
    <Form
      style={{ width: '100%' }}
      onFieldsChange={handleFieldsChange}
      onFinish={handleSubmit}
      autoComplete="off"
      layout="vertical"
      requiredMark={false}
      form={form}
      initialValues={{ dateOfBirth: openingAccountData?.dateOfBirth }}
    >
      <Form.Item name="dateOfBirth" validateTrigger={['onBlur', 'onChange']} validateFirst rules={memoizedAgeDateStringRules}>
        <MaskedInput
          mask={Date}
          maskOptions={{
            pattern: 'm/d/Y',
            parse: parseDate,
            format: formatDate,
            lazy: true,
          }}
          placeholder={t('myInfo.DateFormat')}
          data-testid="dateOfBirth"
          onBeige={isEditMode && !isDesktopSize}
        />
      </Form.Item>
    </Form>
  );
};
