import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText } from 'components/general/Typography';

interface IPromptItem {
  name: string;
  checked?: boolean;
}

export const PromptItem: React.FC<IPromptItem> = ({ name, checked = false }) => {
  const { t } = useTranslation('accountInformation');
  return (
    <CustomRow justifyContent="flex-start" alignItems="center">
      <Checkbox checked={checked} />
      <BodyText textType="bodyText" display="flex" textAlign="center" size="N" fontWeight="R" color="charcoal70" font="DM Sans">
        {t(name)}
      </BodyText>
    </CustomRow>
  );
};
