import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SIconClose, SMaskStyle } from 'components/theme/CustomModal/CustomModal.styles';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { images } from 'assets';
import { CustomTitle } from 'components/theme/CustomTitle/CustomTitle';
import { useTranslation } from 'react-i18next';

interface IFinishCAOFirstModal {
  onClose: () => void;
  isOpen: boolean;
}

export const FinishCAOFirstModal = ({ isOpen, onClose }: IFinishCAOFirstModal) => {
  const { t } = useTranslation('home');

  return (
    <CustomModal open={isOpen} modalName="finishCAOFisrt" centered destroyOnClose footer={null} onClose={onClose} maskStyle={SMaskStyle} closeIcon={<SIconClose />} topPosition="10%">
      <CustomRow marginBottom={32} justifyContent="center">
        <img src={images.sandClock} width={122} alt="Finish cash account opening" />
      </CustomRow>

      <CustomTitle size="xl" fontWeight="light" marginBottom="small" textAlign="start" lineHeight={1.2}>
        {t('prepPage.FinishCAOFirst')}
      </CustomTitle>
    </CustomModal>
  );
};
