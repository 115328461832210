import styled, { css } from 'styled-components/macro';
import { mediaUpTo } from 'utils/helpers/styleHelpers';

export const STitleContainer = styled.div`
  display: flex;
  margin: 35px 0 18px;
  justify-content: space-between;

  ${mediaUpTo(
    'mobile',
    css`
      .transaction-sign-internal {
        width: 32px;
        height: 32px;

        .svg-icon {
          width: 16px;
          height: 16px;
        }
      }
    `
  )};
`;
