import styled from 'styled-components';
import { MEDIA_SIZE, getColor } from 'utils/helpers/styleHelpers';

export const SServiceItem = styled.div`
  flex: 1 1 auto;
  border-radius: 20px;
  background: ${getColor('white')};
  padding: 24px;
  position: relative;

  .service-item__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .service-item__content {
    display: flex;
    justify-content: space-between;
    gap: 2px;
  }

  .badge {
    margin: -15px -15px 0 0;
    border-radius: 8px;
    background-color: ${getColor('green10')};
    padding: 8px;
    display: flex;
    max-width: 105px;

    .custom-text-inner {
      color: ${getColor('green')};
      font-weight: 700;
    }

    &.grey {
      background-color: ${getColor('charcoal10')};
      .custom-text-inner {
        color: ${getColor('charcoal70')};
      }
    }
  }

  @media screen and (max-width: 420px) {
    padding: 15px;
    width: 168px;
  }

  @media screen and (max-width: ${MEDIA_SIZE.tablet}px) {
    .badge {
      margin: -10px -10px 0 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 4px;

      .custom-text-inner {
        font-weight: 500;
      }
    }
  }
`;
