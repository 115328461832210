import React, { useEffect } from 'react';
import { Button } from 'components/theme/Button/Button';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { BodyText, Title } from 'components/general/Typography';
import { cantOpenAccount } from 'assets/images';
import { useUpdateAccountsByIdMutation } from 'store/user/accounts/accounts.api';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/general/Loader/Loader';

interface SetAccountDefaultModalProps {
  accountId: string;
  open: boolean;
  handleClose: () => void;
  currentDefaultAccountName?: string;
}

export const SetAccountDefaultModal = ({ accountId, open, handleClose, currentDefaultAccountName }: SetAccountDefaultModalProps) => {
  const { t } = useTranslation('accountInformation');
  const [updateAccountsById, { isLoading, isSuccess }] = useUpdateAccountsByIdMutation();

  const handleSetAccountDefault = () => {
    updateAccountsById({
      accountId,
      data: { isDefault: true },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <CustomModal open={open} onClose={handleClose} modalName="accountDefault" topPosition="-2%" className="change-edit-account-information">
      {isLoading && <Loader />}
      <img src={cantOpenAccount} alt="close-account" height={160} width={160} style={{ alignSelf: 'center', marginBottom: 32 }} />
      <Title fontWeight="M" size="M" style={{ marginBottom: 16 }}>
        {t('accountInformation.ChangeDefaultCashAccount')}
      </Title>
      <BodyText color="charcoal70" size="M" style={{ marginBottom: 32 }}>
        {t('accountInformation.If you proceed', {
          accountName: currentDefaultAccountName ? `${currentDefaultAccountName} ` : '',
        })}
      </BodyText>
      <Button marginBottom={16} size="large" preset="primary" onClick={handleSetAccountDefault}>
        {t('accountInformation.ChangeSettings')}
      </Button>
      <Button size="large" preset="secondary" onClick={handleClose}>
        {t('accountInformation.KeepSettings')}
      </Button>
    </CustomModal>
  );
};
