import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { SRow } from 'components/theme/CustomRow/CustomRow.styles';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentAuthState, setPercapitaPayCollapsed } from 'store/user/authentication.slice';
import { useSetUIPreferenceMutation } from 'store/user/properties/userProperties.api';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useAppDispatch } from 'utils/hooks/store';
import { useUserStatus } from 'utils/hooks/useUserStatus';
import { Loader } from 'components/general/Loader/Loader';
import { images } from 'assets';
import { PercapitaPay } from './PercapitaPay/PercapitaPay';
import { SEnrollCard, STitleContainer } from './PercapitaPayCard.styles';

interface IPercapitaPayCard {
  isLoading: boolean;
}

export const PercapitaPayCard: React.FC<IPercapitaPayCard> = ({ isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hasPercapitaPay, canEnrollToPercapitaPay } = useUserStatus();
  const { isDesktopSize } = useDeviceDimension();
  const [setUIPreference] = useSetUIPreferenceMutation();
  const currentUser = useSelector(selectCurrentAuthState);
  const { t } = useTranslation(['home', 'percapitaPay']);
  const handleEnrollNow = () => navigate(ROUTES.percapitaPayEnroll.path);

  const handleCollapseChange = () => {
    const data = {
      value: {
        isPercapitaPayCollapsed: !currentUser.UIPreferences?.isPercapitaPayCollapsed,
        isMyAccountCollapsed: currentUser.UIPreferences?.isMyAccountCollapsed,
      },
    };

    setUIPreference(data);
    dispatch(setPercapitaPayCollapsed(!currentUser.UIPreferences?.isPercapitaPayCollapsed));
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {hasPercapitaPay && (
        <>
          <STitleContainer>
            <Title fontWeight="SB" color="charcoal" size="S" font="Poppins">
              {t('percapitaPayHome.Percapita Pay', { ns: 'percapitaPay' })}
            </Title>
            {!isDesktopSize && (
              <BodyText textType="bodyText" color="blue" size="T" fontWeight="B" cursorPointer onClick={handleCollapseChange}>
                {currentUser.UIPreferences?.isPercapitaPayCollapsed ? t('homeScreen.Show more') : t('homeScreen.Show less')}
              </BodyText>
            )}
          </STitleContainer>

          <PercapitaPay isEnrollDisplay={false} isCollapsed={!!currentUser.UIPreferences?.isPercapitaPayCollapsed} />
        </>
      )}

      {canEnrollToPercapitaPay && (
        <>
          <SRow justifyContent="space-between">
            <Title fontWeight="SB" color="charcoal" size="S" font="Poppins">
              {t('percapitaPayHome.Percapita Pay', { ns: 'percapitaPay' })}
            </Title>
          </SRow>

          <SEnrollCard>
            <div className="image-container">
              <img src={images.moneyTime} alt="Enroll" />
            </div>
            <div className="content-container">
              <Title fontWeight={isDesktopSize ? 'M' : 'SB'} size={isDesktopSize ? 'L' : 'T'} marginBottom={16}>
                {t(`homeScreen.Need your money now?`)}
              </Title>
              <BodyText textType="bodyText" size="N" color="charcoal70" fontWeight="R" marginBottom={24} lineHeight={1.4}>
                {t(`homeScreen.See how many hours you've ...`)}
              </BodyText>

              <Button preset="primary" size="middle" onClick={handleEnrollNow} width={isDesktopSize ? 'auto' : '100%'}>
                {t(`homeScreen.Enroll now`)}
              </Button>
            </div>
          </SEnrollCard>
        </>
      )}
    </>
  );
};
