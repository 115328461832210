import React, { useEffect } from 'react';
import { isThirdPartyAccount } from 'store/user/accounts/accounts.types';
import { generatePath, useParams } from 'react-router-dom';
import { useToggle } from 'utils/hooks/useToggle';
import { ConsentType } from 'components/general/Consent/Consents.types';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { useConsents } from 'utils/hooks/useConsents';
import { useAccounts } from 'utils/hooks/useAccounts';
import { Loader } from 'components/general/Loader/Loader';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { ROUTES } from 'vars/const/ROUTES';
import { useDispatch } from 'react-redux';
import { setCurrentBackUrl } from 'store/ui.slice';
import { InternalAccountBlock } from './InternalAccountBlock';
import { ExternalAccountInfoBlock } from './ExternalAccountInfoBlock';

export const SelectedAccountInformationPage = () => {
  const dispatch = useDispatch();
  const { consentsData } = useConsents(ConsentType.SUTTON);
  const { navigate } = useEnhancedNav();
  const disclosureSheet = useToggle();
  const { accountId, sourceType } = useParams();
  const { isLoading, allAccounts, internalAccounts, defaultAccountsGroup } = useAccounts();
  const typePassedAsAccId = sourceType === 'percapita' && (accountId === 'goals' || accountId === 'needs' || accountId === 'cash');
  const selectedAccount = allAccounts.find((account) => account.externalDisplayAccountNumber === accountId || account.accountId === accountId);

  const setBackUrl = (url: string | null) => dispatch(setCurrentBackUrl(url));

  useEffect(() => {
    if (typePassedAsAccId) {
      const defaultAccOfTypeId = defaultAccountsGroup?.[accountId]?.accountId;
      const firstAccOfType = internalAccounts.find((acc) => acc.percapitaType.toLowerCase() === accountId);

      if (defaultAccOfTypeId) {
        navigate(ROUTES.selectedAccountInformation.path, {
          params: {
            sourceType: 'percapita',
            accountId: defaultAccountsGroup[accountId]?.accountId,
          },
          replace: true,
        });
      } else if (firstAccOfType) {
        navigate(ROUTES.selectedAccountInformation.path, {
          params: {
            sourceType: 'percapita',
            accountId: firstAccOfType?.accountId,
          },
          replace: true,
        });
      } else {
        navigate(ROUTES.addNeedsGoalsAccount.path, {
          params: { type: accountId },
        });
      }
      return;
    }

    if (!selectedAccount) {
      navigate(ROUTES.accountInformation.path, {
        params: { type: sourceType },
        replace: true,
      });
    }
  }, [typePassedAsAccId, selectedAccount]);

  useEffect(() => {
    setBackUrl(generatePath(ROUTES.selectedAccountInformation.path, { accountId, sourceType }));
  }, [accountId, sourceType]);

  return (
    <div className="layout-space-between">
      {isLoading && <Loader />}
      {!!selectedAccount &&
        (isThirdPartyAccount(selectedAccount) ? (
          <ExternalAccountInfoBlock account={selectedAccount} accountsDataLoading={isLoading} />
        ) : (
          <InternalAccountBlock account={selectedAccount} showDisclosureSheet={disclosureSheet.show} accountsDataLoading={isLoading} />
        ))}
      <div>
        {consentsData?.map((disclosure) => (
          <ConsentSheet key={disclosure.id} consentData={disclosure} isOpen={disclosureSheet.isActive} onClose={disclosureSheet.hide} isReadonly />
        ))}
        <PageFooter />
      </div>
    </div>
  );
};
