import styled, { css } from 'styled-components/macro';
import { getProp, mediaFrom } from 'utils/helpers/styleHelpers';

export const SPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

export const SPageContent = styled.div<{ desktopMaxWidth?: number }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaFrom(
    'tablet',
    css`
      max-width: ${getProp('desktopMaxWidth', 580)}px;
      align-self: center;
      width: 100%;
      justify-content: flex-start;
    `
  )}
`;
