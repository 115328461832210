import React from 'react';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText, Title } from 'components/general/Typography';
import { congratulation } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SCustomSheet, SButton } from './JointAccountSuccessModal.styles';

interface JointAccountSuccessModalProps {
  open: boolean;
  handleClose: () => void;
}

export const JointAccountSuccessModal = ({ open, handleClose }: JointAccountSuccessModalProps) => {
  const { t } = useTranslation('accountInformation');
  const { isDesktopSize } = useDeviceDimension();

  return (
    <SCustomSheet isModal={isDesktopSize} wrapperPadding={isDesktopSize} isOpen={open} onClose={handleClose}>
      <CustomRow justifyContent="center">
        <img src={congratulation} alt="close-account" width={160} style={{ alignSelf: 'center', marginBottom: 32 }} />
      </CustomRow>
      <Title fontWeight="M" size="M" style={{ marginBottom: 16 }}>
        {t('accountInformation.AccountholderModalTitle')}
      </Title>
      <BodyText color="charcoal70" size="N" style={{ marginBottom: 48 }}>
        {t('accountInformation.AccountholderModalSubtitle')}
      </BodyText>
      <CustomRow justifyContent="center">
        <SButton marginBottom={16} size="large" preset="primary" onClick={handleClose}>
          {t('accountInformation.Done')}
        </SButton>
      </CustomRow>
    </SCustomSheet>
  );
};
