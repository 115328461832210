import React from 'react';
import { ROUTES } from 'vars/const/ROUTES';
import { useNavigate } from 'react-router-dom';
import { PercapitaPayMain } from 'components/general/PercapitaPayMain/PercapitaPayMain';
import { useTranslation } from 'react-i18next';
import { StyledCustomButton, StyledLink, StyledPercapitaPayContainer, StyledPercapitayBox } from './PercapitaPay.styles';

interface PercapitalPayProps {
  isEnrollDisplay: boolean;
  isCollapsed: boolean;
}

export const PercapitaPay = ({ isEnrollDisplay, isCollapsed }: PercapitalPayProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('percapitaPay');

  if (isEnrollDisplay) {
    return (
      <StyledPercapitayBox>
        <StyledLink to={ROUTES.percapitaPayEnroll.path}>
          <StyledCustomButton preset="primary" size="small" marginBottom={3}>
            {t('percapitaPayHome.Enroll')}
          </StyledCustomButton>
        </StyledLink>
      </StyledPercapitayBox>
    );
  }

  return (
    <StyledPercapitaPayContainer>
      <PercapitaPayMain
        onPrimaryButtonClick={() => navigate(ROUTES.percapitaPayHome.path)}
        primaryButtonText={t('percapitaPayHome.Go to Percapita Pay')}
        secondaryButtonText={t('percapitaPayHome.See Time Card')}
        isCollapsed={isCollapsed}
      />
    </StyledPercapitaPayContainer>
  );
};
