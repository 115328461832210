import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useMatch, useLocation, useNavigate, matchPath, useSearchParams } from 'react-router-dom';
import { isAndroid, isIOS } from 'react-device-detect';
import { ROUTES } from 'vars/const/ROUTES';
import { useLanguage } from 'utils/hooks/useLanguage';
import { selectRedirectUrl } from 'store/user/authentication.slice';
import { authenticationApi } from 'store/user/authentication.api';

const APP_DOWNLOAD_URL = 'https://percapita.com/#download';

export const PublicRoute = () => {
  console.log('PublicRoute');
  const redirectUrl = useSelector(selectRedirectUrl);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const emailLinkCode = searchParams.get('code');
  const isMobileAuthPage = useMatch(ROUTES.auth.path);
  const isAppRedirectUrl = useMatch(ROUTES.app.path);
  const [validateEmailLink] = authenticationApi.useLazyValidateEmailLinkQuery();

  useLanguage(true);

  useEffect(() => {
    if (isMobileAuthPage && redirectUrl) {
      console.log(`Mobile auth redirect from PublicRoute to ${redirectUrl}`);
      navigate(redirectUrl);
    }
  }, [isMobileAuthPage, redirectUrl]);

  // Redirect forcefully to the Mobile App from mobile device browsers
  useEffect(() => {
    if (isAppRedirectUrl) {
      // Redirect for notification setting
      if (matchPath(ROUTES.appNotificationsSettings.path, location.pathname)) {
        navigate(ROUTES.notificationSettings.path);
        return;
      }

      // Redirect and checking for email link code
      if (matchPath(ROUTES.appOpenAccountVerifySmsCode.path, location.pathname) && emailLinkCode) {
        console.log('Validate email link code');
        validateEmailLink(emailLinkCode)
          .unwrap()
          .then(() => {
            console.log('Validate email link code success');
            window.location.href = APP_DOWNLOAD_URL;
          })
          .catch((res) => {
            console.error('Validate email link code error', res);
            window.location.href = APP_DOWNLOAD_URL;
          });
        return;
      }

      if (isIOS) {
        window.location.replace('https://apps.apple.com/us/app/percapita/id1617975560');
      } else if (isAndroid) {
        window.location.replace('https://play.google.com/store/apps/details?id=com.percapita');
      } else {
        console.log(`Not iOS or Android detected: Redirect to web version`);
        window.location.href = APP_DOWNLOAD_URL;
      }
    }
  }, [isAppRedirectUrl]);

  return <Outlet />;
};
