import { BodyText, Title } from 'components/general/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { generatePath, useNavigate } from 'react-router-dom';
import { SubscriptionsModal } from 'components/general/Modals/SubscriptionsModal/SubscriptionsModal';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useToggle } from 'utils/hooks/useToggle';
import { CashAccountModal } from 'views/Onboarding/CashAccountModal/CashAccountModal';
import { mobileApiCall } from 'services/mobileService';
import { themeCream } from 'styles/theme';
import cashAndDebitImg from './images/cashAndDebitImg.png';
import percapitaPayImg from './images/percapitaPayImg.png';
import gamesAndMoreImg from './images/gamesAndMoreImg.png';

import onboardingMan from './images/onboardingMan.png';
import enrollPayPeriod from './images/enrollPayPeriod.png';
import onboardingWay from './images/onboardingWay.png';

import { SLayout, SCustomRow, SCustomButton } from './OnboardingFirstStepsPage.styles';
import { StepItem } from './StepItem';

/* This page appears after "Let's get started" clicked on the Onboarding page */
export const OnboardingFirstStepsPage = () => {
  const { t } = useTranslation('onboardingFirstSteps');
  const { isDesktopSize } = useDeviceDimension();
  const { isActive: isCashSheetOpen, show: onCashSheetShow, hide: onCashSheetClose } = useToggle(false);
  const navigate = useNavigate();

  mobileApiCall('backgroundChange', themeCream.cream70);

  const handleHomeClick = () => {
    navigate(ROUTES.home.path);
  };

  return (
    <>
      <SLayout>
        {isDesktopSize ? (
          <>
            <Title size="XXXL" fontWeight="M" color="white" paddingTop={68} marginBottom={28} justifyContent={isDesktopSize ? 'center' : 'start'}>
              {t('onboardingFirstSteps.WelcomeToPercapita')}
            </Title>

            <BodyText size="L" color="white" textAlign="center" justifyContent="center" marginBottom={2}>
              {t('onboardingFirstSteps.PercapitaProvidesSecurePlatform')}
            </BodyText>

            <BodyText size="L" color="white" textAlign="center" justifyContent="center" marginBottom={16}>
              {t('onboardingFirstSteps.WeMakeItEasyForYou')}
            </BodyText>

            <BodyText size="L" fontWeight="B" color="white" justifyContent="center" marginBottom={40}>
              {t('onboardingFirstSteps.ChooseWhatYouWantDoFirst')}
            </BodyText>
          </>
        ) : (
          <Title size="M" fontWeight="M" marginBottom={18} justifyContent={isDesktopSize ? 'center' : 'start'}>
            {t('onboardingFirstSteps.Title')}
          </Title>
        )}

        <SCustomRow>
          <StepItem
            title={t('onboardingFirstSteps.CashAndDebit')}
            description={t('onboardingFirstSteps.CashAndDebitDescription')}
            buttonText={t('onboardingFirstSteps.Open')}
            img={isDesktopSize ? onboardingMan : cashAndDebitImg}
            onClick={() => (isDesktopSize ? onCashSheetShow() : navigate(ROUTES.starter.path))}
            className="cash-account-first-steps"
          />
          <StepItem
            title={t('onboardingFirstSteps.PercapitaPay')}
            description={t('onboardingFirstSteps.PercapitaPayDescription')}
            buttonText={t('onboardingFirstSteps.Enroll')}
            img={isDesktopSize ? enrollPayPeriod : percapitaPayImg}
            onClick={() => navigate(ROUTES.percapitaPayEnroll.path)}
            className="percapita-pay-first-steps"
          />
          <StepItem
            title={t('onboardingFirstSteps.GamesAndMore')}
            description={t('onboardingFirstSteps.GamesAndMoreDescription')}
            buttonText={t('onboardingFirstSteps.Play')}
            img={isDesktopSize ? onboardingWay : gamesAndMoreImg}
            onClick={() => navigate(generatePath(ROUTES.wellness.path, { tabName: 'goal-and-tools', questionName: '' }))}
            className="play-games-first-steps"
          />
        </SCustomRow>

        <SCustomButton onClick={handleHomeClick} marginTop={32} marginBottom={16} size="large" preset="primary">
          {t('onboardingFirstSteps.GoToHome')}
        </SCustomButton>
      </SLayout>

      <CashAccountModal isOpen={isCashSheetOpen} onClose={onCashSheetClose} />
      <SubscriptionsModal />
    </>
  );
};
