import React from 'react';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SLayout } from './HowDoesItWorkSection.styles';
import { WhatYouNeedItem } from './WhatYouNeedItem/WhatYouNeedItem';
import { HowToUseItem } from './HowToUseItem/HowToUseItem';

type THowDoesItWorkCard = {
  className?: string;
};

export const HowDoesItWorkSection: React.FC<THowDoesItWorkCard> = ({ className = '' }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <SLayout className={clsx('how-does-it-work-section', className)}>
      <BodyText size="M" fontWeight="B" marginBottom={16} className="section-title">
        {t('internationalTransferPage.HowDoesItWork')}
      </BodyText>

      <HowToUseItem />

      <WhatYouNeedItem />
    </SLayout>
  );
};
