import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SuccessPage } from 'views/Auth/SuccessPage/SuccessPage';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { images } from 'assets';
import { BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { SContent, SWebContent } from './SuccessModal.styles';

type TSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SuccessModal: React.FC<TSuccessModalProps> = ({ isOpen, onClose }) => {
  const { isDesktopSize } = useDeviceDimension();
  const navigate = useNavigate();
  const { t } = useTranslation('preRegOnboarding');

  const handleContinue = () => {
    navigate(ROUTES.login.path);
    onClose();
  };

  return (
    <CustomModal open={isOpen} closeIconColor="charcoal70" modalName="changePasswordSuccess" onClose={onClose} topPosition={isDesktopSize ? '0' : '5%'} width={isDesktopSize ? '50%' : '60%'}>
      {isDesktopSize ? (
        <SWebContent>
          <img src={images.success} alt="Password Change Success" />

          <h1> {t('preRegOnboarding.SuccessfullRegistration.Title')}!</h1>

          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="L" marginTop={16} marginBottom={32}>
            {t('preRegOnboarding.SuccessfullRegistration.Subtitle')}
          </BodyText>

          <Button preset="primary" onClick={handleContinue}>
            {t('preRegOnboarding.SuccessfullRegistration.Continue')}
          </Button>
        </SWebContent>
      ) : (
        <SContent>
          <SuccessPage />
        </SContent>
      )}
    </CustomModal>
  );
};
