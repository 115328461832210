import { i18n } from 'i18n/i18n';
import { EAccountType, IAccountItem, IPartyAcctRelRecItem, isThirdPartyAccount, IThirdParty } from 'store/user/accounts/accounts.types';

const ACCOUNT_NICKNAME_MAP: IDictionary<string> = {
  Cash: 'Cash',
  Save: 'Goals',
  Stuff: 'Needs',
};

export const getMaskedAcctId = (account: IPartyAcctRelRecItem) => {
  const maskedAccountId = account.partyAcctRelKeys?.acctKeys?.acctId.slice(-4) ?? '0000';
  const accountNickName = account.partyAcctRelInfo?.nickname ?? ACCOUNT_NICKNAME_MAP[account.partyAcctRelKeys?.acctKeys?.percapitaAccountType];

  return `${accountNickName} (${maskedAccountId})`;
};

export const getAccountName = (account: IPartyAcctRelRecItem) => {
  const displayType = ACCOUNT_NICKNAME_MAP[account.partyAcctRelKeys?.acctKeys?.percapitaAccountType];
  return `${account?.partyAcctRelKeys?.acctKeys?.owner ? '' : 'Joint'} ${displayType} Account (${account.partyAcctRelKeys?.acctKeys?.acctId.slice(-4) ?? '0000'})`;
};

export const getIconName = (account: IPartyAcctRelRecItem) => {
  switch (account?.partyAcctRelKeys?.acctKeys?.percapitaAccountType) {
    case 'Cash':
      return 'cash';
    case 'Save':
      return 'goal';
    case 'Stuff':
      return 'stash';
    default:
      return 'cash';
  }
};

export const prepareAccount = (rawAccount: IPartyAcctRelRecItem): IAccountItem => {
  const displayType = ACCOUNT_NICKNAME_MAP[rawAccount.partyAcctRelKeys?.acctKeys?.percapitaAccountType];
  const accountType = rawAccount.partyAcctRelKeys?.acctKeys?.percapitaAccountType ?? '';
  const isOwner = rawAccount.partyAcctRelKeys?.acctKeys?.owner;
  const shortName = i18n.t(`accountInformation.Types.${isOwner ? '' : 'Joint '}${displayType} Account`, { ns: 'accountInformation' });
  const name = `${shortName} (${rawAccount.partyAcctRelKeys?.acctKeys?.acctId.slice(-4) ?? '0000'})`;
  const isCashType = accountType === EAccountType.CASH;
  const hasJointHolder = rawAccount.partyAcctRelKeys?.acctKeys?.hasJointHolder;
  const jointHolderName = rawAccount.partyAcctRelKeys?.acctKeys?.jointHolderName ?? '';
  const ownerName = rawAccount.partyAcctRelKeys?.acctKeys?.ownerName ?? '';

  return {
    type: accountType,
    percapitaType: ACCOUNT_NICKNAME_MAP[accountType],
    accountId: rawAccount.partyAcctRelKeys?.acctKeys?.percapitaAccountId ?? '',
    fiservAccountId: rawAccount.partyAcctRelKeys?.acctKeys?.acctId ?? '',
    fiservAccountType: rawAccount.partyAcctRelKeys?.acctKeys?.acctType ?? '',
    balance: rawAccount.partyAcctRelInfo.acctRef.acctSummInfo.acctBal.find(({ balType }) => balType === 'Avail')?.curAmt.amt ?? 0,
    owner: isOwner,
    ownerId: rawAccount.partyAcctRelKeys?.acctKeys?.ownerId ?? '',
    jointHolderName,
    ownerName,
    hasJointHolder,
    debitCardNumber: rawAccount.partyAcctRelKeys?.acctKeys?.debitCardNumber ?? '',
    maskedAccountId: getMaskedAcctId(rawAccount),
    isDefault: !!rawAccount.partyAcctRelKeys?.acctKeys?.isDefault,
    nickname: rawAccount.partyAcctRelInfo.nickname ?? '',
    shortName,
    name,
    icon: getIconName(rawAccount),
    ownerInd: rawAccount.partyAcctRelInfo?.ownerInd,
    primaryOwnerInd: rawAccount.partyAcctRelInfo?.ownerInd,
    isPrimary: rawAccount.partyAcctRelKeys?.acctKeys?.owner && isCashType,
    isJoint: !!ownerName || hasJointHolder,
    isCashType,
    isNeedsType: accountType === EAccountType.STUFF,
    isGoalsType: accountType === EAccountType.SAVE,
    displayType: ACCOUNT_NICKNAME_MAP[rawAccount.partyAcctRelKeys?.acctKeys?.percapitaAccountType],
    parentAccountId: rawAccount.partyAcctRelKeys?.acctKeys?.parentAccountId ?? '',
  };
};

export interface IAccountGroup {
  cash?: IAccountItem;
  goals?: IAccountItem;
  needs?: IAccountItem;
}

// Group accounts by ownerId
export const groupAccounts = (accounts: IAccountItem[]): IAccountGroup[] =>
  accounts &&
  Object.values(
    accounts.reduce((groups: Record<string, IAccountGroup>, account) => {
      const parentId = account.parentAccountId;
      // eslint-disable-next-line no-param-reassign
      groups[parentId] = groups[parentId]
        ? {
            ...groups[parentId],
            [account.percapitaType.toLowerCase()]: account,
          }
        : {
            [account.percapitaType.toLowerCase()]: account,
          };
      return groups;
    }, {})
  ).sort((groupA, groupB) => {
    if (groupA.cash?.owner && !groupB.cash?.owner) {
      return -1;
    }
    if (!groupA.cash?.owner && groupB.cash?.owner) {
      return 1;
    }
    return 0;
  });

export const getFiservBalance = (fiservAccountData: IPartyAcctRelRecItem[], accountId: string) => {
  const fiservAccount = fiservAccountData?.find((account) => account.partyAcctRelKeys.acctKeys.acctId === accountId);
  if (fiservAccount) {
    return fiservAccount.partyAcctRelInfo.acctRef.acctSummInfo?.acctBal?.find(({ balType }) => balType === 'Avail')?.curAmt.amt ?? 0;
  }

  return undefined;
};

export const getAccountNameByAuth = (account: IAccountItem) => {
  switch (account.type) {
    case 'Cash':
      return account.owner ? 'Primary Cash Account' : 'Joint Cash Account';
    case 'Save':
      return account.owner ? 'Primary Goals Account' : 'Joint Goals Account';
    case 'Stuff':
      return account.owner ? 'Primary Needs Account' : 'Joint Needs Account';
    default:
      return 'Cash Account';
  }
};

export const getLastFourDigitsOfAccId = (allAccounts: IPartyAcctRelRecItem[], accountId?: string) =>
  allAccounts?.find((item) => item?.partyAcctRelKeys?.acctKeys?.acctId === accountId)?.partyAcctRelKeys?.acctKeys?.acctId.slice(-4) || '0000';

export const getSlicedAccountId = (fiservAccountId?: string) => `(${fiservAccountId?.slice(-4)})` || '';

export const findAccountById = (accounts: (IThirdParty | IAccountItem)[], id: string) =>
  accounts.find((account) => (isThirdPartyAccount(account) ? account.thirdPartyAccountId === id : account.fiservAccountId === id)) || null;

export const getExternalAccountSubtitle = (account: IThirdParty) =>
  `${account.thirdPartyAccountType === 'CA' ? i18n.t('common.Card') : i18n.t('common.Account')} ${account.externalDisplayAccountNumber}`;

export const getAccountDisplayNumber = (account: IThirdParty | IAccountItem): string => {
  if (isThirdPartyAccount(account)) {
    return account?.externalDisplayAccountNumber ? `(${account.externalDisplayAccountNumber})` : account.issuingNetwork;
  }

  return getSlicedAccountId(account?.fiservAccountId);
};

export const getAccountDisplayName = (account: IThirdParty | IAccountItem): string => (isThirdPartyAccount(account) ? account?.externalDisplayAccountName : account?.nickname || account?.shortName);

const getAccountType = (account: IThirdParty | IAccountItem) => (isThirdPartyAccount(account) ? account.thirdPartyAccountType : account.type);

export const getTransferType = (from: IThirdParty | IAccountItem | undefined | null, to: IThirdParty | IAccountItem | undefined | null) =>
  from && to ? `${getAccountType(from)}-${getAccountType(to)}` : '';

export const getCashAccountsWithSubIds = (accounts: IAccountItem[], subAccountType = '') => {
  const eligibleIdsSet = new Set<string>();

  accounts.forEach((acc) => {
    const isType = subAccountType === 'needs' ? acc.isNeedsType : acc.isGoalsType;
    if (isType) {
      eligibleIdsSet.add(acc.parentAccountId);
    }
  });

  return Array.from(eligibleIdsSet);
};
