import styled, { css } from 'styled-components/macro';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SAccountSelectorLayout = styled.div`
  display: flex;
  overflow-y: auto;
  margin: 0 -15px 32px;
  padding: 24px 35px 0 15px;

  ::-webkit-scrollbar {
    display: none !important;
  }

  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  .chip {
    align-items: center;
    height: 40px;
    margin-right: 8px;
    padding: 8px 12px;
    background: ${getColor('transparent')};
    color: ${getColor('charcoal70')};
    font-weight: 700;
    font-family: DM Sans, sans-serif;

    &.active {
      color: ${getColor('charcoal')};
    }

    &:last-child {
      margin-right: inherit;
    }
  }

  ${mediaFrom(
    'tablet',
    css`
      justify-content: flex-start;
      margin-bottom: 48px;

      .chip {
        flex: 1;
        border: none;
        border-bottom: solid 2px ${getColor('creamS10')};
        border-radius: 0;
        margin-right: 0;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 600;

        .icon {
          order: 1;
          margin: 0;
          background: ${getColor('charcoal70')} !important;
        }

        .body-text {
          margin-right: 10px;
        }

        &.active {
          border: none;
          border-bottom: solid 2px ${getColor('blue')};
          background: ${getColor('transparent')};

          .icon {
            background: ${getColor('blue')} !important;
          }
        }
      }
    }
    `
  )}
`;
