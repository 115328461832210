import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from 'assets';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useAppDispatch } from 'utils/hooks/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectDisplayMobileCheckComingSoonModal, setShowMobileCheckComingSoonModal, selectMobileCheckComingSoonModalDescription, selectMobileCheckComingSoonModalBackNav } from 'store/ui.slice';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SContainer, SCustomModal } from './MobileCheckComingSoonModal.styles';

export const MobileCheckComingSoonModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('moveMoney');
  const { isDesktopSize } = useDeviceDimension();
  const isOpen = useSelector(selectDisplayMobileCheckComingSoonModal);
  const initialDesctiption = useSelector(selectMobileCheckComingSoonModalDescription);
  const backNav = useSelector(selectMobileCheckComingSoonModalBackNav);

  const handleOnClose = () => {
    dispatch(setShowMobileCheckComingSoonModal({ displayMobileCheckComingSoonModal: false }));
    if (backNav) {
      navigate(backNav);
    }
  };

  return (
    <SCustomModal
      open={isOpen}
      modalName="mobileCheckCommingSoon"
      closeIconColor="charcoal70"
      onClose={handleOnClose}
      topPosition="0"
      padding="12px"
      bodyStyle={!isDesktopSize ? { overflowY: 'auto', maxHeight: '80vh' } : undefined}
    >
      <SContainer>
        <BodyText textType="bodyText" color="charcoal" className="coming-soon" size="T" fontWeight={isDesktopSize ? 'R' : 'M'} lineHeight="16px">
          {t('translations:common.ComingSoon')}
        </BodyText>
        <img height="160px" src={images.sandClock} alt="comingSoon" />

        <div className="mobile-checking-body">
          {isDesktopSize && (
            <Title fontWeight="M" size="L" font="Poppins" marginTop={32}>
              {t('main.MobileCheck')}
            </Title>
          )}

          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="start" justifyContent="start" marginBottom={25} marginTop={25}>
            {initialDesctiption || t('mobileCheckComingSoon.Description1')}
          </BodyText>

          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="start" justifyContent="start" marginBottom={8}>
            {t('mobileCheckComingSoon.Description2')}
          </BodyText>
          <div className="list-item">
            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="start" justifyContent="start" marginLeft={8} marginBottom={6} lineHeight={1.5}>
              1. {t('mobileCheckComingSoon.ListItem1')}
            </BodyText>
          </div>

          <div className="list-item">
            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="start" justifyContent="start" marginLeft={8} marginBottom={6} lineHeight={1.5}>
              2. {t('mobileCheckComingSoon.ListItem2')}
            </BodyText>
          </div>

          <div className="list-item">
            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="start" justifyContent="start" marginLeft={8} marginBottom={6} lineHeight={1.5}>
              3. {t('mobileCheckComingSoon.ListItem3')}
            </BodyText>
          </div>
        </div>

        <Button preset="primary" onClick={handleOnClose} marginTop={isDesktopSize ? 32 : 25} width={isDesktopSize ? 120 : '100%'}>
          {t('mobileCheckComingSoon.Button')}
        </Button>
      </SContainer>
      <div className="sutton-disclaimer-note">
        <SuttonDisclaimerNote />
      </div>
    </SCustomModal>
  );
};
