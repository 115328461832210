import styled, { css } from 'styled-components';
import { percapitaLong } from 'assets/logos';
import { getColor, ifProp, getProp, getColorByProp, flex } from 'utils/helpers/styleHelpers';
import { TThemeColor } from 'styles/theme';
import { discover, bell, chat } from 'assets/icons';

export const SCircle = styled.div<{ marginRight?: number; backgroundColor?: TThemeColor }>`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  margin-right: ${getProp('marginRight', 0)}px;
  background-color: ${getColorByProp('backgroundColor', 'charcoal5')};
`;

export const SHeader = styled.header`
  position: relative;
  ${flex('row', 'space-between', 'center')};
  padding: 24px 32px;
  margin: 24px;
  background-color: ${getColor('white')};
  border-radius: 20px;
  box-shadow: 0 16px 24px 0 #0000000a;

  .flex-view {
    ${flex('row', 'space-between', 'center')};
  }

  h1 {
    height: 24px;
    margin: 0;
  }

  h1 > a {
    display: inline-block;
    width: 145px;
    height: 100%;
    background: url(${percapitaLong}) no-repeat center/contain;
    text-indent: -9999em;
  }

  button.language {
    appearance: none;
    cursor: pointer;
    margin-right: 24px;
    padding: 4px 8px;
    border: none;
    border-radius: 16px;
    background-color: ${getColor('creamS5')};
    font-size: 14px;
    font-weight: 700;
    color: ${getColor('blue')};

    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      aspect-ratio: 1;
      vertical-align: middle;
      margin-right: 8px;
      background: url(${discover}) no-repeat center/contain;
    }

    & > span:first-child {
      display: inline-block;
      text-indent: -9999em;
    }
  }

  button.notifications {
    position: relative;
    appearance: none;
    cursor: pointer;
    aspect-ratio: 1;
    border: none;
    background: url(${bell}) no-repeat center/contain;
    width: 40px;
    height: 40px;
    padding: 8px;
    background-size: 20px;
    border-radius: 50%;

    span {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 20px;
      border: 2px solid ${getColor('white')};
      border-radius: 10px;
      background-color: ${getColor('red')};
      font-size: 8px;
      font-weight: 500;
      font-family: 'DM Sans', sans-serif;
      color: ${getColor('white')};
      bottom: -3px;
      right: -4px;
    }
  }
  button.chat {
    appearance: none;
    cursor: pointer;
    width: 24px;
    aspect-ratio: 1;
    padding: 0;
    border: none;
    background: url(${chat}) no-repeat center/contain;
    text-indent: -9999em;
    ${ifProp(
      'isRounded',
      css``,
      css`
        margin-right: 20px;
      `
    )};
  }

  button.profile {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${getColor('blue10')};
    border: none;
    color: ${getColor('blue')};
    font-family: DM Sans, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-left: 16px;
    padding: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      border: 2px solid ${getColor('blue')};
    }
  }
`;
