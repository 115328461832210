import styled, { css } from 'styled-components';
import { getColor, mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SWebHeader = styled.div<{ showExit: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  padding: ${(showExit) => (showExit ? '24px 0px 50px 0px' : '0 128px 38px 128px')};

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    ${mediaBetween(
      'tablet',
      'desktop',
      css`
        & .title {
          margin-left: 25px;
        }
      `
    )}
  }

  .exit-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      min-width: 241px;
      height: 46px;
      padding: 14px 24px;

      font-size: 14px;
      font-family: Poppins;
      font-weight: 600;
    }
  }

  ${mediaUpTo(
    'tablet',
    css`
      display: none;
    `
  )}
  ${mediaFrom(
    'desktop',
    css`
      padding: ${(showExit) => (showExit ? '24px 52px 50px 52px' : '0 128px 38px 128px')};
    `
  )}
`;

export const SDivider = styled.div`
  width: 105%;
  margin-left: -45px;
  height: 1px;
  background: ${getColor('creamS5')};
  margin-bottom: 24px;

  ${mediaUpTo(
    'tablet',
    css`
      display: none;
    `
  )}
`;
