import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFinishRegestrationMutation, useCheckRegistrationCodeMutation, useGenerateRegistrationCodeMutation } from 'store/user/registration/registration.api';
import { cleanRegistrationData, selectRegistrationData } from 'store/user/registration/registration.slice';
import { ROUTES } from 'vars/const/ROUTES';
import { lsRemoveItem, lsSetItem } from 'utils/helpers/storage';
import { getFingerpint } from 'utils/helpers/webID';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { Loader } from 'components/general/Loader/Loader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { EnterCodeBlock } from 'views/Auth/ForgotPasswordPage/ForgotPasswordCodePage/EnterCodeBlock/EnterCodeBlock';
import { handleError } from 'utils/helpers/errorHelper';
import { getFormattedMobileNumber, getOnlyDigitsNumber } from 'utils/helpers/phoneNumber';
import { encryptAndEncodeData } from 'utils/helpers/encrypt';
import { AuthPage } from 'components/layouts/AuthLayout/AuthPage.styles';
import { AES_STATIC_KEY_STRING } from 'vars/const/ENCRYPTION';
import { SRegistrationCodePage } from './RegistrationCodePage.styles';

export const RegistrationCodePage = () => {
  const { t } = useTranslation('registration');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDesktopSize, fromDesktopSmall } = useDeviceDimension();

  const [generateCodeAPI, generateCodeAPIResult] = useGenerateRegistrationCodeMutation();
  const [checkCodeAPI, checkCodeAPIResult] = useCheckRegistrationCodeMutation();
  const [finishRegistrationAPI, finishRegistrationAPIResult] = useFinishRegestrationMutation();
  const registrationData = useSelector(selectRegistrationData);
  const [smsCode, setSmsCode] = useState('');
  const [isRememberedDevice, setIsRememberedDevice] = useState(false);
  const { phone, email, transactionId } = registrationData;
  const formattedPhone = getFormattedMobileNumber(getOnlyDigitsNumber(phone), '$1-$2-$3');

  const setEncryptedParamsToStorage = async (paramName: string, paramValue: string | undefined, aesKeyString: string) => {
    if (!paramValue) return;

    const { encryptedBase64, ivBase64 } = await encryptAndEncodeData(paramValue, aesKeyString);

    if (isRememberedDevice) {
      lsSetItem(paramName, encryptedBase64);
      lsSetItem(`${paramName}Iv`, ivBase64);
      sessionStorage.removeItem(paramName);
      sessionStorage.removeItem(`${paramName}Iv`);
    } else {
      sessionStorage.setItem(paramName, encryptedBase64);
      sessionStorage.setItem(`${paramName}Iv`, ivBase64);
      lsRemoveItem(paramName);
      lsRemoveItem(`${paramName}Iv`);
    }
  };

  const handleCompletion = (code: string) => {
    setSmsCode(code);
  };

  const handleSubmitCode = async () => {
    await checkCodeAPI({
      transactionId,
      code: smsCode,
    });
  };

  const handleCheckSuccess = useCallback(() => {
    if (registrationData) {
      finishRegistrationAPI({ ...registrationData, fingerprint: getFingerpint(true) });
    }
  }, [registrationData, finishRegistrationAPI]);

  const handleResendCode = async () => {
    generateCodeAPI({
      phone,
      email,
    });
  };

  useEffect(() => {
    if (checkCodeAPIResult.isSuccess) {
      handleCheckSuccess();
    }
  }, [checkCodeAPIResult]);

  useEffect(() => {
    (async () => {
      if (finishRegistrationAPIResult.isSuccess) {
        const otpSeed = finishRegistrationAPIResult?.data?.otpSeed;
        const deviceId = finishRegistrationAPIResult?.data?.deviceId;

        dispatch(cleanRegistrationData());
        await setEncryptedParamsToStorage('deviceId', deviceId, AES_STATIC_KEY_STRING);
        await setEncryptedParamsToStorage('otpSeed', otpSeed, AES_STATIC_KEY_STRING);

        if (fromDesktopSmall) {
          navigate(ROUTES.onboardingFirstSteps.path);
        } else {
          navigate(ROUTES.onboarding.path);
        }
      }
    })();
  }, [dispatch, finishRegistrationAPIResult, navigate]);

  useEffect(() => {
    if (checkCodeAPIResult.isError) handleError(checkCodeAPIResult.error);
    if (generateCodeAPIResult.isError) handleError(generateCodeAPIResult.error);
    if (finishRegistrationAPIResult.isError) handleError(finishRegistrationAPIResult.error);
  }, [checkCodeAPIResult, generateCodeAPIResult, finishRegistrationAPIResult]);

  if (generateCodeAPIResult.isLoading || checkCodeAPIResult.isLoading || finishRegistrationAPIResult.isLoading) return <Loader />;

  return (
    <SRegistrationCodePage className="registration-code-page">
      <AuthPage.Content>
        <Title className="registration-code-page__title" fontWeight="M" marginBottom={isDesktopSize ? 15 : 48}>
          {t('registration.Verification')}
        </Title>
        <BodyText className="registration-code-page__text" color="charcoal70" fontWeight="R" size="N" textType="bodyText" marginBottom={22} lineHeight={1.6}>
          {t('registration.PleaseEnterTheFiveDigit')} {formattedPhone}
        </BodyText>
        <AuthPage.ContentBox>
          {isDesktopSize ? (
            <EnterCodeBlock
              handleCompletion={handleCompletion}
              isError={checkCodeAPIResult.isError}
              handleRememberDeviceChange={setIsRememberedDevice}
              handleSubmitCode={handleSubmitCode}
              handleResendCode={handleResendCode}
            />
          ) : (
            <EnterCodeBlock handleCompletion={handleCompletion} isError={checkCodeAPIResult.isError} handleSubmitCode={handleSubmitCode} handleResendCode={handleResendCode} />
          )}
        </AuthPage.ContentBox>
      </AuthPage.Content>
    </SRegistrationCodePage>
  );
};
