import React from 'react';
import { Button } from 'components/theme/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SFooter } from './Footer.styles';

interface IFooter {
  isDisabled?: boolean;
  isBackVisible?: boolean;
  onClick: () => void;
  onBack?: () => void;
  firstBtnText?: string;
  secondBtnText?: string;
  zIndex?: number;
  absolutePosition?: boolean;
}

export const Footer: React.FC<IFooter> = ({ isDisabled = false, zIndex = 9999, isBackVisible = true, absolutePosition = false, firstBtnText, secondBtnText, onClick, onBack }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <SFooter zIndex={zIndex} absolutePosition={absolutePosition}>
      {isBackVisible && (
        <Button preset="outline" onClick={handleOnBack} size="middleAlt">
          {firstBtnText || t('accountOpening.Back')}
        </Button>
      )}
      <Button preset="primary" disabled={isDisabled} onClick={onClick} size="middleAlt">
        {secondBtnText || t('accountOpening.Continue')}
      </Button>
    </SFooter>
  );
};
