import React, { useEffect, useState, useMemo } from 'react';
import assert from 'assert';
import { useTranslation } from 'react-i18next';
import { isSameDay } from 'date-fns';
import { ROUTES } from 'vars/const/ROUTES';
import { useToggle } from 'utils/hooks/useToggle';
import { useCurrencyFormat } from 'utils/hooks/useCurrencyFormat';
import { Icon } from 'components/general/Icon/Icon';
import { FilterDrawer } from 'components/general/BottomDrawers/FilterDrawer/FilterDrawer';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { accountsApi } from 'store/user/accounts/accounts.api';
import { Loader } from 'components/general/Loader/Loader';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { useSearchParams } from 'react-router-dom';
import { formatDate, formatLocaleDate } from 'utils/helpers/date';
import { EAccountType, IAcctTrnRecItem } from 'store/user/accounts/accounts.types';
import { useLanguage } from 'utils/hooks/useLanguage';
import { maxBy } from 'utils/helpers/minmax';
import { BodyText, Title } from 'components/general/Typography';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { SORT_VARIABLES } from 'components/general/BottomDrawers/FilterDrawer/constants';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useAccounts } from 'utils/hooks/useAccounts';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { SPage, SMainContent, STransaction } from './BalancesTransactionsPage.styles';
import { TransactionDetailsSheet } from './TransactionDetailsSheet/TransactionDetailsSheet';
import { TagBar } from './TagBar/TagBar';
import { TFilterParamsInit, TTagNameList } from './BalancesTransactionsPage.types';
import { Slider } from './Slider/Slider';
import { TransactionDetailsModal } from './TransactionDetailsModal/TransactionDetailsModal';
import { AccountSelector } from './AccountSelector/AccountSelector';
import { PaymentIcon } from './PaymentIcon/PaymentIcon';

const filterParamsInit: TFilterParamsInit = {
  filterBy: {
    incoming: true,
    outgoing: true,
    minAmount: '',
    maxAmount: '',
    fromDate: null,
    toDate: null,
  },
  sortBy: SORT_VARIABLES[0],
  search: '',
};

const tagNameList: TTagNameList = {
  filterBy: {
    incoming: 'Money In',
    outgoing: 'Money Out',
    minAmount: 'Min Amount',
    maxAmount: 'Max Amount',
    fromDate: 'From Date',
    toDate: 'To Date',
  },
};

export const BalancesTransactionsPage = () => {
  const { t } = useTranslation('balancesTransactions');
  const { isDesktopSize, fromDesktopSmall, isMobileSmallSize } = useDeviceDimension();
  const { navigate } = useEnhancedNav();

  const { locale } = useLanguage();
  const { formatAutoSign } = useCurrencyFormat();
  const { internalAccountsGroups } = useAccounts();
  const [searchParams] = useSearchParams();
  const preselectedGroupId = searchParams.get('groupId');
  const accountType = searchParams.get('accountType') || EAccountType.CASH;

  const [selectedAccountTransactions, setSelectedAccountTransactions] = useState<IAcctTrnRecItem[]>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<IAcctTrnRecItem>();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [filterParams, setFilterParams] = useState<TFilterParamsInit>(filterParamsInit);
  const filterSheet = useToggle<{ prevScreen?: string }>(false);
  const transactionSheet = useToggle(false);
  const pendingSumModal = useToggle(false);
  const TransactionDetailsScreen = isDesktopSize ? TransactionDetailsModal : TransactionDetailsSheet;
  const selectedAccGroup = internalAccountsGroups.find((accGroup) => accGroup.cash?.accountId === preselectedGroupId);
  const cashAccount = selectedAccGroup?.cash;
  const selectedAccount = useMemo(() => selectedAccGroup && Object.values(selectedAccGroup).find((account) => account?.type === accountType), [accountType, selectedAccGroup]);
  const fiservAccountType = selectedAccount?.fiservAccountType ?? '';
  const availableBalance = selectedAccount?.balance ?? null;
  const accountId = selectedAccount?.fiservAccountId ?? '';

  const [getFiservAccountsAPI, getFiservAccountsAPIResult] = accountsApi.useLazyGetAccountsQuery();
  const [getAccountTransactionsAPI, getAccountTransactionsAPIResult] = accountsApi.useGetAccountTransactionsMutation();
  const accountHoldsQuery = accountsApi.useGetAccountHoldsQuery(selectedAccount?.accountId ?? '', { skip: !selectedAccount?.accountId });
  const holds = accountHoldsQuery?.data;
  const pendingSum = holds && holds.reduce((acc: number, hold: any) => acc + hold.amount, 0);
  const pendingSumFormatted = formatAutoSign(pendingSum);

  const {
    filterBy: { fromDate, toDate, incoming, outgoing, minAmount, maxAmount },
    sortBy,
  } = filterParams;

  const handleTypeClick = (type: string) => {
    navigate(ROUTES.balancesTransactions.path, { searchParams: { groupId: selectedAccGroup?.cash?.parentAccountId, accountType: type } });
  };

  const displayedAccountTransactions = useMemo(() => {
    let toDateNextDay: Date | null = null;
    if (toDate !== null) {
      toDateNextDay = new Date(toDate);
      toDateNextDay.setDate(toDateNextDay.getDate() + 1);
    }

    return selectedAccountTransactions
      .filter((transaction) => {
        if (!incoming && transaction.acctTrnInfo.drCrType === 'Credit') {
          return false;
        }

        if (!outgoing && transaction.acctTrnInfo.drCrType === 'Debit') {
          return false;
        }

        if (minAmount && transaction.acctTrnInfo.trnAmt.amt < +minAmount) {
          return false;
        }

        if (maxAmount && transaction.acctTrnInfo.trnAmt.amt > +maxAmount) {
          return false;
        }

        if (search) {
          const descriptionValue = transaction.acctTrnInfo?.desc[0]?.toLowerCase().trim();
          const searchValue = search.toLowerCase().trim();
          const regex = new RegExp(searchValue);
          const result = descriptionValue?.match(regex);

          return result !== null;
        }

        return true;
      })
      .sort((a, b) => {
        switch (sortBy.id) {
          case '1':
            return new Date(a.acctTrnInfo.trnDt).getTime() - new Date(b.acctTrnInfo.trnDt).getTime();
          case '2':
            return b.acctTrnInfo.trnAmt.amt - a.acctTrnInfo.trnAmt.amt;
          case '3':
            return a.acctTrnInfo.trnAmt.amt - b.acctTrnInfo.trnAmt.amt;
          case '0':
          default:
            return new Date(b.acctTrnInfo.trnDt).getTime() - new Date(a.acctTrnInfo.trnDt).getTime();
        }
      });
  }, [filterParams, selectedAccountTransactions]);

  const groupedByDateAccountTransactions = useMemo(() => {
    if (displayedAccountTransactions.length === 0) {
      return [];
    }
    const groups: IAcctTrnRecItem[][] = [[]];
    let currentGroupDate = new Date(displayedAccountTransactions[0].acctTrnInfo.trnDt);
    for (const transaction of displayedAccountTransactions) {
      const transactionDate = new Date(transaction.acctTrnInfo.trnDt);
      if (isSameDay(currentGroupDate, transactionDate)) {
        const currentGroup = groups.at(-1);
        assert(currentGroup !== undefined);
        currentGroup.push(transaction);
      } else {
        currentGroupDate = transactionDate;
        groups.push([transaction]);
      }
    }
    return groups;
  }, [displayedAccountTransactions]);

  const maxTransactionAmount = useMemo(() => {
    return maxBy(selectedAccountTransactions, (transaction) => transaction.acctTrnInfo.trnAmt.amt);
  }, [selectedAccountTransactions]);

  const openFilterSheet = (screen: string | undefined) => {
    filterSheet.show();
    filterSheet.setData({ prevScreen: screen ?? 'other' });
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearchClose = () => {
    setIsSearchVisible(false);
    setSearch('');
  };

  const handleOpenDetails = (transaction: IAcctTrnRecItem) => {
    setSelectedTransaction(transaction);
    transactionSheet.show();
  };

  const onFilter = (formValues: TFilterParamsInit) => setFilterParams(formValues);

  const handleTagClose = (tagName: string) => {
    const value = filterParamsInit.filterBy[tagName as keyof typeof filterParamsInit.filterBy];
    let filterValue = { [tagName]: value };

    if (['incoming', 'outgoing'].includes(tagName)) {
      filterValue = { incoming: true, outgoing: true };
    }

    if (['minAmount', 'maxAmount'].includes(tagName)) {
      filterValue = { minAmount: '', maxAmount: '' };
    }

    if (['fromDate', 'toDate'].includes(tagName)) {
      filterValue = { fromDate: null, toDate: null };
    }

    setFilterParams({
      filterBy: { ...filterParams.filterBy, ...filterValue },
      sortBy: filterParams.sortBy,
    });
  };

  const handleShowDetails = () => {
    navigate(ROUTES.selectedAccountInformation.path, {
      params: {
        sourceType: 'percapita',
        accountId: selectedAccount?.accountId,
      },
      backUrl: 'current',
    });
  };

  useEffect(() => {
    getFiservAccountsAPI();
  }, [getFiservAccountsAPI]);

  useEffect(
    () =>
      setFilterParams({
        filterBy: { ...filterParams.filterBy },
        sortBy: { ...filterParams.sortBy },
        search,
      }),
    [search]
  );

  useEffect(() => {
    (async function fetchViewedAccountTransactions() {
      const dateFromDirty = filterParams.filterBy.fromDate;
      const dateToDirty = filterParams.filterBy.toDate;
      const dateFrom = dateFromDirty ? formatDate(dateFromDirty) : '';
      const dateTo = dateToDirty ? formatDate(dateToDirty) : '';

      if (selectedAccount && fiservAccountType) {
        const { acctTrnRec: accountTransactions } = await getAccountTransactionsAPI({
          accountId,
          accountType: fiservAccountType,
          dateFrom,
          dateTo,
        }).unwrap();
        setSelectedAccountTransactions(accountTransactions);
      } else {
        setSelectedAccountTransactions([]);
      }
    })();
  }, [accountType, getAccountTransactionsAPI, toDate, fromDate, accountId]);

  useEffect(() => {
    if (!preselectedGroupId) {
      const defaultAccGroup = internalAccountsGroups.find((accGroup) => accGroup.cash?.isDefault || accGroup.cash?.isPrimary);
      console.log(internalAccountsGroups);
      navigate(ROUTES.balancesTransactions.path, {
        searchParams: {
          groupId: defaultAccGroup?.cash?.accountId,
          accountType: EAccountType.CASH,
        },
        replace: true,
      });
    }
  }, [preselectedGroupId]);

  return (
    <SPage isDesktopSize={fromDesktopSmall}>
      {(getFiservAccountsAPIResult.isFetching || accountHoldsQuery.isLoading) && <Loader />}

      {fromDesktopSmall && <Slider filterParamsInit={filterParamsInit} filterParams={filterParams} search={search} setSearch={setSearch} onFilter={onFilter} amountUpperLimit={maxTransactionAmount} />}

      <SMainContent>
        {isDesktopSize ? (
          <div className="flex-view flex-justify-start account-transaction">
            <Title paddingLeft={fromDesktopSmall ? 34 : 0}>{t(`account.AccountTransactions`)}</Title>
          </div>
        ) : (
          <>
            <div className="flex-view mobile-title">
              <Title>{cashAccount?.nickname || cashAccount?.name}</Title>
              <Icon name="chevronDown" size="smaller" marginTop={8} marginLeft={12} cursorPointer color="charcoal70" />
            </div>
            {!isMobileSmallSize && (
              <BodyText textType="bodyText" color="blue" size="N" fontWeight="M" cursorPointer onClick={handleShowDetails} lineHeight="20px" marginTop={8}>
                {t('balancesTransactions.ShowAccountDetails')}
              </BodyText>
            )}
          </>
        )}

        <div className="flex-view flex-column-view flex-align-stretch content">
          {isDesktopSize && (
            <>
              <div className="flex-view flex-justify-start">
                <Title>{cashAccount?.nickname || cashAccount?.name}</Title>
                <Icon name="chevronDown" size="smaller" marginTop={8} marginLeft={12} cursorPointer />
              </div>
              <BodyText textType="bodyText" color="blue" size="N" fontWeight="M" cursorPointer onClick={handleShowDetails} lineHeight="20px" marginTop={8}>
                {t('balancesTransactions.ShowAccountDetails')}
              </BodyText>
            </>
          )}
          <AccountSelector group={selectedAccGroup} onSelectedAccount={handleTypeClick} selectedAccountType={accountType} />

          <div className="flex-view available-balance">
            <div className="flex-view flex-column-view flex-align-start">
              <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70">
                {t(`account.AvailableBalance`)}:
              </BodyText>

              {availableBalance != null && (
                <CustomAmount
                  amount={availableBalance}
                  color={availableBalance < 0 ? 'red' : 'charcoal'}
                  size={isMobileSmallSize ? 'xl' : 'larger'}
                  isPoppins
                  multiSizable
                  remainingSize={isMobileSmallSize ? 'normal' : 'xl'}
                  remainingWeight={isDesktopSize ? 500 : 600}
                />
              )}
              {pendingSum > 0 && (
                <CustomRow marginTop={6}>
                  <BodyText textType="bodyText" display="inline" fontWeight="M" size="N" color="charcoal70">
                    {t(`account.AdditionalPending`, { pendingSumFormatted })}
                  </BodyText>
                  <Icon name="info" size="smaller" color="blue" marginLeft={10} onClick={pendingSumModal.show} />
                </CustomRow>
              )}
            </div>

            {isDesktopSize && (
              <div className="flex-view additional-details">
                <Icon name="creditCard2" color="creamS30" />
                <div className="flex-view flex-column-view flex-align-start">
                  <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight="20px">
                    {t(`balancesTransactions.AllTransactions`)}
                  </BodyText>
                  <BodyText textType="bodyText" color="blue" size="T" fontWeight="M" cursorPointer onClick={handleShowDetails} lineHeight="20px">
                    {t(`balancesTransactions.AdditionalDetails`)}
                  </BodyText>
                </div>
              </div>
            )}
          </div>

          <TagBar filterBy={filterParams.filterBy} tagNameList={tagNameList} onClose={handleTagClose} locale={locale} />

          {isSearchVisible && (
            <BaseInput
              placeholder={t(`balancesTransactions.WhatAreYouLookingFor`)}
              suffix="close"
              value={search}
              onBeige
              onChange={handleSearchInputChange}
              suffixClick={handleSearchClose}
              marginBottom={15}
              suffixSize="smallest"
            />
          )}

          {getAccountTransactionsAPIResult.isLoading ? (
            <Loader />
          ) : (
            groupedByDateAccountTransactions.map((group, i) => (
              // can't use transaction dates for keys since they are not stable identifiers of transaction groups
              // eslint-disable-next-line react/no-array-index-key
              <section key={i} className="group">
                <div className="flex-view group-name">
                  <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70">
                    {formatLocaleDate(new Date(group[0]?.acctTrnInfo?.trnDt), 'EEE, MMM dd', locale)}
                  </BodyText>
                  {i === 0 && !fromDesktopSmall && (
                    <div className="flex-view filter-search">
                      <Icon name="filter" color="charcoal70" size="small" cursorPointer onClick={() => openFilterSheet('main')} marginRight={16} />
                      <Icon name="search" color="charcoal70" size="small" cursorPointer onClick={() => setIsSearchVisible(true)} />
                    </div>
                  )}
                </div>

                <ul>
                  {group.map((transaction, j) => (
                    // FIXME: couldn't find any kind of stable identifier for a transaction
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={j}>
                      <STransaction onClick={() => handleOpenDetails(transaction)}>
                        <PaymentIcon trnCode={transaction.acctTrnInfo.trnCode ?? ''} type={transaction.acctTrnInfo.drCrType === 'Debit' ? 'expense' : 'income'} />
                        <span className="description">{transaction.acctTrnInfo?.desc[0]}</span>
                        <div className="amount">
                          <BodyText textType="helperText" fontWeight="B" size="S" color="charcoal" nowrap>
                            {transaction.acctTrnInfo.drCrType === 'Debit' ? '- ' : '+ '}
                            {formatAutoSign(transaction.acctTrnInfo?.trnAmt?.amt)}
                          </BodyText>
                        </div>
                      </STransaction>
                    </li>
                  ))}
                </ul>
              </section>
            ))
          )}

          <SuttonDisclaimerNote marginTop={isDesktopSize ? 60 : 0} marginBottom={isDesktopSize ? 80 : 0} />
        </div>
      </SMainContent>

      <FilterDrawer
        isOpen={filterSheet.isActive}
        onOpen={openFilterSheet}
        onClose={filterSheet.hide}
        onFilter={onFilter}
        amountUpperLimit={maxTransactionAmount}
        filterParamsInit={filterParamsInit}
        filterParams={filterParams}
        prevScreen={filterSheet?.data?.prevScreen}
      />

      <CustomModal open={pendingSumModal.isActive} onClose={pendingSumModal.hide} modalName="pendingBalance" topPosition="19.5%" closeIconColor="charcoal70">
        <Title font="Poppins" color="charcoal" marginBottom={16} marginTop={15} fontWeight="M" size="M" extraStyles={{ paddingRight: '15px' }}>
          {t('account.PendingBalanceInfoModalTitle')}
        </Title>
        <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70">
          {t('account.PendingBalanceInfoModalText')}
        </BodyText>
      </CustomModal>

      <TransactionDetailsScreen isOpen={transactionSheet.isActive} transaction={selectedTransaction} onClose={transactionSheet.hide} />
    </SPage>
  );
};
