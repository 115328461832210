import { api } from 'store/api';
import { API_ROUTES } from 'vars/const/API_ROUTES';
import { ISystemProperty } from './types';

export const systemPropertiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSystemProperties: builder.query<ISystemProperty[], string>({
      query: (propertyName) => ({
        url: API_ROUTES.user.properties.systemProperty(propertyName),
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetSystemPropertiesQuery } = systemPropertiesApi;
