import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText, Title } from 'components/general/Typography';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { formatLocaleDate } from 'utils/helpers/date';
import { useCurrencyFormat } from 'utils/hooks/useCurrencyFormat';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ITransactionDetailsSheet } from 'views/Account/BalancesTransactionsPage/BalancesTransactionsPage.types';
import { PaymentIcon } from 'views/Account/BalancesTransactionsPage/PaymentIcon/PaymentIcon';
import { PaymentDescription } from 'views/Account/BalancesTransactionsPage/PaymentDescription/PaymentDescription';
import { STitleContainer } from './TransactionDetailsSheet.styles';

export const TransactionDetailsSheet: React.FC<ITransactionDetailsSheet> = ({ isOpen, transaction, onClose }) => {
  const { t } = useTranslation('accountInformation');
  const { isMobileSmallSize } = useDeviceDimension();
  const theme = useTheme();
  const { locale } = useLanguage();
  const { formatAutoSign } = useCurrencyFormat();

  const dateFormatter = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }, [locale]);

  return (
    <CustomSheet isOpen={isOpen} header={false} wrapperPadding={false} onClose={onClose} modalName="transactionDetails">
      <STitleContainer>
        <Title font="Poppins" color={transaction?.acctTrnInfo.drCrType === 'Debit' ? 'red' : 'green'} fontWeight="SB" size={isMobileSmallSize ? 'T' : 'L'}>
          {transaction?.acctTrnInfo.drCrType === 'Debit' ? '- ' : '+ '}
          {formatAutoSign(transaction?.acctTrnInfo?.trnAmt?.amt)}
        </Title>
        <PaymentIcon trnCode={transaction?.acctTrnInfo.trnCode ?? ''} type={transaction?.acctTrnInfo.drCrType === 'Debit' ? 'expense' : 'income'} />
      </STitleContainer>
      <CustomRow marginBottom={32} flexDirection="column" alignItems="flex-start">
        <Title font="Poppins" color="charcoal" fontWeight="SB" size="sS">
          {transaction?.acctTrnInfo?.desc[0]}
        </Title>
        <BodyText textType="bodyText" color="charcoal70" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="R" marginTop={4}>
          {transaction?.acctTrnInfo?.trnDt && formatLocaleDate(new Date(transaction.acctTrnInfo.trnDt), 'MMMM dd, EEEE, HH:mm', locale)}
        </BodyText>
      </CustomRow>
      <CustomCard border={`2px solid ${theme.charcoal5}`} borderRadius={20} marginTop={16} marginBottom={32}>
        <CustomRow marginBottom={24} justifyContent="space-between">
          <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="R">
            {t('accountInformation.Type')}
          </BodyText>
          <PaymentDescription trnCode={transaction?.acctTrnInfo.trnCode ?? ''} isSheet />
        </CustomRow>
        <CustomRow marginBottom={24} justifyContent="space-between">
          <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="R">
            {t('accountInformation.Status')}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="B">
            {transaction?.acctTrnStatus?.acctTrnStatusCode === 'Valid' ? t(`accountInformation.${transaction?.acctTrnStatus?.acctTrnStatusCode}`) : transaction?.acctTrnStatus?.acctTrnStatusCode}
          </BodyText>
        </CustomRow>
        <CustomRow marginBottom={24} justifyContent="space-between">
          <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="R">
            {t('accountInformation.TransactionDate')}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="B">
            {transaction?.acctTrnInfo?.trnDt && dateFormatter.format(new Date(transaction.acctTrnInfo.trnDt))}
          </BodyText>
        </CustomRow>
        <CustomRow justifyContent="space-between" alignItems="start">
          <CustomRow flexDirection="column" alignItems="start" justifyContent="space-between" width="60%">
            <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="R" marginBottom={8}>
              {t('accountInformation.PostedDate')}
            </BodyText>
            <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R">
              {t('accountInformation.ThePostDateDescription')}
            </BodyText>
          </CustomRow>
          <BodyText textType="bodyText" color="charcoal" size={isMobileSmallSize ? 'T' : 'N'} fontWeight="B">
            {transaction?.acctTrnInfo?.postedDt && dateFormatter.format(new Date(transaction.acctTrnInfo.postedDt))}
          </BodyText>
        </CustomRow>
      </CustomCard>
    </CustomSheet>
  );
};
