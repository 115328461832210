import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setShowOverlayPad } from 'store/ui.slice';
import { BodyText, Title } from 'components/general/Typography';
import { ChipLanguageSwitcher } from 'components/general/ChipLanguageSwitcher/ChipLanguageSwitcher';
import { selectPolicies } from 'store/user/authentication.slice';
import { LogoutItem } from 'components/navigation/MainMenu/MenuItem/LogoutItem/LogoutItem';
import { useGetMenuDataQuery } from 'store/api';
import { IMenuItemData } from 'vars/types/menu.types';
import { MenuGroup } from 'components/navigation/MainMenu/MenuItem/MenuGroup';
import { MenuItem } from 'components/navigation/MainMenu/MenuItem/MenuItem';
import { Loader } from 'components/general/Loader/Loader';
import { SDropdownContainer } from './ProfileSettings.styles';

export const ProfileSettingsDropdown = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation(['profile', 'preRegOnboarding']);
  const dispatch = useDispatch();
  const policies = useSelector(selectPolicies);
  const { isLoading, isFetching, data } = useGetMenuDataQuery();
  const profileMenuRef = useRef(null);
  const profileMenuData = data?.popupProfile;

  useEffect(() => {
    dispatch(setShowOverlayPad(isOpen));
  }, [isOpen]);

  return isOpen ? (
    <SDropdownContainer ref={profileMenuRef} className="profile-settings-menu">
      {(isLoading || isFetching) && <Loader />}
      <Title font="DM Sans" fontWeight="B" size="XS" color="charcoal40" marginLeft={24} marginBottom={8} marginTop={8}>
        {t('profile.ProfileSettings')}
      </Title>
      <div className="section">
        {profileMenuData?.map((menuItem: IMenuItemData) =>
          menuItem.children && menuItem.children.length ? (
            <MenuGroup title={menuItem.text} items={menuItem.children} icon={menuItem.icon} key={menuItem.index} isProfileSettings />
          ) : (
            <MenuItem title={menuItem.text} description={menuItem.description} icon={menuItem.icon} path={menuItem.path} key={menuItem.index} isProfileSettings />
          )
        )}
      </div>
      <div className="section">
        <BodyText textType="bodyText" marginBottom={16} size="T" color="charcoal40" fontWeight="R">
          {t('preRegOnboarding.Language Preference', { ns: 'preRegOnboarding' })}
        </BodyText>
        <ChipLanguageSwitcher defaultValue="en" isDisabled={!policies?.SpanishEnabled} />
      </div>
      <div className="section logout">
        <LogoutItem />
      </div>
    </SDropdownContainer>
  ) : null;
};
