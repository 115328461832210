import styled, { css } from 'styled-components/macro';
import { Title } from 'components/general/Typography';
import { mediaUpTo } from 'utils/helpers/styleHelpers';

export const SMainPage = styled.div`
  max-width: 980px;
  margin: 0 auto;

  .promo-blocks {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
  }
`;

export const SCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const SCardTitle = styled(Title)`
  align-self: flex-start;

  ${mediaUpTo(
    'mobile',
    css`
      font-size: 20px;
    `
  )}
`;

export const Box = styled.div<{
  margin?: string;
  padding?: string;
  justify?: string;
  align?: string;
  changeDirection?: boolean;
  width?: string;
  cursorPointer?: boolean;
}>`
  display: flex;
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  justify-content: ${({ justify }) => justify || 'normal'};
  align-items: ${({ align }) => align || 'normal'};
  flex-direction: ${({ changeDirection }) => (!changeDirection ? 'row' : 'column')};
  width: ${({ width }) => width || 'auto'};
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'default')};
`;
