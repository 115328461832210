import styled, { css } from 'styled-components/macro';
import { AuthPage } from 'components/layouts/AuthLayout/AuthPage.styles';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SRegistrationCodePage = styled(AuthPage.Container)`
  .registration-code-page__title {
    margin-bottom: 24px;
    font-size: 20px;
  }

  ${mediaFrom(
    'mobile',
    css`
      padding-top: 22px;

      .registration-code-page__title {
        font-size: 28px;
        margin-bottom: 48px;
      }
    `
  )}

  ${mediaFrom(
    'tablet',
    css`
      padding-top: 0;

      .registration-code-page__title {
        font-size: 32px;
        margin-bottom: 16px;
      }
    `
  )}
`;
