/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';
import { Icon } from 'components/general/Icon/Icon';
import { MEDIA_SIZE, getColor, flex, mediaFrom, mediaUpToHeight, mediaUpTo, mediaBetween } from 'utils/helpers/styleHelpers';

export const SIcon = styled(Icon)`
  ${mediaUpToHeight(
    700,
    css`
      width: 20px;
      margin-bottom: 2px;
    `
  )}
`;

export const SNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 140px;
  margin: 0 0 24px 24px;
  padding: 24px 10px 24px 4px;
  position: relative;
  background-color: ${getColor('cream20')};
  border-radius: 16px;

  &.desktop-left-nav {
    ${mediaBetween(
      577,
      1199,
      css`
        margin-left: 0;
        margin-top: 24px;
        min-width: 108px;
      `
    )}

    ${mediaFrom(
      'tablet',
      css`
        box-shadow: 0 16px 24px 0 #0000000a;
        margin-top: 20px;
      `
    )};

    ${mediaBetween(
      'tablet',
      735,
      css`
        min-width: 120px;
      `
    )}

    ${mediaUpToHeight(
      700,
      css`
        padding: 10px 10px 10px 4px;
      `
    )}
  }

  &.main-nav-left {
    ${mediaUpTo(
      577,
      css`
        align-items: flex-start;
        height: 72px;
        padding: 8px 8px 0;

        .nav-item {
          row-gap: 3px;

          .svg-icon {
            width: 20px;
            height: 20px;

            &.icon-more {
              width: 10px;
            }
          }

          .nav-item-title {
            font-size: 8px;
          }
        }
      `
    )};

    ${mediaFrom(
      321,
      css`
        height: 98px;

        .nav-item {
          row-gap: 4px;

          .svg-icon {
            width: 24px;
            height: 24px;

            &.icon-more {
              width: 15px;
            }
          }

          .nav-item-title {
            font-size: 10px;
          }
        }
      `
    )};

    ${mediaFrom(
      576,
      css`
        padding: 8px 22px 0;
      `
    )};
  }

  .navigation-item-wrapper {
    position: relative;
    overflow: visible;

    ${mediaUpTo(
      'desktop',
      css`
        margin-bottom: 5px;
      `
    )}

    .navigation-item-desktop {
      ${flex('row')};
      flex: 1;
      position: relative;
      padding: 16px 0;
      cursor: pointer;

      ${mediaUpToHeight(
        700,
        css`
          padding: 7px 0;
        `
      )}

      &::before {
        content: '';
        display: flex;
        position: absolute;
        left: 0;
        height: 24px;
        width: 0;
        border-left: 2px solid ${getColor('transparent')};

        ${mediaUpToHeight(
          700,
          css`
            height: 18px;
          `
        )}
      }

      & .link-container {
        ${flex('column')};
      }

      & .icon-chevronRight {
        position: absolute;
        right: 0;
      }

      &.active,
      &:hover {
        ${SIcon} {
          background: ${getColor('blue')} !important;
        }
        .link-container {
          .link-container-title {
            .custom-text-inner {
              color: ${getColor('blue')};
            }
          }
        }

        &::before {
          border-color: ${getColor('blue')};
        }
      }
    }

    .dropdown-desktop {
      ${flex('column', 'flex-start', 'stretch')};
      position: absolute;
      top: 0;
      left: calc(100% + 18px);
      min-width: 272px;
      background: ${getColor('cream20')};
      backdrop-filter: blur(1.5px);
      border-bottom: 1px solid ${getColor('cream')};
      border-radius: 16px;
      box-shadow: 0 8px 12px 0 #0000000a;
      padding: 8px 0;
      z-index: 11;

      .dropdown-item {
        ${flex('column', 'flex-start', 'flex-start')};
        border-bottom: 1px solid ${getColor('cream')};
        cursor: pointer;

        &:has(.dropdown-item-title-wrapper + .dropdown-item-text) .dropdown-item-title-wrapper {
          padding-bottom: 8px;
        }

        &:last-child {
          border-bottom: none;
        }

        .dropdown-item-title-wrapper {
          ${flex('row', 'flex-start', 'center')};
          padding: 16px 16px 16px 24px;

          ${mediaUpToHeight(
            700,
            css`
              padding: 7px 7px 7px 24px;

              .icon {
                height: 20px;
              }
            `
          )}
        }

        .dropdown-item-text {
          padding: 7px 7px 7px 64px;

          &:last-child {
            padding-bottom: 14px;
          }
        }
      }
    }
  }

  & > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    row-gap: 6px;
    padding-block: 16px;
    text-align: center;
    color: ${getColor('charcoal')};
    font-family: 'DM Sans', sans-serif;
    font-size: 10px;
    font-weight: 500;

    &::before {
      position: absolute;
      display: block;
      left: -22px;
      content: '';
      width: 2px;
      height: 24px;
      background: ${getColor('transparent')};
      margin-top: 8px;
    }

    & .dropdown-desktop {
      background: #7ec1ff;
    }
  }

  & > a.active {
    color: ${getColor('blue')};

    &::before {
      background: ${getColor('blue')};
    }
  }

  .custom-row {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-top: auto;

    .custom-text-inner {
      margin-left: 0;
      font-size: 10px;
      font-weight: 500;
      margin-top: 8px;
    }
  }

  .overlay {
    position: absolute;
    top: -24px;
    left: 100%;
    bottom: 0;
    height: calc(100% + 48px);
    width: 10000%;
    background-color: rgba(174, 170, 162, 0.3);
    backdrop-filter: blur(1.5px);
    z-index: 900;
    &.hide {
      display: none;
    }
  }

  ${mediaUpTo(
    576,
    css`
      position: fixed;
      top: unset;
      left: 0;
      bottom: 0;
      z-index: 999;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 98px;
      padding: 0;
      background: linear-gradient(0deg, rgba(251, 247, 239, 0.2), rgba(251, 247, 239, 0.2)), rgba(255, 255, 255, 0.8);
      border-top: 1px solid ${getColor('creamS5')};
      border-right: none;
      backdrop-filter: blur(12px);
      margin: 0;
      border-radius: 0;

      &.shadow::before {
        content: '';
        z-index: -1;
        position: absolute;
        inset: 0;
        background: linear-gradient(180deg, #f3ebdb 0%, #f9f4e8 100%);
        transform: translate3d(0px, -5px, 0) scale(1);
        filter: blur(52px);
        opacity: var(0.38);
        transition: opacity 0.3s;
        border-radius: inherit;
      }

      &.shadow::after {
        content: '';
        z-index: -1;
        position: absolute;
        inset: 0;
        background: inherit;
        border-radius: inherit;
      }

      & > a {
        flex-direction: column;
        flex: 1;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        color: ${getColor('charcoal70')};

        &::before {
          position: static;
          content: '';
          width: 4px;
          height: 4px;
          aspect-ratio: 1;
          border-radius: 50%;
          background: ${getColor('transparent')};
          margin-top: 0;
        }

        &::after {
          display: none;
        }

        svg {
          margin-right: 0;
        }
      }
    `
  )}
`;

export const SNavigationSubtitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 16px 8px 24px;
  color: ${getColor('charcoal40')};
`;

export const SNavigationItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  :not(:last-child) {
    border-bottom: 2px solid ${getColor('cream')};
    margin-bottom: 8px;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 12px 16px 12px 24px;

    &.community-item {
      padding: 12px 16px;
      background-color: ${getColor('blue5')};
      border-radius: 8px;
      margin-bottom: 20px;
      width: calc(100% - 16px);
      flex-direction: column;
      align-items: start;

      .community-item-caption {
        color: ${getColor('charcoal70')};
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
      }

      .menu-item-icon {
        display: none;
      }

      .menu-item-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }

      .see-more-button {
        border: none;
        padding: 0;
        background: transparent;
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
        color: ${getColor('blue')};
        display: flex;
        cursor: pointer;

        .icon {
          margin-top: 4px;
          margin-left: 9px;
        }
      }
    }

    .title-icon-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
    }

    .menu-item-icon {
      height: 24px !important;
      width: 24px !important;
      flex: 0 0 auto;
    }
  }
`;
