import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';
import { transparentize } from 'polished';
import { FONT_SIZE } from 'components/general/Typography/Typography.types';

const disabledBtnCss = css`
  background: ${getColor('charcoal40')};
  cursor: not-allowed;
  border: 2px solid ${getColor('charcoal30')};
  opacity: 0.7;
`;

export const SButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  transition: all 100ms linear;
  white-space: nowrap;

  &.preset {
    &-primary {
      background: ${getColor('blue')};
      color: ${getColor('white')};
      border: 2px solid ${getColor('blue')};

      &:hover {
        background: ${(props) => transparentize(0.1, getColor('blue')(props) as string)};
      }

      &:active {
        background: ${getColor('blue')};
      }

      &:disabled {
        ${disabledBtnCss};
      }
    }

    &-cream {
      background: ${getColor('cream')};
      color: ${getColor('blue')};
      border: 2px solid ${getColor('cream')};

      &:hover {
        background: ${(props) => transparentize(0.1, getColor('cream')(props) as string)};
      }

      &:active {
        background: ${getColor('creamSS1')};
      }

      &:disabled {
        ${disabledBtnCss};
      }
    }

    &-secondary {
      background: ${getColor('transparent')};
      color: ${getColor('blue')};
      border: 2px solid ${getColor('blue')};

      &:active {
        background: ${getColor('white')};
      }

      &:disabled {
        ${disabledBtnCss};
        background: ${getColor('transparent')};
        color: ${getColor('charcoal40')};
      }
    }

    &-red {
      color: ${getColor('red')};
      background: transparent;
      border: 2px solid ${getColor('red')};
    }

    &-primary-red {
      color: ${getColor('white')};
      background: ${getColor('red')};
      border: 2px solid ${getColor('red')};

      &:hover {
        background: ${(props) => transparentize(0.1, getColor('red')(props))};
      }

      &:active {
        background: ${getColor('red')};
      }

      &:disabled {
        ${disabledBtnCss};
        color: ${getColor('charcoal40')};
        background: transparent;
      }
    }

    &-outline {
      background: transparent;
      color: ${getColor('blue')};
      border: 2px solid ${getColor('blue')};

      &:disabled {
        ${disabledBtnCss};
        background: transparent;
        color: ${getColor('charcoal40')};
      }
    }

    &-primary-with-outline {
      background: ${getColor('blue')};
      color: ${getColor('white')};
      border: 2px solid ${getColor('blue')};

      &:disabled {
        background: transparent;
        ${disabledBtnCss};
      }
    }

    &-transparent {
      color: ${getColor('charcoal')};
      background: transparent;
      border: 2px solid ${getColor('transparent')};
    }

    &-white {
      color: ${getColor('blue')};
      background: ${getColor('white')};
    }
  }

  &.size {
    background: red;
    &-small {
      min-width: 104px;
      padding: 8px 14px;
      font-size: 12px;
      line-height: 18px;
    }

    &-middle {
      min-width: 113px;
      padding: 8px 24px;
      font-size: 14px;
      line-height: 20px;
    }

    &-xl {
      width: 100%;
      padding: 14px 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &-middleStretch {
      width: 100%;
      padding: 10px 24px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &-middleAlt {
      min-width: 120px;
      padding: 10px 24px;
      font-size: 14px;
      line-height: 20px;
    }

    &-large {
      width: 100%;
      padding: 12px 32px;
      font-size: 18px;
      line-height: 28px;

      ${mediaUpTo(
        'tablet',
        css`
          padding: 8px 10px;
          font-size: ${FONT_SIZE.bodyText.M};
        `
      )}
    }
  }

  .btn__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
