import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SMaskStyle } from 'components/theme/CustomModal/CustomModal.styles';
import { Button } from 'components/theme/Button/Button';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { images } from 'assets';
import { BodyText, Title } from 'components/general/Typography';

interface IAddNewSourceModal {
  isSuccess: boolean;
  navPath: string;
  open: boolean;
  onClose: () => void;
}

export const ResultModal: React.FC<IAddNewSourceModal> = ({ isSuccess, navPath, open, onClose }) => {
  const { t } = useTranslation(['moveMoney', 'translations']);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnCancel = () => {
    onClose();
    navigate(navPath, { state: { fromPath: location.pathname } });
  };

  return (
    <CustomModal
      open={open}
      centered
      closable
      onCancel={handleOnCancel}
      modalName="addBankAccountResult"
      destroyOnClose
      footer={null}
      maskStyle={SMaskStyle}
      topPosition="10%"
      closeIconColor="charcoal70"
    >
      <CustomRow marginBottom={32} justifyContent="center">
        {isSuccess ? <img src={images.congratulation} alt="paymentSucceed" /> : <img src={images.errorExclamationMarkImage} alt="failureMark" />}
      </CustomRow>

      <CustomRow flexDirection="column" justifyContent="flex-start" alignItems="start" flexWrap="wrap" marginBottom={32}>
        <Title size="M" fontWeight="M" marginBottom={16} textAlign="start">
          {t(isSuccess ? 'addExternalAccountPage.BankAccountAddedSuccessfully' : 'addExternalAccountPage.WeWereNotAbleToConfirm')}
        </Title>
        <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
          {t(isSuccess ? 'addExternalAccountPage.YouCanUseAccountForExternal' : 'addExternalAccountPage.PleaseEnsurePersonalInformation')}
        </BodyText>
      </CustomRow>

      <Button preset="primary" onClick={handleOnCancel} size="middleStretch">
        {t(isSuccess ? 'common.Continue' : 'common.TryAgain', { ns: 'translations' })}
      </Button>
    </CustomModal>
  );
};
