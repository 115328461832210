import styled, { css } from 'styled-components/macro';
import Slider from 'react-slick';
import { getColor, mediaUpTo, mediaFrom, mediaBetween } from 'utils/helpers/styleHelpers';
import { motion } from 'framer-motion';

export const SOnboardingPageLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  min-height: 100%;

  .top {
    flex: 0 0 auto;
    margin-top: 30px;

    ${mediaFrom(
      'mobile',
      css`
        margin-top: 94px;
      `
    )}

    ${mediaBetween(
      'mobile',
      'tablet',
      css`
        margin-top: 35px;
      `
    )}

    ${mediaFrom(
      'tablet',
      css`
        margin-top: 46px;
      `
    )}

    .slick-list {
      ${mediaUpTo(
        'desktop',
        css`
          padding: 0 7% !important;
        `
      )}
      ${mediaBetween(
        'desktop',
        'desktop',
        css`
          padding: 0 !important;
        `
      )}
    }
  }

  .page-footer {
    padding: 0 5%;

    ${mediaFrom(
      'tablet',
      css`
        padding: 30px 24px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        padding: 0 80px 50px;
      `
    )}
  }
`;

export const SItemsSlider = styled(Slider)`
  margin-bottom: 10px;
  height: auto;

  &.no-slider {
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 0 85px;
    gap: 24px;

    ${mediaUpTo(
      'desktop',
      css`
        flex-direction: column;
      `
    )}

    .onboarding-slide {
      flex: 1 1 0;
      min-width: 328px;
      max-width: 426px;
      margin: 0;
      height: auto;
    }
  }

  .slick-track {
    display: flex !important;
    ${mediaUpTo(
      'desktop',
      css`
        justify-content: space-between;

        .slick-slide {
          width: 33.3% !important;
        }
      `
    )}

    ${mediaBetween(
      'desktop',
      'desktop',
      css`
        .slick-slide {
          width: 32% !important;
        }
      `
    )}
    ${mediaBetween(
      'mobile',
      365,
      css`
        & .slick-slide {
          width: 875px !important;
        }
      `
    )}
  }

  .slick-slide {
    & > div {
      height: 100%;
    }

    height: inherit !important;
  }

  > .slick-dots {
    position: relative;
    bottom: auto;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    align-items: center;

    .dots {
      display: flex;
      gap: 8px;
    }

    .dots li {
      margin: 0;
      width: auto;
      height: auto;

      & button {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin: 0;
        padding: 0;
        background: ${getColor('charcoal60')};
        opacity: 0.4;
      }

      &.slick-active {
        button {
          background: ${getColor('blue')};
          opacity: 0.8;
        }
      }
    }

    ${mediaFrom(
      'tablet',
      css`
        margin-top: 40px;
      `
    )}
  }
`;

export const SActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 6px;

  ${mediaFrom(
    'tablet',
    css`
      padding: 0 12px;

      a .body-text {
        margin-top: 52px;
      }

      .btn {
        width: auto;
      }
    `
  )}

  ${mediaUpTo(
    'mobile',
    css`
      margin-top: 25px;

      a .body-text {
        margin-top: 20px;
      }
    `
  )}

  ${mediaFrom(
    'mobile',
    css`
      margin-top: 40px;
    `
  )}
`;

export const SLearnMoreTextWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0 15px;

  ${mediaUpTo(
    'mobile',
    css`
      margin: 15px 0 10px;
      .custom-text-inner {
        font-size: 12px;
      }
    `
  )}
`;
