import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDisplayChat, setShowChat, setShowNeedSupportModal } from 'store/ui.slice';
import { useSendMessageMutation } from 'store/chat/chat.api';
import { useGetZendeskTokenMutation } from 'store/user/authentication.api';
import { selectPingMessage } from 'store/chat/chat.slice';
import { startChat } from 'views/Chat/zenDeskChat';
import { selectPolicies } from 'store/user/authentication.slice';
import { useLanguage } from './useLanguage';
import { useAppDispatch } from './store';
// import { useUserStatus } from './useUserStatus';

export const useChat = () => {
  const { language } = useLanguage();
  const isChatVisible = useSelector(selectDisplayChat);
  const dispatch = useAppDispatch();
  const pingMessage = useSelector(selectPingMessage);
  const policies = useSelector(selectPolicies);
  const [sendMessage] = useSendMessageMutation();
  const [getZendeskToken, getZendeskTokenResult] = useGetZendeskTokenMutation();
  // const { isClient } = useUserStatus();

  const handleOpenNeedSupportModal = () => {
    dispatch(setShowNeedSupportModal(true));
  };

  const addZendeskScript = () => {
    console.log('*-- Adding zendesk script --*');
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=e3e5cbcc-2521-4174-a041-3c55a404776c';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      getZendeskToken();
    };
  };

  const handleZendeskChatIconClick = () => {
    if (document.getElementById('ze-snippet')) {
      console.log('* -- Zendesk script is already in the document -- *');
      getZendeskToken();
    } else {
      addZendeskScript();
    }
  };

  const handleCloseCustomChat = () => {
    dispatch(setShowChat(false));
  };

  const handleOpenCustomChat = () => {
    dispatch(setShowChat(true));
  };

  const handleOpenChat = () => {
    // if (isClient) {
      if (policies?.ChatEnabled) {
        if (policies?.UseZendeskChat) {
          handleZendeskChatIconClick();
        } else {
          handleOpenCustomChat();
        }
      } else {
        handleOpenNeedSupportModal();
      }
    // } else {
    //   handleOpenNeedSupportModal();
    // }
  };

  useEffect(() => {
    console.log('*-- We enter start chat useEffect --*');
    if (getZendeskTokenResult.isSuccess) {
      console.log('*-- StartChat function called from useEffect, after token result --*');
      startChat(getZendeskTokenResult.data, language);
    }
  }, [getZendeskTokenResult.isSuccess]);

  return {
    isVisible: isChatVisible,
    close: handleCloseCustomChat,
    open: handleOpenChat,
    sendMessage,
    pingMessage,
  };
};
