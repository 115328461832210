import styled, { css } from 'styled-components';
import { getColor, getProp, ifProp, mediaUpTo, mediaFrom } from 'utils/helpers/styleHelpers';

type TFooterStyles = {
  zIndex: number;
  absolutePosition: boolean;
};

export const SFooter = styled.div<TFooterStyles>`
  ${mediaUpTo(
    'tablet',
    css`
      display: none;
    `
  )}

  ${mediaUpTo(
    'desktop',
    css`
      padding-inline: 15px;
    `
  )}

  ${mediaUpTo(
    'desktop',
    css`
      & button {
        margin-inline: auto;
        width: 100%;
        font-size: 16px !important;
        padding: 14px !important;
      }
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;

      align-self: center;

      width: 90%;
      max-width: 982px;
      min-width: 700px;
      height: 100px;
      padding: 24px 32px;
      z-index: ${getProp('zIndex')};

      border-radius: 20px;
      background: ${getColor('caoFooterBg')};
      box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04);
      backdrop-filter: blur(12px);

      ${ifProp(
        'absolutePosition',
        css`
          position: absolute;
          bottom: 5%;
        `
      )}
    `
  )}
`;
