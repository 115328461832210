import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { Title, BodyText } from 'components/general/Typography';
import { OptionItem } from './OptionItem';

interface IModalProps {
  open: boolean;
  onClose: () => void;
}

export const AddMoneyModal: React.FC<IModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('home');
  const navigate = useNavigate();

  return (
    <CustomModal open={open} onCancel={onClose} modalName="addMoney" maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', backdropFilter: 'blur(4px)' }} topPosition="0%">
      <Title size="M" fontWeight="M">
        {t('homeScreen.AddMoney')}
      </Title>
      <BodyText size="N" color="charcoal70" marginTop={16} marginBottom={32}>
        {t('homeScreen.ChooseOptions')}
      </BodyText>

      <OptionItem iconName="externalAccount" text={t('homeScreen.UsingOutsideAccount')} onClick={() => navigate(ROUTES.transfers.path)} />
      <OptionItem iconName="pennyJar" text={t('homeScreen.BySettingDeposit')} onClick={() => navigate(ROUTES.setUpDeposit.path)} />
      <OptionItem iconName="puzzle" text={t('homeScreen.UsingAnotherApp')} onClick={() => navigate(ROUTES.moveMoney.path)} />
    </CustomModal>
  );
};
