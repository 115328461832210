import { Icon } from 'components/general/Icon/Icon';
import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SContent = styled.div`
  position: relative;
  background: ${getColor('white')};
  border-radius: 20px;
  padding: 24px;

  ${mediaFrom(
    'tablet',
    css`
      .code-block {
        border: 2px solid ${getColor('grey2')};
        border-radius: 16px;
        padding: 24px;
        margin-bottom: 32px;
      }
      & .btn {
        margin: 0 auto;
        max-width: fit-content;
        background-color: ${getColor('blue')} !important;
        color: ${getColor('white')};

        &:disabled {
          background: ${getColor('transparent')} !important;
        }
        .svg-icon {
          display: none;
        }
      }
    `
  )}
`;

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 35px;
  padding-left: 5px;
  min-height: 100%;

  & .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-start {
      justify-content: flex-start;
      margin-bottom: 15px;
    }
    &-column {
      flex-direction: column;
    }
  }
  // & .btn {
  //   background: ${getColor('transparent')} !important;
  // }

  ${mediaFrom(
    'tablet',
    css`
      max-width: 580px;
      margin: 0 auto;
    `
  )}

  ${mediaUpTo(
    'tablet',
    css`
      & .btn {
        background: ${getColor('transparent')} !important;
      }
    `
  )}
`;

export const SArrowRight = styled(Icon).attrs({
  name: 'chevronRight',
  size: 'smaller',
  cursorPointer: true,
})`
  flex: 0 0 auto;
  margin-left: 21px;
`;
