import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { ROUTES } from 'vars/const/ROUTES';
import { Button } from 'components/theme/Button/Button';
import { BodyText, Title } from 'components/general/Typography';
import { Form } from 'antd';
import { Loader } from 'components/general/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { IAgeForm, IMyInfoEditFromVerify } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { SButtonWrapper } from 'views/OpenCashAccount/MyInfo/MyInfo.style';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { MyInfoAgeForm } from './MyInfoAgeForm';
import { SPage } from './EditAgePage.styles';

export const EditAgePage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation('cashAccountOpening');

  const { isDesktopSize } = useDeviceDimension();
  const navigate = useNavigate();
  const location = useLocation() as IMyInfoEditFromVerify;
  const isEditing = location?.state?.isEditing;
  const { openingAccountData, saveOnboardingData, getOnboardingDataIsLoading } = useCashAccountOpening();
  const [isContinueActive, setIsContinueActive] = useState(false);

  const handleSubmit = (values: IAgeForm) => {
    const nextUrl = isEditing ? ROUTES.myInfoSummary.path : ROUTES.myInfoTaxId.path;
    saveOnboardingData?.({ ...values, currentUrl: nextUrl });
    navigate(nextUrl);
  };

  useEffect(() => {
    setIsContinueActive?.(Boolean(openingAccountData?.dateOfBirth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openingAccountData]);

  return !isEditing ? (
    <>
      <Header title={t('accountOpening.WhenWereYouBorn')} stage="Age" webStage="Age" />
      <SPage>
        <div className="content-container">
          {getOnboardingDataIsLoading ? (
            <Loader />
          ) : (
            <>
              <div className="web-title-container">
                <Title>{t('accountOpening.WhenWereYouBorn')}</Title>
              </div>
              <div className="birth-card">
                <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" marginBottom={11} marginTop={32}>
                  {t('myInfo.DateOfBirth')}
                </BodyText>
                <MyInfoAgeForm onCompletion={setIsContinueActive} handleSubmit={handleSubmit} form={form} />
              </div>
              <div className="privacy">
                <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal70" lineHeight="16px">
                  {t('myInfo.OurPriority')}
                </BodyText>
                <BodyText textType="bodyText" fontWeight="R" size="T" color="charcoal70" lineHeight="16px">
                  {t('myInfo.OurSecurityMeasures')}
                </BodyText>
              </div>
            </>
          )}
        </div>

        <Footer isDisabled={!isContinueActive} onClick={form.submit} absolutePosition />

        <div className="mobile-footer">
          <Button size="large" disabled={!isContinueActive} onClick={form.submit} preset="primary-with-outline" marginBottom={16}>
            {t('myInfo.Continue')}
          </Button>

          <BodyText textType="bodyText" fontWeight="M" size="T" color="charcoal70" textAlign="end" marginBottom={24} className="next-step" justifyContent="end">
            {t('accountOpening.NextStep')} {t('accountOpening.TaxID')}
          </BodyText>
          <div className="privacy">
            <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal70" lineHeight="16px">
              {t('myInfo.OurPriority')}
            </BodyText>
            <BodyText textType="bodyText" fontWeight="R" size="T" color="charcoal70" lineHeight="16px">
              {t('myInfo.OurSecurityMeasures')}
            </BodyText>
          </div>
        </div>
      </SPage>
    </>
  ) : (
    <>
      <WebHeader showExit={false} title={t('accountOpening.GoBackToAccountInformation')} />
      <SPage>
        <div className="content-container">
          <div className="edit-title-container">
            <Title textAlign="start" size={isDesktopSize ? 'S' : 'M'} fontWeight="SB" font="Poppins">
              {t('myInfo.DateOfBirth')}
            </Title>
            <BodyText textType="bodyText" fontWeight="R" color="charcoal70" size="N" marginTop={4}>
              {t('myInfo.ChangeYourDateOfBirthHere')}
            </BodyText>
          </div>

          {getOnboardingDataIsLoading ? (
            <Loader />
          ) : (
            <div className="edit-birth-card">
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" marginBottom={11} marginTop={32}>
                {t('myInfo.DateOfBirth')}
              </BodyText>
              <MyInfoAgeForm onCompletion={setIsContinueActive} handleSubmit={handleSubmit} form={form} isEditMode />
              <div className="privacy">
                <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal70" lineHeight="16px">
                  {t('myInfo.OurPriority')}
                </BodyText>
                <BodyText textType="bodyText" fontWeight="R" size="T" color="charcoal70" lineHeight="16px">
                  {t('myInfo.OurSecurityMeasures')}
                </BodyText>
              </div>
            </div>
          )}
        </div>

        <Footer isDisabled={!isContinueActive} onClick={form.submit} absolutePosition secondBtnText={t('myInfo.SaveChanges')} />

        <div className="mobile-footer">
          <SButtonWrapper>
            <Button size="large" disabled={!isContinueActive} onClick={form.submit} preset="primary">
              {t('myInfo.SaveChanges')}
            </Button>
          </SButtonWrapper>
        </div>
      </SPage>
    </>
  );
};
