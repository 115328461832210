import styled, { css } from 'styled-components/macro';
import { getColor, mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { dotPatternLeft } from 'assets/images';

export const SOnboardingSlide = styled.div`
  margin: 0 6px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  ${mediaFrom(
    'tablet',
    css`
      margin: 0 12px;
    `
  )}

  .onboarding-slide {
    &__image {
      img {
        width: 100%;
      }

      ${mediaUpTo(
        'mobile',
        css`
          width: 148px;
        `
      )}
      ${mediaFrom(
        'mobile',
        css`
          width: 200px;
        `
      )}
      ${mediaFrom(
        'tablet',
        css`
          width: 136px;
        `
      )}
    }

    &__header {
      ${mediaUpTo(
        'mobile',
        css`
          margin: 15px 0;
        `
      )}
      ${mediaFrom(
        'mobile',
        css`
          margin: 38px 0 42px;
        `
      )}
      ${mediaFrom(
        'tablet',
        css`
          margin: 56px 0 42px;
        `
      )}
    }

    &__title {
      font-family: 'DM Sans', sans-serif;
      font-size: 28px;
      color: ${getColor('charcoal')};
      font-weight: 600;
      line-height: 1.2;

      ${mediaUpTo(
        'mobile',
        css`
          font-size: 20px;
        `
      )}

      ${mediaBetween(
        'mobile',
        'tablet',
        css`
          font-size: 24px;
        `
      )}

      ${mediaFrom(
        'tablet',
        css`
          font-weight: 600;
          font-size: 20px;
        `
      )}
    }

    &__subtitle,
    &__description {
      font-size: 18px;
      font-family: 'DM Sans', sans-serif;
      color: ${getColor('charcoal70')};
      line-height: 24px;
    }

    &__description {
      ${mediaUpTo(
        'mobile',
        css`
          font-size: 12px;
          line-height: 16px;
        `
      )}

      ${mediaBetween(
        'mobile',
        'tablet',
        css`
          font-size: 16px;
        `
      )}

      ${mediaFrom(
        'mobile',
        css`
          line-height: 22px;
        `
      )}

      ${mediaFrom(
        'tablet',
        css`
          font-size: 14px;
          line-height: 20px;
        `
      )}
    }

    &__subtitle {
      color: ${getColor('charcoal')};
      font-size: 22px;

      ${mediaUpTo(
        'mobile',
        css`
          font-size: 16px;
        `
      )}

      ${mediaFrom(
        'mobile',
        css`
          font-size: 20px;
          line-height: 32px;
        `
      )}

      ${mediaFrom(
        'tablet',
        css`
          font-size: 18px;
          line-height: 28px;
        `
      )}
    }
  }

  .bold-text {
    font-weight: 700;
  }
  .middle-text {
    font-weight: 500;
  }
  .regular-text {
    font-weight: 400;
  }
`;

export const SContainer = styled.div`
  background: ${getColor('white')} no-repeat bottom;
  background-size: contain;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;

  ${mediaUpTo(
    'mobile',
    css`
      padding: 20px 18px 24px;
    `
  )}

  ${mediaFrom(
    'mobile',
    css`
      padding: 48px 24px 35px;
    `
  )}
  
  ${mediaFrom(
    'tablet',
    css`
      padding: 32px 24px;
      background-image: url(${dotPatternLeft});
    `
  )}
`;

export const STextContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: normal;
  padding: 0 10px;

  ${mediaBetween(
    'mobile',
    'tablet',
    css`
      padding: 0;
    `
  )}

  .onboarding-slide {
    &__header {
      margin: 0;
      height: 90px;
      margin-top: 32px;

      ${mediaUpTo(
        'mobile',
        css`
          height: auto;
          margin-bottom: 10px;
        `
      )}
    }
  }
`;
