import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { mediaFrom } from 'utils/helpers/styleHelpers';

const STitleStyle = css`
  .title {
    margin-bottom: 8px;

    .custom-title-text {
      font-size: 32px;
      font-weight: 500;
    }
  }
`;

const SDescriptionStyle = css`
  .description {
    margin-bottom: 24px;
  }
`;

const SSubmitButtonStyle = css`
  button[type='submit'] {
    width: auto;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 32px;
    margin: 0 auto;
  }
`;

export const SModalDesktopCommonStyle = css`
  ${STitleStyle}
  ${SDescriptionStyle};

  .content-wrapper {
    margin-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 0;

    &.preferred-name,
    &.username,
    &.first-name,
    &.last-name,
    &.email,
    &.phone-number {
      margin-bottom: 24px;
    }

    &.preferred-name {
      .ant-form-item-label label {
        font-family: MS Sans, sans-serif;
      }
    }

    ${SSubmitButtonStyle};
  }
`;

export const SLayoutInitStyle = css`
  display: flex;
  flex-direction: column;
`;

export const SModalCommonLayout = styled.div`
  ${SLayoutInitStyle};

  &.body-edit-email,
  &.body-edit-phone-number,
  &.body-edit-name,
  &.body-edit-username,
  &.body-edit-legal-name,
  &.body-edit-password {
    .form-item-button-submit {
      margin-bottom: 0;
    }

    button[type='submit'] {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &.body-edit-password {
    .form-item-button-submit {
      margin-top: 48px;
    }
  }

  .ant-form-item {
    &.preferred-name,
    &.username,
    &.first-name,
    &.last-name,
    &.email,
    &.phone-number {
      margin-bottom: 32px;
    }
  }

  ${mediaFrom(
    'tablet',
    css`
      ${SModalDesktopCommonStyle};
    `
  )};
`;

export const SModalAddressLayout = styled.div`
  ${SLayoutInitStyle};

  .content-wrapper {
    margin-bottom: 32px;

    .base-input-container {
      margin-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    .is-mailing-different-container {
      .ant-form-item {
        margin: 32px 0 0;

        .checkbox-custom {
          display: flex;
        }
      }
    }

    .col-state-province,
    .col-postal-code {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .ant-form-item {
    &.submit-button {
      margin-bottom: 0;

      .btn__inner {
        font-weight: 500;
      }
    }
  }

  .footer {
    margin-top: 32px;
    margin-bottom: 0;
  }

  ${mediaFrom(
    'tablet',
    css`
      .content-wrapper {
        ${STitleStyle};
        ${SDescriptionStyle};
        margin-bottom: 24px;

        .ant-form-item {
          margin-bottom: 24px;
        }

        .col-state-province,
        .col-postal-code {
          .ant-form-item {
            margin-bottom: 0;
          }
        }

        .col-state-province {
          padding-right: 12px !important;
        }

        .col-postal-code {
          padding-left: 12px !important;
        }

        .is-mailing-different-container {
          .ant-form-item {
            margin: 24px 0 0;
          }
        }
      }

      .ant-form-item {
        &.submit-button {
          ${SSubmitButtonStyle};

          padding: 0;
          margin-bottom: 0;
        }
      }

      .footer {
        margin-top: 24px;
        margin-bottom: 0;
      }
    `
  )};
`;
