import React from 'react';
import { Chip } from 'components/general/Chip/Chip';
import { STabs, STabLabels } from './Tabs.styles';

interface ITabLabelsProps {
  labels: { id: string; title: string; hidden?: boolean }[];
  onSelectTab: (tabName: string) => void;
  activeTabId?: string;
}

interface ITabPaneProps {
  tabId: string;
  children: React.ReactNode;
}

interface ITabsProps {
  activeTabId?: string;
  tabs: {
    id: string;
    component: React.FC;
  }[];
}

export const TabLabels = ({ labels, onSelectTab, activeTabId }: ITabLabelsProps) => (
  <STabLabels className="tab-labels">
    {labels.map(
      (label) =>
        !label.hidden && (
          <Chip className="tab-labels__label" isActive={activeTabId === label.id} onClick={() => onSelectTab(label.id)}>
            {label.title}
          </Chip>
        )
    )}
  </STabLabels>
);

export const TabPane = ({ tabId, children }: ITabPaneProps) => (
  <div className="tab-pane" data-tab-name={tabId}>
    {children}
  </div>
);

export const Tabs = ({ activeTabId = '', tabs }: ITabsProps) => (
  <STabs className="tabs" activeTabName={activeTabId}>
    {tabs.map((tab) => (
      <TabPane tabId={tab.id}>
        <tab.component />
      </TabPane>
    ))}
  </STabs>
);
