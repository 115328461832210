import React from 'react';
import { ProfileChangePasswordPage } from 'views/Profile/ChangePassword/ProfileChangePasswordPage';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import clsx from 'clsx';
import { EditLegalName } from './EditLegalName';
import { EditName } from './EditName';
import { EditAddress } from './EditAddress';
import { EditMailingAddress } from './EditMailingAddress';
import { EditEmail } from './EditEmail/EditEmail';
import { EditUsername } from './EditUsername';
import { EditPhoneNumber } from './EditPhoneNumber/EditPhoneNumber';
import { SCustomSheet } from './ProfileEditSheet.styles';

interface IProps {
  isSheetOpen?: boolean;
  closeSheet?: () => void;
  type?: string;
  mode?: string;
}

const sheetContentMap: { [key: string]: (props: IProps) => JSX.Element } = {
  legalName: EditLegalName,
  preferredName: EditName,
  username: EditUsername,
  address: EditAddress,
  mailingAddress: EditMailingAddress,
  phoneNumber: EditPhoneNumber,
  email: EditEmail,
  password: ProfileChangePasswordPage,
};

interface ISheetProps {
  isOpen: boolean;
  type: string;
  className?: string;
  mode?: string;
  closeSheet: () => void;
}

export const ProfileEditSheet: React.FC<ISheetProps> = ({ isOpen, type, className, mode = 'edit', closeSheet }) => {
  const { isDesktopSize } = useDeviceDimension();
  const handleOnCancel = () => {
    closeSheet();
  };

  const ContentComponent = type !== undefined ? sheetContentMap[type] : null;

  return (
    <SCustomSheet
      isModal={isDesktopSize}
      height="fit-content"
      isOpen={isOpen}
      header={false}
      wrapperPadding={isDesktopSize}
      paddingTop="32px"
      padding="18px"
      onClose={handleOnCancel}
      className={clsx(type, className, 'profile-edit-sheet', isDesktopSize && 'desktop')}
      modalName="profileEdit"
    >
      {ContentComponent && <ContentComponent isSheetOpen={isOpen} closeSheet={closeSheet} type={type} mode={mode} />}
    </SCustomSheet>
  );
};
