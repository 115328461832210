import React from 'react';
import { TCountriesItem } from 'views/MoveMoney/InternationalTransfer/types';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { SCustomSheet } from './SelectedCountrySheet.styles';
import { TransferMethodsItem } from './TransferMethodsItem/TransferMethodsItem';

type TSheetProps = {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  selectedCountry: TCountriesItem | null;
};

export const SelectedCountrySheet: React.FC<TSheetProps> = ({ isOpen, onClose, onBack, selectedCountry }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <SCustomSheet isOpen={isOpen} onClose={onClose} paddingTop="34px" paddingBottom="48px">
      <div className="header">
        <Icon name="arrowLeft" color="charcoal" onClick={onBack} cursorPointer size="small" marginRight={10} />
        <Title>{t('internationalTransferPage.TransferInformation')}</Title>
      </div>

      <div className="selected-country">
        <Icon name={selectedCountry?.iconName ?? 'flagSV'} marginRight={16} />

        <BodyText size="M" fontWeight="R">
          {selectedCountry?.title}
        </BodyText>
      </div>

      <div className="section-fees">
        <BodyText size="N" fontWeight="B" marginBottom={12}>
          {t('internationalTransferPage.Fees')}
        </BodyText>

        <div className="currency-wrapper">
          <BodyText size="T" fontWeight="R">
            {t('internationalTransferPage.Currency')}
          </BodyText>

          <BodyText size="T" fontWeight="B">
            USD
          </BodyText>
        </div>
      </div>

      <div className="section-transfer-methods">
        <BodyText size="N" fontWeight="B" marginBottom={12}>
          {t('internationalTransferPage.TransferMethods')}
        </BodyText>

        <TransferMethodsItem iconName="debitCard2" title="internationalTransferPage.ByDebitCard" available />
        <TransferMethodsItem iconName="bank" title="internationalTransferPage.ToTheirBankAccount" available />
        <TransferMethodsItem iconName="cash" title="internationalTransferPage.CashPickUp" available={false} />
      </div>

      <SuttonDisclaimerNote />
    </SCustomSheet>
  );
};
