import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { IconSign } from 'components/general/Icon/IconSign';
import { BodyText } from 'components/general/Typography';
import { SFAQGroup } from './FAQGroup.styles';

interface IFAQGroup {
  title: string;
  id: string;
  intentsCount: number;
  onClick: (id: string) => void;
}

export const FAQGroup = ({ title, id, intentsCount, onClick }: IFAQGroup) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick(id);
  };

  return (
    <SFAQGroup onClick={handleClick} className="FAQGroup">
      <div className="FAQGroup__content">
        <IconSign iconName="questionAndInfo" bgColor="blue5" iconColor="blue" className="faq-group-sign" />
        <div className="FAQGroup__text">
          <BodyText textType="bodyText" size="M" fontWeight="B" cursorPointer color="charcoal">
            {title}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal70" cursorPointer size="T" fontWeight="R">
            {`${intentsCount} ${t('helpSupport.questions answered')}`}
          </BodyText>
        </div>
      </div>
      <Icon color="blue" size="smaller" name="chevronRight" className="FAQGroup__arrow" />
    </SFAQGroup>
  );
};
