import React, { useLayoutEffect, useRef, useState } from 'react';
import { Icon } from 'components/general/Icon/Icon';
import clsx from 'clsx';
import { CountryFlag } from 'views/MoveMoney/InternationalTransfer/CountryFlag/CountryFlag';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'utils/hooks/useLanguage';
import { ISystemProperty } from 'store/user/properties/types';
import { SLayout } from './DestinationListItem.styles';

export const DestinationListItem: React.FC<ISystemProperty> = ({ country, currency, flag, min, transferMethods }) => {
  const { t } = useTranslation('moveMoney');
  const [isExpanded, setIsExpanded] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(0);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const { language } = useLanguage();

  const toggleBodyVisible = () => {
    setIsExpanded((prevState: boolean) => !prevState);
  };

  useLayoutEffect(() => {
    if (isExpanded) {
      if (bodyRef.current) setBodyHeight(bodyRef.current?.offsetHeight);
    } else {
      setBodyHeight(0);
    }
  }, [isExpanded, country]);

  return (
    <SLayout className={clsx('card', isExpanded && 'expanded')} bodyHeight={bodyHeight}>
      <div className="header" onClick={toggleBodyVisible}>
        <CountryFlag flagCode={flag} title={country} marginRight={16} />

        <BodyText size="M" className="country-name" marginRight={16}>
          {country}
        </BodyText>

        <BodyText size="M" fontWeight="B" marginRight={16}>
          {currency}
        </BodyText>

        <Icon name={isExpanded ? 'chevronUp' : 'chevronDown'} size="smallest" />
      </div>

      <div className="card-body-wrapper">
        <div className="card-body" ref={bodyRef}>
          <div className="min-transfer-amount-wrapper">
            <BodyText size="S" color="charcoal70" marginRight={4}>
              {t('internationalTransferPage.MinTransferAmount')}
            </BodyText>

            <BodyText size="T" fontWeight="B">
              {min}
            </BodyText>
          </div>

          <div className="columns">
            <div className="row titles">
              <BodyText size="S" color="charcoal70" className="column-title transfer-methods-cell">
                {t('internationalTransferPage.AvailableTransferMethods')}
              </BodyText>

              <BodyText size="S" color="charcoal70" className="column-title fees-cell">
                {t('internationalTransferPage.ServiceFees')}
              </BodyText>

              <BodyText size="S" color="charcoal70" className="column-title max-amount-cell">
                {t('internationalTransferPage.MaxAmount')}
              </BodyText>
            </div>

            <div className="row">
              {transferMethods?.map((methodItem: any) => {
                let iconName = methodItem.method;
                let methodTitle = methodItem.method;

                if (methodItem.method === 'debitCard') {
                  iconName = 'debitCard2';
                  methodTitle = 'internationalTransferPage.ByDebitCard';
                }

                if (methodItem.method === 'bankAccount') {
                  iconName = 'bank';
                  methodTitle = 'internationalTransferPage.ToBankAccount';
                }

                if (methodItem.method === 'cashPickup') {
                  iconName = 'cash';
                  methodTitle = 'internationalTransferPage.CashPickUp';
                }

                return (
                  <div className="row-item">
                    <div className="cell transfer-methods-cell">
                      <Icon name={iconName} color="blue60" marginRight={8} />
                      <BodyText size="T">{t(methodTitle)}</BodyText>
                    </div>

                    <div className="cell fees-cell">
                      <BodyText size="T" fontWeight="B" marginRight={3}>
                        ${methodItem[language]?.fees}
                      </BodyText>

                      {methodItem[language]?.feesPlus ? (
                        <>
                          <BodyText size="S" color="charcoal70" paddingTop={2} marginRight={3}>
                            +
                          </BodyText>

                          <BodyText size="S" color="charcoal70" paddingTop={2}>
                            {methodItem[language]?.feesPlus}
                          </BodyText>
                        </>
                      ) : null}
                    </div>

                    <div className="cell max-amount-cell">
                      <BodyText size="T" fontWeight="B">
                        ${methodItem[language].maxAmount}
                      </BodyText>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </SLayout>
  );
};
