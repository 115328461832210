import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { SAccountInformationModal } from 'views/Account/AccountInformation/SelectedAccountInformationPage.styles';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface ICheckCloseAccountInformationModal {
  isOpen: boolean;
  onClose: () => void;
  onClickHandler: () => void;
}

export const SureCloseCashAccount: React.FC<ICheckCloseAccountInformationModal> = ({ isOpen, onClose, onClickHandler }) => {
  const { t } = useTranslation('accountInformation');
  const { isDesktopSize, isTabletSize } = useDeviceDimension();

  return (
    <CustomSheet
      width={isDesktopSize || isTabletSize ? '648px' : '280px'}
      isModal
      isOpen={isOpen}
      onClose={onClose}
      modalName="cashCheckClose"
      padding="15px"
      closable={false}
      wrapperPadding={isDesktopSize}
    >
      <SAccountInformationModal>
        <Title color="charcoal" fontWeight="M" font="Poppins" size="M" marginBottom={15}>
          {t('accountInformation.AreYouSureWantCloseCashAccount?')}
        </Title>

        <section className="checkCloseCashAccountInformationModalList">
          <BodyText textType="bodyText" size="N" color="charcoal" fontWeight="B" lineHeight={1.3} paddingRight={10} marginTop={30} marginBottom={10}>
            {t(`accountInformation.WhatHappensWhenRequestToCloseCashAccount?`)}
          </BodyText>
          <div className="closeCashAccountListItems">
            <ul>
              <li className="closeCashAccountListItemsInner">
                <Trans
                  i18nKey="accountInformation.YourAccountWillBeSuspendedTransactionCapabilitiesWillBeLimited"
                  ns="accountInformation"
                  components={{
                    Bold: <div className="bold-text" />,
                  }}
                />
              </li>
              <li className="closeCashAccountListItemsInner">
                <Trans
                  i18nKey="accountInformation.YourDebitCardWillStopWorking"
                  ns="accountInformation"
                  components={{
                    Bold: <div className="bold-text" />,
                  }}
                />
              </li>
              <li className="closeCashAccountListItemsInner">
                <Trans
                  i18nKey="accountInformation.YouNoLongerAbleToAddMoneyToAccount"
                  ns="accountInformation"
                  components={{
                    Bold: <div className="bold-text" />,
                  }}
                />
              </li>
              <li className="closeCashAccountListItemsInner">
                <Trans
                  i18nKey="accountInformation.NeedsAndGoalsAccountsWillBeClosedAnyRemainingFundsWillBeMovedRemainingCashAccountBalance"
                  ns="accountInformation"
                  components={{
                    Bold: <div className="bold-text" />,
                  }}
                />
              </li>
              <li className="closeCashAccountListItemsInner">
                <Trans
                  i18nKey="accountInformation.YouWillReceiveInstructionsYourRemainingCashAccountBalance"
                  ns="accountInformation"
                  components={{
                    Bold: <div className="bold-text" />,
                  }}
                />
              </li>
            </ul>
            <BodyText textType="bodyText" size="N" color="charcoal" fontWeight="B" lineHeight={1.3} paddingRight={10} marginTop={25} marginBottom={20}>
              {t(`accountInformation.ShouldBeforeRequestCloseCashAccount`)}
            </BodyText>
            <ul>
              <li className="closeCashAccountListItemsInner">
                <Trans
                  i18nKey="accountInformation.UpdateYourPayrollProvider"
                  ns="accountInformation"
                  components={{
                    Bold: <div className="bold-text" />,
                  }}
                />
              </li>
              <li className="closeCashAccountListItemsInner">
                <Trans
                  i18nKey="accountInformation.UpdateAnyMerchants"
                  ns="accountInformation"
                  components={{
                    Bold: <div className="bold-text" />,
                  }}
                />
              </li>
              <li className="closeCashAccountListItemsInner">
                <Trans
                  i18nKey="accountInformation.DownloadAnyStatementsOrAccountDocuments"
                  ns="accountInformation"
                  components={{
                    Bold: <div className="bold-text" />,
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="closeCashAccountListItemsOpacity" />
        </section>

        <BodyText textType="bodyText" size="N" color="charcoal" fontWeight="R" lineHeight={1.3} marginBottom={25}>
          {t(`accountInformation.ByClickingYesYouAgree`)}
        </BodyText>

        <div className="checkAccountModalFooter">
          <Button preset="primary-red" size="xl" onClick={onClickHandler}>
            {t('accountInformation.CloseMyCashAccount')}
          </Button>

          <Button preset="red" size="xl" onClick={onClose} marginTop={20}>
            {t('accountInformation.Cancel')}
          </Button>
        </div>
      </SAccountInformationModal>
    </CustomSheet>
  );
};
