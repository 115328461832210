import styled from 'styled-components';
import { flex } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  ${flex('row', 'flex-start')};

  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  .methods-item-title {
    ${flex('row', 'flex-start')};
    flex: 1;
  }
`;
