import styled from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { flex, getColor } from 'utils/helpers/styleHelpers';

export const SCustomSheet = styled(CustomSheet)`
  .header {
    padding-right: 24px;
    padding-left: 24px;
  }

  .countries-list-layout {
    ${flex('column', 'flex-start', 'stretch')};

    .countries-list {
      padding-right: 24px;
      padding-left: 24px;
    }

    .title {
      background: ${getColor('charcoal5')};
      padding: 12px 20px;
      margin-bottom: 24px;
    }

    .link {
      font-size: 16px;
      font-family: DM Sans, sans-serif;
      font-weight: 400;
      color: ${getColor('blue')};
      text-decoration: underline;
    }

    .align-self-center {
      justify-self: center !important;
      align-self: center !important;
    }
  }
`;
