/* eslint-disable @typescript-eslint/no-unused-vars */
import { BodyText, Title } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import React, { useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IAccount } from 'store/user/payments/payments.types';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { AmountInputComponent } from 'components/general/AmountInput/AmountInputComponent';
import { useToggle } from 'utils/hooks/useToggle';
import { useSelector } from 'react-redux';
import { selectAccountsData } from 'store/user/accounts/accounts.slice';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useTheme } from 'styled-components';
import { useLanguage } from 'utils/hooks/useLanguage';
import { CurrencyFormatters } from 'utils/helpers/CurrencyFormatters';
import { SAmountResult, SIFrame, SIframeCloseButton } from 'views/PercapitaPay/MainPage/PercapitaPayMainPage.styles';
import { Button } from 'components/theme/Button/Button';
import { Icon } from 'components/general/Icon/Icon';
import { CardCarousel } from 'views/PercapitaPay/MainPage/components/CardCarousel/CardCarousel';
import { Tooltip } from 'views/Profile/ProfilePage/Tooltip/Tooltip';
import { selectPaymentsAccountsData } from 'store/user/payments/payments.slice';
import { IErrorData } from './PaymentPrepareSheet';
import { SContainer } from './PaymentPrepareModal.styles';

interface IPaymentRequestModal {
  isOpen: boolean;
  isLoading: boolean;
  selectedAccount: number | null;
  availableAmount: number;
  availableMin: number;
  accounts: IAccount[];
  getAccounts: (param: {}) => void;
  handleAddAccountClick: () => void;
  toggleRequestPercPay: () => void;
  handleSelectAccount: (accountId: number) => void;
  onConfirm: (transferAmount: number) => void;
}

export const PaymentPrepareModal: React.FC<IPaymentRequestModal> = ({
  isOpen,
  isLoading,
  selectedAccount,
  availableAmount,
  availableMin,
  accounts,
  getAccounts,
  handleAddAccountClick,
  toggleRequestPercPay,
  handleSelectAccount,
  onConfirm,
}) => {
  const { t } = useTranslation('percapitaPay');
  const [isIframeOpen, setIframeOpen] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<IErrorData>({ isError: false, message: '' });
  const [amountToPay, setAmountToPay] = useState<string>(String(availableAmount));
  const [isAmountEditable, setAmountEditable] = useState<boolean>(true);
  const paymentRequestInfoModal = useToggle(false);
  const [isOpenInfoTooltip, setOpenInfoTooltip] = useState(false);

  const { fee } = useSelector(selectPaymentsAccountsData);
  const { immediatePayFrameUrl } = useSelector(selectAccountsData);
  const { isDesktopSize } = useDeviceDimension();

  const theme = useTheme();

  const { locale } = useLanguage();
  const centPrecisionFormatter = CurrencyFormatters.getCentPrecisionFormatter(locale);
  const valueAvailableUpTo = centPrecisionFormatter.format(availableAmount);
  const formattedFee = centPrecisionFormatter.format(Number(fee));

  const handleContinueClick = () => {
    onConfirm(Number(amountToPay));
  };

  const handleCloseIframe = () => {
    setIframeOpen(false);
    getAccounts({});
  };

  const handleInfoClick = () => {
    paymentRequestInfoModal.show();
  };

  const onClickAmountValue = () => {
    setAmountEditable(false);
  };

  const handleAmountFieldBlur = () => {
    setAmountEditable(true);
  };

  const onInfoModalClose = () => {
    paymentRequestInfoModal.hide();
  };

  const handleAmountFieldChange = (value: string) => {
    setAmountToPay(value);
    if (Number(value) > availableAmount) {
      setErrorData({ isError: true, message: t('percapitaPayHome.The amount you have requested is more...') });
    } else if (Number(value) < availableMin) {
      setErrorData({
        isError: true,
        message: t('percapitaPayHome.The amount you have requested is less...', { minAmount: availableMin }),
      });
    } else {
      setErrorData({ isError: false });
    }
  };

  const InfoTooltip = useMemo(
    () => (
      <Tooltip
        open={isOpenInfoTooltip}
        onClose={() => setOpenInfoTooltip(false)}
        layoutExtraStyles={{ top: '-100px', right: '-295px', zIndex: '99999', bottom: 'unset', left: 'unset', width: '328px' }}
      >
        <BodyText size="T" fontWeight="B" marginBottom={4} className="tooltip-header-text">
          {t('percapitaPayHome.How do fees work')}
        </BodyText>

        <BodyText size="T" color="charcoal70" className="tooltip-body-text">
          <Trans i18nKey="percapitaPayHome.FlatServiceFee" ns="percapitaPay" components={{ Bold: <strong /> }} values={{ formattedFee }} />
        </BodyText>
      </Tooltip>
    ),
    [isOpenInfoTooltip, t]
  );

  return (
    <CustomModal open={isOpen} modalName="paymentRequest" closeIconColor="charcoal70" topPosition="0" width="50%" onClose={() => toggleRequestPercPay()}>
      <div>
        <Title font="Poppins" size="XL" fontWeight="M" color="charcoal" marginBottom={32}>
          {t('percapitaPayHome.Request Percapita Pay')}
        </Title>

        <SContainer>
          <div className="input-header">
            <BodyText font="Poppins" size="M" fontWeight="B" color="charcoal" textType="bodyText">
              {t('percapitaPayHome.Enter Amount')}
            </BodyText>

            <div className="fee-container">
              <BodyText font="Poppins" size="N" fontWeight="R" color="charcoal" textType="bodyText" marginRight={10}>
                {t('percapitaPayHome.Fee')}
              </BodyText>
              <CustomAmount amount={3} color="blue" size="smallerStrong" />
              <Icon name="info" color="blue" size="smaller" cursorPointer marginLeft={10} onClick={() => setOpenInfoTooltip(true)} />
              {isOpenInfoTooltip && InfoTooltip}
            </div>
          </div>
          <CustomCard border={`2px solid ${errorData?.isError ? theme.red : theme.blue}`} marginBottom={20} extraStyles={{ minHeight: 114, padding: '24px 24px 2px !important' }}>
            <div className="flex flex-column flex-end">
              {isAmountEditable ? (
                <SAmountResult onClick={onClickAmountValue}>
                  <CustomAmount amount={Number(amountToPay)} color="charcoal" size="larger" remainingSize="xl" remainingWeight={600} multiSizable isPoppins />
                </SAmountResult>
              ) : (
                <AmountInputComponent
                  className="percapita-amount-input"
                  value={Number(amountToPay) > 0 ? amountToPay : ''}
                  onChange={handleAmountFieldChange}
                  onBlur={handleAmountFieldBlur}
                  autoFocus={!isAmountEditable}
                  noPrefix={isDesktopSize}
                />
              )}

              <BodyText color="charcoal70" size="N" textType="helperText" fontWeight="R" marginTop={2} justifyContent="end">
                <Trans
                  i18nKey="percapitaPayHome.One transfer of up to..."
                  ns="percapitaPay"
                  values={{
                    valueAvailableUpTo,
                  }}
                />
              </BodyText>
            </div>
          </CustomCard>

          <div className="error-text">
            <BodyText color="red" size="N" fontWeight="R" textType="helperText">
              {errorData?.message}
            </BodyText>
          </div>

          {!accounts?.length ? (
            <div className="flex flex-column flex-start payment-prepare-sheet-account-part">
              <BodyText font="Poppins" size="M" textType="bodyText" color="charcoal" marginBottom={4} fontWeight="SB">
                {t('percapitaPayHome.AddDebitOrBankAccount')}
              </BodyText>

              <BodyText color="charcoal70" marginRight={5} size="N" fontWeight="R" textType="helperText">
                {t('percapitaPayHome.YourMoneyWillBeDelivered')}
              </BodyText>
            </div>
          ) : (
            <div className="flex flex-column flex-start payment-prepare-sheet-account-part">
              <BodyText font="Poppins" size="M" textType="bodyText" color="charcoal" marginBottom={4} fontWeight="SB">
                {t('percapitaPayHome.SelectAccountOrCard')}
              </BodyText>

              <BodyText color="charcoal70" marginRight={5} size="N" fontWeight="R" textType="helperText">
                {t('percapitaPayHome.DebitCardAvailable')}
              </BodyText>
            </div>
          )}

          <CardCarousel className="carousel" accounts={accounts} handleAddAccountClick={handleAddAccountClick} handleSelectAccount={handleSelectAccount} selectedAccount={selectedAccount} />

          <div className="buttons-container">
            <Button size="middleAlt" marginBottom={32} marginRight={10} marginTop={24} onClick={() => toggleRequestPercPay()}>
              {t('percapitaPayHome.Cancel')}
            </Button>

            <Button size="middleAlt" disabled={errorData?.isError} preset="primary" marginBottom={32} marginTop={24} onClick={handleContinueClick}>
              {t('percapitaPayHome.Continue')}
            </Button>
          </div>

          <CustomModal
            open={isIframeOpen}
            onCancel={handleCloseIframe}
            padding="7px"
            topPosition="0"
            closeIcon={
              <SIframeCloseButton>
                <Icon name="close" size="small" color="blue" />
              </SIframeCloseButton>
            }
          >
            <SIFrame width="100%" height="100%" title="add account" src={immediatePayFrameUrl} />
          </CustomModal>
        </SContainer>
      </div>
    </CustomModal>
  );
};
