import { BodyText } from 'components/general/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { TIconName } from 'components/general/Icon/Icon.types';
import { SLayout } from './TransferMethodsItem.styles';

interface ITransferMethodsItemProps {
  iconName: TIconName;
  title: string;
  available: boolean;
}

export const TransferMethodsItem: React.FC<ITransferMethodsItemProps> = ({ iconName, title, available }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <SLayout>
      <Icon name={iconName} color="blue" marginRight={10} />

      <BodyText size="T" fontWeight="R" className="methods-item-title">
        {t(title)}
      </BodyText>

      <Icon name={available ? 'tickInCircle' : 'circleClose'} color={available ? 'green' : 'red'} />
    </SLayout>
  );
};
