import React from 'react';
import { TThemeColor } from 'styles/theme';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SBar, SBlockWrapper, SContainer, SWebContainer, SWebStep } from './Stepper.styles';

export interface IStepper {
  steps: string[];
  webSteps: string[];
  currentStep: number;
  currentWebStep: number;
  passedColor?: TThemeColor;
  activeColor?: TThemeColor;
  inactiveColor?: TThemeColor;
  scrollable?: boolean;
}

export const Stepper: React.FC<IStepper> = ({ steps, webSteps, scrollable = false, currentStep = 0, currentWebStep = 0, passedColor = 'green', activeColor = 'blue', inactiveColor = 'creamS10' }) => {
  const { t } = useTranslation('header');

  const getBarColor = (index: number): TThemeColor => {
    if (currentStep === index) {
      return activeColor;
    }

    return currentStep > index ? passedColor : inactiveColor;
  };

  const getWebColor = (index: number): TThemeColor => {
    if (currentWebStep === index) return 'blue';
    if (currentWebStep > index) return 'charcoal70';
    return 'creamS10';
  };

  return (
    <>
      <SContainer>
        {steps.map((step, index) => {
          return (
            <SBlockWrapper>
              <SBar bgColor={getBarColor(index)} />
            </SBlockWrapper>
          );
        })}
      </SContainer>
      <SWebContainer scrollable={scrollable}>
        {webSteps.map((step, index) => {
          return (
            <SWebStep className={clsx({ active: currentWebStep === index, done: currentWebStep > index })}>
              <BodyText textType="bodyText" size="T" fontWeight="R" color={getWebColor(index)}>
                {t(`header.${step}`)}
              </BodyText>
              {currentWebStep > index && <Icon name="tick" color="green" size="smallest" />}
            </SWebStep>
          );
        })}
      </SWebContainer>
    </>
  );
};
