import React from 'react';
import { Card } from 'views/MoveMoney/InternationalTransfer/HowDoesItWorkSection/Card/Card';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { IconSign } from 'components/general/Icon/IconSign';

export const WhatYouNeedItem: React.FC = () => {
  const { t } = useTranslation('moveMoney');

  return (
    <Card title={t('internationalTransferPage.WhatYouNeed')}>
      <>
        <div className="what-you-need-item">
          <div className="main-icon">
            <IconSign iconName="idFront" bgColor="blue10" iconColor="blue" size={40} />
          </div>

          <div className="content">
            <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.YourNameAndDateOfBirth')}
            </BodyText>
          </div>
        </div>

        <div className="what-you-need-item">
          <div className="main-icon">
            <IconSign iconName="rent" bgColor="blue10" iconColor="blue" size={40} />
          </div>

          <div className="content">
            <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.YourHomeAddressNoPOBoxes')}
            </BodyText>
          </div>
        </div>

        <div className="what-you-need-item">
          <div className="main-icon">
            <IconSign iconName="mail2" bgColor="blue10" iconColor="blue" size={40} />
          </div>

          <div className="content">
            <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.YourEmailAddress')}
            </BodyText>
          </div>
        </div>

        <div className="what-you-need-item">
          <div className="main-icon center">
            <IconSign iconName="idBack" bgColor="blue10" iconColor="blue" size={40} />
          </div>

          <div className="content">
            <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.YourSocialSecurityNumberSSN')}
            </BodyText>
          </div>
        </div>

        <div className="what-you-need-item unexpired-photo-id">
          <div className="main-icon">
            <IconSign iconName="myInfo" bgColor="blue10" iconColor="blue" size={40} />
          </div>

          <div className="content">
            <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.ValidUnexpiredPhotoId')}
            </BodyText>

            <ul className="list">
              <li>
                <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                  {t('internationalTransferPage.AcceptableUSIdsInclude')}
                </BodyText>
              </li>

              <li className="v-center">
                <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                  {t('internationalTransferPage.AcceptableNonUsIdsIncludePassport')}
                </BodyText>
              </li>
            </ul>
          </div>
        </div>

        <div className="what-you-need-item">
          <BodyText size="T" className="content-body" color="charcoal70" lineHeight={1.4}>
            {t('internationalTransferPage.YourInformationIsOnlyRequiredOnce')}
          </BodyText>
        </div>
      </>
    </Card>
  );
};
