import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { Card } from 'views/MoveMoney/InternationalTransfer/HowDoesItWorkSection/Card/Card';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';

export const HowToUseItem: React.FC = () => {
  const { t } = useTranslation('moveMoney');

  return (
    <Card title={t('internationalTransferPage.HowToUse')}>
      <>
        <div className="how-to-use-item">
          <div className="number">1.</div>

          <div className="content-wrapper">
            <Title size="XS" font="DM Sans" fontWeight="B" marginBottom={6} className="content-title">
              {t('internationalTransferPage.EnterTransferAmount')}
            </Title>

            <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.SelectYourDestinationCountryAnd')}
            </BodyText>
          </div>
        </div>

        <div className="how-to-use-item">
          <div className="number">2.</div>

          <div className="content-wrapper">
            <Title size="XS" font="DM Sans" fontWeight="B" marginBottom={6} className="content-title">
              {t('internationalTransferPage.SelectTransferMethod')}
            </Title>

            <BodyText size="N" color="charcoal70" lineHeight={1.4} marginBottom={12}>
              {t('internationalTransferPage.DependingOnTheDestinationCountrySelected')}
            </BodyText>

            <div className="transfer-method-item">
              <Icon name="debitCard2" color="blue60" />

              <BodyText size="T" color="charcoal" lineHeight={1.4}>
                {t('internationalTransferPage.ByDebitCard')}
              </BodyText>
            </div>

            <div className="transfer-method-item">
              <Icon name="bank" color="blue60" />

              <BodyText size="T" color="charcoal" lineHeight={1.4}>
                {t('internationalTransferPage.ToTheirBankAccount')}
              </BodyText>
            </div>

            <div className="transfer-method-item">
              <Icon name="cash" color="blue60" />

              <BodyText size="T" color="charcoal" lineHeight={1.4}>
                {t('internationalTransferPage.CashPickUp')}
              </BodyText>
            </div>
          </div>
        </div>

        <div className="how-to-use-item">
          <div className="number">3.</div>

          <div className="content-wrapper">
            <Title size="XS" font="DM Sans" fontWeight="B" marginBottom={6} className="content-title">
              {t('internationalTransferPage.SelectRecipient')}
            </Title>

            <BodyText size="N" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.ChooseFromAListOfPreviousRecipientsOr')}
            </BodyText>
          </div>
        </div>

        <div className="how-to-use-item">
          <div className="number">4.</div>

          <div className="content-wrapper">
            <Title size="XS" font="DM Sans" fontWeight="B" marginBottom={6} className="content-title">
              {t('internationalTransferPage.CompleteYourTransfer')}
            </Title>

            <BodyText size="N" color="charcoal70" lineHeight={1.4}>
              {t('internationalTransferPage.YouWillHaveAChanceToReview')}
            </BodyText>
          </div>
        </div>
      </>
    </Card>
  );
};
