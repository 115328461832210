import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { Button } from 'components/theme/Button/Button';
import { useFinishOtpSeedMutation, useGenerateCodeOtpSeedMutation } from 'store/user/authentication.api';
import { lsGetItem, lsRemoveItem } from 'utils/helpers/storage';
import { isBackendError } from 'utils/helpers/rtqErrorHandling';
import { Loader } from 'components/general/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { API_ROUTES } from 'vars/const/API_ROUTES';
import { SmsCodeForm } from 'components/general/SmsCodeForm/SmsCodeForm';
import { BodyText, Title } from 'components/general/Typography';
import { SLayout } from './DeviceVerifyPage.styles';

export const DeviceVerifyPage: React.FC = () => {
  const { t } = useTranslation('verification');
  const navigate = useNavigate();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [code, setCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [generateOtpCode, generateOtpCodeResult] = useGenerateCodeOtpSeedMutation();
  const [finishGetOtpSeed, finishGetOtpSeedResult] = useFinishOtpSeedMutation();
  const { transactionId } = lsGetItem('loginOtpFlow');

  const isLoading = generateOtpCodeResult.isLoading || finishGetOtpSeedResult.isLoading;

  const handleResError = (resError: unknown) => {
    if (isBackendError(resError)) {
      setErrorMsg(resError?.data?.Error || 'Unknown error');
    }
  };

  const handleSubmitCode = () => {
    setErrorMsg('');
    if (transactionId && code) {
      finishGetOtpSeed({
        transactionId,
        code,
      })
        .unwrap()
        .then((finishOtpRes) => {
          console.log(`${API_ROUTES.auth.otpSeed.finish} response: ${finishOtpRes}`);
          lsRemoveItem('loginOtpFlow');
          navigate(finishOtpRes?.systemProperties?.postLoginURL ?? ROUTES.home.path);
        })
        .catch((finishOtpErr) => handleResError(finishOtpErr));
    }
  };

  const generateCode = () => {
    if (transactionId) {
      generateOtpCode({
        transactionId,
        codeDelivery: 'Phone',
      }).unwrap();
    }
  };

  const handleCompletion = (codeVal: string) => {
    setCode(codeVal);
  };

  const handleResendCode = useCallback(() => {
    generateCode?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(transactionId);
    generateCode();
  }, [transactionId]);

  return (
    <SLayout>
      <div className="container">
        {isLoading && <Loader />}
        <Title size="M" fontWeight="M" marginBottom={48} marginTop={48}>
          {t('verification.Title')}
        </Title>
        <BodyText textType="bodyText" fontWeight="R" size="M" color="charcoal70" marginBottom={24}>
          {t('verification.ForYourSecurity')}
        </BodyText>

        <div className="card">
          <div className="inner">
            <BodyText textType="bodyText" color="charcoal" fontWeight="R" size="N" justifyContent="center">
              {t('verification.EnterCode')}
            </BodyText>
            <SmsCodeForm handleCompletion={handleCompletion} onCompletion={setIsSubmitDisabled} checkError={errorMsg} />

            <div className="desktop-resend-wrapper">
              <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N">
                {t('verification.DidntReceive')}
              </BodyText>
              <BodyText textType="bodyText" color="blue" cursorPointer font="Poppins" fontWeight="SB" size="N" onClick={handleResendCode}>
                {t('verification.Resend')}
              </BodyText>
            </div>
          </div>

          <Button preset="primary" type="submit" onClick={handleSubmitCode} disabled={isSubmitDisabled}>
            {isSubmitDisabled ? t('verification.Continue') : t('verification.Verify')}
          </Button>

          <div className="mobile-resend-wrapper">
            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N">
              {t('verification.DidntReceive')}
            </BodyText>
            <BodyText textType="bodyText" color="blue" cursorPointer font="Poppins" fontWeight="SB" size="N" onClick={handleResendCode}>
              {t('verification.Resend')}
            </BodyText>
          </div>
        </div>
      </div>
    </SLayout>
  );
};
