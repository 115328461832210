import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const CheckModal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  padding: 24px;

  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);

  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 520px;
  margin: auto;
  height: fit-content;
  padding: 24px;
  background-color: ${getColor('white')};
  position: relative;
  z-index: 2000;
  bottom: 0;
  border-radius: 16px;
`;
