import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SModalContent = styled.div`
  padding: 32px 24px;

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${mediaFrom(
      'tablet',
      css`
        gap: 24px;
        border-radius: 20px;
        border: 2px solid ${getColor('grey2')};
        padding: 24px;
      `
    )}
  }
`;

export const SActionButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;

  ${mediaFrom(
    'tablet',
    css`
      flex-direction: row-reverse;
      gap: 4px;
      padding: 32px 32px 24px 32px;

      button {
        flex: 1;
      }
    `
  )}
`;
