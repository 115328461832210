import React, { useState } from 'react';
import { Form } from 'antd';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { JointAccountModal } from 'components/general/Modals/JointAccountModal/JointAccountModal';
import { getRequiredRule, getPhoneValidator, getEmailRulesOptional, fullNameCharacterRule } from 'utils/helpers/validationRules';
import { Button } from 'components/theme/Button/Button';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { MaskedInput } from 'components/general/MaskedInput/MaskedInput';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'utils/hooks/useToggle';
import { useInviteJointAccountHolderMutation } from 'store/user/accounts/accounts.api';
import { getUnmaskedMobileNumber } from 'utils/helpers/phoneNumber';
import { Icon } from 'components/general/Icon/Icon';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { IJointAccountsForm } from './JointAccountsForm.types';

const PHONE_MASK = '(000) 000 0000';

const PHONE_MASK_OPTIONS = {
  lazy: true,
};

interface IJointAccountsFormProps {
  isArrow?: boolean;
  isAccountSetup: boolean;
  isUserUnder18?: boolean;
  fromPrep?: boolean;
}

export const JointAccountsForm = ({ isArrow = false, isAccountSetup, isUserUnder18 = false, fromPrep = false }: IJointAccountsFormProps) => {
  const [isActive, setIsActive] = useState(false);
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation(['cashAccountOpening', 'errors', 'profile', 'inviteEarn']);
  const jointAccountModal = useToggle(false);
  const [form] = Form.useForm();
  const { state } = useLocation();
  const [inviteJointAccountHolderAPI] = useInviteJointAccountHolderMutation();
  const isSecondaryCash = state?.alreadyHasCash;

  const isValid = () => {
    const errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    return errors.length === 0;
  };

  const handleFormChange = (): void => {
    const phoneNumber = form.getFieldValue('phone');
    const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
    setIsActive(phoneNumberDigits.length !== 0 && phoneNumberDigits.length !== undefined && phoneNumberDigits.length === 10);
  };

  const hasEmptyValues = () => {
    const values = Object.values(form.getFieldsValue());
    return values.includes(undefined);
  };

  const onFinish = (formValues: IJointAccountsForm) => {
    inviteJointAccountHolderAPI({ isPrimaryOwner: !isUserUnder18, ...formValues, phone: getUnmaskedMobileNumber(`1${formValues.phone}`) });
    jointAccountModal.show();
  };

  const onFieldsChange = () => {
    setIsActive(isValid() && !hasEmptyValues());
  };

  return (
    <>
      <Form onFinish={onFinish} onFieldsChange={onFieldsChange} autoComplete="off" layout="vertical" form={form} requiredMark={false}>
        <JointAccountModal
          open={jointAccountModal.isActive}
          onClose={jointAccountModal.hide}
          isUserUnder18={isUserUnder18}
          fromPrep={fromPrep}
          isAccountSetup={isAccountSetup}
          alreadyHasCash={isSecondaryCash}
        />
        <div className="card">
          <Form.Item
            label={<CustomRequiredLabel isDanger label={isUserUnder18 || fromPrep ? t('accountOpening.ParentOrGuardianFirstName') : t("accountOpening.Joint Accountholder's First Name")} />}
            name="firstName"
            validateTrigger={['onBlur', 'onChange']}
            validateFirst
            rules={[getRequiredRule(t('errors.PleaseInputTheirFirstName', { ns: 'errors' })), fullNameCharacterRule()]}
          >
            <BaseInput placeholder={t(`accountOpening.EnterTheirFirstName`)} />
          </Form.Item>

          <Form.Item
            label={<CustomRequiredLabel isDanger label={isUserUnder18 || fromPrep ? t('accountOpening.ParentOrGuardianLastName') : t("accountOpening.Joint Accountholder's Last Name")} />}
            name="lastName"
            validateTrigger={['onBlur', 'onChange']}
            validateFirst
            rules={[getRequiredRule(t('errors.PleaseInputTheirLastName', { ns: 'errors' })), fullNameCharacterRule()]}
          >
            <BaseInput placeholder={t(`accountOpening.EnterTheirLastName`)} />
          </Form.Item>

          <Form.Item
            label={<CustomRequiredLabel isDanger label={isUserUnder18 || fromPrep ? t('accountOpening.ParentOrGuardianEmail') : t("accountOpening.Joint Accountholder's Email")} />}
            name="email"
            validateTrigger={['onBlur', 'onChange']}
            validateFirst
            rules={[getRequiredRule(t('errors.PleaseInputTheirEmail', { ns: 'errors' })), getEmailRulesOptional()]}
          >
            <BaseInput placeholder={t(`accountOpening.EnterTheirEmail`)} inputMode="email" autoCapitalize="off" />
          </Form.Item>

          <Form.Item
            label={<CustomRequiredLabel isDanger label={isUserUnder18 || fromPrep ? t('accountOpening.ParentOrGuardianMobileNumber') : t("accountOpening.Joint Accountholder's Mobile Number")} />}
            name="phone"
            validateTrigger={['onBlur', 'onChange']}
            rules={[getRequiredRule(t('profile.Please enter your Phone Number', { ns: 'profile' })), getPhoneValidator(t('inviteEarn.PleaseInputCompleteMobileNumber', { ns: 'inviteEarn' }))]}
          >
            <MaskedInput mask={PHONE_MASK} maskOptions={PHONE_MASK_OPTIONS} placeholder={t(`accountOpening.EnterTheirMobileNumber`)} onChange={handleFormChange} inputMode="tel" />
          </Form.Item>
        </div>
      </Form>

      {isDesktopSize ? (
        <Footer isDisabled={!isActive} onClick={form.submit} />
      ) : (
        <div className="mobile-footer">
          <Button onClick={form.submit} disabled={!isActive} preset={!isActive ? 'primary-red' : 'primary'}>
            {t('common.Continue')} {isArrow && <Icon name="chevronRight" size="smallest" marginLeft={12} color={isActive ? 'white' : 'charcoal40'} />}
          </Button>
        </div>
      )}
    </>
  );
};
