import React from 'react';
import { Outlet } from 'react-router-dom';
import { SContainer, SContent } from './GridDesignLayout.styles';

export const GridDesignLayout = () => (
  <SContainer className="grid-layout">
    <SContent>
      <Outlet />
    </SContent>
  </SContainer>
);
