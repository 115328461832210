import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { formatLocaleDate } from 'utils/helpers/date';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAccounts } from 'utils/hooks/useAccounts';
import { useTransfers } from 'utils/hooks/useTransfers';
import { getAccountDisplayName, getAccountDisplayNumber, getAccountNameByAuth } from 'utils/helpers/accounts/accountsHelper';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES } from 'vars/const/ROUTES';
import { images } from 'assets';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { TransferDataRow } from 'components/general/Modals/Transfer/ExternalTransfer/TransferDataRow/TransferDataRow';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Button } from 'components/theme/Button/Button';
import { Loader } from 'components/general/Loader/Loader';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { FingerprintAndBehavioralData } from 'components/general/Modals/FundConfirmationModal/FingerprintAndBehavioralData/FingerprintAndBehavioralData';
import { resetCurrentTransfer, selectCurrentTransfer } from 'store/user/accounts/accounts.slice';
import { IAccountItem, IThirdParty, isThirdPartyAccount } from 'store/user/accounts/accounts.types';
import { useTransferMoneyMutation } from 'store/user/accounts/accounts.api';
import { theme } from 'styles/theme';
import { SLink } from 'views/HelpAndSupport/HelpAndSupport.styles';
import { TransferErrorModal } from './TransferErrorModal';
import { INGO_DECLINE_ERRORS_CODE, INGO_LIMIT_ERRORS_CODE } from './TransferPage';
import { SInternalTransferPage } from './TransferPage.styles';

export const ConfirmTransferPage = () => {
  const { currentWindowSize, fromDesktopSmall } = useDeviceDimension();
  const isDesktopSize = currentWindowSize >= 577;
  const { locale } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(['moveMoney', 'translations']);
  const currentTransfer = useSelector(selectCurrentTransfer);
  const { internalAccounts, externalAccounts, isLoading: areAccountsLoading } = useAccounts();
  const allAccounts = [...internalAccounts, ...externalAccounts];
  const fromAccount = useMemo(() => allAccounts.find((acc) => acc.accountId === currentTransfer.fromId || acc.id === currentTransfer.fromId), [currentTransfer.fromId, allAccounts]);
  const toAccount = useMemo(() => allAccounts.find((acc) => acc.accountId === currentTransfer.toId || acc.id === currentTransfer.toId), [currentTransfer.toId, allAccounts]);
  const isToExternalAccount = !!toAccount && isThirdPartyAccount(toAccount);
  const { transferToExternal, transferFromExternal, riskSessionToken, riskSessionData, updateRiskSession, isTransferDataLoading, transferToExternalResult, transferFromExternalResult } =
    useTransfers();
  const [transferMoneyAPI, transferMoneyAPIResult] = useTransferMoneyMutation();
  const currentDate = formatLocaleDate(new Date(), 'MMM dd, yyyy', locale);
  const transferErrorModal = useToggle(false, { message: '', title: '', isIngoError: false } as { message: string | React.ReactElement; title: string; isIngoError: boolean });

  const handleTransferError = (error: any) => {
    if (INGO_DECLINE_ERRORS_CODE.includes(error?.data?.Data?.status)) {
      transferErrorModal.setData({
        message: t(`ThisTransactionWasDeclined`),
        title: t(`TransactionDeclined`),
        isIngoError: true,
      });
    } else if (INGO_LIMIT_ERRORS_CODE.includes(error?.data?.Data?.status)) {
      transferErrorModal.setData({
        message: <Trans ns="moveMoney" i18nKey="transferPage.SorryForTheInconvenience" components={{ Link: <SLink onClick={() => {}} /> }} />,
        title: t(`TransactionLimitExceeded`),
        isIngoError: true,
      });
    } else {
      transferErrorModal.setData({
        message: t('SorryWeAreUnableToCompleteThisTransaction'),
        title: t('TransferIncomplete'),
        isIngoError: true,
      });
    }
    transferErrorModal.show();
  };

  const handleConfirm = async () => {
    if (currentTransfer.isExternalTransfer) {
      const requestBody = {
        amount: Number(currentTransfer.amount),
        notes: currentTransfer.note ? [currentTransfer.note] : [],
        transactionId: uuidv4(),
        riskSessionToken,
      };

      const requestMethod = isToExternalAccount ? transferToExternal : transferFromExternal;
      const externalAccount = (isToExternalAccount ? toAccount : fromAccount) as IThirdParty;
      const internalAccount = (isToExternalAccount ? fromAccount : toAccount) as IThirdParty;

      if (externalAccount) {
        await requestMethod({
          ...requestBody,
          accountId: internalAccount.accountId,
          externalAccount: (externalAccount as IThirdParty).thirdPartyAccountId,
          accountType: (externalAccount as IThirdParty).thirdPartyAccountType,
        })
          .unwrap()
          .catch((error) => {
            handleTransferError(error);
          });
      }
    } else {
      const transferData = {
        fromAccountId: (fromAccount as IAccountItem)?.fiservAccountId,
        toAccountId: (toAccount as IAccountItem)?.fiservAccountId,
        amount: Number(currentTransfer.amount),
        notes: [currentTransfer.note],
        transactionId: uuidv4(),
      };
      await transferMoneyAPI(transferData);
    }
  };

  const handleDone = () => {
    navigate(ROUTES.transfers.path);
    dispatch(resetCurrentTransfer());
  };

  useEffect(() => {
    updateRiskSession();
  }, []);

  return (
    <>
      {(areAccountsLoading || isTransferDataLoading) && <Loader />}
      <CustomRow flexDirection="column" justifyContent="flex-start" alignItems="flex-start" paddingBottom={44} extraStyles={{ borderBottom: '1px solid #ECE8DC' }}>
        <CustomRow justifyContent="flex-start" marginBottom={8}>
          <Icon name="arrowLeft" cursorPointer color="blue" onClick={() => navigate(ROUTES.transfers.path)} marginRight={8} />
          <Title font="Poppins" fontWeight="SB" size="S" color="charcoal">
            {t('transferPage.TransferBetweenAccounts')}
          </Title>
        </CustomRow>
        {fromDesktopSmall && (
          <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5}>
            {t('transferPage.TransferPageDesctiption')}
          </BodyText>
        )}
      </CustomRow>
      {transferMoneyAPIResult.isSuccess || transferToExternalResult.isSuccess || transferFromExternalResult.isSuccess ? (
        <SInternalTransferPage isDesktop={isDesktopSize} className="confirm">
          <CustomRow marginBottom={32} flexDirection="column">
            <img src={images.congratulation} alt="successInternalTransfer" />
            <Title font="Poppins" color="charcoal" fontWeight="SB" size="S" marginTop={32} extraStyles={{ alignSelf: 'center' }}>
              {t('externalTransfer.TransferComplete')}
            </Title>
          </CustomRow>

          {!currentTransfer.isExternalTransfer && (
            <CustomRow flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="M" fontWeight="B" textAlign="start" marginBottom={16}>
                {t('internalTransfer.Transaction Receipt')}
              </BodyText>
            </CustomRow>
          )}

          <CustomCard border="2px solid #F5F4F4" marginTop={0} minWidth="230px" height="fit-content" extraStyles={{ margin: '0 auto' }}>
            <TransferDataRow title={t('externalTransfer.From')} value={fromAccount ? getAccountDisplayName(fromAccount) : ''} subvalue={fromAccount ? getAccountDisplayNumber(fromAccount) : ''} />
            <TransferDataRow title={t('externalTransfer.To')} value={toAccount ? getAccountDisplayName(toAccount) : ''} subvalue={toAccount ? getAccountDisplayNumber(toAccount) : ''} />
            <TransferDataRow title={t('externalTransfer.TransferAmount')} value={Number(currentTransfer.amount)} isAmount />
            <TransferDataRow title={t('externalTransfer.Date')} value={currentDate} />
            <TransferDataRow title="Funds Availability" value="Within 35 min" />
            <TransferDataRow title={t('internalTransfer.Note')} value={currentTransfer.note} isLast />
          </CustomCard>

          {!currentTransfer.isExternalTransfer && (
            <>
              <CustomRow flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="M" fontWeight="B" textAlign="start" marginBottom={16} marginTop={16}>
                  {t('internalTransfer.New Balances')}
                </BodyText>
              </CustomRow>
              <CustomCard border="2px solid #F5F4F4" marginTop={0} minWidth="230px" height="fit-content" extraStyles={{ margin: '0 auto' }}>
                {fromAccount && (
                  <CustomRow marginBottom={14}>
                    <CustomRow justifyContent="flex-start">
                      <div>
                        <Icon name={(fromAccount as IAccountItem).icon} color="orange" />
                      </div>
                      <CustomRow flexDirection="column" alignItems="flex-start">
                        <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" marginLeft={12} extraStyles={{ whiteSpace: 'break-spaces' }}>
                          {(fromAccount as IAccountItem).nickname || (fromAccount as IAccountItem).shortName}
                        </BodyText>
                        <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={12} nowrap>
                          {(fromAccount as IAccountItem).name}
                        </BodyText>
                      </CustomRow>
                    </CustomRow>
                    {(fromAccount as IAccountItem).balance !== undefined && <CustomAmount size="smallerStrong" amount={(fromAccount as IAccountItem).balance} />}
                  </CustomRow>
                )}
                {toAccount && (
                  <CustomRow>
                    <CustomRow justifyContent="flex-start">
                      <div>
                        <Icon name={(toAccount as IAccountItem).icon} color="orange" />
                      </div>
                      <CustomRow flexDirection="column" alignItems="flex-start">
                        <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" marginLeft={12} extraStyles={{ whiteSpace: 'break-spaces' }}>
                          {(toAccount as IAccountItem).nickname || (toAccount as IAccountItem).shortName}
                        </BodyText>
                        <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={12} nowrap>
                          {(toAccount as IAccountItem).name}
                        </BodyText>
                      </CustomRow>
                    </CustomRow>
                    {(toAccount as IAccountItem).balance !== undefined && <CustomAmount size="smallerStrong" amount={(toAccount as IAccountItem).balance} />}
                  </CustomRow>
                )}
              </CustomCard>
            </>
          )}
          <Button className="done-btn" preset="primary" type="button" onClick={handleDone} size="middleAlt" marginTop={16}>
            {t('externalTransfer.Done')}
          </Button>
        </SInternalTransferPage>
      ) : (
        <SInternalTransferPage isDesktop={isDesktopSize} className="confirm">
          <CustomRow flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Title font="Poppins" color="charcoal" marginBottom={8} fontWeight="SB" size="S" textAlign="start">
              {t('internalTransfer.Confirm Transfer')}
            </Title>

            <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="M" fontWeight="B" textAlign="start" marginBottom={16}>
              {t('internalTransfer.Transfer Details')}
            </BodyText>
          </CustomRow>

          <CustomCard border="2px solid #F5F4F4" marginTop={0} minWidth="230px" height="fit-content" extraStyles={{ margin: '0 auto' }}>
            <BodyText size="L" fontWeight="B" marginBottom={27}>
              {t('transferPage.TransferDetails')}
            </BodyText>

            <TransferDataRow title={t('externalTransfer.TransferAmount')} value={Number(currentTransfer.amount)} isAmount />
            <TransferDataRow
              title={t('externalTransfer.AccountFrom')}
              value={fromAccount ? getAccountDisplayName(fromAccount) : ''}
              subvalue={fromAccount ? getAccountDisplayNumber(fromAccount) : ''}
            />
            <TransferDataRow title={t('externalTransfer.AccountTo')} value={toAccount ? getAccountDisplayName(toAccount) : ''} subvalue={toAccount ? getAccountDisplayNumber(toAccount) : ''} />
            <TransferDataRow title={t('externalTransfer.Date')} value={currentDate} />
            <TransferDataRow title={t('internalTransfer.Note')} value={currentTransfer.note} isLast />
          </CustomCard>

          {!currentTransfer.isExternalTransfer && (
            <>
              <CustomRow flexDirection="column" justifyContent="flex-start" alignItems="flex-start" marginTop={16} marginBottom={16}>
                <BodyText textType="bodyText" font="Poppins" color="charcoal" size="M" fontWeight="SB" textAlign="start">
                  {t('internalTransfer.After Transfer')}
                </BodyText>
              </CustomRow>

              <CustomCard border={`2px solid ${theme.charcoal5}`} minWidth="230px" height="fit-content" extraStyles={{ margin: '0 auto' }}>
                <CustomRow marginBottom={27} alignItems="flex-start">
                  {fromAccount && (
                    <CustomRow justifyContent="flex-start" width="65%" flexWrap="wrap">
                      <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" extraStyles={{ whiteSpace: 'break-spaces' }}>
                        {t(`account.${getAccountNameByAuth(fromAccount as IAccountItem)}`)}
                      </BodyText>
                      <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={5} nowrap>
                        {(fromAccount as IAccountItem)?.maskedAccountId}
                      </BodyText>
                    </CustomRow>
                  )}

                  {fromAccount && (fromAccount as IAccountItem).balance !== undefined && (
                    <CustomRow flexDirection="column" gap={10} alignItems="flex-end" marginLeft={20} width="35%">
                      <CustomAmount size="smaller" amount={(fromAccount as IAccountItem).balance} />
                      <CustomAmount size="smaller" color="red" sign amount={-Number(currentTransfer?.amount)} />
                      <CustomAmount size="smallerStrong" amount={(fromAccount as IAccountItem).balance - Number(currentTransfer?.amount)} />
                    </CustomRow>
                  )}
                </CustomRow>

                <CustomRow marginBottom={27} alignItems="flex-start">
                  {toAccount && (
                    <CustomRow justifyContent="flex-start" width="65%" flexWrap="wrap">
                      <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginRight={5} extraStyles={{ whiteSpace: 'break-spaces' }}>
                        {t(`account.${getAccountNameByAuth(toAccount as IAccountItem)}`)}
                      </BodyText>
                      <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" nowrap>
                        {(toAccount as IAccountItem).maskedAccountId}
                      </BodyText>
                    </CustomRow>
                  )}

                  {toAccount && (toAccount as IAccountItem).balance !== undefined && (
                    <CustomRow flexDirection="column" gap={10} alignItems="flex-end" marginLeft={20} width="35%">
                      <CustomAmount size="smaller" amount={(toAccount as IAccountItem).balance} />
                      <CustomAmount size="smaller" color="green" sign amount={Number(currentTransfer.amount)} />
                      <CustomAmount size="smallerStrong" amount={(toAccount as IAccountItem).balance + Number(currentTransfer.amount)} />
                    </CustomRow>
                  )}
                </CustomRow>
              </CustomCard>
            </>
          )}

          <CustomRow justifyContent="flex-end" marginTop={32} marginBottom={24} width="40%">
            <Button onClick={() => navigate(ROUTES.transfers.path)} marginRight={8} size="middleAlt">
              {t('externalTransfer.Cancel')}
            </Button>
            <Button preset="primary" onClick={handleConfirm} size="middleAlt">
              {t('externalTransfer.Confirm')}
            </Button>
          </CustomRow>
        </SInternalTransferPage>
      )}
      {riskSessionData && <FingerprintAndBehavioralData data={riskSessionData} />}
      <TransferErrorModal isOpen={transferErrorModal.isActive} onClose={transferErrorModal.hide} handleCloseConfirmationModal={() => {}} errorData={transferErrorModal.data} />
    </>
  );
};
