import { Middleware } from 'redux';
import { getChatWebSocket, resetChatWebSocket } from 'views/Chat/services/socket.service';
import { chatActions } from './chat.slice';

/* eslint-disable */
export const chatMiddleware: Middleware = (store) => {
  let pingInterval: NodeJS.Timeout | null = null;

  return (next) => (action) => {
    const { token, userId, activeConversation } = store.getState();
    const { isConnected } = store.getState().chat;
    const { user } = store.getState().authentication;

    if (chatActions.startConnecting.match(action) && !isConnected) {
      const chatSocket = getChatWebSocket(action.payload);

      chatSocket.onopen = () => {
        store.dispatch(chatActions.setIsConnected());
      };

      chatSocket.onerror = (err: any) => {
        store.dispatch(chatActions.reconnectToWebSocket());
      };

      chatSocket.onclose = () => {
        store.dispatch(chatActions.clearPingMessage());
      };

      chatSocket.onmessage = (event: any) => {
        if (event.data.length) {
          try {
            const parsedData = JSON.parse(event.data);

            if (!parsedData.activities || !Array.isArray(parsedData.activities)) {
              return;
            }

            const activities = parsedData.activities;

            activities.forEach((activity: any) => {
              if (activity.type === 'startTyping') {
                store.dispatch(chatActions.setTyping(true));
              } else if (activity.type === 'endTyping') {
                store.dispatch(chatActions.setTyping(false));
              }
            });

            const messages = activities.filter((activity: any) => activity.type === 'message');
            if (messages.length > 0) {
              store.dispatch(chatActions.addMessages(messages));
              messages.forEach((message: any) => message.text && store.dispatch(chatActions.receiveMessage(message)));
            }
          } catch (error) {
            console.error('Error processing message:', error);
          }
        }
      };
    }

    if (chatActions.closeChat.match(action) && isConnected) {
      console.log('closeChat!');
      resetChatWebSocket();
      store.dispatch(chatActions.connectionClosed());
      store.dispatch(chatActions.clearPingMessage());
    }

    next(action);
  };
};
