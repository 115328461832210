import React from 'react';
import { images } from 'assets';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { ROUTES } from 'vars/const/ROUTES';
import { useSetUIPreferenceMutation } from 'store/user/properties/userProperties.api';
import { SPromoCloseModal } from './Modals.styles';

export interface ICashCushionModal {
  open: boolean;
  onClose: () => void;
}

export const CashCushionModal = ({ open, onClose }: ICashCushionModal) => {
  const { t } = useTranslation('home');
  const [setUIPreference] = useSetUIPreferenceMutation();

  const handleContinue = () => {
    setUIPreference({
      value: {
        cashCushionDismissed: true,
      },
    });
    onClose();
  };

  return (
    <SPromoCloseModal open={open} onCancel={onClose} modalName="cashCushion" topPosition="11%" closeIconColor="charcoal70">
      <div className="content">
        <img src={images.cashCushion} alt="Cash Cushion" />
        <Title textAlign="start" marginBottom={16} fontWeight="M" size="M" marginTop={10}>
          {t('cashCushionPromo.CloseModalTitle')}
        </Title>
        <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" lineHeight={1.5}>
          {t('cashCushionPromo.CloseModalText')}
        </BodyText>
        <Button size="large" url={ROUTES.cashCushion.path} preset="primary" marginTop={32}>
          {t('cashCushionPromo.ContinueButtonText')}
        </Button>
        <Button size="large" onClick={handleContinue} marginTop={15}>
          {t('cashCushionPromo.CloseModalButtonText')}
        </Button>
      </div>
    </SPromoCloseModal>
  );
};
