import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { images } from 'assets';
import { v4 as uuidv4 } from 'uuid';
import { useTransfers } from 'utils/hooks/useTransfers';
import { BodyText, Title } from 'components/general/Typography';
import { format } from 'date-fns';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { FingerprintAndBehavioralData } from 'components/general/Modals/FundConfirmationModal/FingerprintAndBehavioralData/FingerprintAndBehavioralData';
import { Loader } from 'components/general/Loader/Loader';
import { TransferDataRow } from 'components/general/Modals/Transfer/ExternalTransfer/TransferDataRow/TransferDataRow';
import { Breadcrumbs } from 'views/Main/Header/Breadcrumbs/Breadcrumbs';
import { useToggle } from 'utils/hooks/useToggle';
import { SLink } from 'views/HelpAndSupport/HelpAndSupport.styles';
import { INGO_LIMIT_ERRORS_CODE, INGO_DECLINE_ERRORS_CODE } from './TransferPage';
import { TransferErrorModal } from './TransferErrorModal';

export const ExternalTransferConfirmPage = () => {
  const { t } = useTranslation('moveMoney');
  const location = useLocation();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(true);

  const { transferToExternal, transferFromExternal, updateRiskSession, riskSessionToken, riskSessionData, isTransferDataLoading } = useTransfers();
  const transferErrorModal = useToggle(false, { message: '', title: '', isIngoError: false } as { message: string | React.ReactElement; title: string; isIngoError: boolean });
  const disclosureSheet = useToggle();

  const {
    isToExternalAccount = undefined,
    amountTransferred = undefined,
    note = undefined,
    fromDisplayDataName = undefined,
    fromDisplayDataNumber = undefined,
    toDisplayDataName = undefined,
    toDisplayDataNumber = undefined,
    accountId = undefined,
    externalAccountId = undefined,
    accountType = undefined,
  } = location?.state || {};

  const dateStr = format(new Date(), 'MMM dd, yyyy');

  const openConsent = () => {
    transferErrorModal.hide();
    disclosureSheet.show();
  };

  const handleTransferError = (error: any) => {
    if (INGO_DECLINE_ERRORS_CODE.includes(error?.data?.Data?.status)) {
      transferErrorModal.setData({
        message: t(`ThisTransactionWasDeclined`),
        title: t(`TransactionDeclined`),
        isIngoError: true,
      });
    } else if (INGO_LIMIT_ERRORS_CODE.includes(error?.data?.Data?.status)) {
      transferErrorModal.setData({
        message: <Trans ns="moveMoney" i18nKey="transferPage.SorryForTheInconvenience" components={{ Link: <SLink onClick={openConsent} /> }} />,
        title: t(`TransactionLimitExceeded`),
        isIngoError: true,
      });
    } else {
      transferErrorModal.setData({
        message: t('SorryWeAreUnableToCompleteThisTransaction'),
        title: t('TransferIncomplete'),
        isIngoError: true,
      });
    }
    transferErrorModal.show();
  };

  const handleConfirm = () => {
    const requestBody = {
      amount: amountTransferred,
      notes: note ? [note] : [],
      transactionId: uuidv4(),
      riskSessionToken,
    };
    const requestMethod = isToExternalAccount ? transferToExternal : transferFromExternal;

    if (externalAccountId) {
      requestMethod({
        ...requestBody,
        accountId,
        externalAccount: externalAccountId,
        accountType,
      })
        .unwrap()
        .then(() => {
          setIsSuccess(true);
        })
        .catch((error) => {
          handleTransferError(error);
        });
    }
  };

  const handleClose = () => navigate(-1);

  useEffect(() => {
    updateRiskSession();
  }, []);

  return (
    <>
      <Breadcrumbs title={t('transferPage.TransferBetweenAccounts')} description={t('transferPage.TransferPageDesctiption')} hasBackNav onlyTitle />
      {isTransferDataLoading && <Loader />}

      <CustomRow width="100%" justifyContent="center">
        <CustomRow width="432px" alignItems="start" flexDirection="column">
          {isSuccess ? (
            <CustomRow marginBottom={18} flexDirection="column" alignItems="center" width="100%">
              <img src={images.congratulation} alt="successInternalTransfer" />
              <Title font="Poppins" color="charcoal" fontWeight="M" size="L" marginTop={32}>
                {t('externalTransfer.TransferComplete')}
              </Title>
            </CustomRow>
          ) : (
            <>
              <CustomRow justifyContent="flex-start" marginBottom={18}>
                <Title font="Poppins" color="charcoal" fontWeight="SB" size="S">
                  {t('externalTransfer.ConfirmTransfer')}
                </Title>
              </CustomRow>
              <BodyText textType="bodyText" font="Poppins" justifyContent="start" fontWeight="SB" size="M" color="charcoal">
                {t('externalTransfer.TransferDetails')}
              </BodyText>
            </>
          )}

          <CustomCard border="2px solid #ECE8DC" width="100%">
            <TransferDataRow title={t('externalTransfer.TransferAmount')} value={amountTransferred} isAmount />
            <TransferDataRow title={t('externalTransfer.AccountFrom')} value={fromDisplayDataName} subvalue={fromDisplayDataNumber} />
            <TransferDataRow title={t('externalTransfer.AccountTo')} value={toDisplayDataName} subvalue={toDisplayDataNumber} />
            <TransferDataRow title={t('externalTransfer.Date')} value={dateStr} isLast />
          </CustomCard>
          <CustomRow justifyContent={isSuccess ? 'center' : 'flex-end'} marginTop={32} marginBottom={24} width="100%">
            {isSuccess ? (
              <Button onClick={handleClose} marginRight={8} preset="primary" width={112}>
                {t('externalTransfer.Done')}
              </Button>
            ) : (
              <>
                <Button onClick={handleClose} marginRight={8} size="middleAlt">
                  {t('externalTransfer.Cancel')}
                </Button>
                <Button preset="primary" onClick={handleConfirm} size="middleAlt">
                  {t('externalTransfer.Confirm')}
                </Button>
              </>
            )}
          </CustomRow>
        </CustomRow>
      </CustomRow>
      {riskSessionData && <FingerprintAndBehavioralData data={riskSessionData} />}
      <SuttonDisclaimerNote marginLeft={62} />
      <TransferErrorModal isOpen={transferErrorModal.isActive} onClose={transferErrorModal.hide} errorData={transferErrorModal.data} />
    </>
  );
};
