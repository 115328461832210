import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SHeader = styled.div`
  ${mediaUpTo(
    'tablet',
    css`
      display: none;
    `
  )}
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  justify-content: space-between;

  ${mediaFrom(
    'tablet',
    css`
      justify-content: flex-start;
    `
  )}

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;

    ${mediaFrom(
      'desktop',
      css`
        width: 580px;
      `
    )}

    .web-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;

      img {
        width: 100%;
      }

      ${mediaUpTo(
        'tablet',
        css`
          display: none;
        `
      )}
    }

    .mobile-image-container {
      display: flex;
      justify-content: center;
      align-items: center;

      ${mediaFrom(
        'tablet',
        css`
          display: none;
        `
      )}
    }

    .continue-container {
      ${mediaUpTo(
        'tablet',
        css`
          display: none;
        `
      )}

      button {
        min-width: 312px !important;
        height: 52px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 54px;
      }
    }
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;
    margin-top: 20px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }

  footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    margin-top: auto;
  }
`;
