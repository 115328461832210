import styled from 'styled-components/macro';
import { getProp, getColor } from 'utils/helpers/styleHelpers';

interface ISFields {
  borderRadius?: string;
}

export const SCodesBlock = styled.div<ISFields>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getProp('borderRadius', '7px')};
  border: 2px solid ${getColor('charcoal10')} !important;
  padding: 0 4px;
  touch-action: pan-y;
`;
