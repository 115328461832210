import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useConsents } from 'utils/hooks/useConsents';
import { Title, BodyText } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Loader } from 'components/general/Loader/Loader';
import { ConsentType } from 'components/general/Consent/Consents.types';
import { DisclosureItem } from './DisclosureItem';
import { SDisclosureContainer, SDisclosuresPageContent, STitleContainer } from './DisclosuresPage.styles';

export const DisclosuresPage = () => {
  const percapitaConsents = useConsents(ConsentType.PERCAPITA);
  const ingoConsents = useConsents(ConsentType.INGO);
  const otherConsents = useConsents(ConsentType.OTHERS);
  const isLoading = percapitaConsents.getConsentByFlowNameResult.isLoading || ingoConsents.getConsentByFlowNameResult.isLoading || otherConsents.getConsentByFlowNameResult.isLoading;
  const [searchParams] = useSearchParams();
  const currentOpenId = searchParams.get('id');
  const { t } = useTranslation('moveMoney');

  return (
    <CustomRow alignItems="start" flexDirection="column" marginTop={6}>
      {isLoading && <Loader />}
      <STitleContainer>
        <Title size="S" color="charcoal" fontWeight="SB" font="Poppins">
          {t(`Documents & Disclosures`)}
        </Title>

        <BodyText textType="bodyText" size="M" fontWeight="R" font="DM Sans" color="charcoal60" marginTop={10} paddingRight={35} lineHeight={1.5}>
          {t(`You can find your documents and disclosures here.`)}
        </BodyText>
      </STitleContainer>

      <SDisclosuresPageContent>
        {!!percapitaConsents?.consentsData?.length && (
          <>
            <Title size="S" color="charcoal" fontWeight="SB" font="Poppins" textAlign="center">
              {t(`Percapita Disclosures`)}
            </Title>
            <SDisclosureContainer key={currentOpenId}>
              {percapitaConsents?.consentsData?.map((data) => (
                <DisclosureItem consentData={data} isOpen={data.id === currentOpenId} key={data.id} />
              ))}
            </SDisclosureContainer>
          </>
        )}

        {!!ingoConsents?.consentsData?.length && (
          <>
            <Title size="S" color="charcoal" fontWeight="SB" font="Poppins" marginTop={35} textAlign="center">
              {t(`Ingo Disclosures`)}
            </Title>

            <SDisclosureContainer>
              {ingoConsents.consentsData.map((data) => (
                <DisclosureItem consentData={data} isOpen={data.id === currentOpenId} key={data.id} />
              ))}
            </SDisclosureContainer>
          </>
        )}

        {!!otherConsents?.consentsData?.length && (
          <>
            <Title size="S" color="charcoal" fontWeight="SB" font="Poppins" marginTop={35} textAlign="center">
              {t(`Others`)}
            </Title>
            <SDisclosureContainer>
              {otherConsents?.consentsData?.map((data) => (
                <DisclosureItem consentData={data} isOpen={data.id === currentOpenId} key={data.id} />
              ))}
            </SDisclosureContainer>
          </>
        )}
      </SDisclosuresPageContent>
    </CustomRow>
  );
};
