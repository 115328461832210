import React, { useEffect, useCallback } from 'react';
import { useAppDispatch } from 'utils/hooks/store';
import { useTranslation } from 'react-i18next';
import { selectDisplayAdditionalInformationModalType, selectAdditionalInformationModalType, setShowAdditionalInformationModal } from 'store/ui.slice';
import { useSelector } from 'react-redux';
import { Title, BodyText } from 'components/general/Typography';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { MODAL_DATA } from './AdditionalInformationHandler.config';
import { SMediaLayout } from './AdditionalInformationHandler.styles';

export const AdditionalInformationModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isModalVisible = useSelector(selectDisplayAdditionalInformationModalType);
  const additionalInformationModalType = useSelector(selectAdditionalInformationModalType);
  const additionalInformationData = MODAL_DATA[additionalInformationModalType];

  const handleOnCancel = useCallback(() => {
    dispatch(
      setShowAdditionalInformationModal({
        displayAdditionalInformationModal: false,
      })
    );
  }, [setShowAdditionalInformationModal]);

  useEffect(() => handleOnCancel, []);

  return additionalInformationData ? (
    <CustomSheet isOpen={isModalVisible} onClose={handleOnCancel} modalName="additionalInformaion">
      <SMediaLayout>
        <Title fontWeight="M" size="M" paddingBottom={17} color={additionalInformationData.TITLE_COLOR} font="Poppins">
          {t(String(additionalInformationData.TITLE))}
        </Title>

        <BodyText
          textType="bodyText"
          font="DM Sans"
          color={additionalInformationData.TEXT_COLOR}
          size="N"
          fontWeight={additionalInformationData.FONT_WEIGHT}
          marginBottom={5}
          lineHeight={1.5}
          paddingRight={15}
        >
          {t(String(additionalInformationData.TEXT))}
        </BodyText>
      </SMediaLayout>
    </CustomSheet>
  ) : null;
};
