import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import styled from 'styled-components/macro';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCustomModal = styled(CustomModal)`
  .ant-modal-body {
    align-items: center;

    .add-success-image {
      img {
        margin-bottom: 32px;
      }
    }

    button.preset-primary {
      padding: 14px 32px;
      font-size: 18px;
      font-weight: 600;
    }

    a.to-home {
      font-size: 18px;
      font-weight: 600;
      color: ${getColor('blue')};
    }
  }
`;
