import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { Title, BodyText } from 'components/general/Typography';
import { PromptItem } from './PromptItem/PromptItem';
import { FeedbackItem } from './FeedbackItem/FeedbackItem';
import { SCustomSheet } from './SurveyModal.styles';

interface IPromptList {
  name: string;
  checked?: boolean;
}
const promptList: IPromptList[] = [
  { name: 'survey.Prompts.FunctionalityOrFeaturesDidntMeetMyNeeds' },
  { name: 'survey.Prompts.PricingOrOffersAreBetterElsewhere' },
  { name: 'survey.Prompts.AppDidntPerformToMyExpectations' },
  { name: 'survey.Prompts.SecurityPrivacyWasConcern' },
  { name: 'survey.Prompts.TooManyFinancialApps' },
  { name: 'survey.Prompts.Other' },
];

const feedbackOptions = ['survey.Feedbacks.Yes', 'survey.Feedbacks.No'];

interface ISurveyModal {
  isOpen: boolean;
  onClose: () => void;
  onClickHandler: () => void;
}

export const SurveyModal: React.FC<ISurveyModal> = ({ isOpen, onClose, onClickHandler }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const { t } = useTranslation('accountInformation');

  const handleOptionChanged = (item: string) => {
    setSelectedOption(item);
  };
  return (
    <SCustomSheet isOpen={isOpen} onClose={onClose} padding="24px" modalName="accountInformationSurvey" closable={false} wrapperPadding>
      <Title fontWeight="M" size="M" paddingRight={18}>
        {t('survey.HowCouldWeHaveMadeYourExperienceWithPercapitaBetter')}
      </Title>
      <BodyText fontWeight="B" size="N" marginTop={32}>
        {t('survey.WhatPromptedYouToCloseAccount')}
      </BodyText>

      <CustomRow justifyContent="flex-start" alignItems="start" flexDirection="column" gap={10} marginTop={24}>
        {promptList.map((item) => (
          <PromptItem name={item.name} checked={item?.checked} />
        ))}
      </CustomRow>

      <BodyText fontWeight="B" size="N" marginTop={32} marginBottom={24}>
        {t('survey.IsThereAnythingElse')}
      </BodyText>
      <BaseInput type="text" placeholder={t('survey.WriteYourAnswer')} data-testid="answer" />

      <BodyText fontWeight="B" size="N" marginTop={32} marginBottom={24}>
        {t('survey.WouldShareFeedbackWithTeam')}
      </BodyText>

      <CustomRow flexDirection="column" width="100%" justifyContent="flex-start" alignItems="flex-start" gap={10}>
        {feedbackOptions.map((item) => (
          <FeedbackItem value={item} key={item} label={item} onChange={() => handleOptionChanged(item)} selected={item === selectedOption} />
        ))}
      </CustomRow>

      <Button preset="primary" size="xl" onClick={onClickHandler} marginTop={32}>
        {t('survey.Submit')}
      </Button>
    </SCustomSheet>
  );
};
