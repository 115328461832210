import React from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Icon } from 'components/general/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useLazyGetNotificationsQuery, useMarkNotificationUnreadMutation } from 'store/user/users.api';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IProps {
  open: boolean;
  status?: string;
  alertHistoryId: string;
  onClose: () => void;
  openDeleteModal: () => void;
  refresh?: () => void;
}

export const SettingsModal: React.FC<IProps> = ({ open, status, alertHistoryId, onClose, openDeleteModal, refresh }) => {
  const { t } = useTranslation('notificationsCenter');
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();

  const [markNotificationUnreadAPI] = useMarkNotificationUnreadMutation();
  const [getNotifications] = useLazyGetNotificationsQuery();

  const handleOpenModal = () => {
    onClose();
    openDeleteModal();
  };

  const handleTurnOff = () => {
    navigate(ROUTES.notificationSettings.path);
  };

  const handleMarkUnread = () => {
    markNotificationUnreadAPI(alertHistoryId).then(() => {
      if (refresh) {
        refresh?.();
      } else {
        getNotifications();
      }
    });
    onClose();
  };

  return (
    <CustomSheet isOpen={open} onClose={onClose} header={false} modalName="settings" className="settings-sheet" isModal={isDesktopSize}>
      <Title font="Poppins" fontWeight="SB" size="S" color="charcoal">
        {t('notificationsCenter.Settings')}
      </Title>

      <CustomRow marginTop={32} justifyContent="flex-start" cursorPointer onClick={handleOpenModal}>
        <Icon name="trash" marginRight={14} cursorPointer />
        <CustomRow flexDirection="column" alignItems="flex-start">
          <BodyText textType="bodyText" size="N" fontWeight="M" color="charcoal" lineHeight={1.5} cursorPointer>
            {t('notificationsCenter.Delete')}
          </BodyText>
          <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70">
            {t('notificationsCenter.DeleteNotification')}
          </BodyText>
        </CustomRow>
      </CustomRow>
      <CustomRow marginTop={16} justifyContent="flex-start" marginBottom={status === 'Read' ? 0 : 32} cursorPointer onClick={handleTurnOff}>
        <Icon name="bellSlash" marginRight={14} cursorPointer />
        <CustomRow flexDirection="column" alignItems="flex-start" cursorPointer>
          <BodyText textType="bodyText" size="N" fontWeight="M" color="charcoal" lineHeight={1.5} cursorPointer>
            {t('notificationsCenter.TurnOff')}
          </BodyText>
          <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70" cursorPointer>
            {t('notificationsCenter.StopReceiving')}
          </BodyText>
        </CustomRow>
      </CustomRow>
      {status === 'Read' && (
        <CustomRow marginTop={16} justifyContent="flex-start" marginBottom={32} cursorPointer onClick={handleMarkUnread}>
          <Icon name="book" marginRight={14} cursorPointer />
          <CustomRow flexDirection="column" alignItems="flex-start">
            <BodyText textType="bodyText" size="N" fontWeight="M" color="charcoal" lineHeight={1.5} cursorPointer>
              {t('notificationsCenter.MarkUnread')}
            </BodyText>
            <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70">
              {t('notificationsCenter.MarkNotificationUnread')}
            </BodyText>
          </CustomRow>
        </CustomRow>
      )}
    </CustomSheet>
  );
};
