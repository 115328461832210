import React from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { Button } from 'components/theme/Button/Button';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { SCustomSheet } from './ICantSelectMyCountrySheet.styles';

interface ISheetProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onSelectDestinationClick: any;
}

export const ICantSelectMyCountrySheet: React.FC<ISheetProps> = ({ isOpen, onClose, onBack, onSelectDestinationClick }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <SCustomSheet isOpen={isOpen} onClose={onClose} paddingTop="36px" paddingBottom="48px">
      <div className="header">
        <Icon name="arrowLeft" color="charcoal" onClick={onBack} cursorPointer size="small" marginRight={12} />
        <Title>{t('internationalTransferPage.WhyCantISelectMyCountry')}</Title>
      </div>

      <BodyText size="N" fontWeight="R" color="charcoal70" lineHeight={1.4} marginBottom={32}>
        {t('internationalTransferPage.WereContinuallyExpandingOurReach')}
      </BodyText>

      <BodyText size="T" fontWeight="SB" font="Poppins" color="charcoal" lineHeight={1.4} marginBottom={16}>
        {t('internationalTransferPage.SelectYourCountryRequestHere')}
      </BodyText>

      <Button className="button-select-destination" onClick={onSelectDestinationClick}>
        <div className="text">{t('internationalTransferPage.SelectDestinationRequest')}</div>
        <Icon name="chevronRight" color="charcoal" size="smallest" />
      </Button>

      <SuttonDisclaimerNote />
    </SCustomSheet>
  );
};
