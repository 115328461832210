import styled from 'styled-components';
import { flex, getColor } from 'utils/helpers/styleHelpers';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';

export const SLayout = styled.div`
  ${flex('column', 'flex-start', 'flex-start')};
  height: 100%;
  padding: 16px 4px 28px;
  margin-bottom: 10px;

  .destination-card {
    ${flex('column', 'flex-start', 'stretch')};
    align-self: stretch;
    border-radius: 20px;
    background: ${getColor('white')};
    padding: 62px 24px 32px;
    margin-bottom: 32px;

    .image-map {
      height: 212px;
      width: 191px;
      align-self: center;
      margin-bottom: 24px;
    }

    .flags-list {
      display: flex;
      margin-bottom: 24px;

      .flags-list-item {
        margin-right: 16px;
      }
    }

    .check-destinations-button {
      font-size: 18px;
      padding: 14px 32px;
    }
  }

  .verification-times {
    border-radius: 20px;
    background: ${getColor('creamSS1')};
    padding: 24px;
    margin-bottom: 50px;

    .terms-link {
      text-decoration: underline;
      color: ${getColor('blue')};

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const SRemindModalLayout = styled(CustomModal)`
  img.main-image {
    height: 125px;
    width: 120px;
    align-self: center;
    margin-bottom: 32px;
  }
`;
