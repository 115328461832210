import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/theme/Button/Button';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { images } from 'assets';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SLayout, SContainer } from './AddMoneyNow.styles';

export const AddMoneyNow: React.FC = () => {
  const { t } = useTranslation('cashAccount');
  const { isDesktopSize } = useDeviceDimension();
  const navigate = useNavigate();

  const handleOnClick = () =>
    navigate(ROUTES.addDebitCard.path, {
      state: {
        fundingDestination: 'debit',
      },
    });
  const handleOnNotNow = () => navigate(ROUTES.home.path);

  return (
    <SLayout>
      {isDesktopSize && <PageHeader title={t('cashAccount.AddMoney')} />}

      <SContainer>
        <div>
          <div className="center-image">
            <img src={images.onboardingMan} alt="onboarding man" width={150} />
          </div>
          <Title font="Poppins" size={isDesktopSize ? 'L' : 'M'} fontWeight="M" marginTop={isDesktopSize ? 48 : 24} marginBottom={isDesktopSize ? 8 : 16} textAlign="center">
            {t('cashAccount.WouldYouLikeAddMoneyToYourAccountNow')}
          </Title>

          <BodyText textType="bodyText" color="charcoal70" size={isDesktopSize ? 'L' : 'N'} fontWeight="R" textAlign="center" justifyContent="center">
            {t('cashAccount.TransferMoneyToYourNewCashAccountUsingExistingDebitCard')}
          </BodyText>
        </div>

        <div className="btn-container">
          <Button marginTop={isDesktopSize ? 40 : 32} size="large" preset="primary" onClick={handleOnClick}>
            {t(`cashAccount.Yes`)}
          </Button>
          <Button className="btn-not-now" marginTop={24} size="large" onClick={handleOnNotNow}>
            {t(`cashAccount.Not Now`)}
          </Button>
        </div>
      </SContainer>
    </SLayout>
  );
};
