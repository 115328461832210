import styled, { css } from 'styled-components';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { getColor, mediaFrom, SCREEN_SIZE } from 'utils/helpers/styleHelpers';

export const SCustomModal = styled(CustomModal)`
  .ant-modal-content {
    padding: 0 0 24px;

    overflow: auto;

    ::-webkit-scrollbar {
      display: none !important;
    }

    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    .modal-title {
      border-radius: 20px 20px 0 0;
      background: ${getColor('charcoal5')};
      padding: 34px 20px;
    }

    .body-content {
      .table {
        margin: 0 8px;

        .ant-table {
          .ant-table-container {
            .ant-table-content {
              table {
                thead {
                  tr {
                    th {
                      background: ${getColor('cream70')};
                      padding: 16px 16px 16px 0;

                      &:first-child {
                        border-top-left-radius: 8px;
                        padding-left: 16px;
                      }

                      &:last-child {
                        border-top-right-radius: 8px;
                      }

                      &:before {
                        display: none;
                      }
                    }
                  }
                }

                tbody {
                  tr {
                    td.ant-table-cell {
                      padding: 0;
                      border: none;
                      // for stretch children content
                      height: 10px;

                      .td-content-wrapper {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        padding: 20px 0 20px;
                        border-bottom: 1px solid ${getColor('charcoal10')};

                        .cell-fee-wrapper {
                          padding-right: 24px;
                        }

                        .cell-amount-wrapper {
                        }

                        .amount-bold {
                          color: ${getColor('charcoal')};
                          font-weight: 700;
                        }
                      }

                      &:first-child {
                        .td-content-wrapper {
                          margin-left: 16px;
                        }
                      }

                      &:last-child {
                        .td-content-wrapper {
                          margin-right: 16px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${mediaFrom(
    SCREEN_SIZE.tablet + 1,
    css`
      height: 76% !important;
      width: 47% !important;
      top: 1% !important;

      .ant-modal-content {
        .modal-title {
          margin-bottom: 16px;
        }

        .body-content {
          .table {
            margin: 0 24px;

            .ant-table {
              .ant-table-container {
                .ant-table-content {
                  table {
                    thead {
                      tr {
                        th {
                          padding: 16px 24px 16px 0;
                        }
                      }
                    }

                    colgroup {
                      &:first-child {
                        col {
                          width: 66% !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )};

  ${mediaFrom(
    'desktopLarge',
    css`
      .ant-modal-content {
        .modal-title {
          margin-bottom: 16px;
        }

        .body-content {
          .table {
            margin: 0 24px;

            .ant-table {
              .ant-table-container {
                .ant-table-content {
                  table {
                    tbody {
                      tr {
                        td.ant-table-cell {
                          &:first-child {
                            .td-content-wrapper {
                              margin-left: 8px;

                              .cell-fee-wrapper {
                                padding-left: 16px;
                              }
                            }
                          }

                          &:last-child {
                            .td-content-wrapper {
                              margin-right: 20px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )};
`;
