import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { BodyText, Title } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { useGetUserProfileDataMutation, useGetCurrentUserQuery } from 'store/user/users.api';
import { getLastFourDigits } from 'utils/helpers/phoneNumber';
import { useToggle } from 'utils/hooks/useToggle';
import { implodeString } from 'utils/helpers/stringFormatter';
import { Loader } from 'components/general/Loader/Loader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Icon } from 'components/general/Icon/Icon';
import { IconSign } from 'components/general/Icon/IconSign';
import { ProfileEditSheet } from './ProfileEditSheet/ProfileEditSheet';
import { DataItem } from './DataItem/DataItem';
import { ChangePasswordModal } from './ChangePasswordModal';
import { DeleteAccountModal } from './DeleteAccountModal/DeleteAccountModal';
import { EditEmailVerificationSheet } from './ProfileEditSheet/EditEmail/EditEmailVerificationSheet';
import { EditPhoneNumberSheet } from './ProfileEditSheet/EditPhoneNumber/EditPhoneNumberSheet';
import { SLayout } from './ProfilePage.styles';
import { editProfileSheetInitData } from './DataItem/DataItem.constants';

export const ProfilePage = () => {
  const { t } = useTranslation('profile');
  const location = useLocation();
  const { isMobileRegularSize, isDesktopSize } = useDeviceDimension();
  const shouldEditAddressOnPageLoad = location.state?.shouldEditAddress;

  const [userProfile, setUserProfile] = useState({
    userLegalName: '',
    userPreferredName: '',
    address: '',
    mailingAddress: '',
    email: '',
    phoneNumber: '',
    password: '',
    username: '',
  });

  const userProfileData = useSelector(selectCurrentUser);
  const userId = userProfileData?.userId;
  const [getUserProfileData] = useGetUserProfileDataMutation();
  const currentUserDataQuery = useGetCurrentUserQuery();

  const deleteAccountModal = useToggle(false);
  const handleOnClick = () => deleteAccountModal.show();

  const editProfileSheet = useToggle(false, editProfileSheetInitData);
  const titleText = useMemo(() => (isMobileRegularSize ? 'profile.PersonalInformation' : 'profile.Profile'), [isMobileRegularSize]);

  const onCloseProfileSheet = () => {
    editProfileSheet.setData(editProfileSheetInitData);
    editProfileSheet.hide();
  };

  useEffect(() => {
    if (shouldEditAddressOnPageLoad) {
      editProfileSheet.setData({ ...editProfileSheetInitData, sheetType: 'address' });
      editProfileSheet.show();
    }
  }, [shouldEditAddressOnPageLoad]);

  useEffect(() => {
    getUserProfileData({ userId });
  }, []);

  useEffect(() => {
    setUserProfile({
      userLegalName: `${userProfileData?.firstName ?? ''} ${userProfileData?.lastName ?? ''}`,
      userPreferredName: `${userProfileData?.preferredName || ''}`,
      address: implodeString([userProfileData?.address1, userProfileData?.address2, userProfileData?.city, userProfileData?.stateProvince, userProfileData?.postalCode]),
      mailingAddress: implodeString([
        userProfileData?.mailingAddress1,
        userProfileData?.mailingAddress2,
        userProfileData?.mailingCity,
        userProfileData?.mailingStateProvince,
        userProfileData?.mailingPostalCode,
      ]),
      email: `${userProfileData?.email ?? ''}`,
      phoneNumber: `${userProfileData?.primaryPhone ?? ''}`,
      password: `${userProfileData?.middleName ?? ''}`,
      username: `${userProfileData?.username ?? ''}`,
    });
  }, [userProfileData]);

  return (
    <SLayout>
      {(currentUserDataQuery.isLoading || currentUserDataQuery.isFetching) && <Loader />}

      <Title className="page-title" fontWeight="SB" color="charcoal" marginBottom={32} font="Poppins" size="M">
        {t(titleText)}
      </Title>

      <div className="cards-container">
        <CustomCard marginTop={0} padding="24px" className="profile-data-card">
          <DataItem label="Name" value={userProfile.userLegalName} sheetType="legalName" setSheetType={editProfileSheet.setData} showSheet={editProfileSheet.show} />
          <DataItem label="Preferred Name" value={userProfile.userPreferredName} sheetType="preferredName" showInfoIcon setSheetType={editProfileSheet.setData} showSheet={editProfileSheet.show} />
          <DataItem label="Username" value={userProfile.username} sheetType="username" setSheetType={editProfileSheet.setData} showSheet={editProfileSheet.show} />
          <DataItem label="Home Address" value={userProfile.address} sheetType="address" setSheetType={editProfileSheet.setData} showSheet={editProfileSheet.show} />

          <DataItem
            label="Mailing Address"
            value={userProfileData?.isMailingAddressTheSame ? t('myInfo.TheSameAddress') : userProfile.mailingAddress}
            sheetType="mailingAddress"
            setSheetType={editProfileSheet.setData}
            showSheet={editProfileSheet.show}
          />
          <DataItem label="Email" value={userProfile.email} sheetType="email" setSheetType={editProfileSheet.setData} showSheet={editProfileSheet.show} />

          <DataItem
            label="Phone Number"
            value={getLastFourDigits(userProfile?.phoneNumber) || ''}
            sheetType="phoneNumber"
            setSheetType={editProfileSheet.setData}
            showSheet={editProfileSheet.show}
            isLast
          />
        </CustomCard>

        {/* Delete Account Button */}
        <CustomCard marginTop={20} marginBottom={25} borderRadius={16} onClick={handleOnClick} cursorPointer className="delete-button-card">
          {isDesktopSize ? <IconSign iconName="brokenHeart" bgColor="red5" iconColor="red" /> : <Icon name="brokenHeart" color="red" marginRight={16} />}

          <BodyText textType="errorText" size="M" color="red" fontWeight="M" font="Poppins" cursorPointer>
            {t(`profile.MenuDeleteAccount`)}
          </BodyText>
        </CustomCard>
      </div>

      <ProfileEditSheet
        isOpen={editProfileSheet.isActive}
        type={editProfileSheet.data?.sheetType || ''}
        className="profile-page"
        mode={editProfileSheet?.data?.mode}
        closeSheet={onCloseProfileSheet}
      />

      <EditEmailVerificationSheet />
      <EditPhoneNumberSheet />
      <ChangePasswordModal />
      <DeleteAccountModal open={deleteAccountModal.isActive} onClose={deleteAccountModal.hide} />
    </SLayout>
  );
};
