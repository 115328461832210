import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { Button } from 'components/theme/Button/Button';
import { useProperties } from 'utils/hooks/useProperties';
import { BodyText, Title } from 'components/general/Typography';
import { DigitalCodeInput } from 'components/general/DigitalCodeInput/DigitalCodeInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { selectAccountOpeningData } from 'store/user/properties/accountOpeningData.slice';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { getRequiredRule } from 'utils/helpers/validationRules';
import { useSelector } from 'react-redux';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SBlock, SPage } from './EditTaxIdPage.styles';

const JOINT_TYPES = ['Joint', 'JointOwner'];

export const EditTaxIdPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { taxId } = useSelector(selectAccountOpeningData);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const { saveOnboardingData, isUnder18 } = useCashAccountOpening();
  const { getProperty } = useProperties();
  const { isDesktopSize } = useDeviceDimension();
  const isEditing = location?.state?.isEditing;

  const [form] = Form.useForm();
  const { t } = useTranslation('cashAccountOpening');

  const checkIsValid = () => {
    const activeItemErrors = form.getFieldError('taxId');
    const itemVal = form.getFieldValue('taxId');
    setIsSubmitDisabled(Boolean(itemVal.length < 9 || activeItemErrors.length));
  };

  const handleSubmit = async (values: { taxId: string }) => {
    const caoTypeProperty = await getProperty('CAOType').unwrap();

    if (isUnder18 && !JOINT_TYPES.includes(caoTypeProperty)) {
      navigate(ROUTES.myAccountJointAccounts.path, { state: { isUserUnder18: true } });
      saveOnboardingData?.({ currentUrl: ROUTES.myAccountJointAccounts.path, taxId: values.taxId });
    } else {
      const nextUrl = isEditing ? ROUTES.myInfoSummary.path : ROUTES.myInfoAdditionalDetails.path;
      saveOnboardingData?.({ currentUrl: nextUrl, taxId: values.taxId });
      navigate(nextUrl);
    }
  };

  useEffect(() => {
    checkIsValid();
  }, [taxId]);

  return !isEditing ? (
    <>
      <Header title={t('accountOpening.WhatsYourTaxId')} stage="Tax ID" webStage="Tax ID" />
      <SPage>
        <div className="content-container">
          <div className="web-title-container">
            <Title>{t('accountOpening.WhatsYourTaxId')}</Title>
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginBottom={32} marginTop={8}>
              {t('accountOpening.TaxDescription')}
            </BodyText>
          </div>

          <div className="mobile-title-container">
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginBottom={32} paddingRight={10} lineHeight={1.4}>
              {t('accountOpening.TaxDescription')}
            </BodyText>
          </div>

          <Form form={form} name="codeForm" onFieldsChange={checkIsValid} onFinish={handleSubmit} initialValues={{ taxId }}>
            <div className="tax-card">
              <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal" marginTop={10} marginBottom={10}>
                {t('step.Tax ID')}
              </BodyText>
              <Form.Item
                name="taxId"
                initialValue=""
                validateTrigger={['onChange']}
                shouldUpdate
                rules={[getRequiredRule(t('myInfo.EnterYourTaxID')), { type: 'string', min: 9, message: t('myInfo.Please input complete code') }]}
              >
                <DigitalCodeInput name="taxId" separatorsAfter={[2, 4]} size={9} borderRadius="16px" isWrapped showCaret />
              </Form.Item>
            </div>
          </Form>

          <div className="privacy">
            <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal70" lineHeight="16px">
              {t('myInfo.OurPriority')}
            </BodyText>
            <BodyText textType="bodyText" fontWeight="R" size="T" color="charcoal70" lineHeight="16px">
              {t('myInfo.OurSecurityMeasures')}
            </BodyText>
          </div>
        </div>

        <Footer isDisabled={isSubmitDisabled} onClick={form.submit} absolutePosition />

        <div className="mobile-footer">
          <Button size="large" disabled={isSubmitDisabled} onClick={form.submit} preset="primary-with-outline" marginBottom={16}>
            {t('myInfo.Continue')}
          </Button>

          <BodyText textType="bodyText" fontWeight="M" size="T" color="charcoal70" textAlign="end" marginBottom={24} className="next-step" justifyContent="end">
            {t('accountOpening.NextStep')} {t('accountOpening.Details')}
          </BodyText>
          <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal70" lineHeight="16px">
            {t('myInfo.OurPriority')}
          </BodyText>
          <BodyText textType="bodyText" fontWeight="R" size="T" color="charcoal70" lineHeight="16px">
            {t('myInfo.OurSecurityMeasures')}
          </BodyText>
        </div>
      </SPage>
    </>
  ) : (
    <>
      <WebHeader showExit={false} title={t('accountOpening.GoBackToAccountInformation')} />
      <SPage>
        <div className="content-container">
          <div className="edit-title-container">
            <Title textAlign="start" size={isDesktopSize ? 'S' : 'M'} marginBottom={4} fontWeight="SB">
              {t('step.Tax ID')}
            </Title>
            <BodyText textType="bodyText" color="charcoal60" size="N" fontWeight="R">
              {t('myInfo.ChangeYourTaxIdHere')}
            </BodyText>
          </div>

          <Form form={form} name="codeForm" onFieldsChange={checkIsValid} onFinish={handleSubmit} initialValues={{ taxId }}>
            <CustomCard padding="0" background="transparent" extraStyles={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CustomCard borderRadius={20} maxWidth="600px">
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal" marginTop={10} marginBottom={10}>
                  {t('step.Tax ID')}
                </BodyText>
                <Form.Item
                  name="taxId"
                  initialValue=""
                  validateTrigger={['onChange']}
                  shouldUpdate
                  rules={[getRequiredRule(t('myInfo.EnterYourTaxID')), { type: 'string', min: 9, message: t('myInfo.Please input complete code') }]}
                >
                  <DigitalCodeInput name="taxId" separatorsAfter={[2, 4]} size={9} borderRadius="16px" isWrapped showCaret />
                </Form.Item>
                <BodyText textType="bodyText" color="charcoal50" size="N" fontWeight="R" marginBottom={5} marginTop={15} lineHeight={1.4}>
                  {t('myInfo.EnterTaxID')}
                </BodyText>
              </CustomCard>
            </CustomCard>
          </Form>

          <div className="privacy">
            <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal70" lineHeight="16px">
              {t('myInfo.OurPriority')}
            </BodyText>
            <BodyText textType="bodyText" fontWeight="R" size="T" color="charcoal70" lineHeight="16px">
              {t('myInfo.OurSecurityMeasures')}
            </BodyText>
          </div>
        </div>

        <Footer isDisabled={isSubmitDisabled} onClick={form.submit} absolutePosition secondBtnText={t('myInfo.SaveChanges')} />

        <div className="mobile-footer">
          <SBlock>
            <Button size="large" disabled={isSubmitDisabled} onClick={form.submit} marginTop={30} preset="primary">
              {t('myInfo.SaveChanges')}
            </Button>
          </SBlock>
        </div>
      </SPage>
    </>
  );
};
