import React from 'react';
import { Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useTranslation } from 'react-i18next';
import exclamation from 'assets/images/exclamation.svg';
import { useDeleteThirdPartyAccountMutation } from 'store/user/accounts/accounts.api';
import { Loader } from 'components/general/Loader/Loader';

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: string;
  openManageSheet: () => void;
}

export const ConfirmRemoveModal: React.FC<IModalProps> = ({ isOpen, onClose, accountId, openManageSheet }) => {
  const [deleteAccount, deleteAccountResult] = useDeleteThirdPartyAccountMutation();
  const { t } = useTranslation('moveMoney');

  const handleClose = () => {
    onClose();
    openManageSheet();
  };

  const handleRemove = async () => {
    await deleteAccount(accountId).unwrap();
    handleClose();
  };

  return (
    <CustomModal open={isOpen} onCancel={onClose} destroyOnClose centered footer={null} closeIconColor="charcoal70" topPosition="10%" modalName="manageExternalAccountConfirm">
      {deleteAccountResult.isLoading && <Loader />}
      <CustomRow justifyContent="center" marginBottom={35}>
        <img src={exclamation} alt="exclamation" />
      </CustomRow>
      <Title fontWeight="M" color="charcoal" font="Poppins" size="M" marginBottom={15} paddingLeft={10}>
        {t('manageExternalAccounts.WantToRemove')}
      </Title>

      <Button preset="primary" size="xl" onClick={handleRemove} marginBottom={12}>
        {t('RemoveAccount')}
      </Button>
      <Button onClick={handleClose} size="xl">
        {t('KeepAccount')}
      </Button>
    </CustomModal>
  );
};
