import { Modal } from 'antd';
import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SModal = styled(Modal)<{ width?: string; height?: string }>`
  .ant-modal-content {
    width: ${({ width }) => (width ? String(width) : '648px')};
    height: ${({ height }) => (height ? String(height) : '648px')};
    overflow: hidden;
    border-radius: 20px;
    padding: 0;
    display: flex;
    flex-direction: column;

    .ant-modal-close {
      top: 15px;
      right: -15px;
    }

    .ant-modal-body {
      overflow-y: scroll;
      height: 100%;
    }

    .ant-modal-header {
      background: ${getColor('charcoal5')};
      padding: 32px 24px 32px 24px;
      border-radius: 20px 20px 0 0;
      width: 648px;

      .send-via-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .email-container {
        display: flex;
        gap: 16px;
        align-items: start;
        justify-content: space-between;

        .base-input-container {
          height: 48px;
        }

        button {
          max-width: 30%;
          height: 48px;

          font-family: Poppins;
          font-size: 14px;
          font-weight: 600;

          :disabled {
            background: ${getColor('charcoal40')} !important;
            color: ${getColor('white')} !important;
            border: none;
          }
        }

        form {
          width: 100%;

          .ant-form-item {
            margin-bottom: 0;
          }
        }
      }

      .successful-container {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 24px;
      }
    }

    .ant-modal-footer {
      border: none;

      .consent-sheet-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 24px;

        .custom-text-inner {
          color: ${getColor('charcoal70')} !important;
        }

        button {
          width: 145px;
          height: 52px;
          align-self: center;

          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;

          margin: 24px 0 24px 0 !important;

          :disabled {
            background: ${getColor('charcoal40')} !important;
            color: ${getColor('white')} !important;
            border: none;
          }
        }
      }
    }
  }
`;

export const SMaskStyle: React.CSSProperties = {
  background: 'rgba(0, 0, 0, 0.60)',
  backdropFilter: 'blur(4px)',
};
