import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { SAmountResult, SIFrame, SIframeCloseButton } from 'views/PercapitaPay/MainPage/PercapitaPayMainPage.styles';
import { AmountInputComponent } from 'components/general/AmountInput/AmountInputComponent';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { IAccount } from 'store/user/payments/payments.types';
import { Icon } from 'components/general/Icon/Icon';
import { Button } from 'components/theme/Button/Button';
import { mobileApiCall } from 'services/mobileService';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { useSelector } from 'react-redux';
import { selectAccountsData } from 'store/user/accounts/accounts.slice';
import { CardCarousel } from 'views/PercapitaPay/MainPage/components/CardCarousel/CardCarousel';
import { BodyText, Title } from 'components/general/Typography';
import { TextButton } from 'components/general/TextButton/TextButton';
import { Loader } from 'components/general/Loader/Loader';
import { useTranslation, Trans } from 'react-i18next';
import { CurrencyFormatters } from 'utils/helpers/CurrencyFormatters';
import { useLanguage } from 'utils/hooks/useLanguage';
import './PaymentPrepareSheet.css';
import { useToggle } from 'utils/hooks/useToggle';
import { PaymentRequestModal } from 'components/general/Modals/PaymentRequestModal/PaymentRequestModal';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IPaymentPrepareSheet {
  isOpen: boolean;
  isLoading: boolean;
  selectedAccount: number | null;
  availableAmount: number;
  availableMin: number;
  accounts: IAccount[];
  getAccounts: (param: {}) => void;
  handleAddAccountClick: () => void;
  toggleRequestPercPay: () => void;
  handleSelectAccount: (accountId: number) => void;
  onConfirm: (transferAmount: number) => void;
}

export interface IErrorData {
  isError: boolean;
  message?: string;
}

export const PaymentPrepareSheet: React.FC<IPaymentPrepareSheet> = ({
  isOpen,
  selectedAccount,
  toggleRequestPercPay,
  accounts,
  getAccounts,
  availableAmount,
  availableMin,
  handleAddAccountClick,
  handleSelectAccount,
  onConfirm,
  isLoading,
}) => {
  const [isIframeOpen, setIframeOpen] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<IErrorData>({ isError: false, message: '' });
  const [amountToPay, setAmountToPay] = useState<string>(String(availableAmount));
  const [isAmountEditable, setAmountEditable] = useState<boolean>(true);
  const paymentRequestInfoModal = useToggle(false);

  const { immediatePayFrameUrl } = useSelector(selectAccountsData);
  const { isDesktopSize } = useDeviceDimension();

  const addNoteSheet = useToggle();
  const theme = useTheme();

  const { t } = useTranslation('percapitaPay');
  const { locale } = useLanguage();
  const centPrecisionFormatter = CurrencyFormatters.getCentPrecisionFormatter(locale);
  const valueAvailableUpTo = centPrecisionFormatter.format(availableAmount);

  const handleContinueClick = () => {
    onConfirm(Number(amountToPay));
  };

  const handleCloseIframe = () => {
    setIframeOpen(false);
    getAccounts({});
  };

  const handleInfoClick = () => {
    paymentRequestInfoModal.show();
  };

  const onClickAmountValue = () => {
    setAmountEditable(false);
  };

  const handleAmountFieldBlur = () => {
    setAmountEditable(true);
  };

  const onInfoModalClose = () => {
    paymentRequestInfoModal.hide();
  };

  const handleAmountFieldChange = (value: string) => {
    setAmountToPay(value);
    if (Number(value) > availableAmount) {
      setErrorData({ isError: true, message: t('percapitaPayHome.The amount you have requested is more...') });
    } else if (Number(value) < availableMin) {
      setErrorData({
        isError: true,
        message: t('percapitaPayHome.The amount you have requested is less...', { minAmount: availableMin }),
      });
    } else {
      setErrorData({ isError: false });
    }
  };

  useEffect(() => {
    mobileApiCall('backgroundChange', isOpen ? theme.white : theme.blue);
    setAmountToPay(availableAmount.toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return !paymentRequestInfoModal.isActive ? (
    <CustomSheet
      id="payment-prepare-sheet"
      isOpen={isOpen}
      header={false}
      wrapperPadding={false}
      headerStyle={{ minHeight: 0, padding: 0 }}
      className="payment-prepare-sheet"
      onClose={toggleRequestPercPay}
      modalName="paymentPrepare"
    >
      {isLoading && <Loader />}
      <Title font="Poppins" size="S" fontWeight="SB" color="charcoal" marginBottom={32}>
        {t('percapitaPayHome.Request Percapita Pay')}
      </Title>

      <div className="flex">
        <div className="flex">
          <BodyText font="Poppins" size="M" fontWeight="B" color="charcoal" textType="bodyText">
            {t('percapitaPayHome.Enter Amount')}
          </BodyText>
          <Icon name="info" color="blue" size="smaller" cursorPointer onClick={handleInfoClick} marginLeft={8} />
        </div>
        <div className="flex">
          <TextButton onClick={addNoteSheet.show} className="note" iconName="comment" fontWeight="M" font="Poppins">
            {t('percapitaPayHome.AddNote')}
          </TextButton>
        </div>
      </div>

      <CustomCard border={`2px solid ${errorData?.isError ? theme.red : theme.blue}`} marginBottom={20} extraStyles={{ minHeight: 114, padding: '24px 24px 2px !important' }}>
        <div className="flex flex-column flex-end">
          {isAmountEditable ? (
            <SAmountResult onClick={onClickAmountValue}>
              <CustomAmount amount={Number(amountToPay)} color="charcoal" size="larger" remainingSize="xl" remainingWeight={600} multiSizable isPoppins />
            </SAmountResult>
          ) : (
            <AmountInputComponent
              className="percapita-amount-input"
              value={Number(amountToPay) > 0 ? amountToPay : ''}
              onChange={handleAmountFieldChange}
              onBlur={handleAmountFieldBlur}
              autoFocus={!isAmountEditable}
              noPrefix={isDesktopSize}
            />
          )}

          <BodyText color="charcoal70" size="N" textType="helperText" fontWeight="R" marginTop={2} justifyContent="end">
            <Trans
              i18nKey="percapitaPayHome.One transfer of up to..."
              ns="percapitaPay"
              values={{
                valueAvailableUpTo,
              }}
            />
          </BodyText>
        </div>
      </CustomCard>

      {errorData?.isError && (
        <BodyText color="red" size="N" marginTop={5} marginBottom={15} fontWeight="R" textType="helperText">
          {errorData?.message}
        </BodyText>
      )}

      {!accounts?.length ? (
        <div className="flex flex-column flex-start payment-prepare-sheet-account-part">
          <BodyText font="Poppins" size="M" textType="bodyText" color="charcoal" marginBottom={4} fontWeight="SB">
            {t('percapitaPayHome.AddDebitOrBankAccount')}
          </BodyText>

          <BodyText color="charcoal70" marginRight={5} size="N" fontWeight="R" textType="helperText">
            {t('percapitaPayHome.YourMoneyWillBeDelivered')}
          </BodyText>
        </div>
      ) : (
        <div className="flex flex-column flex-start payment-prepare-sheet-account-part">
          <BodyText font="Poppins" size="M" textType="bodyText" color="charcoal" marginBottom={4} fontWeight="SB">
            {t('percapitaPayHome.SelectAccountOrCard')}
          </BodyText>

          <BodyText color="charcoal70" marginRight={5} size="N" fontWeight="R" textType="helperText">
            {t('percapitaPayHome.DebitCardAvailable')}
          </BodyText>
        </div>
      )}

      <CardCarousel className="carousel" accounts={accounts} handleAddAccountClick={handleAddAccountClick} handleSelectAccount={handleSelectAccount} selectedAccount={selectedAccount} />

      <div className="flex flex-row flex-end payment-prepare-sheet-button-part">
        <Button size="middleAlt" marginBottom={32} marginRight={10} marginTop={24} onClick={() => toggleRequestPercPay()}>
          {t('percapitaPayHome.Cancel')}
        </Button>

        <Button size="middleAlt" disabled={errorData?.isError} preset="primary" marginBottom={32} marginTop={24} onClick={handleContinueClick}>
          {t('percapitaPayHome.Continue')}
        </Button>
      </div>

      <CustomModal
        open={isIframeOpen}
        onCancel={handleCloseIframe}
        padding="7px"
        topPosition="0"
        closeIcon={
          <SIframeCloseButton>
            <Icon name="close" size="small" color="blue" />
          </SIframeCloseButton>
        }
        modalName="immediatePay"
      >
        <SIFrame width="100%" height="100%" title="add account" src={immediatePayFrameUrl} />
      </CustomModal>
    </CustomSheet>
  ) : (
    <PaymentRequestModal open={paymentRequestInfoModal.isActive} onClose={onInfoModalClose} />
  );
};
