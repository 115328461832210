import styled from 'styled-components/macro';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import { css } from 'styled-components';
import { Title } from 'components/general/Typography';

export const STitleContainer = styled(Title)`
  margin-bottom: 35px;

  ${mediaFrom(
    'tablet',
    css`
      padding-left: 48px;
      padding-bottom: 40px;
      border-bottom: 1px solid ${getColor('creamS5')};
      margin-bottom: 48px;
      width: 100%;
    `
  )};
`;

export const SDisclosuresPageContent = styled.div`
  width: 100%;
  ${mediaFrom(
    'tablet',
    css`
      max-width: 578px;
      margin: 0 auto;
    `
  )}
`;

export const SDisclosureContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 0 12px;
  border-radius: 20px;
  background: ${getColor('white')};

  .disclosure-item {
    padding-right: 18px;
    height: 64px;

    .icon-chevronRight {
      width: 20px;
    }
  }
`;
