import React, { useMemo } from 'react';
import cashAccountStartedV2 from 'assets/images/cashAccountStartedV2.svg';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { useSelector } from 'react-redux';
import { formatPhone } from 'utils/helpers/phone';
import { selectSystemProperties } from 'store/user/authentication.slice';
import dottesInAngle from 'assets/images/dottesInAngle.svg';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { addNewCashAccount } from 'assets/images';
import { SLink } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage.styles';
import { FeeScheduleModal } from 'views/OpenCashAccount/StarterPage/FeeScheduleModal/FeeScheduleModal';
import { Button } from 'components/theme/Button/Button';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SLayout } from './CashAccOpeningDesktopPage.styles';
import { Cards } from './Cards/Cards';

export type TBreadcrumbsPath = {
  id: number;
  name: string;
  path?: string;
};

interface ICashAccountStarterPage {
  onContinueClick: (alreadyHasCash: boolean) => void;
  onFeeScheduleClick: () => void;
  hide?: () => void;
  isActive?: boolean;
}

export const CashAccOpeningDesktopPage: React.FC<ICashAccountStarterPage> = ({ onContinueClick, onFeeScheduleClick, isActive, hide }) => {
  const { t } = useTranslation(['starter', 'cashAccountOpening']);
  const { state } = useLocation();
  const { fromDesktopSmall } = useDeviceDimension();
  const isSecondaryCash = state?.alreadyHasCash;

  const { supportPhoneNumber } = useSelector(selectSystemProperties);
  const supportTelVal = useMemo(() => `tel:${formatPhone(supportPhoneNumber)}`, [supportPhoneNumber]);
  console.log(fromDesktopSmall);
  return (
    <>
      <SLayout bgFooterImage={dottesInAngle}>
        <div className="header-wrapper">
          <WebHeader showExit={false} showBack={false} />
        </div>
        <header className="header">
          <section className="titleContainer">
            <Title fontWeight="M" size="L" font="Poppins" textAlign="start" color="charcoal" marginBottom={15}>
              {isSecondaryCash ? t('starter.headingNewCash') : t('starter.heading')}
            </Title>

            <BodyText textType="bodyText" font="DM Sans" fontWeight="R" size="N" color="charcoal70" lineHeight={1.4} marginBottom={16} paddingRight={5}>
              {isSecondaryCash ? t('starter.welcomeNewCash') : t('starter.welcome')}
            </BodyText>
          </section>

          <div className="imageContainer">
            <img src={isSecondaryCash ? addNewCashAccount : cashAccountStartedV2} alt="cash account start" width="228px" />
          </div>
        </header>

        <section className="content">
          <Cards onFeeScheduleClick={onFeeScheduleClick} />
        </section>

        {fromDesktopSmall ? (
          <Footer onClick={() => onContinueClick(isSecondaryCash)} isDisabled={false} isBackVisible={false} absolutePosition />
        ) : (
          <div className="footer-button">
            <Button preset="primary" width="100%" onClick={() => onContinueClick(isSecondaryCash)}>
              {t('Continue')}
            </Button>
          </div>
        )}

        <section className="help">
          <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="L" justifyContent="center" marginBottom={17}>
            {t('accountOpening.Need help?', { ns: 'cashAccountOpening' })}
          </BodyText>

          <address>
            <a href={supportTelVal}>{supportPhoneNumber}</a>
          </address>
        </section>

        <footer>
          <BodyText textType="bodyText" color="charcoal70" size="S" fontWeight="R" textAlign="start" className="disclosure">
            <Trans i18nKey="starter.legal" components={{ Link: <SLink href="http://www.fdic.gov" /> }} target="_blank" />
          </BodyText>
        </footer>
      </SLayout>

      {isActive && hide ? <FeeScheduleModal isActive={isActive} hide={hide} /> : null}
    </>
  );
};
