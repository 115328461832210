import styled, { css } from 'styled-components';
import bgCreamGradient from 'assets/images/bgCreamGradient.svg';
import { getColor, mediaBetween, mediaUpTo } from 'utils/helpers/styleHelpers';
import { telephone } from 'assets/icons';

export const SLayout = styled.div<{ bgFooterImage?: string }>`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    justify-content: space-between;
    background-image: url(${bgCreamGradient});
    background-position: bottom;
    min-height: 318px;
    padding: 0 60px 52px;

    ${mediaUpTo(
      'desktop',
      css`
        flex-direction: column-reverse;
      `
    )}

    ${mediaUpTo(
      'desktop',
      css`
        padding: 0;
        padding-left: 25px;
        min-height: 455px;
        padding-top: 15px;
      `
    )}
  }

  header .titleContainer {
    display: flex;
    flex-direction: column;
    flex: 5;
    padding-top: 70px;
    ${mediaUpTo(
      'desktop',
      css`
        padding-top: 30px;

        & .custom-title-text {
          font-size: 24px;
        }
      `
    )}

    ${mediaBetween(
      'tablet',
      635,
      css`
        .custom-title-text {
          font-size: 24px;
        }
      `
    )}
  }

  header .imageContainer {
    display: flex;
    flex: 3;
    justify-content: flex-end;
    height: 232px;
    overflow: hidden;
    margin-inline: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 60px 26px;

    ${mediaUpTo(
      'desktop',
      css`
        padding-inline: 25px;
      `
    )}
  }

  section.help {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 16px 0;
    text-align: center;

    button.primary {
      width: 164px;
      margin-bottom: 60px;
    }

    address {
      margin: 0;

      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 24px;
        margin-bottom: 8px;
        mask: url(${telephone}) no-repeat center;
        background-color: ${getColor('blue')};
      }

      a {
        font-weight: 700;
        color: ${getColor('charcoal')};

        &:hover {
          color: ${getColor('blue')};
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 212px;
    padding: 0 60px 64px 60px;

    .disclosure {
      width: 75%;
    }

    ${mediaBetween(
      'tablet',
      'desktop',
      css`
        padding: 0 20px 45px;
        min-height: 195px;
        & div {
          width: 100% !important;
          line-height: 1.3;
        }
      `
    )}

    ${({ bgFooterImage }) => css`
      background: url(${bgFooterImage}) no-repeat bottom right;
    `};
  }
`;
