import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo, mediaUpToHeight } from 'utils/helpers/styleHelpers';
import { TThemeColor } from 'styles/theme';

export interface SBarInputProps {
  bgColor: TThemeColor;
}

export interface SStepperProps {
  scrollable: boolean;
}

export const SBar = styled.div<SBarInputProps>`
  height: 4px;
  background: ${({ bgColor }) => getColor(bgColor)};
  width: 100%;
`;

export const SContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 15px;

  ${mediaFrom(
    'tablet',
    css`
      display: none;
    `
  )}

  ${mediaUpTo(
    400,
    css`
      .custom-text-inner {
        font-size: 11px;
      }
    `
  )}

    ${mediaUpTo(
    365,
    css`
      .custom-text-inner {
        font-size: 10px;
      }
    `
  )}

    ${mediaUpTo(
    340,
    css`
      .custom-text-inner {
        font-size: 9px;
      }
    `
  )}
`;

export const SBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  ${mediaUpTo(
    380,
    css`
      ${mediaUpToHeight(
        700,
        css`
          .custom-text-inner {
            font-size: 11px;
          }
        `
      )}
    `
  )}
`;

export const SWebContainer = styled.div<SStepperProps>`
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 16px;

  ${({ scrollable }) =>
    scrollable
      ? `
    overflow-x: auto;
    width: 100%;
  `
      : ''};

  .active {
    border-bottom: 2px solid ${getColor('blue')};
  }

  .done {
    border-bottom: 2px solid ${getColor('green')};
  }

  ${mediaUpTo(
    'tablet',
    css`
      display: none;
    `
  )}
`;

export const SWebStep = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  background: ${getColor('transparent')};
  padding-bottom: 6px;
  border-bottom: 2px solid ${getColor('creamS30')};
  width: 92px;
  flex-shrink: 0;

  .body-text {
    overflow: hidden;
  }

  .custom-text-inner {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
