import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import percapitaDots from './images/percapitaDots.svg';
import percapitaLeftDots from './images/percapitaLeftDots.svg';
import percapitaRightDots from './images/percapitaRightDots.svg';
import percapitaLogo from './images/percapitaLogo.svg';

export const SInitialPage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url(${percapitaDots}) ${getColor('blue')} no-repeat center left;
  background-size: cover;

  ${mediaFrom(
    'tablet',
    css`
      background: url(${percapitaRightDots}) right bottom no-repeat, ${getColor('blue')} url(${percapitaLeftDots}) left top no-repeat;
      background-size: contain;
    `
  )}

  .logo {
    position: relative;
    margin-top: -35%;
    height: 45px;
    width: 270px;
    background: url('${percapitaLogo}') no-repeat;
    background-size: contain;
    justify-self: flex-end;

    ${mediaFrom(
      'tablet',
      css`
        position: absolute;
        top: 48px;
        height: 34px;
        width: 200px;
        margin: 0;
      `
    )}
  }

  .content {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mediaFrom(
      'tablet',
      css`
        position: relative;
      `
    )}
  }

  .text-container {
    margin-bottom: 40px;
    ${mediaFrom(
      'tablet',
      css`
        margin-bottom: 48px;
      `
    )}
  }

  .language-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 44px;
    margin-bottom: 84px;
    justify-self: flex-end;

    .btn {
      flex: 0 0 auto;
      width: auto;
    }

    ${mediaFrom(
      'tablet',
      css`
        position: relative;
        bottom: 0;
        margin-bottom: 50px;
        gap: 32px;
      `
    )}
  }
`;
