import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { Title, BodyText } from 'components/general/Typography';
import { images } from 'assets';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Button } from 'components/theme/Button/Button';
import { SContainer } from './OpenNewCashApprovedPage.styles';

export const OpenNewCashApprovedPage: React.FC = () => {
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation('cashAccountOpening', { keyPrefix: 'openNewCash' });
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate(ROUTES.home.path);
  };

  return (
    <>
      <WebHeader showExit={false} showBack={false} />
      <SContainer>
        <img src={images.congratulation} alt="congratulation" />
        <Title textAlign="center" justifyContent="center" size={isDesktopSize ? 'L' : 'M'} fontWeight="M" marginBottom={16}>
          <span>
            {t('approved.Congrats')}
            <br />
            {t('approved.YouHaveBeenApproved')}
          </span>
        </Title>
        <BodyText textType="bodyText" fontWeight="R" size={isDesktopSize ? 'L' : 'N'} color="charcoal70" lineHeight={1.6} textAlign="center" justifyContent="center">
          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="center" lineHeight="24px">
            {t('approved.YourNewCashIsOpen')}
          </BodyText>
        </BodyText>

        <Button preset="primary" onClick={handleGetStarted} marginTop={isDesktopSize ? 40 : 48}>
          {t('approved.GetStarted')}
        </Button>
      </SContainer>
    </>
  );
};
