import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationsTab } from 'views/NotificationsAndAlerts/NotificationsTab/NotificationsTab';
import { Title } from 'components/general/Typography';
import { SCustomSheet } from './NotificationsSettingsModal.styles';

interface IModalProps {
  open: boolean;
  onClose: () => void;
}

export const NotificationsSettingsModal: React.FC<IModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('notificationsAlerts');
  return (
    <SCustomSheet isOpen={open} onClose={onClose} modalName="notificationSettings" padding="32px">
      <Title marginBottom={8}>{t('Notifications&Alerts')}</Title>
      <NotificationsTab />
    </SCustomSheet>
  );
};
