import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Button } from 'components/theme/Button/Button';
import { COUNTRIES_FOR_REQUEST } from 'views/MoveMoney/InternationalTransfer/mock';
import { CountriesList } from 'views/MoveMoney/InternationalTransfer/CountriesList/CountriesList';
import { SCustomSheet } from './SelectYourCountrySheet.styles';

const search = (list: any, searchValue: string) =>
  list?.filter((item: any) => {
    const listItemString = item.title.trim().toLowerCase();
    const searchString = searchValue.trim().toLowerCase();

    if (!listItemString || !searchString) return item;

    const regex = new RegExp(searchString);
    return listItemString?.match(regex);
  });

export const SelectYourCountrySheet: React.FC<any> = ({ isOpen, onClose, showCountriesRequestSubmitModal }) => {
  const { t } = useTranslation('moveMoney');
  const [selectCountryForm] = useForm();
  const countriesListInitial = COUNTRIES_FOR_REQUEST.map((item: any) => ({ ...item, isSelected: false })).sort((a: any, b: any) => (a.title < b.title ? -1 : 1));

  const selectCountry = (country: any) => {
    const countriesList = selectCountryForm.getFieldValue('countriesList');

    const countriesListResult = countriesList?.map((item: any) => {
      if (item.code === country.code) return { ...item, isSelected: !country?.isSelected };
      return item;
    });

    selectCountryForm.setFieldValue('countriesList', countriesListResult);
  };

  const handleFieldsChange = (fieldValue: any, values: any[]) => {
    const searchField = values.filter((item) => item.name[0] === 'search')[0];
    const countriesListField = values.filter((item) => item.name[0] === 'countriesList')[0];
    const searchResult = search(countriesListInitial, searchField?.value);
    const countriesList = searchResult?.map((sItem: any) => countriesListField.value?.find((item: any) => sItem?.code === item?.code) ?? sItem);

    selectCountryForm.setFieldValue('countriesList', countriesList);
  };

  const handleFinish = (values: any) => {
    console.log('handleFinish.values = ', values);

    showCountriesRequestSubmitModal();
  };

  return (
    <SCustomSheet isOpen={isOpen} onClose={onClose} height="95%" maxHeight="95%" paddingTop="34px" paddingBottom="56px">
      <div className="header">
        <Icon name="arrowLeft" color="charcoal" onClick={onClose} cursorPointer size="small" marginRight={12} />
        <Title>{t('internationalTransferPage.SelectYourCountryRequest')}</Title>
      </div>

      <BodyText size="N" fontWeight="R" color="charcoal70" lineHeight={1.4} marginBottom={32}>
        {t('internationalTransferPage.SelectTheCountryYouWouldLikeToSee')}
      </BodyText>

      <Form
        form={selectCountryForm}
        name="selectCountryForm"
        onFieldsChange={handleFieldsChange}
        onFinish={handleFinish}
        initialValues={{
          search: '',
          countriesList: [...countriesListInitial],
        }}
      >
        <Form.Item style={{ marginBottom: 32 }} name="search">
          <BaseInput placeholder={t('internationalTransferPage.TypeWhatCountryYouReLookingFor')} suffix="search" suffixSize="big" suffixColor="charcoal" />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }} className="form-item-countries-list-wrapper" name="countriesList" valuePropName="list">
          <CountriesList selectCountry={selectCountry} />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Button preset="primary" size="middleAlt">
            {t('internationalTransferPage.SubmitRequest')}
          </Button>
        </Form.Item>
      </Form>
    </SCustomSheet>
  );
};
