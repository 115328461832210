import styled from 'styled-components/macro';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCrumbs = styled.ul`
  display: flex;
  margin-top: 12px;
  align-items: center;
  gap: 16px;
  list-style-type: none;
  font-family: 'DM Sans', sans-serif;

  .crumbs {
    &__crumb-text {
      color: ${getColor('charcoal70')} !important;
      padding: 4px;

      &_last {
        color: ${getColor('blue')};
        background: ${getColor('blue5')};
      }
    }
  }
`;
