import styled, { css } from 'styled-components/macro';
import { MEDIA_SIZE, getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  width: 100%;
  padding-top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 100%;
    margin-bottom: 122px;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 24px;
    width: 48px;
    height: 48px;
    background: ${getColor('white')};
    flex: 0 0 auto;
  }

  .card {
    background: ${getColor('transparent')};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;

    .phone-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .phone-number {
        color: ${getColor('charcoal')};
      }
    }
  }

  ${mediaFrom(
    MEDIA_SIZE.tablet,
    css`
      .custom-title-text {
        font-size: 32px;
      }

      .title {
        margin-bottom: 16px;
      }

      .container {
        max-width: 580px;
      }

      .icon-wrapper {
        background: ${getColor('blue5')};
      }

      .card {
        background: ${getColor('white')};
        padding: 32px;
        gap: 32px;
        border-radius: 20px;

        button {
          max-width: 168px;
          padding: 14px 40px;
          height: 52px;
        }
      }
    `
  )}
`;
