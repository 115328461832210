import React from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { cantOpenAccount } from 'assets/images';
import { Button } from 'components/theme/Button/Button';
import { SContentLayout } from 'views/Account/AccountInformation/Modals/closingAccount/GoalsHavingIssuesModal/GoalsHavingIssuesModal.styles';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { IModalCommonProps } from 'views/Account/Summary/InternalTransfer/InternalTransferPage.types';
import { EAccountType } from 'store/user/accounts/accounts.types';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';

interface IModalProps extends IModalCommonProps {
  onCloseMyGoalsAccountClick?: () => void;
  onCancel?: () => void;
  accountType?: string;
}

export const WantToCloseModal = ({ open, onClose, onCloseMyGoalsAccountClick, onCancel, accountType }: IModalProps) => {
  const { t } = useTranslation('accountInformation');
  const isGoalsType = accountType === EAccountType.SAVE;

  return (
    <CustomSheet isOpen={open} onClose={onClose} modalName="wantToClose" closable className="wantCloseModal">
      <SContentLayout>
        <div className="wantCloseModalInner">
          <img src={cantOpenAccount} alt="having-issues" />

          <Title size="M" fontWeight="M" marginBottom={14}>
            {isGoalsType ? t('accountInformation.Goals.AreYouSureYouWantToClose') : t('accountInformation.Needs.AreYouSureYouWantToClose')}
          </Title>

          <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5} marginBottom={32}>
            {isGoalsType ? t('accountInformation.Goals.YourGoalsAccountBalanceIs0') : t('accountInformation.Needs.YourNeedsAccountBalanceIs0')}
          </BodyText>

          <CustomRow flexDirection="column">
            <Button preset="primary-red" size="xl" marginBottom={18} onClick={onCloseMyGoalsAccountClick}>
              {isGoalsType ? t('accountInformation.Goals.YesCloseMyGoalsAccount') : t('accountInformation.Needs.YesCloseMyNeedsAccount')}
            </Button>

            <Button preset="red" size="xl" onClick={onCancel}>
              {t('accountInformation.Cancel')}
            </Button>
          </CustomRow>
        </div>
      </SContentLayout>
    </CustomSheet>
  );
};
