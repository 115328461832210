import React, { useEffect, useState } from 'react';
import { Button } from 'components/theme/Button/Button';
import { useLanguage } from 'utils/hooks/useLanguage';
import { DayPickerComponent } from 'components/general/DayPicker/DayPickerComponent';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Icon } from 'components/general/Icon/Icon';
import { TDates } from 'components/general/BottomDrawers/FilterDrawer/FilterDrawer.types';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { useTranslation } from 'react-i18next';
import { Title } from 'components/general/Typography';

export type IIntervalFilterProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  getDatesValue: () => TDates;
  onDateSelect: (dates: TDates) => void;
};

export const IntervalFilter: React.FC<IIntervalFilterProps> = ({ title, isOpen, onClose, getDatesValue, onDateSelect }) => {
  const [selectedDates, setSelectedDates] = useState<TDates>([null, null]);
  const { language } = useLanguage();
  const { t } = useTranslation();

  const onDateRangeChange = (dates: TDates) => {
    setSelectedDates(dates);
  };

  const handleSubmit = () => {
    const isRangeValue = selectedDates.every(Boolean);
    if (isRangeValue) onDateSelect(selectedDates);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      const dates = getDatesValue();
      setSelectedDates(dates);
    }
  }, [isOpen]);

  return (
    <CustomSheet isOpen={isOpen} onClose={onClose} modalName="interval" className="date-range">
      <CustomRow justifyContent="flex-start" alignItems="center" marginBottom={32}>
        <Icon name="arrowLeft" color="charcoal70" marginRight={10} onClick={onClose} />
        <Title>{title}</Title>
      </CustomRow>

      <DayPickerComponent locale={language} value={selectedDates} range open onChange={onDateRangeChange} style={{ marginBottom: 32 }} />

      <Button preset="primary" size="middleStretch" onClick={handleSubmit} disabled={!selectedDates[1]}>
        {t('balancesTransactions.Apply')}
      </Button>
    </CustomSheet>
  );
};
