import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { ICountriesItemWithSelected } from 'views/MoveMoney/InternationalTransfer/types';
import { SLayout } from './CountriesList.styles';

export const CountriesList: React.FC<any> = ({ list, selectCountry, isComingSoon }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <SLayout className="countries-list">
      {list?.map((item: ICountriesItemWithSelected) => (
        <div className="country-row" key={`${item?.id}-${item.code}-soon`} onClick={() => selectCountry?.(item)}>
          <Icon name={item?.iconName} size="xl" marginRight={16} />

          <BodyText size="M" color={!isComingSoon ? 'charcoal' : 'charcoal50'} fontWeight={item?.isSelected ? 'B' : 'R'}>
            {t(`countries.${item?.title}`)}
          </BodyText>

          {item?.isSelected && <Icon name="circleTick" color="blue" className="icon-selected" />}
        </div>
      ))}
    </SLayout>
  );
};
