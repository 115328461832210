import styled, { css } from 'styled-components/macro';
import { MEDIA_SIZE, getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  width: 100%;
  padding-top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 100%;
    margin-bottom: 122px;
  }

  .card {
    background: ${getColor('transparent')};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .mobile-resend-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;

    .custom-text-inner {
      white-space: no-wrap;
    }
  }

  .inner {
    background: ${getColor('white')};
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    justify-content: center;
    aling-items: center;
    gap: 24px;
    padding: 24px;
    margin-bottom: 40px;
    width: 100%;
  }

  .desktop-resend-wrapper {
    display: none;
  }

  ${mediaFrom(
    MEDIA_SIZE.tablet,
    css`
      .mobile-resend-wrapper {
        display: none;
      }

      .desktop-resend-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .custom-title-text {
        font-size: 32px;
      }

      .title {
        margin-bottom: 16px;
      }

      .container {
        max-width: 580px;
      }

      .card {
        padding: 32px;
        gap: 32px;
        border-radius: 20px;
        background: ${getColor('white')};

        .inner {
          border: 2px solid ${getColor('grey2')};
        }

        button {
          max-width: 168px;
          padding: 14px 40px;
          height: 52px;
        }
      }
    `
  )}
`;
