import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from 'styled-components';
import { Button } from 'components/theme/Button/Button';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useTransferMoneyMutation } from 'store/user/accounts/accounts.api';
import { Loader } from 'components/general/Loader/Loader';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { getAccountNameByAuth } from 'utils/helpers/accounts/accountsHelper';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { formatLocaleDate } from 'utils/helpers/date';
import { SSheet } from './InternalTransferConfirmSheet.styles';

type IConfirmTransferSheetProps = {
  isOpen: boolean;
  fromAccount: IAccountItem;
  toAccount: IAccountItem;
  note: string;
  transferAmount: number;
  onClose: () => void;
  onSuccess: () => void;
};

export const InternalTransferConfirmSheet = ({ isOpen, fromAccount, toAccount, note, transferAmount, onClose, onSuccess }: IConfirmTransferSheetProps) => {
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const [transferMoneyAPI, transferMoneyAPIResult] = useTransferMoneyMutation();
  const theme = useTheme();
  const { isDesktopSize } = useDeviceDimension();

  const currentDate = formatLocaleDate(new Date(), 'MMM dd, yyyy', locale);

  const handleConfirm = async () => {
    const transferData = {
      fromAccountId: fromAccount.fiservAccountId,
      toAccountId: toAccount.fiservAccountId,
      amount: transferAmount,
      notes: [note],
      transactionId: uuidv4(),
    };

    await transferMoneyAPI(transferData);
  };

  useEffect(() => {
    if (transferMoneyAPIResult?.isSuccess) {
      onClose();
      onSuccess();
    }

    if (transferMoneyAPIResult?.isError) onClose();
  }, [transferMoneyAPIResult?.isSuccess, transferMoneyAPIResult?.isError]);

  return (
    <SSheet isModal={isDesktopSize} isOpen={isOpen} header={false} wrapperPadding={isDesktopSize} onClose={onClose} isLocked={transferMoneyAPIResult.isLoading}>
      {transferMoneyAPIResult?.isLoading && <Loader />}
      <CustomRow justifyContent="flex-start" marginBottom={24}>
        <Title font="Poppins" color="charcoal" fontWeight="SB" size="sM">
          {t('internalTransfer.Confirm Transfer')}
        </Title>
      </CustomRow>

      <BodyText textType="bodyText" font="Poppins" color="charcoal" size="M" fontWeight="SB" textAlign="start">
        {t('internalTransfer.Transfer Details')}
      </BodyText>

      <CustomCard border={`2px solid ${theme.charcoal5}`} borderRadius={20} marginTop={16} marginBottom={32}>
        <CustomRow marginBottom={16}>
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" lineHeight="20px">
            {t('internalTransfer.Transfer Amount')}
          </BodyText>
          <CustomAmount size="smallerStrong" amount={transferAmount} />
        </CustomRow>

        <CustomRow marginBottom={16}>
          <CustomRow width="30%">
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginRight={10} lineHeight="20px" nowrap>
              {t('internalTransfer.Account from')}
            </BodyText>
          </CustomRow>

          {fromAccount && (
            <CustomRow justifyContent="flex-end" flexWrap="wrap" width="70%" paddingLeft={15}>
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" extraStyles={{ whiteSpace: 'break-spaces' }}>
                {fromAccount.nickname || fromAccount.shortName}
              </BodyText>
              <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={5} nowrap>
                {fromAccount.name}
              </BodyText>
            </CustomRow>
          )}
        </CustomRow>

        <CustomRow marginBottom={16}>
          <CustomRow width="30%">
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginRight={10} lineHeight="20px" nowrap>
              {t('internalTransfer.Account to')}
            </BodyText>
          </CustomRow>
          {toAccount && (
            <CustomRow justifyContent="flex-end" width="70%" flexWrap="wrap">
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" extraStyles={{ whiteSpace: 'break-spaces' }}>
                {toAccount.nickname || toAccount.shortName}
              </BodyText>
              <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={5} nowrap>
                {toAccount.name}
              </BodyText>
            </CustomRow>
          )}
        </CustomRow>

        <CustomRow>
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" lineHeight="20px">
            {t('internalTransfer.Date')}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" lineHeight="20px">
            {currentDate}
          </BodyText>
        </CustomRow>

        <CustomRow marginTop={16}>
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" lineHeight="20px">
            {t('internalTransfer.Note')}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" lineHeight="20px">
            {note}
          </BodyText>
        </CustomRow>
      </CustomCard>

      <BodyText textType="bodyText" font="Poppins" color="charcoal" size="M" fontWeight="SB" textAlign="start">
        {t('internalTransfer.After Transfer')}
      </BodyText>

      <CustomCard border={`2px solid ${theme.charcoal5}`} borderRadius={20} marginTop={16} marginBottom={32}>
        <CustomRow marginBottom={27} alignItems="flex-start">
          {fromAccount && (
            <CustomRow justifyContent="flex-start" width="65%" flexWrap="wrap">
              <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" extraStyles={{ whiteSpace: 'break-spaces' }}>
                {t(`account.${getAccountNameByAuth(fromAccount)}`)}
              </BodyText>
              <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={5} nowrap>
                {fromAccount.maskedAccountId}
              </BodyText>
            </CustomRow>
          )}

          {fromAccount && fromAccount.balance !== undefined && (
            <CustomRow flexDirection="column" gap={10} alignItems="flex-end" marginLeft={20} width="35%">
              <CustomAmount size="smaller" amount={fromAccount.balance} />
              <CustomAmount size="smaller" color="red" sign amount={-transferAmount} />
              <CustomAmount size="smallerStrong" amount={fromAccount.balance - transferAmount} />
            </CustomRow>
          )}
        </CustomRow>

        <CustomRow marginBottom={27} alignItems="flex-start">
          {toAccount && (
            <CustomRow justifyContent="flex-start" width="65%" flexWrap="wrap">
              <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginRight={5} extraStyles={{ whiteSpace: 'break-spaces' }}>
                {t(`account.${getAccountNameByAuth(toAccount)}`)}
              </BodyText>
              <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" nowrap>
                {toAccount.maskedAccountId}
              </BodyText>
            </CustomRow>
          )}

          {toAccount && toAccount.balance !== undefined && (
            <CustomRow flexDirection="column" gap={10} alignItems="flex-end" marginLeft={20} width="35%">
              <CustomAmount size="smaller" amount={toAccount.balance} />
              <CustomAmount size="smaller" color="green" sign amount={transferAmount} />
              <CustomAmount size="smallerStrong" amount={toAccount.balance + transferAmount} />
            </CustomRow>
          )}
        </CustomRow>
      </CustomCard>

      <CustomRow justifyContent="flex-end" gap={8} marginBottom={30}>
        <Button onClick={onClose}>{t('internalTransfer.Cancel')}</Button>
        <Button preset="primary" onClick={handleConfirm}>
          {t('internalTransfer.Confirm')}
        </Button>
      </CustomRow>

      <SuttonDisclaimerNote />
    </SSheet>
  );
};
