import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import { addGoalsV2, addNeedsV2, addNewCashAccount, cashCushion, jointAccountholderV2, onboardingMan, pennyJarMainLogoV2, startDepositV2 } from 'assets/images';
import { ICON_SIZE_MAP } from 'components/general/Icon/Icon.constants';

export const SOptionItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background: white no-repeat center left;
  padding: 16px 20px 16px 72px;
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  max-width: 778px;
  min-height: 82px;
  flex-shrink: 0;

  .do-more-option__content {
    flex: 1;
  }

  .do-more-option__title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .title {
      flex: 1;

      .custom-title-text {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .body-text {
    .custom-text-inner {
      font-size: 11px;
    }
  }

  &.cash-account {
    background-image: url(${onboardingMan});
    background-position: bottom -10px left -24px;
    background-size: auto 88px;
  }

  &.direct-deposit {
    background-image: url(${startDepositV2});
    background-position: bottom left;
    background-size: auto 82px;
  }

  &.joint-accountholder {
    background-image: url(${jointAccountholderV2});
    background-position: bottom left;
    background-size: auto 82px;
  }

  &.goals-account {
    background-image: url(${addGoalsV2});
    background-position: center left -4px;
    background-size: auto 63px;
  }

  &.needs-account {
    background-image: url(${addNeedsV2});
    background-position: center left -2px;
    background-size: auto 64px;
  }

  &.cash-cushion {
    background-image: url(${cashCushion});
    background-position: 5px;
    background-size: auto 78px;
  }

  &.penny-jar {
    background-image: url(${pennyJarMainLogoV2});
    background-position: bottom left;
    background-size: auto 90px;
  }

  &.second-cash-account {
    background-image: url(${addNewCashAccount});
    background-position: bottom left;
    background-size: auto 65px;
  }

  &.disabled::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    cursor: not-allowed;
    background: ${getColor('creamS5')};
    opacity: 0.55;
  }

  .icon-sign {
    height: 36px;
    width: 36px;
    position: absolute;
    left: 18px;
  }

  // 320px
  ${mediaFrom(
    'mobile',
    css`
      border-radius: 20px;
      padding: 24px 24px 24px 96px;

      .do-more-option__content {
        .do-more-option__title-wrapper {
          margin-bottom: 8px;

          .title {
            .custom-title-text {
              font-size: 18px;
              font-family: DM Sans, sans-serif;
              font-weight: 700;
            }
          }
        }

        & > .body-text {
          .custom-text-inner {
            font-size: 12px;
          }
        }
      }

      & > .icon-chevronRight {
        height: ${ICON_SIZE_MAP.smallest}px;
        width: ${ICON_SIZE_MAP.smallest}px;
      }

      &.cash-account {
        background-position: bottom -10px left -28px;
        background-size: auto 109px;
      }

      &.goals-account {
        background-position: center left 0;
        background-size: auto 74px;
      }

      &.needs-account {
        background-position: center left -2px;
        background-size: auto 78px;
      }

      &.penny-jar {
        background-position: bottom left;
        background-size: auto 118px;
      }

      &.second-cash-account {
        background-size: auto 75px;
      }

      .icon-sign {
        height: 40px;
        width: 40px;
        left: 28px;
      }
    `
  )};

  // 768px
  ${mediaFrom(
    'tablet',
    css`
      min-height: 96px;
      padding: 24px 24px 24px 96px;

      .do-more-option__content {
        .do-more-option__title-wrapper {
          margin-bottom: 16px;

          .title {
            margin-right: 10px;
          }
        }
      }

      .body-text {
        .custom-text-inner {
          font-size: 14px;
        }
      }

      & > .icon-chevronRight {
        margin-left: 16px;
      }

      &.cash-account {
        background-position: bottom -10px left -28px;
        background-size: auto 109px;
      }

      &.direct-deposit {
        background-position: top 30px left 5px;
        background-size: auto 92px;
      }

      &.joint-accountholder {
        background-position: top 20px left;
        background-size: auto 90px;
      }

      &.goals-account {
        background-position: center left 1px;
        background-size: auto 74px;
      }

      &.needs-account {
        background-position: center left;
        background-size: auto 78px;
      }

      &.cash-cushion {
        background-position: top 25px left -10px;
        background-size: auto 98px;
      }

      &.penny-jar {
        background-position: bottom left 0;
        background-size: auto 102px;
      }

      &.second-cash-account {
        background-position: top 21px left 2px;
        background-size: auto 90px;
      }
    `
  )};

  // 1200px
  ${mediaFrom(
    'desktop',
    css`
      align-items: flex-start;
      min-height: 260px;
      width: 296px;
      padding: 24px 6px 32px 96px;

      .do-more-option__content {
        .do-more-option__title-wrapper {
          min-height: 46px;

          .title {
            .custom-title-text {
              line-height: 1.3;
            }
          }

          .icon-chevronRight {
            height: ${ICON_SIZE_MAP.smallest}px;
            width: ${ICON_SIZE_MAP.smallest}px;
          }
        }

        & > .body-text {
          .custom-text-inner {
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }

      &.disabled {
        .icon-sign {
          top: 28px;
          left: auto;
          right: 0;
        }
      }

      &.cash-account {
        background-position: top 21px left -20px;
        background-size: auto 96px;
      }

      &.goals-account {
        background-position: top 20px left;
        background-size: auto 71px;
      }

      &.needs-account {
        background-position: top 30px left -3px;
        background-size: auto 78px;
      }

      &.penny-jar {
        background-position: top left;
      }
    `
  )};

  // 1440px
  ${mediaFrom(
    'desktopLarge',
    css`
      width: 310px;
      min-height: 240px;
      padding: 24px 24px 32px 96px;

      &.disabled {
        .icon-sign {
          right: 12px;
        }
      }
    `
  )};

  // 1900px
  ${mediaFrom(
    'desktopLargest',
    css`
      width: 314px;
    `
  )}
`;
