import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { Button } from 'components/theme/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { SActionButtons, SLink, SListItemText, SModalContent, SWebModalFooter } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage.styles';

interface IMakeJointCashAccountWebModal {
  open: boolean;
  onClose: () => void;
  handleOnYes: () => void;
  handleOnNo: () => void;
  isClosable?: boolean;
  accountName?: string;
  isSecondaryCash?: boolean;
  showDisclosureSheet?: () => void;
}

export const MakeJointCashAccountWebModal = ({
  open,
  onClose,
  handleOnYes,
  handleOnNo,
  accountName,
  isClosable = true,
  isSecondaryCash = false,
  showDisclosureSheet,
}: IMakeJointCashAccountWebModal) => {
  const { t } = useTranslation('cashAccountOpening', { keyPrefix: 'myInfo' });
  const accountNameStr = isSecondaryCash ? 'this' : accountName || 'this';

  return (
    <CustomModal
      wrapClassName="long-modal-scroll"
      open={open}
      onCancel={onClose}
      closeIconColor="charcoal70"
      topPosition="0"
      closable={isClosable}
      width="600px"
      padding="0"
      className="make-joint-cash-account-modal"
      modalName="makeJointCashAccount"
    >
      <SModalContent>
        <Title font="Poppins" size="L" fontWeight="M" color="charcoal" marginTop={5} marginBottom={16}>
          {t('MakeThisJointCashAccount', { accountName: accountNameStr })}
        </Title>

        <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" marginBottom={16}>
          {t('YourJointAccountholderWill')}
        </BodyText>

        <div>
          <SListItemText>{t(`CoOwnerCashWeb`)}</SListItemText>
          <SListItemText>{t(`CoOwnerCashNeedsWeb`)}</SListItemText>
          <SListItemText>{t(`HaveTheirOwnDebitCard`)}</SListItemText>
          <SListItemText>{t(`HaveUnrestrictedAccessToTheMoney`)}</SListItemText>
          <SListItemText>
            <Trans i18nKey="myInfo.BeAbleToDoAnythingWeb" ns="cashAccountOpening" components={{ Link: <SLink onClick={showDisclosureSheet} /> }} />
          </SListItemText>
        </div>
      </SModalContent>

      <SActionButtons>
        <Button preset="secondary" onClick={handleOnNo}>
          {t('No')}
        </Button>
        <Button preset="primary" onClick={handleOnYes}>
          {t('Yes')}
        </Button>
      </SActionButtons>

      <SWebModalFooter>
        <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R">
          {t(`PercapitaGroupIncIsADigitalCompanyThatProvidesAccess`)}
        </BodyText>
      </SWebModalFooter>
    </CustomModal>
  );
};
