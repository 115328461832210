import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BodyText, Title } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import exclamation from 'assets/images/exclamation.svg';
import { ROUTES } from 'vars/const/ROUTES';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'utils/hooks/useToggle';
import { useTranslation } from 'react-i18next';
import { INotificationItem } from 'store/user/notificationsCenter/notificationsCenter.types';
import { TagBar } from 'views/Account/BalancesTransactionsPage/TagBar/TagBar';
import { useLanguage } from 'utils/hooks/useLanguage';
import { isSameDay } from 'date-fns';
import { formatLocaleDate } from 'utils/helpers/date';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { Button } from 'components/theme/Button/Button';
import { useLazyGetNotificationsByFilterQuery, useLazyGetNotificationsQuery, useMarkNotificationsDeletedMutation, useMarkNotificationsReadMutation } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import {
  selectUserNotifications,
  setNotificationFilterString,
  setOrderString,
  setNotificationFilterParams,
  setNotificationIsSearchVisible,
  setNotificationSearch,
} from 'store/user/notificationsCenter/notificationsCenter.slice';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { searchValidator } from 'utils/helpers/validationRules';
import { ItemCard } from './ItemCard/ItemCard';
import { SettingsModal } from './SettingsModal/SettingsModal';
import { DeleteModal } from './DeleteModal/DeleteModal';
import { FilterSheet, handleFilterByValue, handleOrderByValue } from './FilterSheet/FilterSheet';
import { SCheckbox, SCustomButton, SMainContentContainer, Wrapper } from './NotificationsCenterPage.styles';
import { ICheckedState, TFiltersParams } from './NotificationsCenterPage.types';
import { SORT_VARIABLES } from './constants';
import { ManagingPopup } from './ManagingPopup/ManagingPopup';
import { Sidebar } from './Sidebar/Sidebar';

const filterParamsInit: TFiltersParams = {
  filterBy: {
    read: false,
    unread: false,
    deleted: false,
    fromDate: null,
    toDate: null,
  },
  sortBy: SORT_VARIABLES[0],
};

const tagNameList: { filterBy: Record<string, string> } = {
  filterBy: {
    read: 'Read',
    unread: 'Unread',
    deleted: 'Deleted',
    fromDate: 'From Date',
    toDate: 'To Date',
  },
};

const LIMIT = 10;

export const NotificationsCenterPage = () => {
  const dispatch = useDispatch();
  const { language, locale } = useLanguage();
  const [searchForm] = useForm();
  const [getNotificationsByFilterAPI, getNotificationsByFilterAPIResult] = useLazyGetNotificationsByFilterQuery();
  const [offset, setOffset] = useState(0);
  const [mergedList, setMergedList] = useState<INotificationItem[]>([]);

  const [markNotificationsAsRead, markNotificationsAsReadResult] = useMarkNotificationsReadMutation();
  const [markNotificationsAsDeleted, markNotificationsAsDeletedResult] = useMarkNotificationsDeletedMutation();
  const [getNotifications, getNotificationsResult] = useLazyGetNotificationsQuery();
  const navigate = useNavigate();
  const { t } = useTranslation('notificationsCenter');
  const settingsModal = useToggle();
  const deleteModal = useToggle();
  const filterSheet = useToggle<{ prevScreen?: string }>(false);
  const [currentNotification, setCurrentNotification] = useState('');
  const { filterParams, notificationFilterString, notificationOrderString, notificationIsSearchVisible: isSearchVisible, notificationSearch: search } = useSelector(selectUserNotifications);
  const [isManaged, setIsManaged] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [checkedState, setCheckedState] = useState<ICheckedState>({});
  const [currentNotificationStatus, setCurrentNotificationStatus] = useState('');

  const openFilterSheet = (screen: string | undefined) => {
    filterSheet.show();
    filterSheet.setData({ prevScreen: screen ?? 'other' });
  };

  const onFilter = (formValues: TFiltersParams, showDeleted: boolean, orderString: string, filterString?: string) => {
    const filter = filterString || undefined;
    dispatch(setNotificationFilterString(filter));
    dispatch(setOrderString(orderString));
    setMergedList([]);
    setOffset(0);
    getNotificationsByFilterAPI({ showDeleted, $orderby: orderString, filter, offset });

    const { filterBy, sortBy } = formValues;

    dispatch(
      setNotificationFilterParams({
        ...filterParams,
        filterBy,
        sortBy,
      })
    );
  };

  const handleTagClose = (tagName: string) => {
    const { filterBy, sortBy } = filterParams;
    const { fromDate: fromDateInit, toDate: toDateInit } = filterParamsInit.filterBy;

    const value = filterParamsInit.filterBy[tagName as keyof typeof filterParamsInit.filterBy];
    let filterValue = { [tagName]: value };

    if (['read', 'unread', 'deleted'].includes(tagName)) {
      filterValue = { [tagName]: false };
    }

    if (['fromDate', 'toDate'].includes(tagName)) {
      filterValue = { fromDate: fromDateInit, toDate: toDateInit };
    }

    dispatch(
      setNotificationFilterParams({
        filterBy: { ...filterBy, ...filterValue },
        sortBy,
      })
    );

    const filterString = handleFilterByValue({ ...filterBy, ...filterValue }, !!search.length, search);
    const orderString = handleOrderByValue(sortBy);
    const filter = filterString || undefined;

    dispatch(setNotificationFilterString(filter));
    dispatch(setOrderString(orderString));
    setOffset(0);
    getNotificationsByFilterAPI({ showDeleted: filterBy.deleted, $orderby: orderString, filter, offset });
  };

  /* Search handling */
  const handleSearch = (searchValue: string) => {
    const { filterBy, sortBy } = filterParams;

    const filterString = handleFilterByValue(filterBy, true, searchValue);
    const orderString = handleOrderByValue(sortBy);
    const filter = filterString || undefined;

    dispatch(setNotificationFilterString(filter));
    dispatch(setOrderString(orderString));
    setOffset(0);
    getNotificationsByFilterAPI({ showDeleted: filterBy.deleted, $orderby: orderString, filter, offset });
  };

  const handleSearchClose = () => {
    dispatch(setNotificationIsSearchVisible(false));
    dispatch(setNotificationSearch(''));
    handleSearch('');
  };

  const handleSearchInputChange = (value: string) => {
    dispatch(setNotificationSearch(value || ''));
    handleSearch(value || '');
  };

  const handleManage = () => {
    setIsManaged(!isManaged);
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCheckChange = (alertId: string) => {
    setCheckedState({
      ...checkedState,
      [alertId]: !checkedState[alertId],
    });
  };

  const checkAll = (type: string) => {
    const newObj: Record<string, boolean> = {};
    const boxesId = Object.keys(checkedState);
    if (type === 'check') {
      boxesId.forEach((key) => {
        newObj[key] = true;
      });
    }
    if (type === 'uncheck') {
      boxesId.forEach((key) => {
        newObj[key] = false;
      });
    }
    setCheckedState(newObj);
  };

  const handleDelete = async (isCheckedAll?: boolean) => {
    const selectedItems = getNotificationsByFilterAPIResult.currentData?.results
      .filter((item: INotificationItem) => checkedState[item.alertHistoryId])
      .map((notif: INotificationItem) => notif.alertHistoryId);

    await markNotificationsAsDeleted(
      isCheckedAll
        ? {
            data: {
              alertHistoryIds: [],
            },
            filter: { showDeleted: false, $orderby: notificationOrderString, filter: notificationFilterString, offset: 0 },
          }
        : {
            data: {
              alertHistoryIds: selectedItems,
            },
            filter: { showDeleted: false, $orderby: notificationOrderString, filter: notificationFilterString, offset: 0 },
          }
    );
    setOffset(0);
    getNotificationsByFilterAPI({ showDeleted: false, $orderby: notificationOrderString, filter: notificationFilterString, offset: 0 });
    getNotifications();
    setIsManaged(false);
    setIsPopupOpen(false);
  };

  const handleRead = async (isCheckedAll?: boolean) => {
    const selectedItems = getNotificationsByFilterAPIResult.currentData?.results
      .filter((item: INotificationItem) => item.status === 'New' && checkedState[item.alertHistoryId])
      .map((notif: INotificationItem) => notif.alertHistoryId);

    await markNotificationsAsRead(
      isCheckedAll
        ? {
            data: {
              alertHistoryIds: [],
            },
            filter: { showDeleted: filterParams.filterBy.deleted, $orderby: notificationOrderString, filter: notificationFilterString, offset: 0 },
          }
        : {
            data: {
              alertHistoryIds: selectedItems,
            },
          }
    );
    setOffset(0);
    getNotificationsByFilterAPI({ showDeleted: filterParams.filterBy.deleted, $orderby: notificationOrderString, filter: notificationFilterString, offset: 0 });
    getNotifications();
    setIsManaged(false);
    setIsPopupOpen(false);
  };

  const handleRefresh = () => {
    setOffset(0);
    getNotificationsByFilterAPI({ showDeleted: filterParams.filterBy.deleted, $orderby: notificationOrderString, filter: notificationFilterString, offset: 0 });
    getNotifications();
  };

  const handleOpenSettings = (status: string) => {
    setCurrentNotificationStatus(status);
    settingsModal.show();
  };

  const groupedByDateNotifications = useMemo(() => {
    if (!mergedList?.length) return [];

    let currentGroupDate = new Date(mergedList[0]?.dateSent);
    const groups: INotificationItem[][] = [[]];

    mergedList?.forEach((item: INotificationItem) => {
      const itemDate = new Date(item?.dateSent);

      if (isSameDay(itemDate, currentGroupDate)) {
        const currentGroup = groups.at(-1);
        currentGroup?.push(item);
      } else {
        currentGroupDate = new Date(item?.dateSent);
        groups.push([item]);
      }
    });
    return groups;
  }, [mergedList, checkedState]);

  const notificationItems = useMemo(
    () =>
      groupedByDateNotifications?.map((group, i: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <section key={i} className="group">
          <div className="flex notification-date">
            {group[0]?.dateSent && (
              <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70">
                {formatLocaleDate(new Date(group[0]?.dateSent), 'EEE, MMM dd', locale)}
              </BodyText>
            )}
            {!i && (
              <Button className="btn-manage" size="small" preset="transparent" onClick={handleManage}>
                {isManaged ? t(`notificationsCenter.Cancel`) : t(`notificationsCenter.Manage`)}
              </Button>
            )}
          </div>

          {group?.map((notification) => (
            <div className="flex">
              {isManaged && (
                <SCheckbox
                  marginBottom={-24}
                  bgColor="white"
                  checked={checkedState[notification.alertHistoryId]}
                  name={notification.alertHistoryId}
                  onChange={() => handleCheckChange(notification.alertHistoryId)}
                />
              )}
              <ItemCard notification={notification} key={notification.dateSent} openSettings={handleOpenSettings} setCurrentNotification={setCurrentNotification} openDeleteModal={deleteModal.show} />
            </div>
          ))}
        </section>
      )),
    [groupedByDateNotifications, isManaged, checkedState]
  );

  const loadMoreData = () => {
    if (!getNotificationsByFilterAPIResult.isFetching && mergedList.length < getNotificationsByFilterAPIResult?.currentData?.paging.total) {
      setOffset((prev) => prev + LIMIT);
    }
  };

  useEffect(() => {
    if (!offset || offset < getNotificationsByFilterAPIResult?.currentData?.paging.total)
      getNotificationsByFilterAPI({ showDeleted: filterParams.filterBy.deleted, $orderby: notificationOrderString, filter: notificationFilterString, offset });
  }, [offset]);

  useEffect(() => {
    searchForm.setFieldValue('search', search);
  }, [search]);

  useEffect(() => {
    if (getNotificationsByFilterAPIResult.isSuccess && !getNotificationsByFilterAPIResult.isFetching) {
      const newCheckedObject: Record<string, boolean> = {};
      for (const item of getNotificationsByFilterAPIResult.data.results) {
        newCheckedObject[item.alertHistoryId] = false;
      }
      setCheckedState(newCheckedObject);
      if (offset === 0) {
        setMergedList(getNotificationsByFilterAPIResult?.currentData?.results);
      } else {
        setMergedList((prev) => [...prev, ...getNotificationsByFilterAPIResult.currentData.results]);
      }
    }
  }, [getNotificationsByFilterAPIResult]);

  return (
    <Wrapper className="wrapper">
      <Sidebar handleSearchInputChange={handleSearchInputChange} filterParamsInit={filterParamsInit} onFilter={onFilter} />
      <SMainContentContainer>
        <div className="notification-center flex">
          <div className="title flex">
            <Title font="Poppins" fontWeight="SB" size="M" color="charcoal">
              {t('notificationsCenter.NotificationsCenter')}
            </Title>
          </div>
          <div className="flex flex-justify-end filter-search">
            <SCustomButton type="button" onClick={() => openFilterSheet('main')} className="noBorder" marginRight={8}>
              <Icon name="filter" size="normal" color="charcoal70" cursorPointer />
            </SCustomButton>

            <SCustomButton type="button" className="noBorder" marginRight={8}>
              <Icon name="search" size="normal" color="charcoal70" cursorPointer onClick={() => dispatch(setNotificationIsSearchVisible(true))} />
            </SCustomButton>

            <SCustomButton type="button" className="noBorder">
              <Icon name="settings" onClick={() => navigate(ROUTES.notificationSettings.path)} cursorPointer />
            </SCustomButton>
          </div>
        </div>

        <div className="filter-block flex flex-start">
          <div className="flex flex-start flex-column">
            <TagBar filterBy={filterParams.filterBy} tagNameList={tagNameList} onClose={handleTagClose} locale={language} marginBottom={12} />
          </div>
        </div>

        {isSearchVisible && (
          <Form form={searchForm} initialValues={{ search: '' }}>
            <Form.Item name="search" validateTrigger={['onBlur', 'onChange']} rules={[searchValidator(handleSearchInputChange)]} style={{ marginBottom: 20 }}>
              <BaseInput placeholder={t(`notificationsCenter.WhatAreYouLookingFor`)} suffix="close" onBeige suffixClick={handleSearchClose} suffixSize="smallest" />
            </Form.Item>
          </Form>
        )}

        {(getNotificationsByFilterAPIResult.isFetching || getNotificationsResult.isFetching || markNotificationsAsReadResult.isLoading || markNotificationsAsDeletedResult.isLoading) && <Loader />}
        {!getNotificationsByFilterAPIResult.isFetching &&
          !groupedByDateNotifications.length &&
          (search ? (
            <div className="flex flex-column flex-justify-center no-result">
              <CustomRow justifyContent="center" marginBottom={48}>
                <img src={exclamation} alt="no search result" />
              </CustomRow>
              <Title size="L" color="charcoal" textAlign="center" fontWeight="M" font="Poppins" marginBottom="spacing-med">
                {t(`notificationsCenter.NoSearchResults`)}
                <br />
                {`"${search}"`}
              </Title>

              <BodyText textType="bodyText" color="charcoal70" fontWeight="R" font="DM Sans" size="N" lineHeight={1.5} marginBottom={24}>
                {t(`notificationsCenter.PleaseTryAnotherKeyword`)}
              </BodyText>
              <Button preset="transparent" onClick={handleSearchClose}>
                {t(`notificationsCenter.ResetKeyword`)}
              </Button>
            </div>
          ) : (
            t('notificationsCenter.NoDataAvailable')
          ))}

        <div className="flex-start flex-column notification-items">
          {getNotificationsByFilterAPIResult.isSuccess && !getNotificationsByFilterAPIResult.isFetching && (
            <InfiniteScroll
              dataLength={mergedList.length}
              next={loadMoreData}
              hasMore={mergedList.length < getNotificationsByFilterAPIResult?.currentData?.paging.total}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {notificationItems}
            </InfiniteScroll>
          )}
        </div>

        <SettingsModal
          refresh={handleRefresh}
          status={currentNotificationStatus}
          open={settingsModal.isActive}
          onClose={settingsModal.hide}
          openDeleteModal={deleteModal.show}
          alertHistoryId={currentNotification}
        />
        <DeleteModal refresh={handleRefresh} open={deleteModal.isActive} onClose={deleteModal.hide} alertHistoryId={currentNotification} />
        <FilterSheet
          open={filterSheet.isActive}
          onOpen={openFilterSheet}
          onClose={filterSheet.hide}
          filterParamsInit={filterParamsInit}
          filterParams={filterParams}
          onFilter={onFilter}
          prevScreen={filterSheet?.data?.prevScreen}
          searchValue={search}
        />
        <ManagingPopup
          open={isPopupOpen}
          checkedState={checkedState}
          checkAll={checkAll}
          onDelete={handleDelete}
          onRead={handleRead}
          total={getNotificationsByFilterAPIResult?.currentData?.paging.total}
        />
      </SMainContentContainer>
    </Wrapper>
  );
};
