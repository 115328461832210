import styled, { css } from 'styled-components';
import { images } from 'assets';
import { SCREEN_SIZE, getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SCard = styled.div`
  display: flex;
  margin-bottom: 30px;
  background: ${getColor('white')};
  border-radius: 20px;
  padding: 24px;
  justify-content: space-between;
  position: relative;

  ${mediaFrom(
    'desktop',
    css`
      width: 100%;
      padding: 56px 40px 56px 56px;
      margin-bottom: 48px;
    `
  )}

  .image-text-container {
    display: flex;
    justify-content: flex-start;

    ${mediaFrom(
      'desktop',
      css`
        padding-right: 14px;
      `
    )}
  }

  .percup-image {
    width: 96px;
    height: 43.199px;
    background-color: ${getColor('white')};
    margin: 0 18px auto 0;
    background: url(${images.earnPercPoints}) no-repeat center / contain;
    flex: 0 0 auto;

    ${mediaUpTo(
      'tablet',
      css`
        width: 80px;
        height: 36px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        width: 184px;
        height: 82.798px;
        margin-right: 64px;
      `
    )}
  }

  .text-button-container {
    display: flex;
    flex-direction: column;

    ${mediaUpTo(
      'tablet',
      css`
        .custom-text-inner {
          font-size: 12px;
        }
      `
    )}

    .button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      ${mediaFrom(
        'tablet',
        css`
          justify-content: flex-start;
        `
      )}

      ${mediaFrom(
        SCREEN_SIZE.desktop,
        css`
          button {
            padding: 14px 32px;
            font-size: 14px;
            font-weight: 500;
          }
        `
      )}

      button {
        width: 139px;
        height: 36px;
        padding: 14px 16px;

        font-size: 12px;
        font-weight: 500;
      }
    }

    ${mediaFrom(
      'desktop',
      css`
        justify-content: flex-start;
        gap: 24px;

        .button-container {
          justify-content: flex-start;
          margin-top: 0;

          button {
            width: auto;
            height: 48px;
            padding: 14px 32px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      `
    )}
  }

  .info-container {
    margin-left: 10px;

    ${mediaFrom(
      'desktop',
      css`
        margin-top: -40px;
        margin-right: -25px;
      `
    )}

    ${mediaUpTo(
      'tablet',
      css`
        position: absolute;
        right: 16px;
        top: 12px;
      `
    )}

    .info {
      flex: 0 0 auto;
    }
  }
`;
