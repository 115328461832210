import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div<{ bodyHeight: number }>`
  display: flex;
  flex-direction: column;
  border: solid 2px ${getColor('blue10')};
  border-radius: 16px;
  margin-bottom: 8px;

  .header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${getColor('transparent')};
    cursor: pointer;

    .country-name {
      flex: 1;
    }
  }

  .card-body-wrapper {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;

    .card-body {
      padding: 0 16px 16px;

      .min-transfer-amount-wrapper {
        display: flex;
        margin-bottom: 16px;
      }

      .columns {
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          flex-direction: column;

          &.titles {
            flex-direction: row;
            margin-bottom: 8px;
          }

          .row-item {
            display: flex;
            margin-bottom: 8px;

            .cell {
              display: flex;
            }
          }
        }

        .transfer-methods-cell {
          width: 45%;
          padding-right: 4px;
        }

        .fees-cell {
          width: 35%;
          padding-right: 4px;
        }

        .max-amount-cell {
          width: 20%;
          justify-content: flex-end;
        }
      }
    }
  }

  &.expanded {
    .card-body-wrapper {
      height: ${({ bodyHeight }) => `${bodyHeight}px`};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
