import styled from 'styled-components/macro';
import { CustomTitle } from 'components/theme/CustomTitle/CustomTitle';
import { mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { css } from 'styled-components';

export const SPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mediaUpTo(
    'tablet',
    css`
      padding: 10px 0 0;

      .menu-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;

        .menu-group-title {
          .custom-text-inner {
            font-size: 12px;
          }
        }

        .menu-item-container {
          gap: 12px;
          border-radius: 16px;
          padding: 20px;

          .menu-item {
            .custom-row {
              .svg-icon {
                width: 20px !important;
                height: 20px !important;
              }

              .body-text {
                .custom-text-inner {
                  font-size: 12px;
                }
              }
            }

            .icon-chevronRight {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .disclaimer-note-wrapper {
        margin-bottom: 16px;

        .custom-text-inner {
          font-size: 11px;

          span {
            font-size: 12px;
          }
        }
      }
    `
  )};

  ${mediaFrom(
    321,
    css`
      padding: 20px 4px 0;

      .menu-content-wrapper {
        .menu-group-title {
          margin-bottom: 22px;

          .custom-text-inner {
            font-size: 16px;
          }
        }

        .menu-item-container {
          gap: 24px;
          border-radius: 20px;
          padding: 24px;
          margin-bottom: 22px;

          .menu-item {
            .custom-row {
              .svg-icon {
                width: 24px !important;
                height: 24px !important;
              }

              .body-text {
                margin-left: 16px;

                .custom-text-inner {
                  font-size: 16px;
                }
              }
            }

            .icon-chevronRight {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .disclaimer-note-wrapper {
        margin: 2px 0 24px;

        .custom-text-inner {
          font-size: 12px;

          span {
            font-size: 12px;
          }
        }
      }
    `
  )};

  ${mediaFrom(
    576,
    css`
      padding-bottom: 0;

      .menu-group-title {
        .custom-text-inner {
          font-size: 12px;
        }
      }

      .disclaimer-note-wrapper {
        margin: 26px 0 48px;
      }
    `
  )};
`;
export const SCardTitle = styled(CustomTitle)`
  align-self: flex-start;
`;
