import styled from 'styled-components/macro';

export const SData = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
  width: 100%;
  position: relative;
`;
