import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, selectPolicies } from 'store/user/authentication.slice';
import { useToggle } from 'utils/hooks/useToggle';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { useLocation, generatePath } from 'react-router-dom';
import { accountsApi } from 'store/user/accounts/accounts.api';
import { ROUTES } from 'vars/const/ROUTES';
import { setPennyJarBackRoute } from 'store/user/accounts/accounts.slice';
import { IAccountItem, IFeatureItem, TAddAccountType } from 'store/user/accounts/accounts.types';
import { useAccounts } from 'utils/hooks/useAccounts';
import { ConfirmJointAccountModal } from 'views/Account/AccountInformation/Modals/editAccount/ConfirmJointAccountModal/ConfirmJointAccountModal';
import { useKYC } from 'utils/hooks/useKYC';
import { Loader } from 'components/general/Loader/Loader';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { Title } from 'components/general/Typography';
import { SimpleAccountSelectSheet } from 'components/general/Accounts/AccountSelect/SimpleAccountSelectSheet/SimpleAccountSelectSheet';
import { mergeArrays } from 'utils/helpers/arrays';
import { useAllCashAccountsFeatures } from 'utils/hooks/useAllCashAccountsFeatures';
import { isUnder18 } from 'utils/helpers/date';
import { DoMorePage } from './DoMore.styles';
import { OptionItem } from './OptionItem/OptionItem';

export const DoMore = () => {
  const { t } = useTranslation('doMore');
  const dispatch = useDispatch();
  const { navigate } = useEnhancedNav();
  const { pathname } = useLocation();
  const { internalAccountsGroups, internalAccounts, cashAccounts, isLoading: isAccountDataLoading } = useAccounts();
  const { isLoading: isKycLoading, currentCAOStepUrl, KYCStatus } = useKYC();
  const { roundUpFeatures, overdraftFeatures, isLoading: isFeaturesLoading } = useAllCashAccountsFeatures();
  const policies = useSelector(selectPolicies);
  const user = useSelector(selectCurrentUser);
  const isUserUnder18 = !!user.birthday && isUnder18(new Date(user.birthday));

  const selectAccountForJointSheet = useToggle(false);
  const selectAccountForDirectDepositSheet = useToggle();
  const confirmJointAccountModal = useToggle(false, { accountForJointId: '', accountName: '' });
  const ownerJointInvitesQuery = accountsApi.useGetOwnerJointInvitesQuery();
  const accountsWithJointInvites = ownerJointInvitesQuery.data?.map((invite) => invite.accountId) ?? [];
  const jointCashAccountsIds = cashAccounts?.filter((item) => item.isCashType && item?.isJoint).map((acc) => acc.accountId);
  const accountsDisabledForJointOpening = mergeArrays(accountsWithJointInvites, jointCashAccountsIds);
  const nonJointCashAccountsCount = cashAccounts.length - accountsDisabledForJointOpening.length;

  const isAddSecondaryCashEnabled = cashAccounts?.length < 2 && cashAccounts?.length !== 0 && !isUserUnder18;
  const hasCashAccounts = internalAccounts?.some((item: IAccountItem) => item.isCashType);
  const hasNeedsAccounts = internalAccounts?.some((item: IAccountItem) => item.isNeedsType);
  const hasGoalsAccounts = internalAccounts?.some((item: IAccountItem) => item.isGoalsType);
  const hasAccountsAvailableForPennyJar = roundUpFeatures?.some(
    (item: IFeatureItem) => !item.isEnabled && internalAccounts.some((acc) => (acc.isNeedsType || acc.isGoalsType) && acc.parentAccountId === item?.accountId)
  );
  const isDisplayPennyJar = policies?.PennyJarEnabled && hasAccountsAvailableForPennyJar;
  const isDisplayCashCushion = overdraftFeatures.some((feature) => feature.overdraft.isEligible && !feature.isEnabled);

  const hasCashWithoutGoals = internalAccountsGroups.some((group) => !group.goals);
  const hasCashWithoutNeeds = internalAccountsGroups.some((group) => !group.needs);
  const isLoading = isAccountDataLoading || isKycLoading || isFeaturesLoading;

  const addAccount = (type: TAddAccountType) => {
    navigate(generatePath(ROUTES.addNeedsGoalsAccount.path, { type }));
  };

  const handleOnPennyJar = () => {
    dispatch(setPennyJarBackRoute(ROUTES.doMore.path));
    navigate(ROUTES.pennyJar.path);
  };

  const selectCashAccountForJoint = (account: IAccountItem) => {
    confirmJointAccountModal.setData({ accountForJointId: account.accountId, accountName: account.name });
    confirmJointAccountModal.show();
    selectAccountForJointSheet.hide();
  };

  const handleSelectAccountForDirectDeposit = ({ accountId }: IAccountItem) => {
    navigate(ROUTES.setUpDeposit.path, { searchParams: { accountId } });
  };

  const handleDirectDepositClick = () => {
    if (cashAccounts.length > 1) {
      selectAccountForDirectDepositSheet.show();
    } else {
      handleSelectAccountForDirectDeposit(cashAccounts[0]);
    }
  };

  return (
    <DoMorePage className="do-more-page">
      {isLoading && <Loader />}
      <div className="do-more-page__content">
        <PageHeader title={t('MoreWithPercapita')} />

        <div className="options-group">
          <Title size="sL" font="Poppins" fontWeight="SB" className="group-title">
            {t('CashAccountAddons')}
          </Title>

          <div className="options">
            {/* Cash Account */}
            {!KYCStatus && !hasCashAccounts && (
              <OptionItem
                title={t('OpenACashAccount')}
                description={t('ThePercapitaCashAccountAndDebitCardMakeAccessing')}
                className="cash-account"
                onClick={() => navigate(currentCAOStepUrl || ROUTES.starter.path)}
              />
            )}

            {/* Direct Deposit */}
            {hasCashAccounts && <OptionItem title={t('SetUpDirectDeposit')} description={t('HaveYourPayrollAutomaticallyDeposited')} className="direct-deposit" onClick={handleDirectDepositClick} />}

            {/* Joint Accounts */}
            {nonJointCashAccountsCount > 0 && (
              <OptionItem
                title={t('AddAJointAccountholder')}
                description={t('ShareYourCashAccount')}
                className="joint-accountholder"
                onClick={selectAccountForJointSheet.show}
                isDisabled={!policies?.JointAccountEnabled}
              />
            )}

            {/* Goals Account */}
            {(hasCashWithoutGoals || !hasCashAccounts) && (
              <OptionItem
                title={t('OpenAGoalsAccount')}
                description={t('SetAsideMoneyForFutureFinancialGoals')}
                className="goals-account"
                onClick={() => addAccount('goals')}
                isDisabled={!hasCashAccounts}
              />
            )}

            {/* Needs Account */}
            {(hasCashWithoutNeeds || !hasCashAccounts) && (
              <OptionItem
                title={t('OpenANeedsAccount')}
                description={t('SetAsideMoneyIntendedForMonthlyExpenses')}
                className="needs-account"
                onClick={() => addAccount('needs')}
                isDisabled={!hasCashAccounts}
              />
            )}

            {/* Cash Cushion */}
            {isDisplayCashCushion && (
              <OptionItem
                title={t('OptInToCashCushion')}
                description={t('AllowYourAccountToGoNegativeUp')}
                className="cash-cushion"
                onClick={() => navigate(ROUTES.cashCushion.path, { state: { backPage: pathname } })}
              />
            )}

            {/* Penny Jar */}
            {isDisplayPennyJar && (
              <OptionItem
                title={t('SetUpPennyJar')}
                description={t('RoundUpEachPurchaseMade')}
                className="penny-jar"
                onClick={handleOnPennyJar}
                isDisabled={!((hasCashAccounts && hasNeedsAccounts) || (hasCashAccounts && hasGoalsAccounts) || (hasCashAccounts && hasNeedsAccounts && hasGoalsAccounts))}
              />
            )}

            {/* Second Cash Account */}
            {isAddSecondaryCashEnabled && (
              <OptionItem
                title={t('AddNewCashAccount')}
                description={t('OpenANewCashAccount')}
                className="second-cash-account"
                onClick={() => navigate(ROUTES.starter.path, { state: { alreadyHasCash: true }, backUrl: 'current' })}
              />
            )}
          </div>
        </div>
      </div>

      <PageFooter type="full" />

      <SimpleAccountSelectSheet
        accounts={cashAccounts}
        isOpen={selectAccountForJointSheet.isActive}
        onClose={selectAccountForJointSheet.hide}
        disableIds={accountsDisabledForJointOpening}
        onSelect={selectCashAccountForJoint}
        title={t('SelectAccountForJointTitle')}
      />

      <SimpleAccountSelectSheet
        accounts={cashAccounts}
        isOpen={selectAccountForDirectDepositSheet.isActive}
        onClose={selectAccountForDirectDepositSheet.hide}
        onSelect={handleSelectAccountForDirectDeposit}
        title={t('SelectAccountForDirectDepositTitle')}
      />

      <ConfirmJointAccountModal open={confirmJointAccountModal.isActive} handleClose={confirmJointAccountModal.hide} {...confirmJointAccountModal?.data} />
    </DoMorePage>
  );
};
