import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';

export const SCustomModal = styled(CustomModal)`
  .ant-modal-content {
    padding: 0;
  }
  .sutton-disclaimer-note {
    padding: 0px 24px 24px;
    ${mediaFrom(
      'tablet',
      css`
        background: ${getColor('charcoal5')};
        border-radius: 0px 0px 12px 12px;
      `
    )}
  }
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  & .mobile-checking-body {
    margin-top: 10px;
  }

  & .coming-soon {
    background: ${getColor('bluePurple40')};
    display: flex;
    align-self: flex-start;
    padding: 10px 24px;
    border-radius: 12px;
    ${mediaFrom(
      'tablet',
      css`
        background: ${getColor('purple10')};
        padding: 5px 10px;
      `
    )}
  }

  & .list-item {
    display: flex;
    position: relative;
    padding-left: 10px;
    padding-right: 13px;

    .first-description {
      left: 7px;
    }

    &-text {
      position: absolute;
      left: 5px;
      top: 2px;
    }
  }
`;
