import styled from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { flex, getColor } from 'utils/helpers/styleHelpers';

export const SCustomSheet = styled(CustomSheet)`
  .header {
    ${flex('row', 'flex-start')};
    margin-bottom: 32px;
  }

  .selected-country {
    ${flex('row', 'flex-start')};
    border-radius: 16px;
    background: ${getColor('cream50')};
    padding: 16px 20px;
    margin-bottom: 32px;
  }

  .section-fees {
    ${flex('column', 'flex-start', 'stretch')};
    margin-bottom: 38px;

    .currency-wrapper {
      ${flex('row', 'space-between')};
    }
  }

  .section-transfer-methods {
    margin-bottom: 32px;
  }
`;
