import React from 'react';
import { ROUTES } from 'vars/const/ROUTES';
import { lsGetItem } from 'utils/helpers/storage';
import { Icon } from 'components/general/Icon/Icon';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SLayout } from './DeviceVerifyWarningPage.styles';

export const DeviceVerifyWarningPage = () => {
  const { t } = useTranslation('verification');
  const navigate = useNavigate();
  const otpFlow = lsGetItem('loginOtpFlow');

  return (
    <SLayout>
      <div className="container">
        <Title size="M" fontWeight="M" marginBottom={48} marginTop={48}>
          {t('verification.Title')}
        </Title>
        <BodyText textType="bodyText" fontWeight="R" size="M" color="charcoal70" marginBottom={24}>
          {t('verification.ForYourSecurity')}
        </BodyText>

        <div className="card">
          <div className="phone-row">
            <div className="icon-wrapper">
              <Icon name="telephone" size="smaller" color="blue" />
            </div>
            <BodyText textType="bodyText" textAlign="center" fontWeight="R" size="L" color="charcoal70" marginLeft={8}>
              {t('verification.Phone')} <span className="phone-number">{otpFlow?.maskedPhone}</span>
            </BodyText>
          </div>

          <Button preset="primary" onClick={() => navigate(ROUTES.verifyDevice.path)}>
            {t('verification.Continue')}
          </Button>
        </div>
      </div>
    </SLayout>
  );
};
