import React, { useMemo } from 'react';
import { usePayments } from 'utils/hooks/usePayments';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setShowPercapitaPayModal } from 'store/ui.slice';
import { getFormattedDateFromString } from 'utils/helpers/dateHelpers';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Tooltip } from 'components/general/Tooltip/Tooltip';
import { useToggle } from 'utils/hooks/useToggle';
import { SPercapitaPayMain } from './PercapitaPayMain.styles';
import { AvailableTodayTooltip } from './components/AvailableTodayTooltip';

interface IPercapitaPayMainProps {
  isActive?: boolean;
  isCollapsed?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  canDisablePrimaryButton?: boolean;
  onPrimaryButtonClick: () => void;
}

export const PercapitaPayMain = ({ isActive = true, isCollapsed, primaryButtonText, secondaryButtonText, canDisablePrimaryButton, onPrimaryButtonClick }: IPercapitaPayMainProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('percapitaPay');
  const { paymentsInfo, getPaymentsInfoQueryResult } = usePayments();
  const dispatch = useDispatch();
  const { isDesktopSize } = useDeviceDimension();
  const availablePayTooltip = useToggle();
  const daysToNextPayCycle = useMemo(() => {
    if (!paymentsInfo.earnCicleEndDate) {
      return 0;
    }
    return differenceInDays(new Date(paymentsInfo.earnCicleEndDate), new Date());
  }, [paymentsInfo]);

  const onSecondaryButtonClick = () => {
    navigate(ROUTES.percapitaPayHistory.path, { state: 1 });
  };

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (isCollapsed) {
      navigate(ROUTES.percapitaPayHome.path);
    }
  };

  const handleAvailableAmountInfoClick = () => {
    if (isDesktopSize) {
      availablePayTooltip.show();
    } else {
      dispatch(setShowPercapitaPayModal({ displayPercapitaPayModal: true, percapitaPayModalType: 'earned' }));
    }
  };

  const payPeriodStart = paymentsInfo.earnCicleStartDate ? getFormattedDateFromString(paymentsInfo.earnCicleStartDate) : null;
  const payPeriodEnd = paymentsInfo.earnCicleEndDate ? getFormattedDateFromString(paymentsInfo.earnCicleEndDate) : null;

  const isPrimaryButtonDisabled = (canDisablePrimaryButton && paymentsInfo.availableNow <= 0) || !isActive;

  return (
    /*
     * TODO: This `onClick` is an accessibility failure. Clicking non-focusable elements such as `<div>`s must never be
     * the only way to perform an action (which is the case for the `<PercapitaPayMain>` instance on `<HomePage>`
     * since clicking on the primary button on that page does not lead a user to the Percapita Pay home screen but clicking
     * on the card itself does) since such functionality cannot be accessed by assistive technologies. The UI/UX team should be
     * made aware of this and come up with a proper solution that would be accessible to all users.
     */
    <SPercapitaPayMain className="percapita-pay-main" onClick={handleCardClick}>
      {paymentsInfo.earnedThisCycle !== undefined && paymentsInfo.availableNow !== undefined && (
        <dl className="balance">
          <div className="container">
            <dt>
              <span>{t('percapitaPayHome.Available Today')}</span>
              {isDesktopSize ? (
                <Tooltip placement="rightBottom" open={availablePayTooltip.isActive} content={<AvailableTodayTooltip />} width={328}>
                  <button type="button">
                    <span>View info</span>
                  </button>
                </Tooltip>
              ) : (
                <button type="button" onClick={handleAvailableAmountInfoClick}>
                  <span>View info</span>
                </button>
              )}
            </dt>
            <dd>
              <CustomAmount amount={paymentsInfo.availableNow} color="charcoal" size="large" align="left" multiSizable remainingSize="xl" remainingWeight={600} />
            </dd>
          </div>
          <div className="divider" />
          <div className="container">
            <dt>
              <div>{t('percapitaPayHome.Earned this pay period')}</div>
              <div>{payPeriodStart && payPeriodEnd && ` (${payPeriodStart} - ${payPeriodEnd})`}</div>
            </dt>
            <dd>
              <CustomAmount className="earned-amount" amount={paymentsInfo.earnedThisCycle} color="charcoal70" size="large" align="left" multiSizable remainingSize="xl" remainingWeight={600} />
            </dd>
          </div>
        </dl>
      )}
      <div className={clsx('actions', isCollapsed && 'collapsed')}>
        <div className="buttons">
          <button type="button" className="new secondary outlined small" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </button>
          <button type="button" className="new primary small" onClick={onPrimaryButtonClick} disabled={isPrimaryButtonDisabled}>
            {primaryButtonText}
          </button>
        </div>
        <small
          className={clsx(
            'pay-period-days-left',
            !!daysToNextPayCycle && 'several',
            !daysToNextPayCycle && getPaymentsInfoQueryResult.isError && 'error',
            !daysToNextPayCycle && !getPaymentsInfoQueryResult.isError && 'last'
          )}
        >
          {!!daysToNextPayCycle && (
            <>
              <span>{daysToNextPayCycle}</span> <span className="days-left-text">{t('percapitaPayHome.DaysUntilYourPercapitaPayBalanceResets')}</span>
            </>
          )}
          {!daysToNextPayCycle && getPaymentsInfoQueryResult.isError && 'No data available'}
          {!daysToNextPayCycle && !getPaymentsInfoQueryResult.isError && t('percapitaPayHome.LastDayOfPayPeriod')}
        </small>
      </div>
    </SPercapitaPayMain>
  );
};
