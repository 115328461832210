import React from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { SCustomModal } from './UploadLaterModal.styles';

interface IModalProps {
  open: boolean;
  onClose: () => void;
}

export const UploadLaterModal: React.FC<IModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('documents');
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(ROUTES.home.path);
  };

  return (
    <SCustomModal open={open} onClose={onClose} modalName="uploadLater">
      <Title lineHeight="32px" fontWeight="M" size="M" marginBottom={16} className="title">
        {t('documents.YourAddressChangeRequestWillNotBeReviewed')}
      </Title>

      <BodyText size="N" color="charcoal70" lineHeight="20px" marginBottom={32} className="description">
        {t('documents.YouCanUploadYourDocumentsLater')}
      </BodyText>

      <div className="button-panel">
        <Button marginBottom={16} preset="primary" onClick={handleCancel} className="go-to-home">
          {t('documents.GoToHome')}
        </Button>

        <Button preset="outline" onClick={onClose} className="submit">
          {t('documents.SubmitDocumentsNow')}
        </Button>
      </div>
    </SCustomModal>
  );
};
