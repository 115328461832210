import React, { useEffect, useState } from 'react';
import { useLogin } from 'utils/hooks/useLogin';
import { useForgotUsernameMutation, useLoginMutation } from 'store/user/authentication.api';
import { Spinner } from 'components/general/Spinner/Spinner';
import { LoginForm } from 'views/Auth/Login/LoginForm/LoginForm';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { lsGetItem } from 'utils/helpers/storage';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { ForgotPasswordSheet } from 'views/Auth/Login/ForgotPasswordSheet/ForgotPasswordSheet';
import { useToggle } from 'utils/hooks/useToggle';
import { ForgotUsernameSheet } from 'views/Auth/Login/ForgotUsernameSheet/ForgotUsernameSheet';
import { TForgotUsernameConfirm, TFormData } from 'views/Auth/Login/Login.types';
import { getFingerpint } from 'utils/helpers/webID';
import { useStartPasswordResetMutation } from 'store/user/forgotPassword/forgotPassword.api';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useDisclosures } from 'utils/hooks/useDisclosures';
import { DisclosureSheet } from 'views/Auth/Registration/RegistrationPage/RegistrationForm/DisclosureSheet/DisclosureSheet';
import { getUnmaskedMobileNumber } from 'utils/helpers/phoneNumber';
import { InformationSentModal } from 'views/Auth/Login/InformationSentModal/InformationSentModal';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText, Title } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { Button } from 'components/theme/Button/Button';
import { getDecryptedDataFromStorage } from 'utils/helpers/encrypt';
import { AuthPage } from 'components/layouts/AuthLayout/AuthPage.styles';
import { AES_STATIC_KEY_STRING } from 'vars/const/ENCRYPTION';

// Testing phone: +18566265400;

export const LoginPage = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const location = useLocation();
  const { isDesktopSize, fromTablet, isMobileSmallSize } = useDeviceDimension();
  const [, { isLoading }] = useLoginMutation();
  const loginClick = useLogin();
  const savedName = lsGetItem('savedPreferredName') ?? lsGetItem('savedFirstName');
  const [deviceId, setDeviceId] = useState<string | undefined>('');
  const [forgotUsername] = useForgotUsernameMutation();
  const [startPasswordReset] = useStartPasswordResetMutation();
  const { getPrivacyPolicyDisclosure } = useDisclosures();
  const { isActive: isPasswordSheetOpen, show: onPasswordSheetShow, hide: onPasswordSheetClose } = useToggle(false);
  const { isActive: isUsernameSheetOpen, show: onUsernameSheetShow, hide: onUsernameSheetClose } = useToggle(false);
  const percPrivPolicySheet = useToggle();
  const informationSentModal = useToggle();

  const [providedEmail, setProvidedEmail] = useState('');
  const [providedPhone, setProvidedPhone] = useState('');

  const motionVariants = {
    hide: { opacity: 0 },
    show: { opacity: 1 },
  };

  const handleForgotUsernameConfirm = ({ email, phone }: TForgotUsernameConfirm) => {
    const requestData: TForgotUsernameConfirm = {};
    if (email) {
      requestData.email = email;
      setProvidedEmail(email);
    }
    if (phone) {
      requestData.phone = getUnmaskedMobileNumber(`1 ${phone}`);
      setProvidedPhone(getUnmaskedMobileNumber(`1 ${phone}`));
    }

    onUsernameSheetClose();
    forgotUsername(requestData)
      .unwrap()
      .then(() => {
        if (isDesktopSize) {
          informationSentModal.show();
        } else {
          navigate(ROUTES.forgotUsernameSent.path);
        }
      });
  };

  const startResetPassword = ({ username }: TFormData) => {
    const fingerprint = getFingerpint(true) as string;
    startPasswordReset({
      username,
      fingerprint,
    })
      .unwrap()
      .then(() => navigate(ROUTES.forgotPassword.path))
      .catch(() => {
        if (isDesktopSize) {
          informationSentModal.show();
        } else {
          navigate(ROUTES.forgotDataInformationSent.path);
        }
      });
  };

  useEffect(() => {
    getDecryptedDataFromStorage('deviceId', AES_STATIC_KEY_STRING).then(setDeviceId);
  }, []);

  useEffect(() => {
    if (deviceId === undefined && !location.state?.isFromRegistration && !location.state?.navigateToLogin) {
      navigate(ROUTES.onboardingLanguageSelection.path);
    }
  }, [deviceId]);

  return (
    <AuthPage.Container className="login-page" as={motion.div} variants={motionVariants} animate={loginClick.motionVariant()}>
      {isLoading && <Spinner />}

      <AuthPage.Content className="login-page__content">
        <Title fontWeight="M" size={isMobileSmallSize ? 'T' : 'L'} marginBottom={isMobileSmallSize ? 18 : 8}>
          {deviceId ? t('loginScreen.WelcomeBack', { context: savedName ? 'name' : '', name: savedName }) : t('loginScreen.Login')}
        </Title>

        {!isMobileSmallSize && (
          <CustomRow justifyContent="flex-start" marginBottom={32}>
            <BodyText size="M" fontWeight="M" color={fromTablet ? 'charcoal70' : 'charcoal'}>
              {t("loginScreen.Don't have an account?")}
            </BodyText>
            <Button className="login-btn" size="middle" preset="transparent" url={ROUTES.registration.path}>
              <BodyText size="N" color="blue" font="Poppins" fontWeight="M" nowrap>
                {t('registration.Register')}
              </BodyText>
              <Icon name="arrowRight" size="smallest" color="blue" marginLeft={8} />
            </Button>
          </CustomRow>
        )}

        <AuthPage.ContentBox>
          <LoginForm handleForgotUsernameClick={onUsernameSheetShow} handleForgotPasswordClick={onPasswordSheetShow} handlePercapitaPolicyClick={percPrivPolicySheet.show} />
        </AuthPage.ContentBox>
      </AuthPage.Content>

      <ForgotPasswordSheet isOpen={isPasswordSheetOpen} onClose={onPasswordSheetClose} onFinish={startResetPassword} />
      <ForgotUsernameSheet isOpen={isUsernameSheetOpen} onClose={onUsernameSheetClose} onFinish={handleForgotUsernameConfirm} />
      <InformationSentModal isOpen={informationSentModal.isActive} email={providedEmail} phoneNumber={providedPhone} isEmail={!!providedEmail} onClose={informationSentModal.hide} />

      <DisclosureSheet
        name={getPrivacyPolicyDisclosure?.name}
        isOpen={percPrivPolicySheet.isActive}
        handleCloseSheet={percPrivPolicySheet.hide}
        subTitle={getPrivacyPolicyDisclosure?.subTitle}
        acceptCheckBoxText={getPrivacyPolicyDisclosure?.acceptCheckBoxText}
        acceptButtonText={getPrivacyPolicyDisclosure?.acceptButtonText}
        disclosureText={getPrivacyPolicyDisclosure?.text}
        isReadOnly
      />
    </AuthPage.Container>
  );
};
