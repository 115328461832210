import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SAccountsTypeSwitcher = styled.div`
  display: flex;
  gap: 6px;

  .chip {
    margin-bottom: 10px;
    background: ${getColor('transparent')};
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    line-height: 16px;
    font-size: 12px;

    &.active {
      background: ${getColor('blue')};
      border-color: ${getColor('blue')};
      color: ${getColor('white')};

      .icon {
        background: ${getColor('white')} !important;
      }
    }
  }
`;
