import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { ListItem } from 'views/Transactions/DirectDeposit/SetUpDeposit/ListItem/ListItem';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { Button } from 'components/theme/Button/Button';
import { SCard } from './PreFilledSection.styles';

export const PreFilledSection = ({ accountId }: { accountId?: string }) => {
  const { t } = useTranslation('deposit');
  const { navigate } = useEnhancedNav();
  const { fromDesktopSmall } = useDeviceDimension();

  const handleCreateForm = () => {
    if (accountId) {
      navigate(ROUTES.directDeposit.path, { searchParams: { accountId } });
    }
  };

  return (
    <SCard>
      <div className="mobile-title-container">
        <BodyText textType="bodyText" color="charcoal" size="M" fontWeight="B" justifyContent="start">
          {t('setUpDeposit.prefilled.Title')}
        </BodyText>
      </div>

      <div className="web-title-container">
        <Title justifyContent="center" textAlign="center">
          {t('setUpDeposit.prefilled.DepositForm')}
        </Title>
        <BodyText textType="bodyText" justifyContent="center" textAlign="center" color="charcoal70" size="N" fontWeight="R" lineHeight={1.3}>
          {t('setUpDeposit.prefilled.Description')}
        </BodyText>
      </div>

      <div className="list-container">
        <ListItem order="1" text={t('setUpDeposit.prefilled.EnterAmount')} />
        <ListItem order="2" text={t('setUpDeposit.prefilled.CheckEmail')} />
        <ListItem order="3" text={t('setUpDeposit.prefilled.ForwardForm')} />
      </div>

      <Button preset="primary" onClick={handleCreateForm} className="create-btn">
        {t('setUpDeposit.prefilled.CreateForm')}
        {!fromDesktopSmall && <Icon name="chevronRight" color="white" size="smaller" marginLeft={10} />}
      </Button>
    </SCard>
  );
};
