import styled from 'styled-components/macro';
import { Collapse } from 'antd';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';
import { css } from 'styled-components';

export const SMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;
  border-radius: 20px;
  margin: 0 0 20px 0;
  background: ${getColor('white')};

  & .icon-profile {
    width: 20px;
    height: 20px;
  }

  ${mediaUpTo(
    'tablet',
    css`
      margin: 0 0 18px 0;
    `
  )}
`;

export const SContainer = styled.div`
  height: 100%;
  background: ${getColor('creamSS2')};
`;

export const SMenuCollapsible = styled(Collapse)`
  padding: 0;

  .ant-collapse-header {
    padding: 0 !important;

    &-text {
      flex: 1 1 auto;
    }
  }

  .ant-collapse-content-box {
    padding: 0;
  }
`;

export const SMenuGroupContainer = styled.div`
  margin-left: 20px;
`;
