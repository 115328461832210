/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { MyInfoHomeAddressModalInputProps } from 'components/general/Modals/MyInfoHomeAddressModal/MyInfoHomeAddressModal.type';
import { BodyText, Title } from 'components/general/Typography';
import { IHomeAddressForm } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import clsx from 'clsx';
import { SAddressDataBlock, SContent, SCustomButton, SWarningImage } from 'components/general/Modals/MyInfoHomeAddressModal/MyInfoHomeAddressModal.style';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { ADDRESS_CHECK_STATUS } from 'vars/const/address';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { CheckModal, Inner, Overlay } from './CheckAddressModal.styles';

type IAddressDataBlockProps = {
  title: string;
  originalValues: IHomeAddressForm;
  suggestedValues: IHomeAddressForm;
  original?: boolean;
};

function diffAddressPart(originalPart: string, suggestedPart: string, original: boolean): React.ReactNode {
  const ucOriginalPart = (originalPart || '').toUpperCase();
  const ucSuggestedPart = (suggestedPart || '').toUpperCase();
  if (ucOriginalPart === ucSuggestedPart) {
    return ucOriginalPart;
  }
  return <span className={clsx('diff', original ? 'original' : 'suggested')}>{original ? ucOriginalPart : ucSuggestedPart}</span>;
}

const AddressDataBlock = ({ title, originalValues, suggestedValues, original = false }: IAddressDataBlockProps) => {
  const addressParts = useMemo(() => {
    return {
      apt: diffAddressPart(originalValues.apt, suggestedValues.apt, original),
      address: diffAddressPart(originalValues.address, suggestedValues.address, original),
      address2: diffAddressPart(originalValues.address2, suggestedValues.address2, original),
      city: diffAddressPart(originalValues.city, suggestedValues.city, original),
      stateProvince: diffAddressPart(originalValues.stateProvince, suggestedValues.stateProvince, original),
      zipCode: diffAddressPart(originalValues.zipCode, suggestedValues.zipCode, original),
    };
  }, [originalValues, suggestedValues, original]);

  return (
    <SAddressDataBlock>
      <BodyText textType="bodyText" size="M" fontWeight="R" color="charcoal60" textAlign="center">
        {title}
      </BodyText>
      <BodyText textType="bodyText" size="M" fontWeight="M" color="charcoal" textAlign="center">
        {addressParts.apt ? <>{addressParts.apt} </> : ''}
        {addressParts.address}
      </BodyText>
      {addressParts.address2 && (
        <BodyText textType="bodyText" size="M" fontWeight="M" color="charcoal60" textAlign="center">
          {addressParts.address2}
        </BodyText>
      )}
      <BodyText textType="bodyText" size="M" fontWeight="M" color="charcoal60" textAlign="center" marginBottom={24}>
        {addressParts.city}, {addressParts.stateProvince} {addressParts.zipCode}
      </BodyText>
    </SAddressDataBlock>
  );
};

export const CheckAddressModal: React.FC<MyInfoHomeAddressModalInputProps> = ({
  onClose,
  errorType,
  errors = [],
  onConfirmClick,
  onEditClick,
  isModalVisible,
  addressForm,
  suggested = {},
  isMailingAddress = false,
}) => {
  const { t } = useTranslation('cashAccountOpening', { keyPrefix: 'myInfo' });
  const formValues = addressForm.getFieldsValue();
  const originalValues = isMailingAddress
    ? {
        ...formValues,
        address: formValues.mailingAddress1,
        address2: formValues.mailingAddress2,
        city: formValues.mailingCity,
        stateProvince: formValues.mailingState,
        zipCode: formValues.mailingPostalCode,
      }
    : { ...formValues, address: formValues.address1, zipCode: formValues.postalCode };

  const suggestedValues = {
    ...formValues,
    ...suggested,
  };

  return (
    <CheckModal className={`check-modal ${isModalVisible ? 'show' : 'hide'}`}>
      <Overlay />
      <Inner>
        <SContent>
          {errorType === ADDRESS_CHECK_STATUS.DPVCMRA && (
            <>
              <SWarningImage />
              <Title size="M" fontWeight="M" color="charcoal" marginTop={30} marginBottom={12}>
                {t(`address.provideHome`)}
              </Title>
              <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal60" marginBottom={32} lineHeight={1.5}>
                {t(`address.mailbox`)}
              </BodyText>
              <Button preset="primary" size="large" onClick={onClose}>
                {t(`address.modify`)}
              </Button>
            </>
          )}
          {errorType === ADDRESS_CHECK_STATUS.INVALID && (
            <>
              <SWarningImage />
              <Title size="M" textAlign="start" justifyContent="start" fontWeight="M" color="charcoal" marginTop={30} marginBottom={16}>
                {t(`address.invalid`)}
              </Title>
              {errors?.length ? (
                errors.map((error) => (
                  <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal60" textAlign="start" justifyContent="start" marginBottom={12} lineHeight={1.5}>
                    {error}
                  </BodyText>
                ))
              ) : (
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal60" textAlign="start" justifyContent="start" marginBottom={12} lineHeight={1.5}>
                  {t(`address.provideValid`)}
                </BodyText>
              )}

              <Button preset="primary" size="middleStretch" onClick={onClose} marginTop={25}>
                {t(`address.modify`)}
              </Button>
            </>
          )}
        </SContent>
        {errorType === ADDRESS_CHECK_STATUS.WITH_SUGGESTIONS && (
          <>
            <Title size="M" textAlign="start" fontWeight="M" color="charcoal" marginTop={20} marginBottom={20}>
              {t(`address.incorrect`)}
            </Title>
            <BodyText textType="bodyText" size="M" fontWeight="R" color="charcoal60" marginBottom={24} lineHeight={1.5}>
              {t(`address.preferSuggestedOrOriginal`)}
            </BodyText>

            <AddressDataBlock title={t('address.original')} originalValues={originalValues} suggestedValues={suggestedValues} original />
            <AddressDataBlock title={t('address.suggested')} originalValues={originalValues} suggestedValues={suggestedValues} />

            <CustomRow justifyContent="space-between" gap={8}>
              <SCustomButton size="middle" onClick={onEditClick}>
                {t(`address.edit`)}
              </SCustomButton>
              <SCustomButton preset="primary" size="middle" onClick={onConfirmClick}>
                {t(`address.confirmSuggested`)}
              </SCustomButton>
            </CustomRow>
          </>
        )}
      </Inner>
    </CheckModal>
  );
};
