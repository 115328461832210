import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { ROUTES } from 'vars/const/ROUTES';
import { Button } from 'components/theme/Button/Button';
import { BodyText, Title } from 'components/general/Typography';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { setAppLocked } from 'store/ui.slice';
import { lsGetItem } from 'utils/helpers/storage';
import { encryptPwd } from 'utils/helpers/encrypt';
import { useToggle } from 'utils/hooks/useToggle';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Icon } from 'components/general/Icon/Icon';
import { getRequiredRule, passwordMaxLength, passwordMinLength } from 'utils/helpers/validationRules';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { TextButton } from 'components/general/TextButton/TextButton';
import { LogoutModal } from 'components/general/Modals/LogoutModal/LogoutModal';
import { Loader } from 'components/general/Loader/Loader';
import { Wrapper } from './LockScreen.styles';

export const LockScreen = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isDesktopSize } = useDeviceDimension();
  const hashedPwd = lsGetItem('sessionHash');
  const [form] = Form.useForm();
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const logoutModal = useToggle(false);

  const onFieldsChange = () => {
    const isPasswordEmpty = form.getFieldValue('password') === '';
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    setSubmitDisabled(isPasswordEmpty || hasErrors);
  };

  const onFinish = async (values: { password: string }) => {
    setIsLoading(true);
    const valueHash = await encryptPwd(values.password);
    setIsLoading(false);
    if (valueHash === hashedPwd) {
      dispatch(setAppLocked(false));
      setIsValid(true);
      navigate(location.state?.from ?? ROUTES.home.path);
    } else {
      setIsValid(false);
    }
  };

  const onLogout = () => {
    logoutModal.show();
  };

  useEffect(() => {
    dispatch(setAppLocked(true));
  }, []);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Title size="L" color="charcoal" fontWeight="M" font="Poppins" marginBottom={16}>
        {t('lockScreen.ApplicationLocked')}
      </Title>
      <BodyText size="L" fontWeight="R" color="charcoal70" marginBottom={isDesktopSize ? 24 : 72}>
        {t('lockScreen.PleaseEnterPassword')}
      </BodyText>
      <CustomCard background={isDesktopSize ? 'white' : 'transparent'} padding={isDesktopSize ? '24px' : '0'}>
        <Form
          onFinish={onFinish}
          name="loginForm"
          onFieldsChange={onFieldsChange}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          form={form}
          initialValues={{
            password: '',
          }}
        >
          {!isValid && (
            <BodyText size="N" color="red">
              Invalid password!
            </BodyText>
          )}
          <Form.Item
            label={t('loginScreen.Password')}
            name="password"
            rules={[
              getRequiredRule('loginScreen.Please input password'),
              passwordMinLength(t('preRegOnboarding.Minimum 8 characters', { ns: 'preRegOnboarding' })),
              passwordMaxLength(t('preRegOnboarding.Maximum 60 characters', { ns: 'preRegOnboarding' })),
            ]}
          >
            <BaseInput
              onBeige={!isDesktopSize}
              type="password"
              placeholder="*************"
              autoComplete="new-password"
              data-testid="passwordInput"
              passwordIcon
              suffixColor="blue"
              suffixSize="big"
              tabIndex={2}
              marginBottom={32}
            />
          </Form.Item>

          <Form.Item>
            <Button className="unlock-btn" preset="primary" type="submit" disabled={isSubmitDisabled}>
              {t('lockScreen.Unlock')}
            </Button>
          </Form.Item>
        </Form>

        {!isDesktopSize && (
          <CustomRow flexDirection="column" marginBottom={160}>
            <Icon name="faceIdBig" color="blue" cursorPointer size="biggest" marginBottom={16} />
            <BodyText textType="bodyText" fontWeight="M" size="M" color="blue" font="Poppins">
              {t('lockScreen.UnlockWithFaceID')}
            </BodyText>
          </CustomRow>
        )}

        <CustomRow justifyContent="center" marginBottom={isDesktopSize ? 8 : 48}>
          <TextButton size="M" fontWeight="M" onClick={onLogout}>
            {t('lockScreen.Logout')}
          </TextButton>
        </CustomRow>
      </CustomCard>

      <LogoutModal open={logoutModal.isActive} onClose={logoutModal.hide} />
    </Wrapper>
  );
};
