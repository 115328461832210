import React, { useEffect, useMemo, useState } from 'react';
import { images } from 'assets';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { useToggle } from 'utils/hooks/useToggle';
import { mergeArrays } from 'utils/helpers/arrays';
import { useAccounts } from 'utils/hooks/useAccounts';
import { ROUTES } from 'vars/const/ROUTES';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { SimpleAccountSelectSheet } from 'components/general/Accounts/AccountSelect/SimpleAccountSelectSheet/SimpleAccountSelectSheet';
import { useAllCashAccountsFeatures } from 'utils/hooks/useAllCashAccountsFeatures';
import { AccountFeatureLayout } from 'components/layouts/AccountFeatureLayout/AccountFeatureLayout';
import { Loader } from 'components/general/Loader/Loader';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import CashAccount from './Images/CashAccount.svg';
import { PennyJarActivatedModal } from './PennyJarActivatedModal';

export const PennyJarPage = () => {
  const { t } = useTranslation('pennyJar');
  const { navigate } = useEnhancedNav();
  const selectCashAccountSheet = useToggle();
  const roundUpToSelectSheet = useToggle();
  const successModal = useToggle();
  const { internalAccounts, cashAccounts, internalAccountsGroups } = useAccounts();
  const [searchParams] = useSearchParams();
  const preselectedCashAcc = cashAccounts.find((acc) => acc.accountId === searchParams.get('accountId'));
  const [forAccount, setForAccount] = useState<IAccountItem | null>();
  const { roundUpFeatures, setRoundUp, isFeatureChangeSuccess, isLoading } = useAllCashAccountsFeatures();
  const hasAccount = !!cashAccounts && cashAccounts.length > 0;
  // Accounts with already active Penny Jar or with no sub accounts
  const accountsWithActivePennyJarIds = roundUpFeatures.filter((feature) => feature.isEnabled).map((feature) => feature.accountId);
  const accountsWithoutSubAccountIds = internalAccountsGroups.filter((accGroup) => !accGroup.goals && !accGroup.needs).map((accGroup) => accGroup.cash?.accountId);
  const disabledAccountIds = mergeArrays(accountsWithActivePennyJarIds, accountsWithoutSubAccountIds);
  const subAccounts = useMemo(() => internalAccounts.filter((acc) => !acc.isCashType && acc.parentAccountId === forAccount?.accountId), [internalAccounts, forAccount]);

  const handleSelectAccount = (account: IAccountItem) => {
    setForAccount(account);
    selectCashAccountSheet.hide();
    roundUpToSelectSheet.show();
  };

  const handleSelectRoundUpTo = (account: IAccountItem) => {
    if (forAccount?.accountId) {
      roundUpToSelectSheet.hide();
      setRoundUp(forAccount?.accountId, true, account.accountId);
    }
  };

  const handleOnContinue = () => {
    if (preselectedCashAcc) {
      handleSelectAccount(preselectedCashAcc);
      roundUpToSelectSheet.show();
    } else if (cashAccounts.length >= 1) {
      selectCashAccountSheet.show();
    } else {
      navigate(ROUTES.openAccountPrep.path);
    }
  };

  const handleCloseSuccessModal = () => {
    successModal.hide();
    navigate(ROUTES.accountPennyJar.path, { params: { accountId: forAccount?.accountId } });
  };

  useEffect(() => {
    if (isFeatureChangeSuccess) {
      selectCashAccountSheet.hide();
      roundUpToSelectSheet.hide();
      successModal.show();
    }
  }, [isFeatureChangeSuccess]);

  return (
    <AccountFeatureLayout header={t('pennyJar.Title')} title={t('pennyJar.SubTitle')} subtitle={t('pennyJar.Description')}>
      {isLoading && <Loader />}
      <div className="center">
        {hasAccount ? (
          <div className="info-card">
            <div className="logo-container">
              <img src={images.pennyJarMainLogo} className="logo" alt="Penny Jar" />
            </div>
            <div className="text-container">
              <span className="title">{t(`pennyJar.CarouselTitle1`)}</span>
              <span className="description">{t(`pennyJar.CarouselSubtitle1`)}</span>
            </div>
          </div>
        ) : (
          <div className="info-card">
            <div className="logo-container">
              <img src={CashAccount} className="logo" alt="Cash Account" />
            </div>

            <div className="text-container">
              <span className="title">{t('pennyJar.CashAccountTitle')}</span>
              <span className="description">{t('pennyJar.CashAccountSubtitle')}</span>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="btn-container">
          <Button preset="primary" onClick={handleOnContinue}>
            {hasAccount ? t('pennyJar.SetUp') : t('pennyJar.OpenCash')}
          </Button>
        </div>
        <PageFooter type="full" />
      </div>

      <SimpleAccountSelectSheet
        accounts={cashAccounts}
        isOpen={selectCashAccountSheet.isActive}
        onClose={selectCashAccountSheet.hide}
        onSelect={handleSelectAccount}
        disableIds={disabledAccountIds}
        description={t('pennyJar.SelectCashAccountDescription')}
        title={t(cashAccounts.length - disabledAccountIds.length > 1 ? 'pennyJar.SelectCashAccountTitle' : 'pennyJar.ConfirmCashAccountTitle')}
      />
      {subAccounts.length > 0 && (
        <SimpleAccountSelectSheet
          accounts={subAccounts}
          isOpen={roundUpToSelectSheet.isActive}
          onClose={roundUpToSelectSheet.hide}
          onSelect={handleSelectRoundUpTo}
          description={t('pennyJar.SetupDescription')}
          title={t('pennyJar.SelectCashAccountForPennyJar')}
        />
      )}
      <PennyJarActivatedModal open={successModal.isActive} accountId={forAccount?.accountId} onClose={handleCloseSuccessModal} />
    </AccountFeatureLayout>
  );
};
