import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { ROUTES } from 'vars/const/ROUTES';
import { authenticationApi } from 'store/user/authentication.api';
import { accountsApi } from './accounts.api';
import { IAcctTrnRecItem, IFiservAccounts, IGetAccountsResponse, IInitialAccountsState, IPartyAcctRelRecItem, ISetLastTransactionData, ICurrentTransfer } from './accounts.types';

export const initialAccountsState: IInitialAccountsState = {
  fiservAccountsData: {} as IFiservAccounts,
  recentTransactions: [],
  currentTransfer: {},
  lastTransactionFromAccount: '',
  lastTransactionToAccount: '',
  lastTransactionAmount: '',
  lastTransactionDate: '',
  lastTransactionNote: '',
  immediatePayFrameUrl: '',
  walletAccounts: [],
  thirdPartyData: [],
  cashAccountId: '',
  addAccountType: 'needs',
  pennyJarDestinationAccount: {} as IAccountItem,
  pennyJarDestinationAccounts: [],
  summaryTopAccount: null,
  pennyJarBackRoute: ROUTES.selectedAccountInformation.path,
};

// TODO: Fix page / sheet / switch logic when backend service work
export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: initialAccountsState,
  reducers: {
    resetAccountData(state) {
      state.fiservAccountsData = {} as IFiservAccounts;
      state.recentTransactions = [];
      state.currentTransfer = {};
      state.lastTransactionFromAccount = '';
      state.lastTransactionToAccount = '';
      state.lastTransactionAmount = '';
      state.lastTransactionDate = '';
      state.lastTransactionNote = '';
      state.immediatePayFrameUrl = '';
      state.walletAccounts = [];
      state.thirdPartyData = [];
      state.cashAccountId = '';
      state.addAccountType = 'needs';
      state.pennyJarDestinationAccount = {} as IAccountItem;
      state.pennyJarDestinationAccounts = [];
      state.summaryTopAccount = null;
      state.pennyJarBackRoute = ROUTES.selectedAccountInformation.path;
    },
    setAccounts(state, { payload }: PayloadAction<IFiservAccounts>) {
      state.fiservAccountsData = payload;
    },
    setMainTransactions(state, { payload }: PayloadAction<IAcctTrnRecItem[]>) {
      state.recentTransactions = payload;
    },
    setLastTransaction(state, { payload }: PayloadAction<ISetLastTransactionData>) {
      state.lastTransactionFromAccount = payload.lastTransactionFromAccount;
      state.lastTransactionToAccount = payload.lastTransactionToAccount;
      state.lastTransactionAmount = payload.lastTransactionAmount;
      state.lastTransactionDate = payload.lastTransactionDate;
      state.lastTransactionNote = payload.lastTransactionNote;
    },
    setPennyJarDestinationAccount(state, { payload }: PayloadAction<IAccountItem>) {
      state.pennyJarDestinationAccount = payload;
    },
    setPennyJarDestinationAccounts(state, { payload }: PayloadAction<IAccountItem>) {
      state.pennyJarDestinationAccounts = [payload, ...state.pennyJarDestinationAccounts];
    },
    resetPennyJarDestinationAccounts(state) {
      state.pennyJarDestinationAccounts = [];
    },
    setSummaryTopAccount(state, { payload }: PayloadAction<IAccountItem>) {
      state.summaryTopAccount = payload;
    },
    setCurrentTransfer(state, { payload }: PayloadAction<ICurrentTransfer>) {
      state.currentTransfer = payload;
    },
    resetCurrentTransfer(state) {
      state.currentTransfer = {};
    },
    setPennyJarBackRoute(state, { payload }: PayloadAction<string>) {
      state.pennyJarBackRoute = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(accountsApi.endpoints.getAccounts.matchFulfilled, (state, { payload }: PayloadAction<IGetAccountsResponse>) => {
      state.fiservAccountsData = payload.fiservAccounts;
      state.cashAccountId =
        payload.fiservAccounts.partyAcctRelRec.find(
          (item: IPartyAcctRelRecItem) => item?.partyAcctRelKeys?.acctKeys?.percapitaAccountType === 'Cash' && item?.partyAcctRelInfo?.primaryOwnerInd && item?.partyAcctRelInfo?.ownerInd
        )?.partyAcctRelKeys.acctKeys.percapitaAccountId || '';
    });
    builder.addMatcher(accountsApi.endpoints.getImmediatePayFrameUrl.matchFulfilled, (state, { payload }) => {
      state.immediatePayFrameUrl = payload.frameUrl;
    });
    builder.addMatcher(accountsApi.endpoints.getThirdPartyData.matchFulfilled, (state, { payload }) => {
      state.thirdPartyData = payload;
    });
    builder.addMatcher(authenticationApi.endpoints.logout.matchFulfilled, (state) => {
      accountsSlice.caseReducers.resetAccountData(state);
    });
  },
});

export const selectAccountsData = (state: RootState) => state.accounts;
export const selectCurrentTransfer = createSelector(selectAccountsData, (state) => state.currentTransfer);
export const { setPennyJarDestinationAccount, setPennyJarDestinationAccounts, resetPennyJarDestinationAccounts, setCurrentTransfer, setPennyJarBackRoute, resetCurrentTransfer, setLastTransaction } =
  accountsSlice.actions;
