import styled, { css } from 'styled-components';
import { mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLogoutModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image-container {
    height: 128px;
  }

  .custom-row {
    ${mediaUpTo(
      'mobile',
      css`
        flex-direction: column;
        & button {
          width: 100%;
        }
      `
    )}
  }
`;
