import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SError, SSmsCodeWrapper } from './SmsCodeForm.styles';
import { SmsCodeInput } from './SmsCodeInput';
import { ISmsFormProps, ISmsFormValues } from './SmsCodeForm.types';

export const SmsCodeForm: React.FC<ISmsFormProps> = ({
  handleCompletion,
  onCompletion,
  checkError,
  generateError,
  inputTheme = 'sms-code',
  size = 5,
  boxSize = '56px',
  separatorsAfter = [],
  isWrongCode = false,
  isDisabledFocusOnStart = false,
  placeholder = '',
  codeVal = '',
}) => {
  const [form] = Form.useForm();
  const currentError = checkError || generateError;
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { isDesktopSize } = useDeviceDimension();

  const getParsedValues = (value: string) => {
    const valArr = value.split?.('');
    const emptySize = size - valArr.length;
    const codeItems = [...valArr, ...new Array(emptySize).fill(null)];
    form.resetFields();
    form.setFieldsValue({ 'list-smsCode': codeItems });
    return form.getFieldsValue();
  };

  const getValues = () => {
    const values = form.getFieldsValue();
    if (values[`list-smsCode`][0].length > 1) {
      return getParsedValues(values[`list-smsCode`][0]);
    }
    return values;
  };

  const handleFormChange = () => {
    const values = getValues();
    const smsCode = values[`list-smsCode`].join('');
    const isCompleted = smsCode.length === size;
    const hasErrors = !isCompleted || form.getFieldsError().some(({ errors }) => errors.length);
    if (!hasErrors && isCompleted) {
      onCompletion?.(false);
      handleCompletion(smsCode);
      setIsSuccess(true);
    } else {
      onCompletion?.(true);
      setIsSuccess(false);
    }
  };

  const onFinish = (values: ISmsFormValues) => {
    handleCompletion(values.smsCode?.join(''));
  };

  const fillForm = (pasted: string) => {
    const cleanedPasted = pasted.replace(/\D/g, '');

    const valuesArray = cleanedPasted.split('').slice(0, size);

    valuesArray.forEach((char, index) => {
      form.setFieldValue(['list-smsCode', index], char);
    });

    form.validateFields();
  };

  const onPaste = (event: React.ClipboardEvent<HTMLFormElement>) => {
    event.preventDefault();
    const pasted = event.clipboardData.getData('text/plain');
    fillForm(pasted);
  };

  useEffect(() => {
    if (codeVal) {
      fillForm(codeVal);
    }
    document.addEventListener('keydown', async (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        event.preventDefault();
        const clipboardData = await navigator.clipboard.readText();
        fillForm(clipboardData);
      }
    });
  }, []);

  return (
    <Form className="sms-code-form" autoComplete="off" onPaste={onPaste} form={form} requiredMark={false} onFieldsChange={handleFormChange} onFinish={onFinish}>
      <SSmsCodeWrapper className="sms-code-wrapper">
        <SmsCodeInput
          name="smsCode"
          isCompleted={isSuccess}
          isWrongCode={isWrongCode}
          placeholder={placeholder || (isDesktopSize ? '—' : '')}
          inputTheme={inputTheme}
          hasSubmitError={Boolean(currentError)}
          size={size}
          boxSize={boxSize}
          separatorsAfter={separatorsAfter}
          isDisabledFocusOnStart={isDisabledFocusOnStart}
        />
        {currentError && (
          <SError textColor="red" size="big" textAlign="center">
            {currentError}
          </SError>
        )}
      </SSmsCodeWrapper>
    </Form>
  );
};
