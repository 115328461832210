import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import styled from 'styled-components/macro';
import { getColor } from 'utils/helpers/styleHelpers';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';

export const SIcon = styled(Icon).attrs({
  name: 'edit',
  size: 'smaller',
})`
  flex: 0 0 auto;
  margin-left: 8px;
`;

export const SCustomRow = styled(CustomRow)`
  button.button-add {
    width: auto;
    padding: 0;
    border: none;

    .button-title {
      color: ${getColor('blue')};
      font-size: 14px;
      font-family: DM Sans, sans-serif;
      font-weight: 700;
      margin-right: 8px;
    }
  }

  &.has-additional-text {
    margin-bottom: 4px !important;
  }
`;

export const SData = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
  flex: 1;
  position: relative;

  .data-item-header {
    margin-bottom: 4px;

    .icon-circleInfo {
      margin-left: 8px;
    }

    .tooltip {
      .tooltip-header-text .custom-text-inner,
      .tooltip-body-text .custom-text-inner {
        font-size: 12px !important;
      }
    }
  }

  & .data-text-inner {
    display: flex;
    flex-direction: row;
  }

  & .custom-text-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3;
  }

  & .icon-circleInfo {
    margin-top: 2px;
  }
`;

export const SLabelText = styled(BodyText)`
  .custom-text-inner {
    word-break: keep-all;
  }
`;

export const SUnderReviewLabel = styled(BodyText)`
  margin-left: 8px;
  background-color: ${getColor('charcoal5')};
  padding: 4px 8px 4px 8px;
  border-radius: 16px;
  max-height: 24px;

  .custom-text-inner {
    color: ${getColor('charcoal50')};
    margin-right: 0;
  }
`;

export const SButton = styled(Button)`
  border: none !important;
  margin-top: 8px;
`;
