import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectCurrentUser, selectPolicies } from 'store/user/authentication.slice';
import { BodyText } from 'components/general/Typography';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { TAccountSourceType } from 'vars/types/accounts.types';
import { ROUTES } from 'vars/const/ROUTES';
import { InternalAccountsGroup } from 'components/general/Accounts/Internal/InternalAccountsGroup';
import { AccountsTypeSwitcher } from 'components/general/Accounts/AccountSelect/AccountsTypeSwitcher/AccountsTypeSwitcher';
import { ExternalAccount } from 'components/general/Accounts/External/ExternalAccount';
import { useAccounts } from 'utils/hooks/useAccounts';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { TextButton } from 'components/general/TextButton/TextButton';
import { Icon } from 'components/general/Icon/Icon';
import { Loader } from 'components/general/Loader/Loader';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { IAccountItem, isThirdPartyAccount, IThirdParty } from 'store/user/accounts/accounts.types';
import { isUnder18 } from 'utils/helpers/date';
import { SAccountsList, SAccountsTabs, SAccountInformationPage } from './SelectedAccountInformationPage.styles';

export const AccountsInformationPage = () => {
  const { navigate } = useEnhancedNav();
  const policies = useSelector(selectPolicies);
  const { type } = useParams();
  const { t } = useTranslation('accountInformation');
  const { internalAccountsGroups, externalAccounts, isLoading, cashAccounts } = useAccounts();
  const { isDesktopSize } = useDeviceDimension();
  const isExternalActive = Boolean(policies?.ExternalTransferEnabled) && externalAccounts.length > 0;
  const user = useSelector(selectCurrentUser);
  const isUserUnder18 = !!user.birthday && isUnder18(new Date(user.birthday));
  const isAddSecondaryCashEnabled = cashAccounts?.length < 2 && cashAccounts?.length !== 0 && !isUserUnder18;

  const handleSwitchType = (selectedType: TAccountSourceType) => {
    navigate(ROUTES.accountInformation.path, { params: { type: selectedType } });
  };

  const handleAccountClick = (account: IAccountItem | IThirdParty) => {
    navigate(ROUTES.selectedAccountInformation.path, {
      params: {
        sourceType: isThirdPartyAccount(account) ? 'external' : 'percapita',
        accountId: account.externalDisplayAccountNumber || account.accountId,
      },
    });
  };

  const handleAddNewAccount = () => {
    navigate(ROUTES.addExternalAccounts.path, {
      backUrl: 'current',
    });
  };

  const handleAddNewCashAccount = () => {
    navigate(ROUTES.starter.path, {
      state: {
        alreadyHasCash: true,
      },
      backUrl: 'current',
    });
  };

  useEffect(() => {
    if ((type !== 'percapita' && type !== 'external') || (!isExternalActive && type === 'external')) {
      navigate(ROUTES.accountInformation.path, { params: { type: 'percapita' }, replace: true });
    }
  }, [type]);

  return (
    <SAccountInformationPage className="account-information-page">
      {isLoading && <Loader />}
      <div>
        <PageHeader title={t('accountInformation.Accounts')} hasNavBack={false} />
        <div className="account-information-page__content">
          <SAccountsTabs>
            <AccountsTypeSwitcher isDesktopSize={isDesktopSize} onChange={handleSwitchType} activeType={type as TAccountSourceType} isExternalActive={isExternalActive} />
          </SAccountsTabs>

          <SAccountsList>
            {type === 'percapita' && (
              <>
                <div className="internal-accounts">
                  {internalAccountsGroups?.map((group) => (
                    <InternalAccountsGroup group={group} key={group.cash?.fiservAccountId} onAccountSelect={handleAccountClick} />
                  ))}
                </div>

                {isAddSecondaryCashEnabled && (
                  <div className="add-new-cash-account" onClick={handleAddNewCashAccount}>
                    <BodyText textType="bodyText" color="blue" size="N" fontWeight="M" font="Poppins" cursorPointer>
                      {t('accountInformation.AddNewCashAccount')}
                    </BodyText>
                    <Icon name="circlePlus" color="blue" cursorPointer size="small" />
                  </div>
                )}
              </>
            )}

            {type === 'external' && (
              <>
                {externalAccounts?.map((account) => (
                  <ExternalAccount account={account} key={account.thirdPartyAccountId} onClick={() => handleAccountClick(account)} />
                ))}
                <TextButton iconName="circlePlus" className="add-new-account-btn" onClick={handleAddNewAccount} fontWeight="M" font="Poppins">
                  {t('accountInformation.Add new Account')}
                </TextButton>
              </>
            )}
          </SAccountsList>
        </div>
      </div>
      <PageFooter />
    </SAccountInformationPage>
  );
};
