import { images } from 'assets';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ROUTES } from 'vars/const/ROUTES';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { useAddAccountMutation } from 'store/user/accounts/accounts.api';
import { EAccountType } from 'store/user/accounts/accounts.types';
import { useLazyGetCurrentUserQuery } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { API_RESPONSE_CODES } from 'vars/const/API_CODES';
import { SContainer } from './OpenNewCashPage.styles';

export const OpenNewCashPage: React.FC = () => {
  const { t } = useTranslation('cashAccountOpening');
  const { state } = useLocation();
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();
  const [addAccount, addAccountResult] = useAddAccountMutation();
  const [getCurrentUser, getCurrentUserResult] = useLazyGetCurrentUserQuery();

  const handleOpen = async () => {
    addAccount({
      percapitaAccountType: EAccountType.CASH,
      nickname: '',
      jointInviteType: '',
      inviteId: '',
      userReferralCode: getCurrentUserResult?.data?.referralCode || getCurrentUserResult?.currentData?.referralCode || '',
    })
      .unwrap()
      .then(() => {
        getCurrentUser()
          .unwrap()
          .then(() => {
            navigate(ROUTES.openNewCashApproved.path);
          });
      })
      .catch((error: any) => {
        if (error.data?.Code === API_RESPONSE_CODES.FISERV_ADD_CARD_ERROR || error.data?.Code === API_RESPONSE_CODES.PARTY_ID_CREATED_FAILED_TO_OPEN_ACCOUNT) {
          navigate(ROUTES.home.path);
        }
      });
  };

  const handleDontOpen = () => {
    navigate(state?.fromPage || ROUTES.home.path);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  if (addAccountResult?.isLoading || getCurrentUserResult?.isLoading) return <Loader />;

  return (
    <>
      <WebHeader showExit={false} showBack={false} />
      <SContainer>
        <img src={images.addNewCashAccount} alt="newCashAccount" width={isDesktopSize ? '200px' : '154px'} />

        <Title size={isDesktopSize ? 'L' : 'M'} fontWeight="M" marginTop={28} marginBottom={16} textAlign="center">
          {t('openNewCash.AreYouSure')}
        </Title>
        <BodyText textType="bodyText" color="charcoal70" size={isDesktopSize ? 'L' : 'M'} fontWeight="R" textAlign="center">
          {t('openNewCash.YouHaveProvidedAll')}
        </BodyText>

        <Button preset="primary" marginTop={48} marginBottom={24} onClick={handleOpen}>
          {t('openNewCash.Open')}
        </Button>
        <BodyText textType="bodyText" color="blue" cursorPointer size={isDesktopSize ? 'L' : 'N'} fontWeight={isDesktopSize ? 'SB' : 'M'} font="Poppins" onClick={handleDontOpen}>
          {t('openNewCash.DontOpen')}
        </BodyText>
      </SContainer>
    </>
  );
};
