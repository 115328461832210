import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAppLocked } from 'store/ui.slice';
import { cleanUserData, selectCurrentAuthState } from 'store/user/authentication.slice';
import { lsGetItem } from 'utils/helpers/storage';
import { ROUTES } from 'vars/const/ROUTES';
import { useLogout } from './useLogout';

export const useActivity = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileApp = lsGetItem('isMobileApp');
  const [isIdle, setIsIdle] = useState(false);
  const { logout } = useLogout();
  const { systemProperties } = useSelector(selectCurrentAuthState);
  const sessionTimeout = Number(systemProperties?.sessionLock) * 1000 || 600000;
  const idleTimeout = Number(systemProperties?.idleTimeout) * 1000 || 12000000;

  const whiteList = [
    ROUTES.login.path,
    ROUTES.registration.path,
    ROUTES.onboardingHome.path,
    ROUTES.onboardingLanguageSelection.path,
    ROUTES.onboardingFirstSteps.path,
    ROUTES.onboarding.path,
    ROUTES.registrationCode.path,
    ROUTES.forgotPassword.path,
    ROUTES.forgotPasswordCode.path,
    ROUTES.forgotUsernameSent.path,
    ROUTES.checkCode.path,
    ROUTES.verifyDevice.path,
  ];

  const onPrompt = () => {
    // Fire a Prompt
    if (!isMobileApp) {
      navigate(ROUTES.sessionLocked.path, { state: { from: location.pathname } });
      console.log('session expired after sessionTimeout');
    }
  };

  const onIdle = () => {
    if (!isMobileApp) {
      console.log('User is idle idleTimeout');
      setIsIdle(true);
      const isWhiteListPath = whiteList.some((path) => path === location.pathname);
      if (isWhiteListPath) {
        console.log(`User on ${location.pathname} page (whiteList)`);
        return;
      }
      dispatch(setAppLocked(false));
      dispatch(cleanUserData());
      logout();
    }
  };

  const onActive = () => {
    setIsIdle(false);
    dispatch(setAppLocked(false));
  };

  const { activate, reset, pause } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    timeout: idleTimeout,
    promptBeforeIdle: idleTimeout - sessionTimeout,
    stopOnIdle: true,
  });

  return {
    activate,
    reset,
    isIdle,
    pause,
  };
};
