import React, { useState } from 'react';
import { Divider, Form, ModalProps } from 'antd';
import { getRequiredRule } from 'utils/helpers/validationRules';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { useForm } from 'antd/lib/form/Form';
import { useSendToEmailMutation } from 'store/user/consents/consents.api';
import { Loader } from 'components/general/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { SMaskStyle, SModal } from './ConsentModal.styles';

interface IConsentModal extends ModalProps {
  children: React.ReactNode;
  showSendViaEmail?: boolean;
  consentId: string;
  height?: string;
  width?: string;
  onClose: () => void;
  title?: string;
  subtitle?: string;
  sendViaText?: string;
  sendViaDescription?: string;
}

export const ConsentModal: React.FC<IConsentModal> = ({ onClose, height, width, children, consentId, showSendViaEmail = true, title, subtitle, sendViaText, sendViaDescription, ...props }) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [sendToEmail, { isLoading }] = useSendToEmailMutation();

  const [isDisabled, setIsDisabled] = useState(true);
  const [sentTo, setSentTo] = useState('');
  const [wasSent, setWasSent] = useState(false);
  const [isSendViaOpened, setIsSendViaOpened] = useState(true);

  const handleClose = () => {
    setSentTo('');
    setWasSent(false);
    onClose();
  };

  const handleFieldsChange = () => {
    const hasErrors = form.isFieldsTouched() && form.getFieldsError().some(({ errors }) => errors.length);
    setIsDisabled(hasErrors);
  };

  const handleSubmit = () => {
    if (consentId) {
      sendToEmail({
        email: form.getFieldValue('email'),
        consentId,
      })
        .unwrap()
        .then(() => {
          setSentTo(form.getFieldValue('email'));
          setWasSent(true);
        });
    }
  };

  if (isLoading) return <Loader />;

  return (
    <SModal
      {...props}
      maskStyle={SMaskStyle}
      closeIcon={<Icon name="circleClose" color="charcoal70" cursorPointer onClick={handleClose} />}
      title={
        <>
          <Title size="T" marginBottom={8}>
            {title || t('myInfo.disclosures.ElectronicConsent')}
          </Title>
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
            {subtitle || t('myInfo.disclosures.PleaseRead')}
          </BodyText>

          {showSendViaEmail && (
            <>
              <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
              <div className="send-via-container">
                <div>
                  <BodyText icon={<Icon name="sendByEmail" color="blue" />} textType="bodyText" color="charcoal70" size="T" fontWeight="R">
                    {sendViaText || t('myInfo.disclosures.SendViaEmail')}
                  </BodyText>
                </div>

                <Icon name={isSendViaOpened ? 'chevronUp' : 'chevronDown'} color="charcoal70" size="smaller" cursorPointer onClick={() => setIsSendViaOpened(!isSendViaOpened)} />
              </div>

              {isSendViaOpened && !wasSent && (
                <div>
                  <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" marginBottom={8} marginTop={16}>
                    {sendViaDescription || t('myInfo.disclosures.EnterYourEmail')}
                  </BodyText>
                  <div className="email-container">
                    <Form form={form} onFieldsChange={handleFieldsChange}>
                      <Form.Item
                        name="email"
                        rules={[
                          getRequiredRule('myInfo.Please input your email'),
                          {
                            type: 'email',
                            message: t('myInfo.This is not a valid email'),
                          },
                        ]}
                      >
                        <BaseInput inputMode="email" autoCapitalize="off" />
                      </Form.Item>
                    </Form>
                    <Button onClick={handleSubmit} disabled={isDisabled}>
                      {t('myInfo.disclosures.SendConsent')}
                    </Button>
                  </div>
                </div>
              )}
              {isSendViaOpened && wasSent && (
                <div className="successful-container">
                  <Icon name="tick" color="green" size="small" />
                  <BodyText textType="bodyText" color="green" size="N" fontWeight="R" display="inline-flex">
                    {t('myInfo.disclosures.SuccessfulSent')}
                    <BodyText textType="bodyText" fontWeight="B" size="N" color="green" marginLeft={5}>
                      {sentTo}
                    </BodyText>
                  </BodyText>
                </div>
              )}
            </>
          )}
        </>
      }
      width={width}
      height={height}
    >
      {children}
    </SModal>
  );
};
