export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
};

export const API_RESPONSE_CODES = {
  NO_DEVICE_ID: 1171,
  GET_PROFILE_ERROR: 3065,
  FISERV_ADD_CARD_ERROR: 2051,
  PARTY_ID_CREATED_FAILED_TO_OPEN_ACCOUNT: 2049,
};
