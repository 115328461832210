import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { Button } from 'components/theme/Button/Button';
import { InternalAccount } from 'components/general/Accounts/Internal/InternalAccount';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SAccountSelectSheet } from './SimpleAccountSelectSheet.styles';

type ITransferSelectorSheetProps = {
  isOpen: boolean;
  accounts?: IAccountItem[];
  selected?: IAccountItem | null;
  onClose: () => void;
  onSelect: (account: IAccountItem) => void;
  title: string;
  description?: string;
  disableIds?: string[];
};

export const SimpleAccountSelectSheet = ({ isOpen, accounts = [], selected = null, disableIds = [], onClose, onSelect, title, description }: ITransferSelectorSheetProps) => {
  const { t } = useTranslation('moveMoney');
  const [ticked, setTicked] = useState<IAccountItem | null>(selected);
  const selectedId = ticked?.accountId || selected?.accountId;
  /* eslint-disable-next-line no-unsafe-optional-chaining */
  const avalableAccs = accounts?.filter((acc) => !disableIds?.includes(acc.accountId));
  const isOnlyOneAccAvailable = avalableAccs.length === 1;
  const { isDesktopSize } = useDeviceDimension();

  const handleTick = (account: IAccountItem) => {
    setTicked(account);
  };

  const handleConfirmSelection = () => {
    if (ticked) {
      onSelect(ticked);
      setTicked(null);
    }
  };

  useEffect(() => {
    if (isOnlyOneAccAvailable) {
      setTicked(avalableAccs[0]);
    }
  }, [isOnlyOneAccAvailable]);

  return (
    <SAccountSelectSheet className="account-select-sheet" modalName="simpleAccountSelect" isModal={isDesktopSize} header={false} isOpen={isOpen} wrapperPadding={isDesktopSize} onClose={onClose}>
      <div className="account-select-sheet__container">
        <header className="account-select-sheet__header">
          <Title size="S" font="Poppins" color="charcoal" fontWeight="SB">
            {title}
          </Title>
        </header>

        {description && (
          <BodyText textType="bodyText" font="DM Sans" fontWeight="R" size="N" color="charcoal70" marginBottom={32} lineHeight={1.5}>
            {description}
          </BodyText>
        )}

        <div className="accounts">
          {accounts.map((account) => (
            <InternalAccount
              account={account}
              isAmountHidden
              key={account.accountId}
              isDesktop={isDesktopSize}
              onSelect={handleTick}
              selected={account.accountId === selectedId}
              isDisabled={disableIds?.includes(account.accountId)}
              isArrowHidden
              isAddNeedsGoals
            />
          ))}
        </div>
      </div>

      <Button preset="primary" onClick={handleConfirmSelection} disabled={!ticked} marginBottom={24}>
        {t('percapitaPayHome.Select Account')}
      </Button>

      <SuttonDisclaimerNote />
    </SAccountSelectSheet>
  );
};
