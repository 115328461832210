import React, { useEffect, useState } from 'react';
import { Button } from 'components/theme/Button/Button';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { fullNameCharacterRule, getEmailRulesOptional, getPhoneValidator, getRequiredRule } from 'utils/helpers/validationRules';
import { selectCurrentAuthState } from 'store/user/authentication.slice';
import { referApi } from 'store/user/refer/refer.api';
import { getUnmaskedMobileNumber } from 'utils/helpers/phoneNumber';
import { RadioButton } from 'components/general/RadioButton/RadioButton';
import { useTranslation } from 'react-i18next';
import { handleError } from 'utils/helpers/errorHelper';
import { MaskedInput } from 'components/general/MaskedInput/MaskedInput';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { Loader } from 'components/general/Loader/Loader';
import { useToggle } from 'utils/hooks/useToggle';
import { BodyText } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { useNavigate } from 'react-router-dom';
import { ReferSuccessModal } from 'components/general/Modals/ReferSuccessModal/ReferSuccessModal';
import { IPostReferRequest } from 'vars/types/refer.types';
import { lsGetItem } from 'utils/helpers/storage';
import { SDetailsFormLayout } from 'views/Refer/ReferPage/ReferPage.styles';
import { PHONE_MASK, PHONE_MASK_OPTIONS } from './inviteMasks';

interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  language: 'en' | 'es';
}

export const ReferForm = () => {
  const { user } = useSelector(selectCurrentAuthState);
  const { t } = useTranslation('refer');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const savedLanguage = lsGetItem('lastLanguage');

  const referSuccessModal = useToggle(false);

  const [createReferral, createReferralResult] = referApi.useCreateReferralMutation();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [language, setLanguage] = useState('');

  const onFinish = () => {
    referSuccessModal.show();
  };

  const onSendAnother = () => {
    referSuccessModal.hide();
    form.resetFields();
  };

  const onDone = () => {
    referSuccessModal.hide();
    navigate('/home');
  };

  const sendReferralInvintation = async (data: IPostReferRequest) => {
    try {
      await createReferral(data).unwrap();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    setLanguage(savedLanguage);
  }, [savedLanguage]);

  useEffect(() => {
    if (createReferralResult.isSuccess) {
      referSuccessModal.show();
    }
  }, [createReferralResult.isSuccess]);

  const handleOnFinish = async (values: IFormValues) => {
    const data: IPostReferRequest = {
      referralCampaignId: 'e05648e4-fc4f-4d06-882d-9255b4c08729',
      first: values.firstName,
      last: values.lastName,
      email: values.email,
      phone: values.phone ? getUnmaskedMobileNumber(`1 ${values.phone}`) : null,
      language: values.language,
    };

    sendReferralInvintation(data);
  };

  const onLanguageSelect = (e: any) => {
    setLanguage(e.target.value);
  };

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const phoneError = form.getFieldError('phone');
    setIsPhoneError(!!phoneError.length);
    setIsDisabled(hasErrors);
  };

  return (
    <SDetailsFormLayout>
      {createReferralResult.isLoading && <Loader />}
      <Form autoComplete="off" requiredMark={false} onFinish={handleOnFinish} layout="vertical" onFieldsChange={onFieldsChange} form={form}>
        <CustomCard marginBottom={48}>
          <Form.Item
            label={t('Your Friend or Family Member’s First Name')}
            name="firstName"
            rules={[getRequiredRule(t('Please Input First Name')), fullNameCharacterRule()]}
            style={{ marginBottom: '20px' }}
            validateTrigger={['onBlur', 'onChange']}
            validateFirst
          >
            <BaseInput placeholder={t('Enter First Name')} defaultValue={user?.firstName} />
          </Form.Item>

          <Form.Item
            label={t('Your Friend or Family Member’s Last Name')}
            name="lastName"
            validateTrigger={['onBlur', 'onChange']}
            validateFirst
            rules={[getRequiredRule(t('Please Input Last Name')), fullNameCharacterRule()]}
          >
            <BaseInput placeholder={t('Enter Last Name')} />
          </Form.Item>

          <Form.Item
            label={t('Your Friend or Family Member’s Email')}
            name="email"
            validateTrigger={['onBlur', 'onChange']}
            rules={[getRequiredRule(t('Please Input Email')), getEmailRulesOptional()]}
          >
            <BaseInput placeholder={t('Enter Email')} data-testid="email" autoCapitalize="off" inputMode="email" />
          </Form.Item>

          <Form.Item
            label={t('Your Friend or Family Member’s Phone Number')}
            name="phone"
            validateTrigger={['onBlur', 'onChange']}
            validateFirst
            rules={[getPhoneValidator(t('Please Input Phone Number'))]}
          >
            <MaskedInput mask={PHONE_MASK} maskOptions={PHONE_MASK_OPTIONS} placeholder={t('Enter Phone Number')} isError={isPhoneError} inputMode="tel" />
          </Form.Item>

          <BodyText textType="bodyText" size="M" color="charcoal" fontWeight="B" display="inline">
            {t('Choose language to send the referral code')}
          </BodyText>

          <Form.Item name="language" style={{ marginTop: 24, marginBottom: 0 }}>
            <div className="language-selection">
              <RadioButton checked={language === 'en'} value="en" onChange={onLanguageSelect}>
                English
              </RadioButton>
              <RadioButton checked={language === 'es'} value="es" onChange={onLanguageSelect}>
                Spanish
              </RadioButton>
            </div>
          </Form.Item>
        </CustomCard>
        <Form.Item>
          <div className="send-btn-container">
            <Button preset="primary" disabled={isDisabled} onClick={() => onFinish}>
              {t('Send Invite')}
            </Button>
          </div>
        </Form.Item>
      </Form>

      <ReferSuccessModal open={referSuccessModal.isActive} onSendAnother={onSendAnother} onClose={onSendAnother} onDone={onDone} />
    </SDetailsFormLayout>
  );
};
