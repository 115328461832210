import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { TCountriesItem } from 'views/MoveMoney/InternationalTransfer/types';
import { COUNTRIES_AVAILABLE, COUNTRIES_COMING_SOON } from 'views/MoveMoney/InternationalTransfer/mock';
import { NavLink } from 'react-router-dom';
import { CountriesList } from 'views/MoveMoney/InternationalTransfer/CountriesList/CountriesList';
import { SCustomSheet } from './CountriesListSheet.styles';

type TCountryListProps = {
  isOpen: boolean;
  onClose: () => void;
  selectCountry: (country: TCountriesItem) => void;
  learnMore: () => void;
};

export const CountriesListSheet: React.FC<TCountryListProps> = ({ isOpen, onClose, selectCountry, learnMore }) => {
  const { t } = useTranslation('moveMoney');
  const countriesAvailableSorted = COUNTRIES_AVAILABLE.sort((a, b) => (a.title < b.title ? -1 : 1));
  const countriesComingSoonSorted = COUNTRIES_COMING_SOON.sort((a, b) => (a.title < b.title ? -1 : 1));

  return (
    <SCustomSheet isOpen={isOpen} onClose={onClose} maxHeight="95%" padding="0" paddingTop="32px" paddingBottom="56px">
      <div className="header">
        <Title marginBottom={8} lineHeight={1.3}>
          {t('internationalTransferPage.WhichCountryWouldYouLikeToSendMoneyTo')}
        </Title>

        <BodyText size="N" color="charcoal70" lineHeight="20px" marginBottom={32}>
          {t('internationalTransferPage.SelectYourDestinationToCheck')}
        </BodyText>
      </div>

      <div className="countries-list-layout">
        <div className="title">
          <BodyText size="N" color="charcoal70">
            {t('internationalTransferPage.AvailableCountries')}
          </BodyText>
        </div>

        <CountriesList list={countriesAvailableSorted} selectCountry={selectCountry} />

        {countriesComingSoonSorted?.length ? (
          <>
            <div className="title">
              <BodyText size="N" color="charcoal70">
                {t('internationalTransferPage.ComingSoon')}
              </BodyText>
            </div>

            <CountriesList list={countriesComingSoonSorted} isComingSoon />

            <BodyText size="M" fontWeight="M" className="align-self-center">
              {t('internationalTransferPage.DontSeeYourCountryHere')}
            </BodyText>

            <NavLink to="#" className="link align-self-center" onClick={learnMore}>
              {t('internationalTransferPage.ClickHereToLearnMore')}
            </NavLink>
          </>
        ) : null}
      </div>
    </SCustomSheet>
  );
};
