import React from 'react';
import clsx from 'clsx';
import { BodyText } from 'components/general/Typography';
import { TThemeColor } from 'styles/theme';
import { TIconName, TIconSize } from 'components/general/Icon/Icon.types';
import { Icon } from 'components/general/Icon/Icon';
import { TSizeProp, TWeightProp } from 'components/general/Typography/Typography.types';
import { STextBtn } from './TextButton.styles';

export type TTextFontProp = 'Poppins' | 'DM Sans';
interface ITextButtonProps {
  children: React.ReactNode;
  iconName?: TIconName;
  preIconName?: TIconName;
  iconSize?: TIconSize;
  color?: TThemeColor;
  size?: TSizeProp;
  fontWeight?: TWeightProp;
  font?: TTextFontProp;
  onClick: () => void;
  className?: string;
  buttonType?: 'submit' | 'button';
}

export const TextButton = ({
  children,
  iconName,
  preIconName,
  color = 'blue',
  size = 'N',
  iconSize = 'small',
  fontWeight = 'R',
  font = 'DM Sans',
  onClick,
  className,
  buttonType = 'button',
}: ITextButtonProps) => (
  <STextBtn onClick={onClick} className={clsx('text-btn', className)} type={buttonType}>
    {preIconName && <Icon name={preIconName} size="small" color={color} marginRight={8} />}
    <BodyText size={size} color={color} font={font} fontWeight={fontWeight}>
      {children}
    </BodyText>
    {iconName && <Icon name={iconName} size={iconSize} color={color} marginLeft={8} />}
  </STextBtn>
);
