import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 0 20px 5px;
  min-height: 100%;
  width: 100%;

  ${mediaFrom(
    'desktop',
    css`
      align-items: center;
      padding: 0 20px 40px 20px;
    `
  )}

  .privacy {
    margin-top: 32px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${mediaFrom(
      'tablet',
      css`
        width: 580px;
        margin: 0 auto;
      `
    )}

    .ant-form {
      width: 100%;

      .ant-form-item {
        width: 100%;
      }
    }

    .tax-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      width: 100%;
      border-radius: 20px;
      background: ${getColor('white')};
      padding: 32px 24px;

      ${mediaFrom(
        'desktop',
        css`
          height: 148px;
        `
      )}
    }

    .web-title-container {
      ${mediaUpTo(
        'tablet',
        css`
          display: none;
        `
      )}
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin-bottom: 16px;
      margin-top: 48px;
      width: 100%;
    }

    .mobile-title-container {
      margin-top: 16px;

      ${mediaFrom(
        'tablet',
        css`
          display: none;
        `
      )}
    }

    .edit-title-container {
      width: 100%;
      margin-top: 15px;

      ${mediaFrom(
        'desktop',
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          margin-top: 48px;
        `
      )}
    }
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;
    margin-top: 20px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }
`;

export const SBlock = styled.div`
  position: relative;
`;
