import { BodyText } from 'components/general/Typography/BodyText/BodyText';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Icon } from 'components/general/Icon/Icon';
import { setLeftSubmenuActiveIndex, setShowOverlayPad } from 'store/ui.slice';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import { IMenuItemData } from 'vars/types/menu.types';
import { useToggle } from 'utils/hooks/useToggle';
import { SIcon } from './NavigationBar.styles';

interface INavigationItemProps {
  item: IMenuItemData;
  itemIndex: number;
  isActive: (item: IMenuItemData) => boolean;
  isGroup?: boolean;
  activeSubmenuIndex: null | number;
  onOpenCardHub: () => void;
}

export const NavigationItemDesktop = ({ item, itemIndex, isActive, activeSubmenuIndex, isGroup = false, onOpenCardHub }: INavigationItemProps) => {
  const navItemRef = useRef<HTMLDivElement | null>(null);
  const submenuRef = useRef<HTMLDivElement | null>(null);
  const menuItemSubmenu = useToggle(false);
  const desktopItemIsActive = useMemo(() => (activeSubmenuIndex === null ? isActive(item) : menuItemSubmenu.isActive), [isActive, item, activeSubmenuIndex, menuItemSubmenu.isActive]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const hasSubmenu = !!item?.children?.length;
  const isSubmenuActive = menuItemSubmenu.isActive && activeSubmenuIndex === itemIndex;

  const handleLinkClick = (event: React.MouseEvent<HTMLDivElement | HTMLElement>, menuItem: IMenuItemData, isSub = false) => {
    event.stopPropagation();

    if (menuItem.action === 'cardhub') {
      onOpenCardHub();
      return;
    }

    if (menuItem.children && menuItem.children.length && !isSub) {
      menuItemSubmenu.toggle();
    } else {
      if (isSub) {
        menuItemSubmenu.hide();
      }
      if (`${location.pathname}${location.search || ''}` === `/${menuItem.path}`) {
        navigate(0);
      } else {
        navigate(menuItem.path);
      }
    }
  };

  useClickOutside(navItemRef, () => {
    menuItemSubmenu.hide();
  });

  useEffect(() => {
    dispatch(setShowOverlayPad(menuItemSubmenu.isActive));
    dispatch(setLeftSubmenuActiveIndex(menuItemSubmenu.isActive ? itemIndex : null));
  }, [menuItemSubmenu.isActive]);

  useEffect(() => {
    console.log(submenuRef.current);
    if (submenuRef.current) {
      const viewportOverflowDiff = window.innerHeight - submenuRef.current.getBoundingClientRect().bottom;
      console.log(window.innerHeight, submenuRef.current.getBoundingClientRect().bottom, viewportOverflowDiff);
      submenuRef.current.style.top = `${viewportOverflowDiff <= 0 ? viewportOverflowDiff - 15 : 0}px`;
    }
  }, [isSubmenuActive]);

  return (
    <div className="navigation-item-wrapper" ref={navItemRef}>
      <div className={clsx('navigation-item-desktop', desktopItemIsActive && 'active', menuItemSubmenu.isActive && 'sub-open')} onClick={(e) => handleLinkClick(e, item)}>
        <div className="link-container">
          <SIcon name={item.icon} color={desktopItemIsActive ? 'blue' : 'charcoal70'} marginBottom={8} cursorPointer />
          <BodyText size="S" fontWeight="M" color={desktopItemIsActive ? 'blue' : 'charcoal'} className="link-container-title">
            {item.text}
          </BodyText>
        </div>

        {isGroup ? <SIcon name="chevronRight" size="smallest" color={desktopItemIsActive ? 'blue' : 'charcoal70'} cursorPointer /> : null}
      </div>

      {hasSubmenu && isSubmenuActive && (
        <div className="dropdown-desktop" key="submenu" ref={submenuRef}>
          {item?.children?.map((subItem: IMenuItemData) => (
            <div className="dropdown-item" onClick={(e) => handleLinkClick(e, subItem, true)}>
              <div className="dropdown-item-title-wrapper">
                <Icon name={subItem?.icon} marginRight={16} />

                <BodyText size="T" fontWeight="M" className="dropdown-item-title">
                  {subItem?.text}
                </BodyText>
              </div>

              {subItem?.children?.map((subItemChild: IMenuItemData) => (
                <BodyText size="T" fontWeight="R" color="charcoal70" className="dropdown-item-text" onClick={(e) => handleLinkClick(e, subItemChild as any, true)}>
                  {subItemChild?.text}
                </BodyText>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
