import React from 'react';
import { Title } from 'components/general/Typography';
import { ExitIcon } from 'views/OpenCashAccount/MyInfo/ExitIcon';
import { ProgressBar } from 'views/OpenCashAccount/MyInfo/ProgressBar/ProgressBar';
import { TWeightProp, TTitleSizeProp } from 'components/general/Typography/Typography.types';
import { SCustomRow } from './Header.styles';
import { WebHeader } from './WebHeader/WebHeader';

interface IHeaderProps {
  title: string;
  stage?: string;
  webStage?: string;
  marginTop?: number;
  marginBottom?: number;
  titleMarginRight?: number;
  fontWeight?: TWeightProp;
  size?: TTitleSizeProp;
  showExit?: boolean;
  showBack?: boolean;
  scrollableProgressBar?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({
  title,
  scrollableProgressBar = false,
  stage,
  webStage,
  marginBottom = 28,
  marginTop = 0,
  titleMarginRight = 0,
  fontWeight = 'SB',
  size = 'M',
  showExit = true,
  showBack = true,
}) => {
  return (
    <>
      <WebHeader showBack={showBack} showExit={showExit} />
      <SCustomRow marginBottom={marginBottom} marginTop={marginTop}>
        <Title textAlign="start" size={size} fontWeight={fontWeight} font="Poppins" marginRight={titleMarginRight}>
          {title}
        </Title>
        {(stage || showExit) && <ExitIcon />}
      </SCustomRow>
      {stage && webStage && <ProgressBar stage={stage} webStage={webStage} scrollableProgressBar={scrollableProgressBar} />}
    </>
  );
};
