import styled, { css } from 'styled-components';
import { Icon } from 'components/general/Icon/Icon';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Page } from 'components/layouts/Page/Page';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SCustomCard = styled(CustomCard)`
  & .base-input-container .base-input-input::placeholder {
    font-size: 12px;
  }
`;

export const SIcon = styled(Icon)`
  width: 22px;
  margin-bottom: 25px;
`;

export const SAddBankAccountPage = styled(Page.Container)`
  height: 100%;
  display: flex;

  .add-bank-account-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;

    ${mediaFrom(
      'tablet',
      css`
        background: ${getColor('white')};
        padding: 32px 24px;
        border-radius: 20px;
      `
    )}
  }

  .bank-name-container {
    height: 56px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .anticon-loading {
    color: rgba(62, 79, 229, 1);
  }

  .ant-spin-spinning {
    display: flex;
    gap: 8px;
  }

  .ant-spin-text {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    color: rgba(154, 152, 152, 1);
  }

  .add-bank-account-page__content {
    .btn {
      margin-top: 22px;
    }

    ${mediaFrom(
      'tablet',
      css`
        margin: 0 auto;

        .btn {
          align-self: center;
          width: auto;
          margin-top: 0;
        }
      `
    )}
  }
`;
