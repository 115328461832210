import { Icon } from 'components/general/Icon/Icon';
import styled from 'styled-components';

export const SMenuIcon = styled(Icon)`
  height: 24px !important;
  width: 24px !important;
  flex: 0 0 auto;
`;

export const SMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.bottom-margin {
    :not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;
