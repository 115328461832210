import { useTranslation } from 'react-i18next';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { errorExclamationMarkImage } from 'assets/images';
import { BodyText, Title } from 'components/general/Typography';
import React from 'react';
import { Button } from 'components/theme/Button/Button';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';

type BillPayErrorModalProps = {
  open: boolean;
  onClose: () => void;
};

export const BillPayErrorModal: React.FC<BillPayErrorModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <CustomModal open={open} closeIconColor="charcoal70" modalName="billPayError" onClose={onClose} topPosition="10%">
      <CustomRow justifyContent="center" marginBottom={32}>
        <img src={errorExclamationMarkImage} alt="transferError" />
      </CustomRow>
      <Title size="M" color="charcoal" fontWeight="M" font="Poppins" marginBottom="spacing-med">
        {t('WeHaveATechnicalIssue')}
      </Title>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" font="DM Sans" size="N" lineHeight={1.5} marginBottom={12}>
        {t('WereSorryForTheInconvenience')}
      </BodyText>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" font="DM Sans" size="N" lineHeight={1.5} marginBottom={40}>
        {t('IfTheProblemContinuesPleaseContact')}
      </BodyText>
      <CustomRow justifyContent="flex-end" gap={8}>
        <Button preset="primary" onClick={onClose} width={120}>
          {t('OK')}
        </Button>
      </CustomRow>
    </CustomModal>
  );
};
