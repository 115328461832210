import styled from 'styled-components';
import { flex, getColor } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  ${flex('column', 'flex-start', 'stretch')};
  align-self: stretch;
  margin-bottom: 16px;

  .card {
    .card-body {
      .how-to-use-item {
        ${flex('row', 'flex-start', 'flex-start')};
        margin-bottom: 16px;

        .number {
          ${flex('row')};
          height: 32px;
          width: 32px;
          font-size: 12px;
          font-weight: 700;
          font-family: DM Sans, sans-serif;
          border-radius: 50%;
          color: ${getColor('blue')};
          background: ${getColor('blue10')};
          margin-right: 16px;
        }

        .content-wrapper {
          ${flex('column', 'flex-start', 'flex-start')};
          flex: 1;

          .content-title {
          }

          .transfer-method-item {
            ${flex('row', 'flex-start', 'center')};
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }

            .svg-icon {
              margin-right: 8px;
            }
          }
        }
      }

      .what-you-need-item {
        ${flex('row', 'flex-start', 'stretch')};
        margin-bottom: 12px;

        .main-icon {
          ${flex('row', 'flex-start', 'flex-start')};
          margin-right: 16px;

          &.center {
            ${flex('row', 'flex-start')};
          }
        }

        .content {
          ${flex('column', 'center', 'flex-start')};

          ul.list {
            ${flex('column', 'flex-start', 'stretch')};
            padding: 18px 0 12px;
            margin-bottom: 0;
            list-style: none;

            li {
              ${flex('row', 'flex-start', 'flex-start')};
              padding-left: 6px;
              margin-bottom: 16px;

              &::before {
                content: '\u2022';
                color: ${getColor('blue')};
                font-weight: bold;
                margin-right: 14px;
              }

              &:last-child {
                margin-bottom: 0;
              }

              &.v-center {
                align-items: center;
              }
            }
          }
        }

        &.unexpired-photo-id {
          .content {
            padding-top: 10px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
