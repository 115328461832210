import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import dialogPolyfill from 'dialog-polyfill';
import { useTheme } from 'styled-components';
import { mobileApiCall } from 'services/mobileService';
import { useToggle } from 'utils/hooks/useToggle';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useAnalytics } from 'utils/hooks/useAnalytics';
import { CustomSheetTitle } from './CustomSheetTitle';
import { ICustomSheetProps } from './CustomSheet.types';
import { SCloseIcon, SDialog, SDialogBody, SDialogHeader, SWrapper } from './CustomSheet.styles';

export const CustomSheet: React.FC<ICustomSheetProps> = ({
  id,
  isOpen = false,
  isLocked = false,
  hasCloseIcon = true,
  isModal = false,
  onClose,
  children,
  title,
  subtitle,
  titleExtra,
  footer,
  header = true,
  modalName = '',
  height = 'fit-content',
  width,
  minHeight,
  maxHeight,
  modalBottom,
  wrapperPadding = true,
  headerStyle = {},
  paddingBottom,
  paddingTop,
  padding,
  className,
}) => {
  const contentRef = useRef<HTMLDialogElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const sheet = useToggle();
  const { track } = useAnalytics();
  const { isDesktopSize } = useDeviceDimension();
  const sheetClassName = clsx('custom-sheet', { 'modal-mode': isModal }, className);

  const [isSheet, setIsSheet] = useState<boolean>(false);

  useEffect(() => {
    setIsSheet(!isDesktopSize && !isModal);
  }, [isDesktopSize, isModal]);

  useEffect(() => {
    const dialogElement = contentRef.current;
    if (sheet.isActive) {
      dialogElement?.showModal?.();
      mobileApiCall('backgroundChange', theme.blockingOverlay);
    } else {
      dialogElement?.close?.();
      mobileApiCall('backgroundChange', theme.blue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheet.isActive]);

  useEffect(() => {
    if (isOpen) {
      track('modal', modalName);
    }
  }, [isOpen]);

  const handleClose = () => {
    if (!isLocked) {
      sheet.hide();
      onClose?.();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  const handleCloseSheet = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (event.target === contentRef.current && hasCloseIcon) {
      handleClose();
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      dialogPolyfill.registerDialog(contentRef.current);
    }
  }, [contentRef.current]);

  useEffect(() => {
    sheet.toggle(isOpen);
  }, [isOpen]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (bodyRef.current) {
        bodyRef.current.scrollTop = 0;
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [children]);

  return (
    <SDialog
      id={id}
      isDialogOpen={sheet.isActive}
      isModal={!isSheet}
      height={height}
      width={width}
      modalBottom={modalBottom}
      minHeight={minHeight}
      maxHeight={maxHeight}
      onKeyDown={handleKeyDown}
      className={sheetClassName}
      onClick={handleCloseSheet}
      ref={contentRef}
      wrapperPadding={wrapperPadding}
    >
      <SWrapper className="dialog-body-wrapper">
        {header && title && (
          <SDialogHeader $headerStyle={headerStyle}>
            <CustomSheetTitle title={title} subtitle={subtitle} extra={titleExtra} />
            {hasCloseIcon && <SCloseIcon cursorPointer color="charcoal70" name="circleClose" onClick={handleClose} />}
          </SDialogHeader>
        )}
        {(!header || !title) && hasCloseIcon && <SCloseIcon cursorPointer color="charcoal70" name="circleClose" onClick={handleClose} />}

        <SDialogBody ref={bodyRef} className="body" padding={padding} paddingBottom={paddingBottom} paddingTop={paddingTop}>
          {children}
        </SDialogBody>
        {footer && <footer>{footer}</footer>}
      </SWrapper>
    </SDialog>
  );
};
