import styled from 'styled-components/macro';
import { getColor, getProp } from 'utils/helpers/styleHelpers';

export const SChip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms linear;
  height: 36px;
  border-radius: 100px;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 15px;
  margin-top: ${getProp('marginTop', 0)}px;
  margin-right: ${getProp('marginRight', 0)}px;
  margin-left: ${getProp('marginLeft', 0)}px;
  margin-bottom: ${getProp('marginBottom', 0)}px;
  cursor: pointer;

  &.type {
    &-blue {
      background: ${getColor('transparent')};
      border: 2px solid ${getColor('blue')};
      color: ${getColor('blue')};

      .icon {
        background: ${getColor('blue')};
      }

      &.active {
        background: ${getColor('blue')};
        color: ${getColor('white')};

        .icon {
          background: ${getColor('white')};
        }
      }
    }

    &-light {
      background: ${getColor('white')};
      border: 2px solid ${getColor('charcoal20')};
      color: ${getColor('charcoal70')};

      .icon {
        background: ${getColor('charcoal')};
      }

      &.active {
        background: ${getColor('blue10')};
        border: 2px solid ${getColor('blue10')};
        color: ${getColor('charcoal')};
      }
    }

    &-red {
      background: ${getColor('white')};
      border: 2px solid ${getColor('red')};
      color: ${getColor('red')};

      .icon {
        background: ${getColor('red')};
      }

      &.active {
        background: ${getColor('red')};
        color: ${getColor('white')};
      }
    }

    &-gold {
      background: ${getColor('gold10')};
      color: ${getColor('charcoal')};

      .icon {
        background: ${getColor('gold10')};
      }

      &.active {
        background: ${getColor('gold10')};
        color: ${getColor('charcoal')};
      }
    }

    &.disabled {
      opacity: 0.7;
    }
  }
`;
