import React from 'react';
import { IAccountItem, IThirdParty } from 'store/user/accounts/accounts.types';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { IAccountGroup } from 'utils/helpers/accounts/accountsHelper';
import { SInternalAccountGroup } from './InternalAccount.styles';
import { InternalAccount } from './InternalAccount';

type IInternalAccountsGroupProps = {
  group: IAccountGroup;
  onAccountSelect: (account: IAccountItem) => void;
  selectedAccount?: IAccountItem | IThirdParty | null;
  disableId?: string;
  isDesktop?: boolean;
  isFrom?: boolean;
  isAmountHidden?: boolean;
};

export const InternalAccountsGroup = ({ onAccountSelect, group, selectedAccount, disableId, isDesktop, isFrom, isAmountHidden }: IInternalAccountsGroupProps) => {
  const { pathname } = useLocation();
  const isTransfersPage = pathname === ROUTES.transfers.path;
  const cashAccount = group.cash;
  const secondaryAccounts = Object.values(group).filter((account) => account.type !== 'Cash');

  return (
    <SInternalAccountGroup className="internal-account-group" isDesktop={isDesktop}>
      {cashAccount && (
        <div className="primary">
          <InternalAccount
            account={cashAccount}
            onSelect={onAccountSelect}
            selected={selectedAccount === cashAccount}
            isDisabled={cashAccount.fiservAccountId === disableId}
            isDesktop={isDesktop}
            isFrom={isFrom}
            isAmountHidden={isAmountHidden}
            isArrowHidden={isTransfersPage}
          />
        </div>
      )}
      {secondaryAccounts.length > 0 && (
        <div className="secondary">
          {secondaryAccounts.map((account) => (
            <InternalAccount
              account={account}
              key={account.fiservAccountId}
              onSelect={onAccountSelect}
              selected={selectedAccount === account}
              isDisabled={account.fiservAccountId === disableId}
              isDesktop={isDesktop}
              isFrom={isFrom}
              isAmountHidden={isAmountHidden}
              isArrowHidden={isTransfersPage}
            />
          ))}
        </div>
      )}
    </SInternalAccountGroup>
  );
};
