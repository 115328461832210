import React from 'react';
import { Button } from 'components/theme/Button/Button';
import { BodyText, Title } from 'components/general/Typography';
import { Form } from 'antd';
import { IVerifyEmailForm } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { Loader } from 'components/general/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { IRequestResult } from 'utils/helpers/request';
import { MyInfoVerifyEmailForm } from 'views/OpenCashAccount/MyInfo/EmailPage/EmailForm/EmailForm';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SLayout } from './EditEmailPage.styles';

interface IEditEmailPageProps {
  getOnboardingDataIsLoading: boolean | undefined;
  setIsSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (values: IVerifyEmailForm) => void;
  isDisabled: boolean | undefined;
  generateCodeEmailStatus: IRequestResult | undefined;
  email: string;
  setIsEmailChanged: (isChanged: boolean) => void;
}
export const EditEmailPage: React.FC<IEditEmailPageProps> = ({ getOnboardingDataIsLoading, setIsSubmitDisabled, handleSubmit, isDisabled, generateCodeEmailStatus, email, setIsEmailChanged }) => {
  const [form] = Form.useForm();
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation('cashAccountOpening');
  const initialValues = { email };

  return (
    <>
      <WebHeader showExit={false} title={t('accountOpening.GoBackToAccountInformation')} />
      <SLayout>
        <div className="content-container">
          <div className="title-container">
            <Title size={isDesktopSize ? 'S' : 'M'}>{t('myInfo.Email')}</Title>
            <BodyText textType="bodyText" color="charcoal60" size="N" fontWeight="R" marginBottom={32} lineHeight={1.5}>
              {t('myInfo.ChangeYourEmailHere')}
            </BodyText>
          </div>

          {getOnboardingDataIsLoading ? (
            <Loader />
          ) : (
            <div className="form-card">
              <MyInfoVerifyEmailForm setIsDisabled={setIsSubmitDisabled} form={form} handleSubmit={handleSubmit} initialValues={initialValues} setIsEmailChanged={setIsEmailChanged} isEditMode />
            </div>
          )}
          {generateCodeEmailStatus?.isError && (
            <BodyText textType="bodyText" marginTop={30} color="red" size="N" fontWeight="R">
              {generateCodeEmailStatus?.errorMessage}
            </BodyText>
          )}
        </div>

        <Footer isDisabled={isDisabled} onClick={form.submit} absolutePosition secondBtnText={t('myInfo.SaveChanges')} />

        <div className="mobile-footer">
          <Button size="large" disabled={isDisabled} onClick={form.submit} preset="primary">
            {t('myInfo.SaveChanges')}
          </Button>
        </div>
      </SLayout>
    </>
  );
};
