import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'components/general/Icon/Icon';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { useAppDispatch } from 'utils/hooks/store';
import { setShowCashOpeningExitModal } from 'store/ui.slice';
import { SDivider, SWebHeader } from './WebHeader.styles';

interface IWebHeader {
  showExit?: boolean;
  showBack?: boolean;
  title?: string;
}

export const WebHeader: React.FC<IWebHeader> = ({ showExit = true, showBack = true, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onBack = () => {
    navigate(-1);
  };

  const onExit = () => {
    dispatch(setShowCashOpeningExitModal(true));
  };

  return (
    <>
      <SWebHeader showExit={showExit}>
        <div className="title-container">
          {showBack && <Icon name="arrowLeft" color="blue" size="normal" onClick={onBack} cursorPointer />}
          <Title size="S" fontWeight="SB">
            {title || t('header.Cash Account Opening')}
          </Title>
        </div>

        {showExit && (
          <div className="exit-container">
            <Button onClick={onExit}>{t('header.Exit Cash Account Opening')}</Button>
          </div>
        )}
      </SWebHeader>
      <SDivider />
    </>
  );
};
