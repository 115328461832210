import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, generatePath, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { useFAQ } from 'utils/hooks/useFAQ';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useNavBack } from 'utils/hooks/useNavBack';
import { formatPhone } from 'utils/helpers/phone';
import { selectSystemProperties } from 'store/user/authentication.slice';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { Loader } from 'components/general/Loader/Loader';
import { FAQGroup } from 'components/general/FAQ/FAQGroup/FAQGroup';
import { Question } from 'components/general/FAQ/Question/Question';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { IFAQGroup, IFAQIntent } from 'views/Wellness/HelpAndSupport/FAQ.types';
import { SFAQContent, SHelpAndSupportPage, SSearch, SDesktopSearch, SLink } from './HelpAndSupport.styles';

interface IPageState {
  isFAQFromHomePage?: boolean;
  isFromPercPlaysInfoModal?: boolean;
}

export const HelpAndSupport = () => {
  const { navigate } = useEnhancedNav();
  const { setBackUrl } = useNavBack();
  const { generateUrl } = useEnhancedNav();
  const params = useParams();
  const [searchStr, setSearchStr] = useState('');
  const [searchResults, setSearchResults] = useState<IFAQIntent[]>([]);
  const { isFAQFromHomePage, isFromPercPlaysInfoModal } = (useLocation().state as IPageState) || {};
  const { t } = useTranslation('helpSupport');
  const { isDesktopSize } = useDeviceDimension();
  const { groups, isLoading, activeGroup, activeGroupIntents, allQuestions } = useFAQ({ activeGroupId: params.groupId });
  const shouldShowGroups = !searchStr && !params.groupId;
  const shouldShowQuestions = !searchStr && params.groupId && !params.questionName;
  const shouldShowAnswer = !searchStr && params.groupId && params.questionName;
  const selectedQuestion = useMemo(() => activeGroupIntents.find((intent: IFAQIntent) => intent.IntentName === params.questionName), [params, activeGroupIntents]);
  const { supportPhoneNumber } = useSelector(selectSystemProperties);
  const supportTelVal = useMemo(() => `tel:${formatPhone(supportPhoneNumber)}`, [supportPhoneNumber]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(event.target?.value || '');
  };

  useEffect(() => {
    setSearchResults(
      searchStr?.trim()
        ? allQuestions.filter((intent: IFAQIntent) => intent.Questions.some((question) => question.QuestionText.trim().toLocaleLowerCase().includes(searchStr?.trim().toLowerCase())))
        : allQuestions
    );
  }, [searchStr]);

  const crumbs = useMemo(
    () =>
      activeGroup
        ? [
            {
              name: t('helpSupport.FrequentlyAskedQuestions'),
              url: generatePath(ROUTES.helpAndSupport.path, {}),
            },
            {
              name: activeGroup.Name,
              url: generatePath(ROUTES.helpAndSupport.path, {
                groupId: activeGroup.Id,
              }),
            },
          ]
        : [],
    [activeGroup]
  );

  const handleClickGroup = (groupId: string) => {
    navigate(ROUTES.helpAndSupport.path, { backUrl: 'current', params: { groupId } });
  };

  const handleClickIntent = (intentName: string) => {
    if (!isDesktopSize) {
      navigate(ROUTES.helpAndSupport.path, {
        backUrl: 'current',
        params: { groupId: params.groupId, questionName: intentName },
      });
    }
  };

  const handleOnSearch = () => {
    navigate(generatePath(ROUTES.helpAndSupportSearch.path));
  };

  const memoizedActiveGroupQuestions = useMemo(
    () =>
      activeGroupIntents.map((intent: IFAQIntent) => (
        <Question
          answerText={intent.CorrectAnswerText}
          intentName={intent.IntentName}
          key={intent.IntentName}
          text={intent.Questions[0].QuestionText}
          onClick={handleClickIntent}
          isCollapsible={isDesktopSize}
        />
      )),
    [activeGroupIntents, isDesktopSize]
  );

  const memoizedGroupQuestions = useMemo(
    () => groups?.map((group: IFAQGroup) => <FAQGroup id={group.Id} title={group.Name} key={group.Id} intentsCount={group.Intents?.length} onClick={handleClickGroup} />),
    [groups]
  );

  const searchBlock = useMemo(
    () =>
      isDesktopSize ? (
        <SDesktopSearch value={searchStr} placeholder={t('helpSupport.TypeIn')} onChange={handleSearchChange} suffix="search" suffixColor="charcoal70" className="desktop=search" />
      ) : (
        <SSearch className="help-support-search" onClick={handleOnSearch}>
          <BodyText textType="bodyText" color="blue" size="N" fontWeight="B" cursorPointer nowrap>
            {t('helpSupport.Search')}
          </BodyText>

          <Icon name="search" size="small" color="blue" />
        </SSearch>
      ),
    [handleOnSearch, isDesktopSize]
  );

  useEffect(() => {
    if (shouldShowGroups) {
      setBackUrl(ROUTES.mainMenu.path);
    }
    if (shouldShowQuestions) {
      setBackUrl(generatePath(ROUTES.helpAndSupport.path, {}));
    }
    if (shouldShowAnswer) {
      setBackUrl(generatePath(ROUTES.helpAndSupport.path, { groupId: params.groupId }));
    }
    if (isFromPercPlaysInfoModal) {
      setBackUrl(generateUrl(ROUTES.home.path, { searchParams: { isFromPercPlaysInfoModal } }));
    }
  }, [params]);

  return (
    <SHelpAndSupportPage className="help-and-support-page">
      {isLoading && <Loader />}
      {!isFAQFromHomePage && (
        <PageHeader
          title={shouldShowQuestions && activeGroup ? activeGroup.Name : t('helpSupport.FrequentlyAskedQuestions')}
          rightPart={searchStr || shouldShowGroups ? searchBlock : null}
          crumbs={crumbs}
          hasNavBack={!!activeGroup}
        />
      )}
      <SFAQContent className="help-and-support-page__content">
        {shouldShowGroups && memoizedGroupQuestions}
        {shouldShowQuestions && memoizedActiveGroupQuestions}
        {shouldShowAnswer && selectedQuestion && (
          <Question intentName={selectedQuestion.IntentName} answerText={selectedQuestion.CorrectAnswerText} isAlwaysOpen text={selectedQuestion.Questions[0].QuestionText} />
        )}
        {searchStr &&
          (searchResults?.length > 0 ? (
            searchResults.map((item: IFAQIntent) => (
              <Question answerText={item.CorrectAnswerText} key={item.IntentName} text={item.Questions[0].QuestionText} intentName={item.IntentName} isCollapsible={isDesktopSize} />
            ))
          ) : (
            <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal" marginTop={32}>
              {t('helpSupport.NoResultPart1')}
              <SLink href={supportTelVal}>{t('helpSupport.here')}</SLink>
              {t('helpSupport.NoResultPart2')}
            </BodyText>
          ))}
      </SFAQContent>
      <PageFooter />
    </SHelpAndSupportPage>
  );
};
