import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { useConsents } from 'utils/hooks/useConsents';
import { useToggle } from 'utils/hooks/useToggle';
import { shortenLinks } from 'utils/helpers/urlString/urlString';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { IConsentData } from 'store/user/consents/consents.types';
import { SendViaEmailSheet } from './SendViaEmailSheet';
import { SButton, SCustomSheet } from './Consent.styles';
import { ConsentModal } from './ConsentModal';

interface IConsentSheetProps {
  consentData: IConsentData;
  flowName?: string;
  isOpen: boolean;
  isReadonly?: boolean;
  onClose: () => void;
  onAccepted?: () => void;
  width?: string;
  height?: string;
  isSheet?: boolean;
  isSendEmail?: boolean;
}

export const ConsentSheet: React.FC<IConsentSheetProps> = ({
  consentData,
  flowName,
  isOpen,
  onClose,
  onAccepted,
  isReadonly = false,
  width = '100%',
  height = '90%',
  isSheet = true,
  isSendEmail = true,
}) => {
  const { acceptConsent, acceptConsentResult } = useConsents();
  const { t } = useTranslation('cashAccount');

  const sendToEmailSheet = useToggle();
  const consentCheck = useToggle();
  const isAcceptBtnDisabled = (consentData?.acceptCheckBoxText && !consentCheck.isActive) || acceptConsentResult?.isLoading;
  const shouldShowFooter = !isReadonly && isOpen;
  const consentId = consentData?.id || consentData?.disclaimerId || '';

  const SheetSendEmail = (
    <BodyText
      display="inline-flex"
      textType="bodyText"
      fontWeight="R"
      size="T"
      font="DM Sans"
      color="charcoal"
      marginTop={25}
      onClick={sendToEmailSheet.show}
      icon={<Icon name="sendByEmail" color="blue" />}
      cursorPointer
    >
      {t('cashAccount.Send statement by email')}
    </BodyText>
  );

  const handleAcceptBtn = () => {
    if (!consentData || !flowName) {
      return;
    }
    acceptConsent?.(consentId, flowName);
  };

  const SheetFooter = shouldShowFooter && (
    <div className="consent-sheet-footer">
      {consentData?.acceptCheckBoxText && (
        <Checkbox id={`eConsent-checkbox-${consentId}`} checked={consentCheck.isActive} onChange={() => consentCheck.toggle()} disabled={consentData?.accepted}>
          {consentData?.acceptCheckBoxText}
        </Checkbox>
      )}
      {consentData?.acceptButtonText && (
        <SButton preset="outline" size="large" disabled={isAcceptBtnDisabled} onClick={handleAcceptBtn}>
          {consentData?.acceptButtonText}
        </SButton>
      )}
    </div>
  );

  useEffect(() => {
    consentCheck.toggle(consentData?.accepted);
  }, [consentData?.accepted]);

  useEffect(() => {
    if (acceptConsentResult.isSuccess) {
      onClose();
      consentCheck.toggle(consentData?.accepted);
      onAccepted?.();
    }
  }, [acceptConsentResult]);

  return (
    <>
      {isSheet ? (
        <SCustomSheet
          height={height}
          width={width}
          title={consentData?.name}
          isOpen={isOpen}
          onClose={onClose}
          subtitle={consentData?.subTitle || ''}
          footer={SheetFooter}
          titleExtra={isSendEmail ? SheetSendEmail : null}
          className="consent-sheet"
          modalName="consent"
        >
          <div dangerouslySetInnerHTML={{ __html: isOpen ? shortenLinks(consentData?.text, 35) : '' }} />
        </SCustomSheet>
      ) : (
        <ConsentModal zIndex={99999} height={height} width="648px" open={isOpen} title={consentData?.name} footer={SheetFooter} consentId={consentId} onClose={onClose} showSendViaEmail={isSendEmail}>
          <div dangerouslySetInnerHTML={{ __html: isOpen ? shortenLinks(consentData?.text, 35) : '' }} />
        </ConsentModal>
      )}

      <SendViaEmailSheet isOpen={sendToEmailSheet.isActive} onClose={sendToEmailSheet.hide} consentId={consentId} consentTitle={consentData?.name} />
    </>
  );
};
