import React from 'react';
import { RadioButton } from 'components/general/RadioButton/RadioButton';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';

interface IFeedbackItem {
  label: string;
  value: string;
  onChange: (value: string) => void;
  selected: boolean;
}

export const FeedbackItem: React.FC<IFeedbackItem> = ({ label, value, onChange, selected }) => {
  const handleRadioChange = () => {
    onChange(value);
  };
  const { t } = useTranslation('accountInformation');

  return (
    <CustomRow justifyContent="flex-start">
      <RadioButton onChange={handleRadioChange} checked={selected}>
        <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="N" fontWeight={selected ? 'B' : 'R'} cursorPointer>
          {t(label)}
        </BodyText>
      </RadioButton>
    </CustomRow>
  );
};
