import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { images } from 'assets';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'utils/hooks/useToggle';
import { ConsentId, ConsentType } from 'components/general/Consent/Consents.types';
import { ROUTES } from 'vars/const/ROUTES';
import { useConsents } from 'utils/hooks/useConsents';
import { IConsentData } from 'store/user/consents/consents.types';
import { Button } from 'components/theme/Button/Button';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { BodyText, Title } from 'components/general/Typography';
import { Loader } from 'components/general/Loader/Loader';
import { useProperties } from 'utils/hooks/useProperties';
import {
  useAcceptJointAccountHolderMutation,
  useCheckInviteIdQuery,
  useDeclineJointAccountHolderMutation,
  useHideJointAccountHolderMutation,
  useJointAccountHolderInvitesQuery,
} from 'store/user/accounts/accounts.api';
import { useUserStatus } from 'utils/hooks/useUserStatus';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { ListItem } from './ListItem/ListItem';
import { SAcceptInvitePage, SArrowRight, SButtonContentWrapper, SSubList } from './AcceptInvite.styles';
import { ResponseModal } from './ResponseModal/ResponseModal';
import { InviteFooter } from './InviteFooter/InviteFooter';

export const AcceptInvite = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('prepPage');
  const { inviteId } = useParams();
  const { isDesktopSize, isTabletSize, isMobileSize } = useDeviceDimension();
  const { isClient, isProspect } = useUserStatus();
  const consentSheetToggler = useToggle();
  const responseToggler = useToggle();
  const [responseIsSuccess, setResponseIsSuccess] = useState(false);
  const [holderInviteId, setHolderInviteId] = useState('');
  const otherConsents = useConsents(ConsentType.JOINT_ACCOUNT_HOLDER);
  const { setProperty } = useProperties();
  const consentData = otherConsents?.consentsData?.find((item) => item?.id === ConsentId.JOINT_ACCOUNT_DISCLAIMER) ?? ({} as IConsentData);
  const { saveOnboardingData, isLoading: onboardingDataLoading } = useCashAccountOpening();

  const { data: inviteIdStatus } = useCheckInviteIdQuery(inviteId || '');
  const invites = useJointAccountHolderInvitesQuery(inviteId || '');
  const [declineJointAccountHolderAPI] = useDeclineJointAccountHolderMutation();
  const [acceptJointAccountHolderAPI, acceptJointAccountHolderAPIResult] = useAcceptJointAccountHolderMutation();
  const [hideJointAccountHolder] = useHideJointAccountHolderMutation();
  const isLoading = acceptJointAccountHolderAPIResult.isLoading || onboardingDataLoading;

  const pageTitle = isProspect
    ? `${t('prepPage.BeenInvited')} ${invites.currentData?.inviterFirstName || ''} ${t('prepPage.ToBeACoOwner')}`
    : `${invites.currentData?.inviterFirstName || ''} ${t(`prepPage.InvitedYou`)}`;

  const goHome = () => navigate(ROUTES.home.path);

  const handleAcceptJointAccountHolder = () => {
    acceptJointAccountHolderAPI({ inviteId: holderInviteId });
    hideJointAccountHolder(holderInviteId);

    if (isProspect) {
      navigate(ROUTES.openCashAccountCongrats.path, { state: { inviteId: holderInviteId, inviteIdStatus } });
    }
  };

  const handleLaterClick = () => {
    goHome();
  };

  const handleClose = () => {
    responseToggler.hide();
    goHome();
  };

  const handleDeclineClick = () => {
    declineJointAccountHolderAPI(holderInviteId);
    hideJointAccountHolder(holderInviteId);
    goHome();
  };

  const handleButtonClick = async () => {
    if (isClient) {
      hideJointAccountHolder(holderInviteId);
      consentSheetToggler.show();
    }

    if (isProspect) {
      await setProperty({
        propertyName: 'CAOType',
        value: 'Joint',
      });
      saveOnboardingData({ currentJointInviteId: inviteId || '' });
      navigate(ROUTES.myInfoDisclosures.path);
    }
  };

  const getTitleSize = useMemo(() => {
    switch (true) {
      case isMobileSize:
        return 'M';
      case isTabletSize:
        return 'L';
      default:
        return 'XXL';
    }
  }, [isMobileSize, isTabletSize]);

  useEffect(() => {
    setHolderInviteId(inviteId || invites.currentData?.id || '');
  }, [inviteId, invites.currentData?.id]);

  useEffect(() => {
    if (acceptJointAccountHolderAPIResult.isError || acceptJointAccountHolderAPIResult.isSuccess) {
      setResponseIsSuccess(acceptJointAccountHolderAPIResult.isSuccess);
      responseToggler.show();
    }
  }, [acceptJointAccountHolderAPIResult.isError, acceptJointAccountHolderAPIResult.isSuccess]);

  if (invites.isFetching) return <Loader />;

  return (
    <SAcceptInvitePage>
      <div className="container">
        {isLoading && <Loader />}
        <div className="hero">
          <div className="hero-text">
            <Title font="Poppins" size={getTitleSize} color="charcoal" fontWeight="M" lineHeight={1.1}>
              {pageTitle}
            </Title>
            <BodyText font="DM Sans" textType="bodyText" color={isDesktopSize ? 'charcoal' : 'charcoal70'} fontWeight="R" size={isDesktopSize ? 'L' : 'M'}>
              {isProspect ? t('prepPage.LessThanTenMinutes') : t(`prepPage.LessThanMinute`)}
            </BodyText>
          </div>
          <img src={images.sharedAccount} alt="sharedAccount" className="hero-image" />
        </div>

        <div className="bottom-content">
          <BodyText font="DM Sans" textType="bodyText" fontWeight="SB" size="L" color="charcoal" marginBottom={18}>
            {isProspect ? t(`prepPage.NeedToBeCoowner`) : t(`prepPage.AsAJointAccountholderYouWill`)}
          </BodyText>

          {isProspect ? (
            <>
              <ListItem size="N" circleFilled={!isDesktopSize} text={t(`prepPage.ValidPhotoId`)} IconName="myInfo" marginTop={16} />
              <SSubList>
                <ul className="listitem">
                  <li>
                    <BodyText marginLeft={16} lineHeight={1.6} size="N" fontWeight="R" textType="bodyText" color="charcoal70">
                      {t(`prepPage.ValidPhotoListItem1`)}
                    </BodyText>
                  </li>
                  <li>
                    <BodyText marginLeft={16} lineHeight={1.6} size="N" fontWeight="R" textType="bodyText" color="charcoal70">
                      {t(`prepPage.ValidPhotoListItem2`)}
                    </BodyText>
                  </li>
                </ul>
              </SSubList>
              <ListItem circleFilled={!isDesktopSize} size="N" text={t(`prepPage.YourNameAndBirth`)} IconName="idFront" marginTop={16} />
              <ListItem circleFilled={!isDesktopSize} size="N" text={t(`prepPage.YourHomeAddress`)} IconName="rent" marginTop={16} />
              <ListItem circleFilled={!isDesktopSize} size="N" text={t(`prepPage.YourEmail`)} IconName="mail2" marginTop={16} />
              <ListItem circleFilled={!isDesktopSize} size="N" text={t(`prepPage.YounSSN`)} IconName="idBack" marginTop={16} />
            </>
          ) : (
            <>
              <ListItem circleFilled={!isDesktopSize} size="N" text={t(`prepPage.BeCoOwnerOfCashAccount`)} IconName="refer" marginTop={16} />
              <ListItem circleFilled={!isDesktopSize} size="N" text={t(`prepPage.HaveYourOwnDebitCard`)} IconName="creditCard" marginTop={16} />
              <ListItem circleFilled={!isDesktopSize} size="N" text={t(`prepPage.BeCoOwnerOfNeedsGoals`)} IconName="cash" marginTop={16} />
            </>
          )}

          {isProspect && (
            <BodyText font="DM Sans" textType="bodyText" fontWeight="B" size="N" color="charcoal" marginBottom={24} marginTop={41} paddingRight={5} lineHeight={1.4}>
              {t(`prepPage.WellReviewYourPersonalInformationYouNeedToTakePictureOfYourIdAndSelfie`)}
            </BodyText>
          )}

          <BodyText font="DM Sans" textType="bodyText" fontWeight="R" size="N" color="charcoal70" marginBottom={40} marginTop={30} lineHeight={1.4}>
            {t(`prepPage.PercapitaGroupInc`)}
          </BodyText>

          {isDesktopSize ? (
            <InviteFooter isProspect={isProspect} isClient={isClient} onDecline={handleDeclineClick} onJoinLater={handleLaterClick} onContinue={handleButtonClick} />
          ) : (
            <div className="buttons-wrapper">
              {isClient && (
                <BodyText
                  className="btn-decline"
                  font="Poppins"
                  marginBottom={12}
                  marginTop={12}
                  textType="bodyText"
                  fontWeight="M"
                  size="L"
                  color="blue70"
                  onClick={handleDeclineClick}
                  justifyContent="center"
                >
                  {t(`prepPage.Decline`)}
                </BodyText>
              )}
              <div className="buttons-right">
                {isClient && (
                  <Button onClick={handleLaterClick}>
                    <SButtonContentWrapper>{t(`prepPage.JoinLater`)}</SButtonContentWrapper>
                  </Button>
                )}
                <Button preset="primary" onClick={handleButtonClick}>
                  <SButtonContentWrapper>
                    {isProspect ? t(`prepPage.Continue`) : t(`prepPage.BeginOpening`)}
                    {isClient && <SArrowRight />}
                  </SButtonContentWrapper>
                </Button>
              </div>
            </div>
          )}
        </div>
        <ConsentSheet
          consentData={consentData}
          isOpen={consentSheetToggler.isActive}
          onClose={consentSheetToggler.hide}
          onAccepted={handleAcceptJointAccountHolder}
          width="648px"
          height="624px"
          isSheet={!isDesktopSize}
          flowName={consentData.disclaimerName}
        />
        <ResponseModal navPath={ROUTES.home.path} isSuccess={responseIsSuccess} inviteIdStatus={inviteIdStatus} open={responseToggler.isActive} onClose={handleClose} />
      </div>
    </SAcceptInvitePage>
  );
};
