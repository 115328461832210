import styled from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { flex } from 'utils/helpers/styleHelpers';

export const SCustomSheet = styled(CustomSheet)`
  .header {
    ${flex('row', 'flex-start')};
    margin-bottom: 8px;
  }

  .dialog-body-wrapper {
    .body {
      display: flex;
      flex-direction: column;

      form#selectCountryForm {
        display: flex;
        flex-direction: column;
        flex: 1;

        .form-item-countries-list-wrapper {
          flex: 1;

          .countries-list {
            overflow-y: scroll;
            height: 522px;
          }
        }
      }
    }
  }

  button.preset-primary {
    height: 44px;
    width: 100%;
  }
`;
