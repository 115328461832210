import React from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'utils/hooks/useAnalytics';
import { useAppDispatch } from 'utils/hooks/store';
import { selectZogoData } from 'store/user/zogo/zogo.slice';
import { setPreviousTab } from 'store/user/help.slice';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { setShowAdditionalInformationModal, selectAdditionalInformationModalType, setDisplayPercPlaysInfoModal } from 'store/ui.slice';
import { Button } from 'components/theme/Button/Button';
import { BodyText } from 'components/general/Typography';
import { usePercapitaPlayWarning } from 'utils/hooks/usePercapitaPlayWarning';
import { PercapitaPlayWarningModal } from 'components/general/Modals/PercapitaPlayWarningModal/PercapitaPlayWarningModal';
import { ROUTES } from 'vars/const/ROUTES';
import { Icon } from 'components/general/Icon/Icon';
import { IconSign } from 'components/general/Icon/IconSign';
import { Loader } from 'components/general/Loader/Loader';
import { useLazyPostAttuneResultQuery } from 'store/user/attune/attune.api';
import { IBigCard } from './BigCard.types';
import { SContainer } from './BigCard.styles';

export const BigCard: React.FC<IBigCard> = ({
  iconName,
  iconColor = 'charcoal70',
  bgColor,
  title,
  buttonText,
  description,
  boldText = false,
  tooltip = false,
  showPoints = false,
  handlePassedButtonClick,
  to = '',
  attuneQuizId = '',
  quizState = '',
  className = '',
}) => {
  const { t } = useTranslation('wellness');
  const { isDesktopSize } = useDeviceDimension();
  const dispatch = useAppDispatch();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const informationModalType = useSelector(selectAdditionalInformationModalType);
  const { primaryPoints } = useSelector(selectZogoData);
  const [postAttuneResult] = useLazyPostAttuneResultQuery();
  const { handleGoToPlay, handleCloseWarning, isWarningActive, isStatusLoading, handleContinue, isAcceptPending } = usePercapitaPlayWarning();
  const isWellnessPage = !!useMatch(ROUTES.wellness.path);
  const isWellnessDesktop = isDesktopSize && isWellnessPage;

  const handleButtonClick = () => {
    if (quizState === 'new') postAttuneResult({ id: attuneQuizId });
    if (to === ROUTES.wellness.path) dispatch(setPreviousTab('goals-and-tools'));
    if (to === ROUTES.playPercUp.path) {
      handleGoToPlay();
      return;
    }
    navigate(to, { state: { fromPage: pathname } });
  };

  const handleTooltipClick = () => {
    if (to === ROUTES.playPercUp.path) {
      dispatch(setDisplayPercPlaysInfoModal(true));
      track('navigate', '/home/playpercup/percapita-plays-info');
    } else
      dispatch(
        setShowAdditionalInformationModal({
          displayAdditionalInformationModal: true,
          additionalInformationModalType: informationModalType,
        })
      );
  };

  return (
    <SContainer className={className} isWellnessDesktop={isWellnessDesktop}>
      {isStatusLoading && <Loader />}
      <div>
        <div className="header">
          <div className="text-container">
            <div className="title-container">
              <div className="icon-text-container">
                <IconSign bgColor={bgColor} iconName={iconName} iconColor={iconColor} />
                {!isWellnessDesktop && (
                  <BodyText
                    textType="bodyText"
                    fontWeight={isDesktopSize ? 'B' : 'SB'}
                    color="charcoal"
                    size={isDesktopSize ? 'N' : 'L'}
                    font={isDesktopSize ? 'DM Sans' : 'Poppins'}
                    textAlign="start"
                    marginBottom={20}
                    marginTop={15}
                    marginRight={25}
                  >
                    {t(title)}
                  </BodyText>
                )}
                {tooltip && <Icon name="circleInfo" color="blue" size="smaller" onClick={handleTooltipClick} cursorPointer />}
              </div>
            </div>

            {showPoints && (
              <div className="web-points">
                <div className="percapita-points">
                  <div className="title">{t('learnPlay.Percapita Points')}</div>
                  <div className="points">{primaryPoints}</div>
                </div>
              </div>
            )}

            <div className="description">
              <BodyText textType="bodyText" color="charcoal70" size={isDesktopSize ? 'T' : 'N'} fontWeight="R" font="DM Sans" justifyContent="start">
                {description}
              </BodyText>{' '}
              {boldText ?? ''}
            </div>
          </div>
        </div>

        {showPoints && (
          <div className="mobile-points">
            <div className="percapita-points">
              <div className="title">{t('learnPlay.Percapita Points')}</div>
              <div className="points">{primaryPoints}</div>
            </div>
          </div>
        )}
      </div>

      <div className="button-container">
        <Button preset="primary" size="small" minWidth={20} onClick={handlePassedButtonClick === undefined ? handleButtonClick : handlePassedButtonClick}>
          {t(buttonText)}
        </Button>
      </div>

      {to === ROUTES.playPercUp.path && <PercapitaPlayWarningModal open={isWarningActive} onClose={handleCloseWarning} onContinue={handleContinue} isLoading={isAcceptPending} />}
    </SContainer>
  );
};
