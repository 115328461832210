import { SmsCodeForm } from 'components/general/SmsCodeForm/SmsCodeForm';
import { BodyText } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { matchRoutes, useLocation } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { SLayout, SButtonWrapper, SContinueBtn } from './EnterCodeBlock.styles';

interface IEnterCodeBlockProps {
  handleRememberDeviceChange?: (checked: boolean) => void;
  handleCompletion: (code: string) => void;
  handleResendCode: () => Promise<void>;
  handleSubmitCode: () => Promise<void>;
  isError?: boolean;
}

export const EnterCodeBlock: React.FC<IEnterCodeBlockProps> = ({ handleRememberDeviceChange, handleCompletion, handleResendCode, handleSubmitCode, isError }) => {
  const { t } = useTranslation(['verification', 'profile']);
  const { isDesktopSize } = useDeviceDimension();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const location = useLocation();
  const isRememberDeviceCheckbox = matchRoutes([ROUTES.registrationCode], location.pathname);

  const handleRememberDeviceClick = (event: React.ChangeEvent<HTMLInputElement>) => handleRememberDeviceChange?.(event.target.checked);

  return (
    <SLayout isDesktopSize={isDesktopSize}>
      <CustomCard marginTop={isDesktopSize ? 0 : 16} border={isDesktopSize ? '2px solid #E5E5E5' : 'none'}>
        <BodyText textType="bodyText" color="charcoal" justifyContent="center" size="N" fontWeight="R" marginBottom={25}>
          {t('verification.EnterCode')}
        </BodyText>
        <SmsCodeForm handleCompletion={handleCompletion} onCompletion={setIsSubmitDisabled} isWrongCode={isError} />

        {isDesktopSize && (
          <div className="footer">
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
              {t('verification.DidntReceive')}
            </BodyText>
            <BodyText textType="bodyText" color="blue" size="N" fontWeight="SB" font="Poppins" onClick={handleResendCode} cursorPointer>
              {t('verification.Resend')}
            </BodyText>
          </div>
        )}
      </CustomCard>

      {isError && (
        <BodyText textType="bodyText" size="N" fontWeight="R" font="Poppins" color="red" justifyContent="center" marginTop={12}>
          {t('profile.InvalidCode', { ns: 'profile' })}
        </BodyText>
      )}

      {isDesktopSize && isRememberDeviceCheckbox && (
        <Checkbox className="remember-this-device" onChange={handleRememberDeviceClick}>
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
            {t('verification.RememberThisDevice')}
          </BodyText>
        </Checkbox>
      )}

      <SButtonWrapper marginTop={isRememberDeviceCheckbox ? 24 : 32}>
        <SContinueBtn preset="primary" type="submit" onClick={handleSubmitCode} disabled={isSubmitDisabled}>
          {t('verification.Continue')}
        </SContinueBtn>
      </SButtonWrapper>

      {!isDesktopSize && (
        <div className="footer">
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
            {t('verification.DidntReceive')}
          </BodyText>
          <BodyText textType="bodyText" color="blue" size="N" fontWeight="M" onClick={handleResendCode} cursorPointer>
            {t('verification.Resend')}
          </BodyText>
        </div>
      )}
    </SLayout>
  );
};
