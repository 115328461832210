import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';

interface INotInterestedModalProps {
  onRemindLater: () => void;
  onHide: () => void;
  open: boolean;
}
export const NotInterestedModal: React.FC<INotInterestedModalProps> = ({ onHide, onRemindLater, open }) => {
  const { t } = useTranslation('rightTimeNotification', { keyPrefix: 'notInterestedModal' });
  return (
    <CustomModal open={open} modalName="notificationNotInterested" centered footer={null} closable={false}>
      <Title font="Poppins" color="charcoal" fontWeight="M" size="T" marginBottom={24} lineHeight={1.6}>
        {t('Title')}
      </Title>
      <Button preset="primary" onClick={onRemindLater} size="middleAlt" marginBottom={16}>
        {t('ButtonLater')}
      </Button>
      <Button onClick={onHide} size="middleAlt">
        {t('ButtonHide')}
      </Button>
    </CustomModal>
  );
};
