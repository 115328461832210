import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import React from 'react';
import { useNavigate } from 'react-router';
import { useDeleteThirdPartyAccountMutation } from 'store/user/accounts/accounts.api';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { Loader } from 'components/general/Loader/Loader';
import { ROUTES } from 'vars/const/ROUTES';

interface IRemoveExternalAccountModal {
  open: boolean;
  onClose: () => void;
  accountId?: string;
  handleOpenEditModal: () => void;
}

export const RemoveExternalAccountModal = ({ open, onClose, handleOpenEditModal, accountId = '' }: IRemoveExternalAccountModal) => {
  const { t } = useTranslation('accountInformation');
  const navigate = useNavigate();
  const [deleteAccount, deleteAccountResult] = useDeleteThirdPartyAccountMutation();

  const handleRemove = () => {
    if (accountId) {
      deleteAccount(accountId)
        .unwrap()
        .then(() => {
          onClose();
          navigate(ROUTES.accountInformation.path);
        });
    }
  };

  const handleKeep = () => {
    onClose();
    handleOpenEditModal();
  };

  return (
    <CustomModal open={open} onClose={onClose} modalName="removeExternalAccountModal" topPosition="-2%" closeIconColor="charcoal70">
      {deleteAccountResult.isLoading && <Loader />}

      <Title size="M" fontWeight="M" marginBottom={16}>
        {t('accountInformation.SureWantToRemove')}
      </Title>

      <Button preset="primary" size="xl" onClick={handleRemove} marginTop={30}>
        {t('accountInformation.YesRemove')}
      </Button>
      <Button preset="outline" size="xl" onClick={handleKeep} marginTop={16}>
        {t('accountInformation.NoKeep')}
      </Button>
    </CustomModal>
  );
};
