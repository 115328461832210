import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import isNumeric from 'antd/es/_util/isNumeric';
import { Trans, useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import { Loader } from 'components/general/Loader/Loader';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { useToggle } from 'utils/hooks/useToggle';
import { useAccounts } from 'utils/hooks/useAccounts';
import { findAccountById, getAccountDisplayName, getAccountDisplayNumber, getTransferType } from 'utils/helpers/accounts/accountsHelper';
import { IAccountItem, isThirdPartyAccount, IThirdParty } from 'store/user/accounts/accounts.types';
import { useLazyGetThirdPartyDataQuery } from 'store/user/accounts/accounts.api';
import { useGetTransactionLimitsQuery } from 'store/user/users.api';
import { selectPolicies, selectTransactionLimits, selectUserLanguage } from 'store/user/authentication.slice';
import { setCurrentTransfer } from 'store/user/accounts/accounts.slice';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { AmountInputComponent } from 'components/general/AmountInput/AmountInputComponent';
import { format } from 'date-fns';
import { Icon } from 'components/general/Icon/Icon';
import { TextButton } from 'components/general/TextButton/TextButton';
import { ExternalTransferSuccessSheet } from 'components/general/Modals/Transfer/ExternalTransfer/ExternalTransferSuccessSheet';
import { InternalTransferConfirmSheet } from 'components/general/Modals/Transfer/InternalTransfer/InternalTransferConfirmSheet/InternalTransferConfirmSheet';
import { InternalTransferSuccessSheet } from 'components/general/Modals/Transfer/InternalTransfer/InternalTransferSuccessSheet';
import { AccountSelect } from 'components/general/Accounts/AccountSelect/AccountSelect';
import { ManageExternalSheet } from 'components/general/Accounts/External/ManageExternalAccount/ManageExternalSheet';
import { Title, BodyText } from 'components/general/Typography';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { AmountInfoModal } from 'views/MoveMoney/Transfers/InfoModal/AmountInfoModal';
import { TransferDataRow } from 'components/general/Modals/Transfer/ExternalTransfer/TransferDataRow/TransferDataRow';
import { SLink } from 'views/HelpAndSupport/HelpAndSupport.styles';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { useTransfers } from 'utils/hooks/useTransfers';
import { initialValueValidator, searchValidator } from 'utils/helpers/validationRules';
import { TAccountSourceType } from 'vars/types/accounts.types';
import { lsGetItem } from 'utils/helpers/storage';
import { SInternalTransferPage, SPopupContainer, SWebHeader } from './TransferPage.styles';
import { AddNoteSheet } from './AddNoteSheet';
import { ExternalTransferConfirmSheet } from './ExternalTransferConfirmSheet';
import { TransferErrorModal } from './TransferErrorModal';

export const INGO_LIMIT_ERRORS_CODE = [603, 711, 712, 713, 714, 715, 716];
export const INGO_DECLINE_ERRORS_CODE = [710, 851, 852, 853, 854, 856, 857, 858, 859, 860, 862, 863, 864, 865];

export const TransferPage = () => {
  const { currentTransfer, saveCurrentTransfer, clearCurrentTransfer } = useTransfers();
  const isFirstRender = useRef(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileApp = lsGetItem('isMobileApp');
  const { t } = useTranslation(['moveMoney', 'translations']);
  const [searchParams] = useSearchParams();
  const toSelectPreopen = (searchParams.get('open-to') as TAccountSourceType) || undefined;
  const fromSelectPreopen = (searchParams.get('open-from') as TAccountSourceType) || undefined;
  const userLanguage = useSelector(selectUserLanguage);
  const [getThirdPartyData] = useLazyGetThirdPartyDataQuery();
  const transferLimitsQuery = useGetTransactionLimitsQuery();
  const policies = useSelector(selectPolicies);
  const limitsConfig = useSelector(selectTransactionLimits);
  const { internalAccountsGroups, internalAccounts, externalAccounts, isLoading: areAccountsLoading, refetchInternal, refetchExternal } = useAccounts();
  const isLoading = areAccountsLoading || transferLimitsQuery.isLoading;
  const allAccounts = [...internalAccounts, ...externalAccounts];
  const { preSelectedAccountType } = useLocation().state ?? {};
  const defaultCashAccount = internalAccounts.find((accountItem) => accountItem.isDefault);
  const hasSingleInternalAccount = internalAccounts.length === 1;
  const [isAmountEditable, setAmountEditable] = useState<boolean>(true);

  const externalTransferConfirmModal = useToggle();
  const internalTransferConfirmSheet = useToggle();
  const internalTransferSuccessSheet = useToggle();
  const whenAvailableModal = useToggle();
  const manageExternalSheet = useToggle();
  const infoModal = useToggle();
  const disclosureSheet = useToggle();
  const addNoteSheet = useToggle();
  const transferErrorModal = useToggle(false, { message: '', title: '', isIngoError: false } as { message: string | React.ReactElement; title: string; isIngoError: boolean });
  const transferSuccessModal = useToggle();

  const [form] = Form.useForm();
  const [fromId, setFromId] = useState(defaultCashAccount?.fiservAccountId ?? '');
  const [toId, setToId] = useState('');

  const fromAccount = useMemo(() => findAccountById(allAccounts, fromId), [fromId, allAccounts]);
  const toAccount = useMemo(() => findAccountById(allAccounts, toId), [toId, allAccounts]);
  const fromName = fromAccount && getAccountDisplayName(fromAccount);
  const toName = toAccount && getAccountDisplayName(toAccount);
  const fromNumber = fromAccount && getAccountDisplayNumber(fromAccount);
  const toNumber = toAccount && getAccountDisplayNumber(toAccount);
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [isAmountTouched, setIsAmountTouched] = useState(false);
  const { currentWindowSize, fromDesktopSmall } = useDeviceDimension();
  const isDesktopSize = currentWindowSize >= 577;
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const [isNoteInputVisible, setIsNoteInputVisible] = useState(true);
  const isContinueDisabled = !fromAccount || !toAccount || !isAmountValid;
  const isFromExternalAccount = !!fromAccount && isThirdPartyAccount(fromAccount);
  const isFromInternalAccount = !!fromAccount && !isFromExternalAccount;
  const isFromCompanion = isFromInternalAccount && (fromAccount.isGoalsType || fromAccount.isNeedsType);
  const isToExternalAccount = !!toAccount && isThirdPartyAccount(toAccount);
  const isExternalTransfer = isFromExternalAccount || isToExternalAccount;
  const isInternalTransfer = !!fromAccount && !!toAccount && !isExternalTransfer;
  const dateStr = format(new Date(), 'MMM dd, yyyy');

  const externalAccount = (isToExternalAccount ? toAccount : fromAccount) as IThirdParty;
  const isExternalAccountDebit = (externalAccount as IThirdParty)?.thirdPartyAccountSubType === 'Debit';

  const validateAmount = async () => {
    try {
      await form.validateFields(['amount']);
      setIsAmountValid(true);
    } catch (err) {
      setIsAmountValid(false);
    }
  };

  const limits = useMemo(() => {
    const currentLimits = limitsConfig?.[getTransferType(fromAccount, toAccount)] ?? {};
    console.log('current limits', currentLimits);

    if (isFromExternalAccount && fromAccount?.transactionsCount === 0 && currentLimits.first) {
      return {
        ...currentLimits,
        maxFinal: currentLimits.first,
        max: currentLimits.first,
      };
    }

    return {
      ...currentLimits,
      maxFinal: isFromInternalAccount ? Math.min(fromAccount?.balance, currentLimits?.max ?? Infinity) : currentLimits.max,
    };
  }, [limitsConfig, fromAccount, toAccount, isFromExternalAccount, isFromInternalAccount]);
  const hasAllLimits = limits && limits.max && limits.daily && limits.weekly && limits.monthly;
  const amountBottomText = useMemo(
    () =>
      limits.min && limits.max
        ? t('transferPage.AmountBetween', {
            max: limits.max && new Intl.NumberFormat().format(limits.max),
            min: limits.min && new Intl.NumberFormat().format(limits.min),
            daily: limits.daily && new Intl.NumberFormat().format(limits.daily),
            weekly: limits.weekly && new Intl.NumberFormat().format(limits.weekly),
            monthly: limits.monthly && new Intl.NumberFormat().format(limits.monthly),
          })
        : '',
    [limits]
  );

  const openConsent = () => {
    transferErrorModal.hide();
    disclosureSheet.show();
  };

  const handleAddNewAccountTo = () => {
    saveCurrentTransfer({ amount, fromId: 'WAITING_TO_ADD', toId });
  };

  const handleAddNewAccountFrom = () => {
    saveCurrentTransfer({ amount, fromId: 'WAITING_TO_ADD', toId });
  };

  const handleAmountFieldChange = async (value: string) => {
    form.setFieldsValue({ amount: value });
    setAmount(value);
    setIsAmountTouched(true);
  };

  const handleTransferError = (error: any) => {
    if (INGO_DECLINE_ERRORS_CODE.includes(error?.data?.Data?.status)) {
      transferErrorModal.setData({
        message: t(`ThisTransactionWasDeclined`),
        title: t(`TransactionDeclined`),
        isIngoError: true,
      });
    } else if (INGO_LIMIT_ERRORS_CODE.includes(error?.data?.Data?.status)) {
      transferErrorModal.setData({
        message: <Trans ns="moveMoney" i18nKey="transferPage.SorryForTheInconvenience" components={{ Link: <SLink onClick={openConsent} /> }} />,
        title: t(`TransactionLimitExceeded`),
        isIngoError: true,
      });
    } else {
      transferErrorModal.setData({
        message: t('SorryWeAreUnableToCompleteThisTransaction'),
        title: t('TransferIncomplete'),
        isIngoError: true,
      });
    }
    transferErrorModal.show();
  };

  const amountValidationRules: Rule[] = useMemo(
    () => [
      {
        required: true,
        message: t('transferPage.amount.required'),
      },
      () => ({
        validator: async (_, value) => {
          if (!isNumeric(value)) {
            throw new Error(t('transferPage.amount.number'));
          }
          if (Number(value) === 0) {
            throw new Error(t('transferPage.amount.zero'));
          }
          if (Number(value) < 0) {
            throw new Error(t('transferPage.amount.negative'));
          }
          if (isFromInternalAccount && value > fromAccount.balance) {
            throw new Error(t('transferPage.amount.balanceTooLow'));
          }
          if (limits && limits.max && value > limits.max) {
            throw new Error(t('transferPage.amount.maximum'));
          }
          if (limits && limits.min && value < limits?.min) {
            throw new Error(t('transferPage.amount.zero'));
          }
        },
      }),
    ],
    [t, fromAccount, limits, amount]
  );

  const handleSelectFrom = (account: IAccountItem | IThirdParty | null) => {
    if (account) {
      const accountId = isThirdPartyAccount(account) ? account.thirdPartyAccountId : account.fiservAccountId;
      setFromId(accountId);

      if (accountId === toId) {
        setToId('');
      }
    }
  };

  const handleSelectTo = (account: IAccountItem | IThirdParty | null) => {
    if (account) {
      const accountId = isThirdPartyAccount(account) ? account.thirdPartyAccountId : account.fiservAccountId;
      setToId(accountId);

      if (accountId === fromId) {
        setFromId('');
      }
    }
  };

  const onClickAmountValue = () => {
    setAmountEditable(false);
  };

  const handleAmountFieldBlur = () => {
    setAmountEditable(true);
  };

  const handleContinue = async () => {
    const details = {
      fromId: isFromExternalAccount ? fromAccount?.id || '' : fromAccount?.accountId,
      toId: isToExternalAccount ? toAccount?.id || '' : toAccount?.accountId,
      amount: `${amount}`,
      note,
      isExternalTransfer,
    };
    dispatch(setCurrentTransfer(details));

    if (isDesktopSize) {
      navigate(ROUTES.confirmTransfer.path);
    } else {
      if (isExternalTransfer) externalTransferConfirmModal.show();
      if (isInternalTransfer) internalTransferConfirmSheet.show();
    }
  };

  const onFieldsChange = () => {
    const noteVal = form.getFieldValue('noteValue');
    setNote(noteVal);
  };

  const handleNote = () => {
    const noteVal = form.getFieldValue('noteValue');
    setNote(noteVal);
    setIsNoteInputVisible(false);
  };

  const changeNote = () => {
    setIsNoteInputVisible(true);
  };
  const removeNote = () => {
    form.setFieldValue('noteValue', '');
    setNote('');
  };

  useEffect(() => {
    if (preSelectedAccountType !== undefined && isFirstRender.current) {
      const cashAccount = internalAccountsGroups[0].cash;
      const selectedToAccount = Object.values(internalAccountsGroups[0]).find((account) => account.type === preSelectedAccountType);
      if (cashAccount && selectedToAccount && isFirstRender.current) {
        handleSelectFrom(cashAccount);
        handleSelectTo(selectedToAccount);
        isFirstRender.current = false;
      }
    }
  }, []);

  useEffect(() => {
    if (isAmountTouched) {
      validateAmount();
    }
  }, [fromAccount, amount]);

  useEffect(() => {
    getThirdPartyData();
  }, [getThirdPartyData]);

  useEffect(() => {
    if (isMobileApp && (fromSelectPreopen || toSelectPreopen)) {
      refetchExternal();
    }
  }, [fromSelectPreopen, toSelectPreopen]);

  useEffect(() => {
    const hasJustAddedAccountFrom = currentTransfer?.fromId === 'JUST_ADDED';
    const hasJustAddedAccountTo = currentTransfer?.toId === 'JUST_ADDED';

    if ((!hasJustAddedAccountFrom && !hasJustAddedAccountTo) || !externalAccounts.length) {
      return;
    }

    const justAddedExternalId = externalAccounts[0].thirdPartyAccountId;
    const updatedTransferData = {
      ...currentTransfer,
      fromId: hasJustAddedAccountFrom ? justAddedExternalId : currentTransfer.fromId,
      toId: hasJustAddedAccountTo ? justAddedExternalId : currentTransfer.toId,
    };

    if (updatedTransferData.fromId) {
      setFromId(updatedTransferData.fromId);
    }

    if (updatedTransferData.toId) {
      setToId(updatedTransferData.toId);
    }

    if (updatedTransferData.amount) {
      form.setFieldsValue({ amount: updatedTransferData.amount });
    }

    clearCurrentTransfer();
  }, [currentTransfer, externalAccounts]);

  useEffect(() => {
    if (isFromCompanion || isToExternalAccount) {
      setToId('');
    }
  }, [isFromCompanion]);

  useEffect(() => {
    refetchInternal();
  }, [userLanguage]);

  return (
    <>
      {isDesktopSize && (
        <SWebHeader>
          <CustomRow justifyContent="flex-start" marginBottom={8}>
            <Icon name="arrowLeft" cursorPointer color="blue" onClick={() => navigate(ROUTES.moveMoney.path)} marginRight={8} />
            <Title font="Poppins" fontWeight="SB" size="S" color="charcoal">
              {t('transferPage.TransferBetweenAccounts')}
            </Title>
          </CustomRow>
          {fromDesktopSmall && (
            <BodyText className="description" textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5}>
              {t('transferPage.TransferPageDesctiption')}
            </BodyText>
          )}
        </SWebHeader>
      )}
      <SInternalTransferPage isDesktop={isDesktopSize}>
        {isLoading && <Loader />}

        {!isDesktopSize && <h2 className="transfer-header">{t('transferPage.TransferBetweenAccounts')}</h2>}

        <Form form={form} onFinish={handleContinue} onFieldsChange={onFieldsChange}>
          {!isDesktopSize && <header>{t('transferPage.formHeader')}</header>}
          <div className="form-box">
            <div className="transfer-selector">
              <div className="form-fields">
                <div className="account-selectors">
                  <div className="sender">
                    {!fromDesktopSmall && <div className="account-label">{t('common.From', { ns: 'translations' })}</div>}
                    <AccountSelect
                      internalAccounts={internalAccounts}
                      externalAccounts={externalAccounts}
                      onSelect={handleSelectFrom}
                      selected={fromAccount}
                      onAddNewAccount={handleAddNewAccountFrom}
                      isFrom
                      preopen={fromSelectPreopen}
                    />
                  </div>
                  <div className="recipient">
                    {!fromDesktopSmall && <div className="account-label">{t('common.To', { ns: 'translations' })}</div>}
                    <AccountSelect
                      internalAccounts={internalAccounts}
                      externalAccounts={externalAccounts}
                      fromAccount={fromAccount}
                      onSelect={handleSelectTo}
                      selected={toAccount}
                      onAddNewAccount={handleAddNewAccountTo}
                      hasSingleInternalAccount={hasSingleInternalAccount}
                      preopen={toSelectPreopen}
                    />
                  </div>
                  <div className="amount">
                    <CustomRow justifyContent={isDesktopSize ? 'flex-end' : 'space-between'}>
                      {!isDesktopSize && (
                        <div className="account-label">
                          {t('transferPage.EnterAmount')}
                          {hasAllLimits && <Icon color="blue" name="circleInfo" size="smallest" onClick={infoModal.show} cursorPointer marginLeft={8} />}
                        </div>
                      )}
                      {!fromDesktopSmall && isInternalTransfer && (
                        <TextButton onClick={addNoteSheet.show} className="note" iconName="comment" font={isDesktopSize ? 'Poppins' : 'DM Sans'} fontWeight={isDesktopSize ? 'SB' : 'R'}>
                          {note ? t('transferPage.EditNote') : t('transferPage.AddNote')}
                        </TextButton>
                      )}
                    </CustomRow>

                    <Form.Item className="amount-form-item" name="amount" validateTrigger={['onBlur', 'onChange', 'onFocus']} validateFirst rules={amountValidationRules}>
                      {isAmountEditable ? (
                        <div className="editable-custom-amount" onClick={onClickAmountValue}>
                          <CustomAmount
                            amount={Number(amount)}
                            color="charcoal"
                            size={isDesktopSize ? 'xl' : 'larger'}
                            remainingSize={isDesktopSize ? 'xs' : 'xl'}
                            remainingWeight={600}
                            multiSizable
                            isPoppins
                          />
                        </div>
                      ) : (
                        <div className="amount-input-component">
                          <AmountInputComponent
                            wrapperClassName={clsx('amount-input', !isAmountValid && 'error', form.isFieldTouched('amount') && 'touched')}
                            value={Number(amount) > 0 ? amount : ''}
                            onChange={handleAmountFieldChange}
                            noPrefix={isDesktopSize}
                            bottomText={amountBottomText}
                            autoFocus={!isAmountEditable}
                            onBlur={handleAmountFieldBlur}
                          />
                        </div>
                      )}
                      {isAmountEditable && (
                        <BodyText color="charcoal70" size="N" textType="helperText" fontWeight="R" marginTop={2} justifyContent="end">
                          {amountBottomText}
                        </BodyText>
                      )}
                      {fromDesktopSmall && hasAllLimits && isAmountEditable && (
                        <Icon color="blue" name="circleInfo" size="normal" onClick={infoModal.show} cursorPointer marginTop={4} marginLeft={8} />
                      )}
                    </Form.Item>

                    {fromDesktopSmall && (
                      <CustomRow justifyContent="flex-end" flexDirection="column" alignItems="flex-end" className="desktop-note">
                        {isNoteInputVisible && (
                          <Form.Item name="noteValue" initialValue={note} validateTrigger={['onBlur', 'onChange']} rules={[initialValueValidator(''), searchValidator()]}>
                            <BaseInput placeholder={t('transferPage.WriteYourNoteHere')} containerStyle={{ height: '104px', width: '100%' }} />
                          </Form.Item>
                        )}
                        <CustomRow justifyContent="flex-end" alignItems="center">
                          {note && !isNoteInputVisible && (
                            <Button type="button" preset="outline" onClick={changeNote} className="web-note" size="smaller">
                              {t('transferPage.EditNote')}
                              <Icon name="edit" color="blue" marginLeft={8} size="small" />
                            </Button>
                          )}
                          {isNoteInputVisible && (
                            <>
                              {note && (
                                <TextButton className="remove-note" fontWeight="SB" size="T" onClick={removeNote}>
                                  {t('transferPage.RemoveNote')}
                                </TextButton>
                              )}
                              <Button type="button" preset="outline" onClick={handleNote} className="web-note" size="smaller" disabled={!note}>
                                {t('transferPage.SaveNote')}
                              </Button>
                            </>
                          )}
                        </CustomRow>
                      </CustomRow>
                    )}

                    {!fromDesktopSmall && (
                      <CustomRow justifyContent={!fromDesktopSmall && policies?.ExternalTransferEnabled ? 'space-between' : 'flex-start'}>
                        <TextButton onClick={whenAvailableModal.show} className="when-available" font={isDesktopSize ? 'Poppins' : 'DM Sans'} fontWeight={!fromDesktopSmall ? 'SM' : 'R'}>
                          {t('transferPage.WhenAvailable')}
                        </TextButton>
                      </CustomRow>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isDesktopSize && (
              <CustomCard className="details-block" border="2px solid #F5F4F4" marginTop={0} marginLeft={54} width="40%" minWidth="230px">
                <BodyText size="L" fontWeight="B" marginBottom={27}>
                  {t('transferPage.TransferDetails')}
                </BodyText>

                <TransferDataRow title={t('externalTransfer.AmountTransferred')} value={Number(amount)} isAmount />
                <TransferDataRow title={t('externalTransfer.AccountFrom')} value={fromName || '-'} subvalue={fromNumber || ''} />
                <TransferDataRow title={t('externalTransfer.AccountTo')} value={toName || '-'} subvalue={toNumber || ''} />
                <TransferDataRow title={t('externalTransfer.Date')} value={dateStr} isLast />

                <CustomRow justifyContent="center" marginTop={42}>
                  <Button type="submit" preset="primary" disabled={isContinueDisabled} size="middleAlt">
                    {t('common.Continue', { ns: 'translations' })}
                  </Button>
                </CustomRow>
              </CustomCard>
            )}
          </div>
          <footer>
            <div className="buttons">
              <button type="submit" className="new primary" disabled={isContinueDisabled}>
                {t('common.Continue', { ns: 'translations' })}
              </button>
            </div>
          </footer>
        </Form>
      </SInternalTransferPage>
      <SuttonDisclaimerNote />

      <SPopupContainer className="popup-container">
        {isExternalTransfer && (
          <>
            <ExternalTransferConfirmSheet
              isOpen={externalTransferConfirmModal.isActive}
              onClose={externalTransferConfirmModal.hide}
              amountTransferred={Number(amount)}
              onError={handleTransferError}
              onSuccess={transferSuccessModal.show}
              fromAccount={fromAccount as IThirdParty | IAccountItem}
              toAccount={toAccount as IThirdParty | IAccountItem}
              isToExternal={isToExternalAccount}
              note={note}
            />
            <ExternalTransferSuccessSheet
              isOpen={transferSuccessModal.isActive}
              isDebit={isExternalAccountDebit}
              handleClose={transferSuccessModal.hide}
              fromAccount={fromAccount as IThirdParty}
              toAccount={toAccount as IThirdParty}
              amountTransferred={Number(amount)}
            />
          </>
        )}

        {isInternalTransfer && (
          <>
            <InternalTransferConfirmSheet
              isOpen={internalTransferConfirmSheet.isActive}
              fromAccount={fromAccount as IAccountItem}
              toAccount={toAccount as IAccountItem}
              note={note}
              transferAmount={Number(amount)}
              onClose={internalTransferConfirmSheet.hide}
              onSuccess={internalTransferSuccessSheet.show}
            />
            <InternalTransferSuccessSheet
              isOpen={internalTransferSuccessSheet.isActive}
              toAccount={toAccount}
              fromAccount={fromAccount}
              transferNote={note}
              transferAmount={Number(amount)}
              onClose={internalTransferSuccessSheet.hide}
            />
          </>
        )}

        <ManageExternalSheet
          handleOpen={manageExternalSheet.show}
          externalAccounts={externalAccounts}
          isOpen={manageExternalSheet.isActive}
          onClose={manageExternalSheet.hide}
          title={t('transferPage.ManageExternalAccounts')}
        />

        <TransferErrorModal
          isOpen={transferErrorModal.isActive}
          onClose={transferErrorModal.hide}
          handleCloseConfirmationModal={externalTransferConfirmModal.hide}
          errorData={transferErrorModal.data}
        />
        <AddNoteSheet isOpen={addNoteSheet.isActive} note={note} onClose={addNoteSheet.hide} handleNoteAdded={setNote} />
        <AmountInfoModal isModalVisible={infoModal.isActive} handleOnCancel={infoModal.hide} limits={limits} isFromExternalAccount={isFromExternalAccount} />
        <CustomModal open={whenAvailableModal.isActive} onClose={whenAvailableModal.hide} topPosition={isDesktopSize ? '0' : '25%'}>
          <Title color="charcoal" size="M" fontWeight="M" marginBottom={16} lineHeight={1.5} paddingRight={15}>
            {t('transferPage.WhenAvailable')}
          </Title>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="N" fontWeight="R" marginBottom={16} lineHeight={1.55} paddingRight={20}>
            {t(`transferPage.AvailableModalText1`)}
          </BodyText>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="N" fontWeight="R" marginBottom={16} lineHeight={1.5} paddingRight={20}>
            {t(`transferPage.AvailableModalText2`)}
          </BodyText>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="N" fontWeight="R" lineHeight={1.5} paddingRight={20}>
            {t(`transferPage.AvailableModalText3`)}
          </BodyText>
        </CustomModal>
      </SPopupContainer>
    </>
  );
};
