import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import clsx from 'clsx';
import { STransactionSign } from './PaymentIcon.styles';
import { IPaymentIcon, ITransactionCodeItem, TRANSACTION_CODES } from './transactionTypes';

export const PaymentIcon: React.FC<IPaymentIcon> = ({ trnCode, type }) => {
  const getIconProps = () => {
    let currentIconProps: ITransactionCodeItem = {
      icon: 'transaction_external',
      type,
      codes: [],
      description: '',
    };

    Object.keys(TRANSACTION_CODES).forEach((item: string) => {
      if (TRANSACTION_CODES[item].codes && TRANSACTION_CODES[item].codes.includes(trnCode)) {
        currentIconProps = TRANSACTION_CODES[item];
      }
    });

    return currentIconProps;
  };

  return (
    <STransactionSign className="transaction-sign">
      <div className="transaction-sign-internal">
        <Icon name={getIconProps().icon} size="normal" color="charcoal70" />
        <div className={clsx('arrow', getIconProps().type)} />
      </div>
    </STransactionSign>
  );
};
