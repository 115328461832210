import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const DoMorePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-bottom: 32px;

  .mobile-page-header {
    margin-bottom: 24px;

    .page-header {
      .custom-title-text {
        font-size: 20px;
      }
    }
  }

  .options-group {
    margin: 0 auto;
    max-width: 980px;

    .group-title {
      margin-bottom: 16px;

      .custom-title-text {
        font-size: 12px;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      gap: 16px;
    }

    .group-title {
      margin-bottom: 16px;
    }
  }

  .page-footer {
    padding: 0;

    .body-text {
      padding: 0;

      .custom-text-inner {
        font-size: 11px;
        line-height: 1.3;
      }
    }
  }

  // 428px
  ${mediaFrom(
    'mobile',
    css`
      padding: 16px 4px 40px 4px;

      .mobile-page-header {
        margin-bottom: 32px;

        .page-header {
          .custom-title-text {
            font-size: 28px;
          }
        }
      }

      .options-group {
        .group-title {
          margin-bottom: 20px;

          .custom-title-text {
            font-size: 16px;
          }
        }

        .options {
          gap: 20px;
          margin-bottom: 48px;
        }
      }

      .page-footer {
        .body-text {
          .custom-text-inner {
            font-size: 12px;
          }
        }
      }
    `
  )}

  // 768px
  ${mediaFrom(
    'tablet',
    css`
      padding: 40px 24px 52px;

      .do-more-page__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .desktop-page-header {
          width: 452px;
          border: none;
          padding: 0;
          margin-bottom: 32px;

          .custom-title-text {
            font-size: 28px;
          }
        }

        .options-group {
          display: flex;
          flex-direction: column;
          align-items: center;

          .group-title,
          .options {
            width: 452px;
          }
        }
      }
    `
  )};

  // 1200px
  ${mediaFrom(
    'desktop',
    css`
      padding: 30px 0 60px;

      .do-more-page__content {
        .desktop-page-header,
        .options-group {
          max-width: 100%;
          width: 100%;
          padding: 0 48px;
        }

        .desktop-page-header {
          border-bottom: 1px solid ${getColor('creamS5')};
          padding-bottom: 58px;
          margin-bottom: 90px;

          .title {
            .custom-title-text {
              font-size: 24px;
            }
          }
        }

        .options-group {
          align-items: center;

          .group-title,
          .options {
            width: 100%;
          }

          .group-title {
            margin-bottom: 24px;

            .custom-title-text {
              font-size: 20px;
            }
          }

          .options {
            flex-direction: row;
            gap: 25px;
            flex-wrap: wrap;
            margin-bottom: 50px;
          }
        }
      }

      .page-footer {
        padding: 0 250px 0 48px;
      }
    `
  )}

  // 1440px
  ${mediaFrom(
    'desktopLarge',
    css`
      padding-bottom: 78px;

      .do-more-page__content {
        .desktop-page-header {
          margin-bottom: 90px;
        }

        .options-group {
          width: 100%;
          padding: 0 48px;

          .group-title,
          .options {
            width: 980px;
          }

          .options {
            margin-bottom: 68px;
          }
        }
      }

      .page-footer {
        padding: 0 248px 0 48px;
      }
    `
  )}

    // 1900px
  ${mediaFrom(
    'desktopLarge',
    css`
      padding-bottom: 84px;

      .do-more-page__content {
        .desktop-page-header {
          padding: 0 128px 58px;
        }

        .options-group {
          padding: 0 128px;

          .group-title,
          .options {
            width: 992px;
          }

          .options {
            margin-bottom: 74px;
          }
        }
      }

      .page-footer {
        padding-left: 128px;
      }
    `
  )}
`;
