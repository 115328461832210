import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { mobileApiCall } from 'services/mobileService';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { lsGetItem } from 'utils/helpers/storage';
import { ROUTES } from 'vars/const/ROUTES';
import { Icon } from 'components/general/Icon/Icon';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { inviteH210 } from 'assets/images';
import { SLayout, SIconWrapper, SList } from './ChangeAddressPage.styles';

export const ChangeAddressPage = () => {
  const { t } = useTranslation('documents');
  const { isDesktopSize } = useDeviceDimension();
  const navigate = useNavigate();
  const isMobileApp = lsGetItem('isMobileApp');
  const location = useLocation();

  const handleUploadDocument = () => {
    if (isMobileApp) {
      mobileApiCall('documentUploadRequest', location.state.documentRequestId);
    } else {
      navigate(ROUTES.uploadDocuments.path, {
        state: location.state,
      });
    }
  };

  return (
    <SLayout>
      <div className="flex header">
        <Title>{t(`changeAddress.Title`)}</Title>

        {isDesktopSize ? (
          <Button type="button" size="middle" onClick={() => navigate(ROUTES.profile.path)}>
            {t('changeAddress.ExitAddressChanging')}
          </Button>
        ) : (
          <Icon name="exitFlow" marginRight={4} cursorPointer onClick={() => navigate(ROUTES.profile.path)} />
        )}
      </div>

      <div className="content-wrapper">
        <div className="center-image">
          <img src={inviteH210} alt="envelope" />
        </div>

        <BodyText textType="bodyText" font="Poppins" size="M" fontWeight="SB" className="subtitle">
          {t(`changeAddress.Subtitle`)}
        </BodyText>

        <div className="documents-container">
          <div className="document">
            <div className="flex document-header">
              <SIconWrapper>
                <Icon name="myInfo" color="white" size="normal" cursorPointer />
              </SIconWrapper>

              <BodyText textType="bodyText" className="document-title" size="N" fontWeight="B" lineHeight={1.4}>
                {t(`changeAddress.Unexpired`)}
              </BodyText>
            </div>

            <BodyText textType="bodyText" className="list-title" size="T" fontWeight="B" lineHeight="16px">
              {t(`changeAddress.AcceptableUS`)}
            </BodyText>

            <SList>
              <li>{t(`changeAddress.DriversLicense`)}</li>
              <li>{t(`changeAddress.Passport`)}</li>
              <li>{t(`changeAddress.StateID`)}</li>
              <li>{t(`changeAddress.PermanentResidence`)}</li>
            </SList>

            <BodyText textType="bodyText" className="list-title" size="T" fontWeight="B" lineHeight="16px">
              {t(`changeAddress.AcceptableNonUS`)}
            </BodyText>

            <SList>
              <li>{t(`changeAddress.Passport`)}</li>
            </SList>
          </div>

          <div className="document">
            <div className="flex document-header">
              <SIconWrapper>
                <Icon name="duplicate" color="white" size="normal" cursorPointer />
              </SIconWrapper>

              <BodyText textType="bodyText" className="document-title" size="N" fontWeight="B" lineHeight={1.4}>
                {t(`changeAddress.ProofMatchingAddressDocument`)}
              </BodyText>
            </div>

            <BodyText textType="bodyText" className="list-title" size="T" fontWeight="B" lineHeight="16px">
              {t(`changeAddress.AcceptableDocuments`)}
            </BodyText>

            <SList>
              <li>{t(`changeAddress.Paystub`)}</li>
              <li>{t(`changeAddress.UtilityBill`)}</li>
              <li>{t(`changeAddress.LeaseRentalAgreement`)}</li>
              <li>{t(`changeAddress.MortgageStatement`)}</li>
              <li>{t(`changeAddress.SchoolAdmissionsTranscript`)}</li>
              <li>{t(`changeAddress.BankStatement`)}</li>
              <li>{t(`changeAddress.InsuranceStatement`)}</li>
              <li>{t(`changeAddress.MotorVehicleRegistration`)}</li>
            </SList>
          </div>
        </div>

        <div className="button-panel">
          <Button preset="primary" className="button-upload" onClick={handleUploadDocument}>
            {t('documents.UploadDocuments')}
          </Button>
        </div>
      </div>

      {isDesktopSize && (
        <BodyText size="T" fontWeight="R" color="charcoal70" lineHeight={1.4} extraStyles={{ padding: '84px 260px 84px 128px' }}>
          {t('documents.PercapitaDesc')}
        </BodyText>
      )}
    </SLayout>
  );
};
