import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateDowntimeUpdateMutation } from 'store/user/users.api';
import { Title, BodyText } from 'components/general/Typography';
import { IconSign } from 'components/general/Icon/IconSign';
import { Button } from 'components/theme/Button/Button';
import { Loader } from 'components/general/Loader/Loader';
import { SSystemNotAvailable } from './SystemIsNotAvailable.styles';

export const SystemIsNotAvailable = () => {
  const { t } = useTranslation('home');

  const [createDowntimeUpdateAPI, { isLoading }] = useCreateDowntimeUpdateMutation();
  const [isLetMeKnowBtnDisabled, setIsLetMeKnowBtnDisabled] = useState(false);

  const handleOnClick = () => {
    createDowntimeUpdateAPI('Cleartouch');
    setIsLetMeKnowBtnDisabled(true);
  };

  return (
    <SSystemNotAvailable>
      {isLoading && <Loader />}
      <Title marginBottom={16}>{t('homeScreen.My Accounts')}</Title>
      <div className="content">
        <IconSign iconName="triangleWarning" bgColor="orange10" iconColor="goldOrange" />
        <div>
          <BodyText textType="bodyText" fontWeight="SB" color="charcoal" size="L" font="Poppins" marginBottom={7}>
            {t('homeScreen.UnavailableSystem')}
          </BodyText>
          <BodyText textType="bodyText" fontWeight="R" color="charcoal70" size="N">
            {t('homeScreen.TryAgainLater')}
          </BodyText>
          <div className="let-me-know">
            <Button preset="secondary" size="middle" disabled={isLetMeKnowBtnDisabled} onClick={handleOnClick}>
              {t('homeScreen.LetMeKnow')}
            </Button>
          </div>
        </div>
      </div>
    </SSystemNotAvailable>
  );
};
