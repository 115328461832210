import styled from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCustomSheet = styled(CustomSheet)`
  .additional-text {
    background: ${getColor('blue10')};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 32px;
  }

  .destination-list {
    margin-bottom: 32px;
  }

  .terms-link {
    text-decoration: underline;
    color: ${getColor('blue')};

    &:hover {
      text-decoration: none;
    }
  }
`;
