import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: -16px;

  ${mediaFrom(
    577,
    css`
      margin-right: -24px;
    `
  )}

  ${mediaFrom(
    'desktopLarge',
    css`
      margin-right: -14%;
    `
  )}

  .points-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    width: 237px;
    max-width: 100%;
    height: 55px;
    background: ${getColor('white')};
    padding-right: 18px;
    margin-top: -72px;

    ${mediaFrom(
      321,
      css`
        .custom-text-inner {
          font-size: 14px;
        }
      `
    )}

    .coin {
      margin-left: 10px;
    }
  }
`;
