export const COUNTRIES = [
  {
    id: 0,
    title: 'Colombia',
    name: 'colombia',
    code: 'co',
    iconName: 'flagCO',
  },
  {
    id: 1,
    title: 'Dominican Republic',
    name: 'dominican-republic',
    code: 'do',
    iconName: 'flagDO',
  },
  {
    id: 2,
    title: 'El Salvador',
    name: 'el-salvador',
    code: 'sv',
    iconName: 'flagSV',
  },
  {
    id: 3,
    title: 'Guatemala',
    name: 'guatemala',
    code: 'gt',
    iconName: 'flagGT',
  },
  {
    id: 4,
    title: 'Haiti',
    name: 'haiti',
    code: 'ht',
    iconName: 'flagHT',
  },
  {
    id: 5,
    title: 'Honduras',
    name: 'honduras',
    code: 'hn',
    iconName: 'flagHN',
  },
  {
    id: 6,
    title: 'Jamaica',
    name: 'jamaica',
    code: 'jm',
    iconName: 'flagJM',
  },
  {
    id: 7,
    title: 'Mexico',
    name: 'mexico',
    code: 'mx',
    iconName: 'flagMX',
  },
  {
    id: 8,
    title: 'Nigeria',
    name: 'nigeria',
    code: 'ng',
    iconName: 'flagNG',
  },
  {
    id: 9,
    title: 'Philippines',
    name: 'philippines',
    code: 'ph',
    iconName: 'flagPH',
  },
  {
    id: 10,
    title: 'Vietnam',
    name: 'vietnam',
    code: 'vn',
    iconName: 'flagVN',
  },
  {
    id: 11,
    title: 'Cuba',
    name: 'cuba',
    code: 'cu',
    iconName: 'flagCUTransparent',
  },
  {
    id: 12,
    title: 'Afghanistan',
    name: 'afghanistan',
    code: 'af',
    iconName: 'flagAF',
  },
  {
    id: 13,
    title: 'Aland Islands',
    name: 'aland-islands',
    code: 'ax',
    iconName: 'flagAX',
  },
  {
    id: 14,
    title: 'Albania',
    name: 'albania',
    code: 'al',
    iconName: 'flagAL',
  },
  {
    id: 15,
    title: 'Algeria',
    name: 'algeria',
    code: 'dz',
    iconName: 'flagDZ',
  },
  {
    id: 16,
    title: 'American Samoa',
    name: 'american-samoa',
    code: 'as',
    iconName: 'flagAS',
  },
  {
    id: 17,
    title: 'Andorra',
    name: 'andorra',
    code: 'ad',
    iconName: 'flagAD',
  },
  {
    id: 18,
    title: 'Abkhazia',
    name: 'abkhazia',
    code: 'ab',
    iconName: 'flagAB',
  },
  {
    id: 19,
    title: 'Costa Rica',
    name: 'costa-rica',
    code: 'cr',
    iconName: 'flagCR',
  },
  {
    id: 20,
    title: 'Peru',
    name: 'peru',
    code: 'pe',
    iconName: 'flagPE',
  },
];

export const COUNTRIES_AVAILABLE = COUNTRIES.filter((item) => ['co', 'cr', 'do', 'sv', 'gt', 'hn', 'jm', 'mx', 'pe', 'ph', 'vn'].includes(item.code));
export const COUNTRIES_COMING_SOON = COUNTRIES.filter((item) => item.code === 'cu');
export const COUNTRIES_FOR_REQUEST = COUNTRIES.filter((item) => ['af', 'ax', 'al', 'dz', 'as', 'ad', 'ab'].includes(item.code));
