import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useTransfers } from 'utils/hooks/useTransfers';
import { getAccountDisplayName, getAccountDisplayNumber } from 'utils/helpers/accounts/accountsHelper';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { IAccountItem, IThirdParty } from 'store/user/accounts/accounts.types';
import { format } from 'date-fns';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { FingerprintAndBehavioralData } from 'components/general/Modals/FundConfirmationModal/FingerprintAndBehavioralData/FingerprintAndBehavioralData';
import { Loader } from 'components/general/Loader/Loader';
import { TransferDataRow } from 'components/general/Modals/Transfer/ExternalTransfer/TransferDataRow/TransferDataRow';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useConsents } from 'utils/hooks/useConsents';
import { ConsentId, ConsentType } from 'components/general/Consent/Consents.types';
import { IConsentData } from 'store/user/consents/consents.types';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { useToggle } from 'utils/hooks/useToggle';
import { SLink } from './TransferPage.styles';

interface IExternalTransferConfirmSheetProps {
  fromAccount: IThirdParty | IAccountItem;
  toAccount: IThirdParty | IAccountItem;
  isToExternal?: boolean;
  isOpen?: boolean;
  onClose: () => void;
  amountTransferred: number;
  onSuccess: () => void;
  onError: (error: any) => void;
  note?: string;
}

export const ExternalTransferConfirmSheet = ({ isOpen, isToExternal, fromAccount, toAccount, amountTransferred, onClose, onSuccess, onError, note }: IExternalTransferConfirmSheetProps) => {
  const { t } = useTranslation('moveMoney');
  const { transferToExternal, transferFromExternal, updateRiskSession, riskSessionToken, riskSessionData, isTransferDataLoading } = useTransfers();
  const { isDesktopSize } = useDeviceDimension();
  const dateStr = useMemo(() => format(new Date(), 'MMM dd, yyyy'), [isOpen]);
  const otherConsents = useConsents(ConsentType.OTHERS);
  const consentData = otherConsents?.consentsData?.find((item) => item?.id === ConsentId.ESIGN_CONSENT) ?? ({} as IConsentData);
  const agreementSheet = useToggle();

  const handleConfirm = () => {
    const requestBody = {
      amount: amountTransferred,
      notes: note ? [note] : [],
      transactionId: uuidv4(),
      riskSessionToken,
    };
    const requestMethod = isToExternal ? transferToExternal : transferFromExternal;
    const externalAccount = (isToExternal ? toAccount : fromAccount) as IThirdParty;
    const internalAccount = (isToExternal ? fromAccount : toAccount) as IThirdParty;

    if (externalAccount) {
      requestMethod({
        ...requestBody,
        accountId: internalAccount.accountId,
        externalAccount: (externalAccount as IThirdParty).thirdPartyAccountId,
        accountType: (externalAccount as IThirdParty).thirdPartyAccountType,
      })
        .unwrap()
        .then(() => {
          onClose();
          onSuccess();
        })
        .catch((error) => {
          onClose();
          onError(error);
        });
    }
  };

  const handleClose = () => {
    if (!isTransferDataLoading) onClose();
  };

  useEffect(() => {
    if (isOpen) {
      updateRiskSession();
    }
  }, [isOpen]);

  return (
    <CustomSheet
      isModal={isDesktopSize}
      isOpen={isOpen}
      isLocked={isTransferDataLoading}
      onClose={handleClose}
      header={false}
      wrapperPadding={isDesktopSize}
      contentWrapperStyle={{ borderRadius: '24px 24px 0px 0px' }}
      modalName="externalTransferConfirm"
    >
      {isTransferDataLoading && <Loader />}
      <CustomRow justifyContent="flex-start" marginBottom={32}>
        <Icon name="arrowLeft" color="charcoal" cursorPointer onClick={handleClose} />
        <Title font="Poppins" color="charcoal" marginLeft={15} fontWeight="SB" size="S">
          {t('externalTransfer.ConfirmTransfer')}
        </Title>
      </CustomRow>
      <BodyText textType="bodyText" font="Poppins" justifyContent="start" fontWeight="SB" size="M" color="charcoal">
        {t('externalTransfer.ExternalTransferDetails')}
      </BodyText>
      <CustomCard border="2px solid #F5F4F4">
        <TransferDataRow title={t('externalTransfer.AmountTransferred')} value={amountTransferred} isAmount />
        <TransferDataRow title={t('externalTransfer.AccountFrom')} value={getAccountDisplayName(fromAccount)} subvalue={getAccountDisplayNumber(fromAccount)} />
        <TransferDataRow title={t('externalTransfer.AccountTo')} value={getAccountDisplayName(toAccount)} subvalue={getAccountDisplayNumber(toAccount)} />
        <TransferDataRow title={t('externalTransfer.Date')} value={dateStr} isLast />
      </CustomCard>

      <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" lineHeight="20px" marginTop={16}>
        <Trans i18nKey={t('externalTransfer.BySelectingConfirm')} ns="" components={{ Link: <SLink onClick={agreementSheet.show} /> }} />
      </BodyText>

      <CustomRow justifyContent="flex-end" marginTop={32} marginBottom={24}>
        <Button onClick={handleClose} marginRight={8} size="middleAlt">
          {t('externalTransfer.Cancel')}
        </Button>
        <Button preset="primary" onClick={handleConfirm} size="middleAlt">
          {t('externalTransfer.Confirm')}
        </Button>
      </CustomRow>

      {riskSessionData && <FingerprintAndBehavioralData data={riskSessionData} />}

      <SuttonDisclaimerNote />
      <ConsentSheet key={consentData?.disclaimerId} consentData={consentData} isOpen={agreementSheet.isActive} onClose={agreementSheet.hide} isReadonly isSheet={!isDesktopSize} />
    </CustomSheet>
  );
};
