import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES } from 'vars/const/ROUTES';
import { Icon } from 'components/general/Icon/Icon';
import { Title } from 'components/general/Typography';
import { IThirdParty } from 'store/user/accounts/accounts.types';
import { TextButton } from 'components/general/TextButton/TextButton';
import { Button } from 'components/theme/Button/Button';
import { ExternalAccount } from 'components/general/Accounts/External/ExternalAccount';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { EditAccountNameSheet } from './EditAccountNameSheet';
import { SManageExternalSheet } from './ManageExternalSheet.styles';
import { ConfirmRemoveModal } from './ConfirmRemoveModal';

type ITransferSelectorSheetProps = {
  isOpen: boolean;
  externalAccounts: IThirdParty[];
  onClose: () => void;
  handleOpen: () => void;
  title: string;
};

export const ManageExternalSheet = ({ isOpen, externalAccounts = [], onClose, handleOpen, title }: ITransferSelectorSheetProps) => {
  const { navigate } = useEnhancedNav();
  const { t } = useTranslation(['moveMoney'], { keyPrefix: 'manageExternalAccounts' });
  const editNameSheet = useToggle<{ account?: IThirdParty }>(false, {});
  const confirmRemovalModal = useToggle<{ accountId?: string }>(false, {});
  const { isDesktopSize } = useDeviceDimension();

  const handleAddNewAccount = () => {
    onClose();
    navigate(ROUTES.addExternalAccounts.path, { backUrl: 'current' });
  };

  const handleClickEditAccount = (account: IThirdParty) => {
    onClose();
    editNameSheet.setData({ account });
    editNameSheet.show();
  };

  const handleClickRemoveAccount = (account: IThirdParty) => {
    onClose();
    confirmRemovalModal.setData({ accountId: account.id });
    confirmRemovalModal.show();
  };

  useEffect(() => {
    // Updating editing account data in edit sheet on successful name change
    const updatedAccount = externalAccounts.find((account) => account.id === editNameSheet.data?.account?.id);
    if (updatedAccount) {
      editNameSheet.setData({ account: updatedAccount });
    }
  }, [externalAccounts]);

  return (
    <>
      <SManageExternalSheet isModal={isDesktopSize} isOpen={isOpen} modalName="manageExternal" header={false} wrapperPadding={isDesktopSize} onClose={onClose}>
        <div className="container">
          <div className="header">
            <Icon name="arrowLeft" color="charcoal" onClick={onClose} cursorPointer size="small" />
            <Title size="S" font="Poppins" color="charcoal" fontWeight="SB" marginLeft={15}>
              {title}
            </Title>
          </div>
          <div className="content">
            <div className="accounts">
              {externalAccounts.map((account) => (
                <ExternalAccount account={account} key={account.thirdPartyAccountId} onEdit={handleClickEditAccount} onRemove={handleClickRemoveAccount} />
              ))}
            </div>
            <TextButton iconName="circlePlus" className="add-new-account-btn" onClick={handleAddNewAccount}>
              {t('AddNewAccount')}
            </TextButton>
            <Button preset="primary" width={isDesktopSize ? 'fit-content' : '100%'} marginTop={isDesktopSize ? 32 : 68} onClick={onClose} marginLeft="auto" marginRight="auto">
              {t('DoneEditing')}
            </Button>
          </div>
        </div>
      </SManageExternalSheet>
      {editNameSheet.data?.account && <EditAccountNameSheet isOpen={editNameSheet.isActive} externalAccount={editNameSheet.data?.account} onClose={editNameSheet.hide} title={t('EditAccountName')} />}
      {confirmRemovalModal.data?.accountId && (
        <ConfirmRemoveModal isOpen={confirmRemovalModal.isActive} onClose={confirmRemovalModal.hide} accountId={confirmRemovalModal.data.accountId} openManageSheet={handleOpen} />
      )}
    </>
  );
};
