import React, { useState, useEffect } from 'react';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { SmsCodeForm } from 'components/general/SmsCodeForm/SmsCodeForm';
import { useProperties } from 'utils/hooks/useProperties';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { SModalContent, SActionButtons } from './ReferralCodeModal.styles';

interface IReferralCodeModal {
  open: boolean;
  onClose: () => void;
  handleOnYes: () => void;
  handleOnNo: () => void;
  handleCompletion: (code: string) => void;
  isClosable?: boolean;
  isError?: boolean;
}

export const ReferralCodeModal: React.FC<IReferralCodeModal> = ({ open, onClose, handleCompletion, handleOnYes, handleOnNo, isError, isClosable = true }) => {
  const { t } = useTranslation('cashAccountOpening', { keyPrefix: 'starter' });
  const { getProperty } = useProperties();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [refCode, setRefCode] = useState('');

  const getOnboardingPreferences = async () => {
    const onboardingPreferences = JSON.parse(await getProperty('OnboardingPreferences').unwrap());
    setRefCode(onboardingPreferences?.MyInfo?.referralCode);
  };

  useEffect(() => {
    if (open) {
      getOnboardingPreferences();
    }
  }, [open]);

  return (
    <CustomSheet isModal isOpen={open} onClose={onClose} closable={isClosable} modalName="referralCode" width="660px" padding="0" className="">
      <SModalContent>
        <Title font="Poppins" size="M" fontWeight="M" color="charcoal" marginTop={5} marginBottom={16}>
          {t('RefCodeTitle')}
        </Title>

        <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginBottom={48} lineHeight={1.4}>
          {t('RefCodeDescription')}
        </BodyText>

        <div className="form-container">
          <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" justifyContent="center" marginBottom={16} lineHeight={1.4}>
            {t('EnterCode')}
          </BodyText>
          <SmsCodeForm size={6} codeVal={refCode} boxSize="52px" handleCompletion={handleCompletion} onCompletion={setIsSubmitDisabled} isWrongCode={isError} isDisabledFocusOnStart />
          {isError && (
            <BodyText textType="bodyText" color="red" size="T" fontWeight="R" marginBottom={16} marginTop={8} lineHeight={1.4}>
              {t('PleaseTryAgain')}
            </BodyText>
          )}
        </div>
      </SModalContent>

      <SActionButtons>
        <Button preset="primary" onClick={handleOnYes} disabled={isSubmitDisabled}>
          {t('Continue')}
        </Button>
        <Button preset="secondary" onClick={handleOnNo}>
          {t('No')}
        </Button>
      </SActionButtons>
    </CustomSheet>
  );
};
