import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { BodyText, Title } from 'components/general/Typography';
import { SMediaLayout } from './AmountInfoModal.styles';

interface IInfoModalProps {
  isModalVisible?: boolean;
  handleOnCancel?: () => void;
  isFromExternalAccount: boolean;
  limits: {
    max?: number;
    min?: number;
    daily?: number;
    weekly?: number;
    monthly?: number;
  };
}

export const AmountInfoModal: React.FC<IInfoModalProps> = ({ isFromExternalAccount, limits, isModalVisible = false, handleOnCancel }) => {
  const { t } = useTranslation('moveMoney', { keyPrefix: 'transferPage' });
  const { isDesktopSize } = useDeviceDimension();
  return (
    <CustomModal open={isModalVisible} onCancel={handleOnCancel} modalName="amountInfo" topPosition="0" display="flex" closeIconColor="charcoal70">
      <SMediaLayout>
        <Title size={isDesktopSize ? 'XS' : 'M'} marginBottom={4}>
          {t(`EnterAmount`)}
        </Title>
        <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="N" fontWeight="R" marginBottom={5} lineHeight={1.5} paddingRight={15}>
          {isFromExternalAccount
            ? t('LimitsInfoFromExternal', {
                max: limits.max && new Intl.NumberFormat().format(limits.max),
                daily: limits.daily && new Intl.NumberFormat().format(limits.daily),
                weekly: limits.weekly && new Intl.NumberFormat().format(limits.weekly),
                monthly: limits.monthly && new Intl.NumberFormat().format(limits.monthly),
              })
            : t('LimitsInfoToExternal', {
                max: limits.max && new Intl.NumberFormat().format(limits.max),
                daily: limits.daily && new Intl.NumberFormat().format(limits.daily),
                weekly: limits.weekly && new Intl.NumberFormat().format(limits.weekly),
                monthly: limits.monthly && new Intl.NumberFormat().format(limits.monthly),
              })}
        </BodyText>

        {isDesktopSize && (
          <>
            <Title size="XS" marginBottom={4} marginTop={16}>
              {t(`WhenAvailable`)}
            </Title>
            <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="N" fontWeight="R" marginBottom={16} lineHeight={1.5} paddingRight={15}>
              {t(`AvailableModalText1`)}
            </BodyText>
            <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="N" fontWeight="R" marginBottom={16} lineHeight={1.5} paddingRight={15}>
              {t(`AvailableModalText2`)}
            </BodyText>
            <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="N" fontWeight="R" lineHeight={1.5} paddingRight={15}>
              {t(`AvailableModalText3`)}
            </BodyText>
          </>
        )}
      </SMediaLayout>
    </CustomModal>
  );
};
