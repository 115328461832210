import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { MaskedInput } from 'components/general/MaskedInput/MaskedInput';
import { Icon } from 'components/general/Icon/Icon';
import { SAmountSeparator } from 'components/general/BottomDrawers/FilterDrawer/FilterDrawer.styles';
import { format } from 'date-fns';
import { DayPickerComponent } from 'components/general/DayPicker/DayPickerComponent';
import { useLanguage } from 'utils/hooks/useLanguage';
import { SORT_VARIABLES } from 'components/general/BottomDrawers/FilterDrawer/constants';
import { RadioButton } from 'components/general/RadioButton/RadioButton';
import { Button } from 'components/theme/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateFilter,
  initialHistoryFilterState,
  onResetFilter,
  selectHistoryFilterData,
  setDateFrom,
  setDateTo,
  setDestinationAccount,
  setMaxAmount,
  setMinAmount,
  setSortBy,
} from 'store/historyFilter/historyFilter.slice';
import { TDates } from 'components/general/BottomDrawers/FilterDrawer/FilterDrawer.types';
import { TSortBy } from 'views/Account/BalancesTransactionsPage/BalancesTransactionsPage.types';
import { SCalendarContainer, SDateRangeContainer, SLayout } from './SideFilter.styles';
import { DestinationAccount } from './DestinationAccount/DestinationAccount';

const AMOUNT_MASK = '000000000000';
const AMOUNT_MASK_OPTIONS = {
  lazy: true,
  placeholderChar: ' ',
};

export const SideFilter: React.FC = () => {
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const { t } = useTranslation('percapitaPay');
  const [form] = useForm();
  const [isDatesTooltipVisible, setDatesTooltipVisible] = useState(false);
  const filterData = useSelector(selectHistoryFilterData);

  const onDateRangeChange = (dates: TDates) => {
    if (dates?.length === 2) {
      form.setFieldsValue({
        filterBy: {
          fromDate: dates[0],
          toDate: dates[1],
        },
      });
    }
  };

  const handleSortChanged = ({ id, title }: TSortBy) => {
    form.setFieldValue('sortBy', { id, title });
  };

  const onFilter = () => {
    dispatch(setDateFrom(form.getFieldValue(['filterBy', 'fromDate'])?.toISOString()));
    dispatch(setDateTo(form.getFieldValue(['filterBy', 'toDate'])?.toISOString()));
    dispatch(setMinAmount(form.getFieldValue(['filterBy', 'minAmount'])));
    dispatch(setMaxAmount(form.getFieldValue(['filterBy', 'maxAmount'])));
    dispatch(setSortBy(form.getFieldValue('sortBy')));
    dispatch(activateFilter());
  };

  const onReset = () => {
    form.resetFields();
    dispatch(setDestinationAccount({ id: 0, title: 'percapitaPayHome.Select Account', alias: '', iconName: '' }));
    dispatch(onResetFilter());
  };

  useEffect(() => {
    form.setFieldsValue(filterData);
  }, []);

  return (
    <SLayout>
      <Form name="historyFilterForm" form={form} initialValues={initialHistoryFilterState}>
        {/* Amount */}
        <Title size="sS" marginBottom={14}>
          {t('percapitaPayHome.Amount')}
        </Title>

        <CustomRow alignItems="center" marginBottom={34}>
          <Form.Item name={['filterBy', 'minAmount']} style={{ margin: 0 }}>
            <MaskedInput
              placeholder={t('percapitaPayHome.From', { ns: 'percapitaPay' })}
              suffix={<Icon name="dollarSign" color="charcoal" />}
              mask={AMOUNT_MASK}
              maskOptions={AMOUNT_MASK_OPTIONS}
              minWidth={120}
              isSuffixIcon
            />
          </Form.Item>

          <SAmountSeparator />

          <Form.Item name={['filterBy', 'maxAmount']} style={{ margin: 0 }}>
            <MaskedInput
              placeholder={t('percapitaPayHome.To', { ns: 'percapitaPay' })}
              suffix={<Icon name="dollarSign" color="charcoal" />}
              mask={AMOUNT_MASK}
              maskOptions={AMOUNT_MASK_OPTIONS}
              minWidth={120}
              isSuffixIcon
            />
          </Form.Item>
        </CustomRow>

        {/* Destination account */}
        <Title size="sS" marginBottom={14}>
          {t('percapitaPayHome.Destination Account')}
        </Title>
        <DestinationAccount />

        {/* Date Range */}
        <Title size="sS" marginBottom={14}>
          {t('percapitaPayHome.Date range')}
        </Title>

        <Form.Item name={['filterBy', 'fromDate']} hidden />
        <Form.Item name={['filterBy', 'toDate']} hidden />

        <Form.Item shouldUpdate style={{ marginBottom: 32 }}>
          {({ getFieldValue }) => {
            const fromDateValue = getFieldValue(['filterBy', 'fromDate']) ?? null;
            const toDateValue = getFieldValue(['filterBy', 'toDate']) ?? null;
            const fromDateTimestamp = fromDateValue?.valueOf() ?? '';
            const toDateTimestamp = toDateValue?.valueOf() ?? '';
            const fromDateString = fromDateTimestamp ? format(fromDateTimestamp, 'PP') : '';
            const toDateString = toDateTimestamp ? format(toDateTimestamp, 'PP') : '';
            const value = fromDateTimestamp && toDateTimestamp ? `${fromDateString} - ${toDateString}` : '';

            return (
              <SDateRangeContainer>
                <BaseInput
                  placeholder={`${t('percapitaPayHome.From')} - ${t('percapitaPayHome.To')}`}
                  value={value}
                  readOnly
                  onClick={() => setDatesTooltipVisible(!isDatesTooltipVisible)}
                  suffix={isDatesTooltipVisible ? 'collapse' : 'calendar'}
                  suffixColor="charcoal"
                />

                {isDatesTooltipVisible && (
                  <SCalendarContainer>
                    <DayPickerComponent locale={language} value={[fromDateValue, toDateValue]} range open onChange={onDateRangeChange} />
                  </SCalendarContainer>
                )}
              </SDateRangeContainer>
            );
          }}
        </Form.Item>

        <Title size="sS" marginBottom={14}>
          {t('balancesTransactions.SortByTitle')}
        </Title>

        {/* SortBy */}
        <Form.Item name="sortBy" hidden />

        <Form.Item shouldUpdate style={{ marginBottom: 20 }}>
          {({ getFieldValue }) => {
            const sortValue = getFieldValue('sortBy');

            return SORT_VARIABLES.map((item) => {
              const selected = item.id === sortValue.id;

              return (
                <CustomRow marginBottom={16}>
                  <RadioButton onChange={() => handleSortChanged(item)} checked={selected} key={item.id}>
                    <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="N" fontWeight={selected ? 'B' : 'R'} cursorPointer>
                      {t(item.title)}
                    </BodyText>
                  </RadioButton>
                </CustomRow>
              );
            });
          }}
        </Form.Item>

        <Form.Item>
          <Button size="middleStretch" type="submit" onClick={() => onFilter()} className="btn">
            {t('percapitaPayHome.Apply')}
          </Button>

          <Button preset="transparent" size="middleStretch" type="button" onClick={() => onReset()} className="btn reset">
            {t('percapitaPayHome.Reset')}
          </Button>
        </Form.Item>
      </Form>
    </SLayout>
  );
};
