import { RadioButton } from 'components/general/RadioButton/RadioButton';
import { Button } from 'components/theme/Button/Button';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import styled from 'styled-components';
import { MEDIA_SIZE, getColor, flex } from 'utils/helpers/styleHelpers';

export const SCustomButton = styled(Button)`
  border: 2px solid ${getColor('creamS10')};
  background-color: transparent;
  color: ${getColor('charcoal70')};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: auto;
  padding: 8px 16px;

  &.noBorder {
    border-color: transparent;
    padding: 0;
  }

  &.active {
    border-color: ${getColor('blue')};
    background-color: ${getColor('blue')};
    color: ${getColor('white')};
  }
`;

export const SDisclosure = styled.div`
  margin-top: auto;
  margin-bottom: 48px;
`;

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;

  .locations {
    margin-bottom: 48px;
  }

  .heading {
    @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
      margin: 0 -32px;
      padding: 0 48px 40px;
      border-bottom: 1px solid ${getColor('creamS10')};
    }
  }
`;

export const SContentWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
    max-width: 876px;
    margin: 0 auto 32px;
  }

  & .atm-location-tooltips {
    ${flex('column', 'flex-start', 'flex-start')};

    & .first-tooltip {
      position: relative;
      margin-bottom: 15px;

      & .btn__inner {
        white-space: normal;
        text-align: start;
        padding-left: 32px;

        & .icon-noFee {
          position: absolute;
          left: 20px;
        }
      }
    }
  }

  & .atm-location-tools {
    ${flex('row', 'flex-end', 'center')};
    width: 100%;

    & .btn {
      width: auto;
    }
  }
`;

export const SAddressCard = styled(CustomCard)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  margin-top: 48px;
  padding: 16px 40px;
`;

export const SCustomCard = styled(CustomCard)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${getColor('charcoal10')};

  .body-text {
    margin-right: auto;
    margin-left: 16px;
  }
`;

export const SRadioButton = styled(RadioButton)`
  display: block;
  margin-bottom: 24px;

  @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
    width: 100%;
    padding: 24px;
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 16px;
  }
`;

export const SFilterButton = styled(Button)`
  margin-top: 32px;

  @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
`;

export const SLocationIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;

  .location-btn {
    background: ${getColor('blue')};
    border-radius: 100px;
    padding: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
