import React, { useCallback, useState } from 'react';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { Icon } from 'components/general/Icon/Icon';
import { SSelectSheet } from './SelectSheet.styles';
import { IOption } from './SelectSheet.types';

export interface IBottomSheetProps {
  options: IOption[];
  open: boolean;
  onSelect: (value: string) => void;
  onClose: () => void;
}

export interface ISelectSheetProps {
  placeholder: string;
  onClick?: () => void;
  onSelect?: (value: string) => void;
  options: IOption[];
}

const BottomSheet = ({ options, open, onSelect, onClose }: IBottomSheetProps) => {
  return (
    <CustomSheet paddingTop="0px" hasCloseIcon={false} isOpen={open}>
      <div className="header">
        <button type="button" className="button-close">
          <Icon name="arrowLeft" onClick={onClose} />
        </button>
      </div>
      <div className="content">
        {options?.map(({ value, label }) => (
          <div className="select-option" onClick={() => onSelect(value)}>
            {label}
          </div>
        ))}
      </div>
    </CustomSheet>
  );
};

export const SelectSheet = ({ placeholder, onClick, onSelect, options }: ISelectSheetProps) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleOpen = useCallback(() => {
    onClick?.();
    setOpen(!open);
  }, [onClick]);

  const handleSelect = useCallback(
    (value: string) => {
      onSelect?.(value);
      setSelectedOption(value);
      setOpen(false);
    },
    [onSelect]
  );

  return (
    <SSelectSheet>
      <BottomSheet open={open} options={options} onSelect={handleSelect} onClose={() => setOpen(false)} />
      <div className="select-field">
        <BaseInput
          className="select-input"
          value={selectedOption}
          style={{ cursor: 'pointer' }}
          placeholder={placeholder}
          readOnly
          onClick={handleOpen}
          suffix="chevronDown"
          suffixColor="charcoal"
          suffixSize="smaller"
        />
      </div>
    </SSelectSheet>
  );
};
